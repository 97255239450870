@charset "UTF-8";
@import url("../vendors/fontawesome-pro/css/all.css");
@media (max-width: 479px) {
    .heading,
    .hp-navigation__box--content h2,
    .modal--new-game .modal__heading {
        font-size: 25px;
    }
}

@media (max-width: 991px) {
    .download__content--text h2,
    .heading-secondary h2 {
        font-size: 27px;
    }
}

.header__balance--top span,
.heading-modal,
.modal--change-fourth .modal__text h1,
.modal--change .modal__text p,
.remodal h2 {
    font-size: 20px;
}

.footer-mobile__item--count span,
.header__cart--count span,
.text-count {
    font-size: 12px;
}

.btn {
    border: 2px solid transparent;
    background: transparent;
    color: var(--iq-black);
    display: inline-block;
    position: relative;
    border-radius: 5px;
    padding: 15px 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    overflow: hidden;
    text-transform: uppercase;
}

.btn:focus {
    box-shadow: none;
}

.btn:disabled,
.btn:disabled:hover {
    background-color: var(--iq-dullgrey) !important;
    border: none;
}

.btn:disabled:hover {
    border-color: var(--iq-black);
}

.btn.disabled,
.btn.disabled:hover {
    background-color: var(--iq-dullgrey) !important;
    border: none;
}

.btn.disabled:hover {
    border-color: var(--iq-black);
}

.btn--primary {
    border-color: transparent;
    background-color: var(--iq-pink) !important;
    padding: 15px 20px 12px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
    color: var(--iq-white);
}

.btn--dark {
    border-color: transparent;
    background-color: var(--iq-pink);
    padding: 15px 5px 12px;
    color: var(--iq-white);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 34px;
}

.btn--yellow {
    border-color: transparent;
    background-color: var(--iq-yellow);
    padding: 15px 5px 12px;
    color: var(--iq-black);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 34px;
}

.btn--blue {
    border: 2px solid var(--iq-orange);
    background-color: transparent;
    padding: 15px 5px 12px;
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    color: var(--iq-white);
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 34px;
}

.btn--modal {
    text-transform: uppercase;
}

.btn--secondary {
    border-color: transparent;

    padding: 15px 15px 12px;
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
}

.btn--arrow {
    overflow: hidden;
}

.btn--arrow i {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 1;
}

.btn--arrow:hover i {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
    opacity: 0;
}

.btn--secondary-border {
    background-color: transparent;
    padding: 15px 20px 12px;
    color: var(--iq-orange);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
}

.btn--terciary {
    border-color: transparent;
    padding: 15px 20px 12px;
    color: var(--iq-white);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 40px;
}

.btn--white-border {
    border-color: var(--iq-white);
    background-color: transparent;
    padding: 15px 20px 12px;
    color: var(--iq-white);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
}

.btn--white-border:hover {
    border-color: var(--iq-yellow);
    color: var(--iq-yellow);
}

.btn--white {
    border-color: var(--iq-white);
    background-color: var(--iq-white);
    padding: 15px 20px 12px;
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
    border-radius: 15px;
}

.btn--filebtn {
    position: relative;
    cursor: pointer;
    width: 200px;
    height: 48px;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 479px) {
    .btn--filebtn {
        width: 100%;
        max-width: 100%;
    }
}

.btn--filebtn:hover:before {
    border-color: var(--iq-black);
}

.btn--filebtn:before {
    width: 200px;
    height: 48px;
    font-size: 17px;
    line-height: 32px;
    display: inline-block;
    background: var(--iq-light-pink) !important;
    border: 2px solid transparent;
    padding: 8px 10px;
    text-align: center;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: attr(data-title);
}

@media (max-width: 479px) {
    .btn--filebtn:before {
        width: 100%;
        max-width: 100%;
    }
}

.btn--filebtn input[type="file"] {
    position: absolute;
    right: 0;
    opacity: 0;
}

@media (max-width: 479px) {
    .btn--filebtn input[type="file"] {
        width: 100%;
        max-width: 100%;
    }
}

.page-rtl .btn--dark {
    padding: 15px 5px;
    color: var(--iq-white);
}

body {
    -webkit-font-smoothing: antialiased;
}

body.menu_lock {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
}

html {
    background-color: var(--iq-white);
    scroll-behavior: smooth;
}

p {
    margin-bottom: 0;
}

a {
    color: var(--iq-black);
}

a:hover {
    text-decoration: unset;
    color: unset;
}

* {
    outline: none;
}

.cookie__bar {
    display: block;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1266px;
    }
}

@media (max-width: 575px) {
    .dev_be_call_log_block,
    .sm-d-none-iq {
        display: none;
    }
}

#paymentiframe {
    border: none;
}

@media (max-width: 575px) {
    .main {
        padding-top: 75px;
    }
}

.main.blank {
    padding-top: 0;
}

.error-text p {
    font-size: 14px;
    color: var(--iq-darkred) !important;
    margin-top: 10px;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 28px;
    height: 28px;
    -webkit-box-flex: 0;
    flex: 0 0 28px;
    border-radius: 0;
    background-color: var(--iq-white);
    border: 1px solid var(--iq-dullgrey);
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
}

input[type="checkbox"]:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 6px;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: var(--iq-orange);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

input[type="checkbox"]:checked:after {
    opacity: 1;
}

input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
        [type="file"]
    ) {
    width: 100%;
    height: 50px;
    border: 1px solid var(--iq-orange);
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: 0 0;
    box-shadow: none;
    background-color: var(--iq-white);
    padding: 20px 20px 15px;
    font-size: 17px;

    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
        [type="file"]
    )
    :focus,
input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
        [type="file"]
    )
    :hover {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type="file"] {
    cursor: pointer;
    width: 200px;
    height: 48px;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

input[type="file"]:hover:before {
    border-color: var(--iq-black);
}

input[type="file"]:before {
    width: 200px;
    height: 48px;
    font-size: 17px;
    line-height: 32px;
    display: inline-block;
    background: #fce300;
    border: 2px solid transparent;
    padding: 8px 10px;
    text-align: center;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
}

textarea {
    color: var(--iq-darkgrey);
    width: 100%;
    height: 163px;
    resize: none;
    border: 1px solid var(--iq-dullgrey);
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--iq-white);
    box-shadow: none;
    padding: 20px;
    font-size: 17px;

    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::-ms-input-placeholder,
textarea::placeholder {
    color: var(--iq-darkgrey);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type="number"]:focus,
input[type="number"]:hover {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
}

button,
input,
select,
textarea {
    outline: none;
}

button:focus {
    outline: 0;
}

.header {
    background-color: var(--iq-white);
    margin-bottom: 30px;
    position: relative;
    z-index: 4;
    padding-bottom: 180px;
}

@media (max-width: 991px) {
    .header {
        padding-bottom: 0;
    }
}

@media (max-width: 575px) {
    .header {
        position: fixed;
        top: 0;
        width: 100%;
        margin-bottom: 0px;
        z-index: 100;
    }
}

.header.sticky .header__inner {
    padding: 15px 0;
}

.header.sticky .header__inner--logo {
    width: 240px;
    padding-bottom: 60px;
}

@media (max-width: 479px) {
    .header.sticky .header__inner--logo {
        width: 130px;
    }
}

.header.sticky .header__cart--icon {
    width: 45px;
    height: 45px;
}

.header.sticky .header__cart--count {
    width: 20px;
    height: 20px;
    top: -1px;
}

.header.sticky .header__cart--count span {
    font-size: 16px;
}

.header__inner {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 25px 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 479px) {
    .header__inner {
        padding: 5px 0;
    }
}

.header__inner--right {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 991px) {
    .header__inner--right.desktop {
        display: none;
    }
}

.header__inner--right.mobile {
    -webkit-box-align: center;
    align-items: center;
}

@media (min-width: 992px) {
    .header__inner--right.mobile {
        display: none;
    }
}

.header__inner--logo {
    width: 235px;
    padding-bottom: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 767px) {
    .header__inner--logo {
        width: 200px;
    }
}

@media (max-width: 479px) {
    .header__inner--logo {
        width: 130px;
        padding-bottom: 50px;
    }
}

.header__inner--logo img {
    max-width: 100%;
}

.header__login-menu {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.header__login-menu:after {
    content: "";
    height: 27px;
    width: 0.8px;
    background-color: var(--iq-black);
    display: block;
    margin: 0 35px 0 18px;
}

@media (max-width: 991px) {
    .header__login-menu:after {
        display: none;
    }
}

.header__login-menu a {
    color: var(--iq-darkgrey);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: left;
}

.header__login-menu a.highlighted,
.header__login-menu a:hover {
    color: var(--iq-orange);
}

.header__login-menu a:not(:last-child) {
    margin-right: 20px;
}

.header__login-menu.logged {
    position: relative;
}

.header__login-menu.logged ul {
    display: -webkit-box;
    display: flex;
    padding: 0;
    margin-bottom: 0;
}

.header__login-menu.logged ul li {
    list-style-type: none;
    position: relative;
}

.header__login-menu.logged ul li:not(:last-child) {
    margin-right: 14px;
}

.header__login-menu.logged > ul > li:last-child {
    margin-left: 5px;
    top: 10px;
}

.header__login-menu.logged ul li:nth-child(3):hover .header__dropdown.secondary,
.header__login-menu.logged ul li:nth-child(4):hover .header__dropdown.primary {
    display: block;
}

.header__login-menu.logged ul li.disabled > a {
    cursor: default;
}

.header__login-menu.logged ul li.disabled:hover > a {
    color: var(--iq-darkgrey);
}

.header__login-menu.logged ul li a {
    margin-right: 0 !important;
}

.header__login-menu.logged ul li a i,
.header__login-menu.logged ul li a strong {
    margin-left: 5px;
}

.header__login-menu.logged ul li a.img-wrapper {
    opacity: 0.4;
    pointer-events: none;
}
.header__login-menu--mobile {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--iq-white);
    width: 249px;
    height: 100%;
    box-shadow: 0 0 55px rgba(0, 0, 0, 0.15);
    z-index: 10;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translate(calc(100% + 10px));
    transform: translate(calc(100% + 10px));
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

@media (min-width: 992px) {
    .header__login-menu--mobile {
        display: none;
    }
}

.header__login-menu--mobile.active {
    -webkit-transform: none;
    transform: none;
}

.header__login-menu--mobile .nav-wrapper {
    position: fixed;
    top: 90px;
    bottom: 0;
    overflow: auto;
    width: 249px;
    max-width: 100%;
}

.header__login-menu--mobile nav {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow-y: scroll;
}

.header__login-menu--mobile nav ul {
    -webkit-box-align: start;
    align-items: flex-start;
    padding-left: 0;
    list-style-type: none;
}

.header__login-menu--mobile nav ul,
.header__login-menu--mobile nav ul li {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.header__login-menu--mobile nav ul li {
    width: 100%;
    max-width: 100%;
}

.header__login-menu--mobile nav ul li .accordion-top {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid var(--iq-dullgrey);
}

.header__login-menu--mobile nav ul li .accordion-top i {
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 5px;
}

.header__login-menu--mobile nav ul li .accordion-top.active {
    border-bottom: 1px solid var(--iq-black);
}

.header__login-menu--mobile nav ul li .accordion-top.active i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.header__login-menu--mobile nav ul li .accordion-top.accordion-logged {
    background-color: rgba(21, 168, 224, 0.12);
    border-color: var(--iq-white);
}

.header__login-menu--mobile nav ul li .accordion-top.countries {
    padding: 10px 20px;
}

.header__login-menu--mobile nav ul li .accordion-top.countries img {
    width: 32px;
    max-width: 100%;
    margin-right: 10px;
    margin-bottom: 5px;
}

.header__login-menu--mobile nav ul li .accordion-top.countries a {
    text-transform: uppercase;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: var(--iq-white);
}

.header__login-menu--mobile nav ul li .accordion-top.countries i {
    color: var(--iq-white);
}

.header__login-menu--mobile nav ul li .accordion-top.countries.active {
    border-bottom: 1px solid var(--iq-white);
}

.header__login-menu--mobile nav ul li .accordion-top.currency {
    padding: 10px 20px;
    background: hsla(0, 0%, 78.4%, 0.25);
}

.header__login-menu--mobile nav ul li.active ul {
    display: block;
}

.header__login-menu--mobile nav ul li ul {
    display: none;
}

.header__login-menu--mobile nav ul li ul li {
    padding: 15px 35px;
    border-bottom: 1px solid var(--iq-dullgrey);
}

.header__login-menu--mobile nav ul li ul .accordion-content li:last-child {
    border-bottom: 2px solid var(--iq-dullgrey);
}

.header__login-menu--mobile nav ul li ul.countries--list {
    background-color: #7bb8de;
}

.header__login-menu--mobile nav ul li ul.countries--list li {
    border-bottom: 1px solid var(--iq-white);
    padding: 10px 20px;
}

.header__login-menu--mobile nav ul li ul.countries--list li img {
    width: 32px;
    max-width: 100%;
    margin-right: 10px;
    margin-bottom: 5px;
}

.header__login-menu--mobile nav ul li ul.countries--list li a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: var(--iq-white);
}

.header__login-menu--mobile nav ul li ul.currency--list li {
    padding: 10px 20px;
}

.header__login-menu--mobile
    .accordion-nested
    .accordion-content
    .accordion-top {
    padding: 15px 20px 15px 35px;
}

.header__login-menu--mobile .accordion-nested .accordion-content ul li {
    padding: 15px 20px 15px 60px;
}

.header__login-menu--button {
    display: block;
    padding: 15px 20px;
    border-bottom: 1px solid var(--iq-dullgrey);
}

.header__login-menu--button.border-none {
    border: none;
}

.header__login-menu--button a {
    display: block;
    margin: 0;
    width: 100%;
    max-width: 100%;
    text-align: left;
}

.header__login-menu--button.primary {
    border-color: var(--iq-white);
}

.header__login-menu--block {
    display: block;
    padding: 15px 20px;
}

.header__login-menu--block p {
    font-size: 17px;
    color: var(--iq-darkblack);
}

.header__login-menu--welcome {
    position: absolute;
    top: -15px;
    color: var(--iq-darkgrey);
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
}

@media (max-width: 991px) {
    .header__login-menu--welcome {
        padding: 10px 20px;
        position: static;
        margin-right: 15px;
        font-size: 17px;
        width: auto;
    }
}

@media (max-width: 991px) {
    .header__login-menu--welcome:after {
        right: 15px;
    }
}

.header__dropdown {
    position: absolute;
}

.header__dropdown.primary {
    width: 260px;
    top: 20px;
    left: -150px;
    padding-top: 25px;
    display: none;
}

.header__dropdown.primary ul {
    padding: 0;
    list-style-type: none;
    position: relative;
    background-color: var(--iq-white);
    margin-bottom: 0;
    border-radius: 5px;
    z-index: 5;
    display: block;
    box-shadow: 0 -2px 16px rgba(0, 0, 0, 0.06);
}

.header__dropdown.primary ul img {
    position: absolute;
    width: 20px;
    right: 40px;
    top: -12px;
}

.header__dropdown.primary ul li {
    border-radius: 5px;
    margin-right: 0 !important;
}

/* .header__dropdown.primary ul li:first-of-type a {
border-bottom: 1px solid var(--iq-dullgrey);
border-radius: 5px 5px 0 0;
} */

.header__dropdown.primary ul li:last-child a {
    border-radius: 0 0 5px 5px;
}

.header__dropdown.primary ul li a {
    color: var(--iq-darkblack);
    padding: 13px 20px 10px;
    background-color: var(--iq-white);
    display: block;
    margin-right: 0 !important;
}

.header__dropdown.primary ul li a:hover {
    background-color: var(--iq-pink);
    color: var(--iq-white);
}

.header__dropdown.secondary {
    left: -292px;
    top: 12px;
    padding-top: 25px;
    display: none;
}

.header__balance {
    width: 400px;
    position: relative;
    z-index: 5;
    border: 1px solid var(--iq-dullgrey);
    border-radius: 5px;
}

.header__balance--top {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 20px 20px 15px;
    background-color: var(--iq-white);
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid var(--iq-dullgrey);
}

.header__balance--top p {
    color: var(--iq-darkblack);
}

.header__balance--item {
    background-color: var(--iq-white);
    padding: 15px 20px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.header__balance--item p {
    color: var(--iq-darkblack);
    margin-bottom: 10px;
}

.header__balance--item.last {
    border-top: 1px solid hsla(0, 0%, 76.9%, 0.28);
    border-radius: 0 0 5px 5px;
}

.header__balance--row {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.header__balance--row .btn {
    width: 210px;
    max-width: 100%;
    color: var(--iq-black);
}

.header__balance--row .btn:hover {
    color: var(--iq-black);
    border-color: var(--iq-pink);
}

.header__balance--box {
    width: 140px;
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: hsla(0, 0%, 78.4%, 0.25);
    border-radius: 5px;
}

.header__balance--box span {
    color: var(--iq-darkblack);
}

.header__wrapper {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: var(--iq-white);
    position: fixed;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
}

@media (max-width: 991px) {
    .header__wrapper {
        position: unset;
        width: unset;
        top: unset;
        left: unset;
        max-width: unset;
    }
}

.header__wrapper--nav {
    box-shadow: none;
    border-top: 1px solid var(--iq-dullgrey);
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop {
        display: none;
    }
}

.header__nav {
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.header__nav,
.header__nav--menu {
    display: -webkit-box;
    display: flex;
}

.header__nav--menu {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.header__nav--menu li {
    position: relative;
}

.header__nav--menu li:not(:last-child) {
    margin-right: 20px;
}

.header__nav--menu li:hover .header__nav--dropdown {
    display: block;
}

.header__nav--menu li:hover .header__nav--dropdown.secondary {
    display: none;
}

.header__nav--menu li:hover i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.header__nav--menu li:hover i.fa-chevron-right {
    -webkit-transform: none;
    transform: none;
}

.header__nav--menu li a {
    color: var(--iq-darkgrey);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.header__nav--menu li a i {
    color: var(--iq-pink);
    margin-left: 10px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.header__nav--dropdown {
    position: absolute;
    min-width: 234px;
    max-width: 100%;
    background-color: var(--iq-white);
    border-radius: 0 0 5px 5px;
    display: none;
    padding-top: 20px;
}

.header__nav--dropdown.secondary {
    right: -234px;
    top: 0;
    padding-top: 0;
}

.header__nav--dropdown.secondary ul li a {
    color: var(--iq-darkgrey) !important;
}

.header__nav--dropdown.secondary ul li a:hover {
    color: var(--iq-white) !important;
}

.header__nav--dropdown ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    border-top: 1px solid var(--iq-black);
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.header__nav--dropdown ul li {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.header__nav--dropdown ul li:not(:last-child) {
    margin: 0;
    border-bottom: 1px solid var(--iq-dullgrey);
}

.header__nav--dropdown ul li:last-child {
    border-radius: 0 0 5px 5px;
}

.header__nav--dropdown ul li:hover {
    background-color: var(--iq-pink);
}

.header__nav--dropdown ul li:hover a,
.header__nav--dropdown ul li:hover i {
    color: var(--iq-white);
}

.header__nav--dropdown ul li a {
    padding: 15px 15px 10px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    text-transform: none !important;
}

.header__nav--dropdown ul li:hover .header__nav--dropdown.secondary {
    display: block;
}

.header__nav--right button.bold-iq {
    font-family: "Swissra-Bold" !important;
    color: var(--iq-white);
}

.page-rtl .header__nav--right button.bold-iq {
    font-family: "Product-Sans-Bold" !important;
}
.header__currency a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #f1f1f1;
    padding: 8px;
    border-radius: 5px;
}

.header__currency a i {
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-left: 8px;
}

.header__currency a:hover {
    border-radius: 5px 5px 0 0;
}

.header__currency ul {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    list-style-type: none;
    display: none;
    position: absolute;
    top: 30px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #f1f1f1;
    width: 91px;
    border-radius: 0 0 5px 5px;
    margin-bottom: 0;
}

.header__currency ul::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.header__currency ul::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px hsla(0, 0%, 100%, 0.5);
}

.header__currency ul li a,
.header__currency ul li a:hover {
    border-radius: 0;
}

.header__countries {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.header__countries a {
    background-color: var(--iq-pink);
    border-radius: 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: var(--iq-white);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 147px;
    max-width: 100%;
    padding: 7px 12px;
    text-transform: uppercase;
}

.header__countries a.droplink {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.header__countries a.droplink img {
    margin: 0;
}

.header__countries a:hover {
    border-radius: 5px 5px 0 0;
}

.header__countries a img {
    max-width: 100%;
    width: 32px;
    margin-right: 8px;
}

.header__countries a i {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.header__countries ul {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    list-style-type: none;
    display: none;
    position: absolute;
    top: 30px;
    border-radius: 0 0 5px 5px;
    margin-bottom: 0;
    background-color: var(--iq-pink);
}

.header__countries ul li a,
.header__countries ul li a:hover {
    border-radius: 0;
}

.header__countries ul li:last-child a {
    border-radius: 0 0 5px 5px;
}

.header__cart {
    position: relative;
    border: 1px solid var(--iq-pink);
    border-radius: 5px;
    background-color: var(--iq-pink);
}

.header__cart--content {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.header__cart--icon {
    width: 50px;

    position: relative;
    border-radius: 50%;
}

.header__cart--count,
.header__cart--icon {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.header__cart--count {
    width: 23px;
    height: 23px;
    position: absolute;
    border-radius: 50%;
    top: -7px;
}

.header__cart--count span {
    color: var(--iq-white);
    margin-top: 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 17px;
}

.page-rtl .header__cart--info {
    padding: 4px 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.header__cart--info {
    background-color: var(--iq-white);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 1px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header__cart--info p {
    color: #232f3e;
    font-size: 13px;
}

.header__cart--icon img {
    margin-top: 10px;
}

.header__cart--info span {
    font-size: 12px;
    color: var(--iq-black);
}

.header__toggle {
    display: none;
    cursor: pointer;
    position: relative;
    z-index: 999999;
    float: right;
    height: 30px;
    top: 0;
    width: 30px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-left: 22px;
}

@media (max-width: 991px) {
    .header__toggle {
        display: block;
    }
    .header__nav--right li button.bold-iq {
        color: var(--iq-black);
        padding: 10px 0 0 20px;
        text-align: left;
        font-size: 17px;
    }
    .page-rtl .header__nav--right li button.bold-iq {
        padding: 10px 20px 0 0;
        text-align: right;
    }
}
.header__button a {
    color: var(--iq-white);
    border: 1px solid;
    background-color: var(--iq-light-pink);
    padding: 5px 10px;
    border-radius: 5px;
}

@media (max-width: 576px) {
    .header__toggle {
        height: 25px;
        width: 25px;
    }

    .header__button a {
        font-size: 12px;
    }
}

.header__toggle div {
    background: var(--iq-pink);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 2px;
    margin: 8px auto;
    width: 100%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.header__toggle.active div:first-child {
    -webkit-transform: rotate(45deg) translate(5px, 7px);
    transform: rotate(45deg) translate(5px, 7px);
}

.header__toggle.active div:last-child {
    -webkit-transform: rotate(-45deg) translateY(-2px);
    transform: rotate(-45deg) translateY(-2px);
}

.header__wrapper--nav.desktop .header__nav--menu .accordion-content li a {
    text-transform: none;
}

.accordion-content .countries--list li a {
    text-transform: uppercase;
}

.jumbotron {
    margin-bottom: 25px;
}

.footer {
    background-color: var(--iq-brown);
    position: relative;
}

.footer__nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 30px 0px;
    border-bottom: 1px solid var(--iq-pink);
}

@media (max-width: 991px) {
    .footer__nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 575px) {
    .footer__nav {
        padding: 15px 0 2px;
    }

    .footer__socials--platforms a img {
        height: 25px !important;
        width: 25px !important;
    }
}

.footer__nav--img {
    background-image: url(../img/mahzooz-logo-white.png);
    width: 127px;
    padding-bottom: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-right: 60px;
}

@media (max-width: 991px) {
    .footer__nav--img {
        margin: 0 0 20px;
    }
}

@media (max-width: 575px) {
    .footer__nav--img {
        margin: 0;
    }
}

.footer__nav--img img {
    max-width: 100%;
}

.footer__nav--text {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 991px) {
    .footer__nav--text {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        text-align: center;
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .footer__nav--text.desktop {
        display: none;
    }
}

.footer__nav--text.mobile {
    margin-bottom: 0;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--iq-pink);
}

@media (min-width: 576px) {
    .footer__nav--text.mobile {
        display: none;
    }
}

.footer__nav--text.mobile ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.footer__nav--text.mobile ul li:not(:last-child) {
    margin: 0 0 20px;
}

@media (max-width: 576px) {
    .footer__nav--text.mobile ul li:not(:last-child),
    .page-rtl .footer__nav--text.mobile ul li {
        margin: 0 20px 10px 0;
        position: relative;
    }

    .footer__nav--text.mobile ul li:not(:last-child):after,
    .page-rtl .footer__nav--text.mobile ul li:after {
        content: "";
        position: absolute;
        right: -10px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: var(--iq-white);
    }

    .page-rtl .footer__nav--text.mobile ul li:first-child:after {
        display: none;
    }

    .page-rtl .footer__nav--text.mobile ul li:first-child {
        margin: 0;
    }
}

@media (max-width: 575px) {
    .footer__nav--text.mobile ul li a {
        line-height: 1;
        font-size: 12px !important;
    }

    .grecaptcha-badge {
        bottom: 52px !important;
        box-shadow: none !important;
    }
}

@media (max-width: 575px) {
    .footer__nav--text.mobile ul {
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.footer__nav--text ul {
    display: -webkit-box;
    display: flex;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    margin-bottom: 0;
    list-style-type: none;
}

@media (max-width: 991px) {
    .footer__nav--text ul {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.footer__nav--text ul li {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.footer__nav--text ul li:not(:last-child) {
    margin-right: 22px;
}

.footer__nav--text ul li:hover a {
    color: var(--iq-orange);
}

.footer__nav--text ul li:hover a:after {
    opacity: 1;
}

@media (max-width: 575px) {
    .footer__nav--text ul li:hover a:after {
        opacity: 0;
    }
}

.footer__nav--text ul li a {
    color: var(--iq-white);
    text-transform: uppercase;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
}

.footer__socials {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 991px) {
    .footer__socials {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

@media (max-width: 575px) {
    .footer__socials {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
    }
}

.footer__socials p {
    text-transform: uppercase;
    color: var(--iq-white);
    margin-right: 20px;
}
.footer__socials p:last-child {
    margin-right: 0;
}
.page-rtl .footer__socials p:last-child {
    margin-left: 0;
}

@media (max-width: 575px) {
    .footer__socials p {
        display: block;
        margin: 0 0 8px;
    }
}

.footer__socials--item {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .footer__socials--item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 991px) {
    .footer__socials--item:first-of-type {
        margin-right: 15px;
    }
}

@media (max-width: 575px) {
    .footer__socials--item:first-of-type {
        margin-top: 5px;
        margin-right: 0;
    }
}

.footer__socials--platforms,
.footer__socials--platforms a {
    display: -webkit-box;
    display: flex;
}

.footer__socials--platforms a {
    width: 35px;
    height: 35px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--iq-white);
}

.footer__socials--platforms p:not(:last-child) {
    margin-right: 15px;
}

.footer__socials--platforms a i {
    color: var(--iq-white);
    font-size: 19px;
}

.footer__socials--platforms img {
    height: 19px;
}

.footer__bottom {
    padding: 30px 0;
    text-align: center;
}

@media (max-width: 767px) {
    .footer__bottom {
        padding: 30px 0;
    }
}

@media (max-width: 575px) {
    .footer__bottom {
        padding: 10px 0 5px;
    }
}

.footer__bottom p,
.footer__bottom p span {
    color: var(--iq-white);
    text-transform: uppercase;
    font-size: 17px;
}

@media (max-width: 575px) {
    .footer__bottom p,
    .footer__bottom p span {
        font-size: 12px;
    }
}

.remodal {
    padding: 75px 60px 60px;
    position: relative;
    border-radius: 5px;
}

@media only screen and (min-width: 641px) {
    .remodal {
        max-width: 541px;
    }
}

@media (max-width: 541px) {
    .remodal {
        padding: 55px 25px 25px;
    }
}

.remodal h2 {
    color: var(--iq-darkblack);
    margin-bottom: 30px;
}

@media (max-width: 575px) {
    .remodal h2 {
        font-size: 16px;
    }
}

.remodal .remodal-close {
    width: 50px;
    height: 50px;
    border: none;
    color: var(--iq-darkgrey);
    margin-bottom: 0;
    left: unset;
    right: 0;
    z-index: 3;
}

.remodal .remodal-close:hover i {
    color: var(--iq-orange);
}

.remodal .remodal-close i {
    font-size: 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.remodal .remodal-close:before {
    content: unset;
}

.remodal form .form-group {
    margin-bottom: 18px;
    text-align: left;
}

.remodal form .form-group label {
    line-height: normal;
    margin-bottom: 15px;
}

.remodal form .form-group label sup {
    color: #b61414;
}

.remodal form .form-group__buttons {
    display: -webkit-box;
    display: flex;
    margin-bottom: 0;
}

.remodal form .form-group__buttons .btn {
    width: 100%;
    margin-bottom: 0;
    height: 51px;
}

.remodal form .form-group__buttons .btn:first-child {
    margin-right: 10px;
}

.remodal form .form-group__buttons .btn--modal {
    margin-right: 0 !important;
    text-transform: uppercase;

    font-size: 20px;
    border-radius: 15px;
    height: 40px;
}

@media (max-width: 479px) {
    .remodal form .form-group__buttons .btn--modal {
        font-size: 18px;
        height: 30px;
    }
}

.remodal form .form-group__buttons a {
    text-decoration: none;
}

.remodal button {
    height: 48px;
    width: 100%;
}

.remodal textarea {
    margin-bottom: 27px;
}

.remodal p {
    color: var(--iq-black);
    font-size: 14px;
    margin-bottom: 0;
}

.remodal a {
    text-decoration: underline;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.remodal__numberlist {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

@media (max-width: 575px) {
    .remodal__numberlist {
        flex-wrap: nowrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.remodal__numberlist--number {
    height: 48px;
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    flex: 0 0 48%;
    width: 48%;
    background-color: #f6f6f6;
    border: 1px solid var(--iq-pink);
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 575px) {
    .remodal__numberlist--number {
        width: 100%;
        -webkit-box-flex: 0;
        flex: none;
        margin-right: 0;
    }
}

.remodal__numberlist--number.active {
    background-color: var(--iq-pink);
}

.remodal__numberlist--number.active p {
    color: var(--iq-white);
}

.remodal__numberlist--number:hover {
    background-color: var(--iq-pink);
}
.remodal__numberlist--number:hover p {
    color: var(--iq-white);
}

.remodal__numberlist--number:nth-child(2n) {
    margin-right: 0;
}

.remodal__numberlist--number:not(:last-child) {
    margin-bottom: 10px;
}

.remodal__numberlist--number p {
    color: var(--iq-darkblack);
    font-size: 14px;
    padding: 0 20px;
    margin-top: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.modal__heading.disabled {
    display: none;
}

.modal__img-big {
    max-width: 350px;
}

@media (max-width: 640px) {
    .modal__img-big {
        margin-bottom: 25px;
    }
}

@media (max-width: 479px) {
    .modal__img-big {
        max-width: 200px;
        margin-bottom: 20px;
    }
}

.modal__img-big img {
    max-width: 100%;
}

.modal--fav-numbers {
    padding-top: 70px;
}

.modal--fav-numbers .modal__heading {
    margin-bottom: 42px;
}

@media (max-width: 479px) {
    .modal--fav-numbers .modal__heading {
        margin-bottom: 20px;
    }
}

.modal--cart .modal__img {
    max-width: 154px;
    margin: 0 auto 18px;
}

.modal--cart .modal__img img {
    max-width: 100%;
}

.modal--cart h2 {
    margin-bottom: 25px;
}

.modal--cart .btn {
    margin-right: 0 !important;
}

.modal--cart-secondary .form-group__buttons {
    -webkit-box-pack: center;
    justify-content: center;
}

.modal--cart-secondary .form-group__buttons:not(:last-child) {
    margin-bottom: 10px;
}

.modal--cart-secondary .btn {
    max-width: 215px;
}

@media (max-width: 479px) {
    .modal--cart-secondary .btn {
        max-width: 100%;
    }
}

.modal--manage-account {
    padding: 50px 25px 60px;
}

@media only screen and (min-width: 641px) {
    .modal--manage-account {
        max-width: 633px;
    }
}

.modal--manage-account .modal__img {
    max-width: 64px;
    margin: 0 auto 18px;
}

.modal--manage-account .modal__img img {
    max-width: 100%;
}

.modal--manage-account h2 {
    margin-bottom: 12px;
}

.modal--manage-account p {
    color: var(--iq-darkgrey);
    margin-bottom: 25px;
}

.modal--manage-account form {
    max-width: 440px;
    margin: 0 auto;
}

@media only screen and (min-width: 641px) {
    .modal--favourite-numbers {
        max-width: 600px;
    }
}

.modal--favourite-numbers .modal__img {
    max-width: 92px;
    margin: 0 auto 20px;
}

.modal--favourite-numbers .modal__img img {
    max-width: 100%;
}

.modal--favourite-numbers a {
    width: 215px;
    max-width: 100%;
    margin: 0 auto;
    text-decoration: none;
}

.modal--verification {
    padding: 100px 30px 57px;
}

@media only screen and (min-width: 641px) {
    .modal--verification {
        max-width: 695px;
    }
}

@media (max-width: 575px) {
    .modal--verification {
        padding: 60px 23px 25px;
    }
}

.modal--verification p {
    text-align: center;
}

.modal--verification p:nth-child(2) {
    font-size: 17px;
    line-height: 30px;
    color: var(--iq-darkblack);
    margin-bottom: 15px;
}

@media (max-width: 575px) {
    .modal--verification p:nth-child(2) {
        font-size: 14px;
    }
}

.modal--verification p:nth-child(3) {
    font-size: 13px;
    line-height: 24px;
    color: var(--iq-darkgrey);
    margin-bottom: 30px;
}

@media (max-width: 575px) {
    .modal--verification p:nth-child(3) {
        font-size: 13px;
        margin-bottom: 20px;
    }
}

.modal--verification form .form-group__buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.modal--verification form .form-group__buttons a:hover {
    color: var(--iq-black);
}

.modal--verification form .form-group__buttons .btn--filebtn {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    height: 51px;
}

.modal--verification form .form-group__buttons .btn--filebtn:before {
    width: 100%;
    max-width: 100%;
    height: 51px;
}

.modal--verification
    form
    .form-group__buttons
    .btn--filebtn
    input[type="file"] {
    width: 100%;
    max-width: 100%;
}

.modal--rules {
    padding: 75px 120px 60px;
}

@media (max-width: 541px) {
    .modal--rules {
        padding: 55px 25px 25px;
    }
}

@media only screen and (min-width: 641px) {
    .modal--rules {
        max-width: 695px;
    }
}

.modal--rules .modal__heading {
    margin-bottom: 20px;
}

.modal--rules p {
    color: var(--iq-darkgrey);
    line-height: 30px;
    margin-bottom: 20px;
}

.modal--rules p a {
    color: var(--iq-pink);
    text-decoration: none;
}

.modal--rules .btn {
    margin-right: 0;
}

.modal--withdrawal {
    padding: 50px 25px 55px;
}

@media only screen and (min-width: 633px) {
    .modal--withdrawal {
        max-width: 633px;
    }
}

@media (max-width: 479px) {
    .modal--withdrawal {
        padding: 50px 25px 25px;
    }
}

.modal--withdrawal .modal__img {
    max-width: 64px;
    margin: 0 auto 18px;
}

.modal--withdrawal .modal__img img {
    max-width: 100%;
}

.modal--withdrawal p {
    color: var(--iq-darkgrey);
    margin-bottom: 25px;
}

.modal--withdrawal .form-group__buttons {
    max-width: 435px;
    margin: 0 auto;
}

@media (max-width: 479px) {
    .modal--withdrawal .form-group__buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 479px) {
    .modal--withdrawal .form-group__buttons .btn:first-child {
        margin-right: 0;
        margin-bottom: 5px;
    }
}

@media only screen and (min-width: 641px) {
    .modal--change {
        max-width: 800px;
    }
}

.modal--change .form-group__buttons a {
    text-decoration: none;
}

.modal--change .modal__inner {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 640px) {
    .modal--change .modal__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
    }
}

.modal--change .modal__img {
    max-width: 250px;
}

@media (max-width: 640px) {
    .modal--change .modal__img {
        margin-bottom: 25px;
    }
}

@media (max-width: 479px) {
    .modal--change .modal__img {
        max-width: 200px;
        margin-bottom: 20px;
    }
}

.modal--change .modal__img img {
    max-width: 100%;
}

.modal--change .modal__text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 385px;
}

.modal--change .modal__text p {
    color: var(--iq-pink);
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 25px;
}

@media (max-width: 479px) {
    .modal--change .modal__text p {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 15px;
    }
}

@media only screen and (min-width: 641px) {
    .modal--change-secondary {
        max-width: 1000px;
    }
}

.modal--change-secondary .modal__text {
    max-width: 545px;
}

@media (max-width: 479px) {
    .modal--change-secondary .modal__text p {
        font-size: 13px;
        line-height: 16px;
    }
}

.modal--change-secondary .form-group__buttons {
    width: 230px;
    max-width: 100%;
}

@media (max-width: 479px) {
    .modal--change-secondary .form-group__buttons {
        width: 155px;
    }
}

.modal--change-secondary .form-group__buttons button {
    border-radius: 10px;
}

@media (max-width: 479px) {
    .modal--change-secondary .form-group__buttons button {
        height: 33px;
    }
}

.modal--change-terciary .modal__text p {
    font-size: 35px;
    line-height: 47px;
}

@media (max-width: 479px) {
    .modal--change-terciary .modal__text p {
        font-size: 20px;
        line-height: 28px;
    }
}

.modal--change-terciary .modal__text form {
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.modal--change-terciary .modal__text form .form-group__buttons {
    width: 220px;
}

@media (max-width: 479px) {
    .modal--change-terciary .modal__text form .form-group__buttons {
        width: 170px;
    }
}

.modal--change-terciary .modal__text form .form-group__buttons .btn--modal {
    font-size: 30px;
    height: 48px;
    padding: 20px 20px 12px;
}

@media (max-width: 480px) {
    .modal--change-terciary .modal__text form .form-group__buttons .btn--modal {
        font-size: 20px;
        height: 35px;
        padding: 18px 20px 12px;
    }
}

.modal--change-fourth .modal__text {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: left;
}

.modal--change-fourth .modal__text h1 {
    font-size: 35px;
    line-height: unset;
    margin-bottom: 0;
    color: var(--iq-pink);
}

@media (max-width: 479px) {
    .modal--change-fourth .modal__text h1 {
        font-size: 20px;
        line-height: 28px;
    }
}

.modal--change-fourth .modal__text p {
    font-size: 17px;
}

@media (max-width: 479px) {
    .modal--change-fourth .modal__text p {
        font-size: 12px;
    }
}

.modal--change-fourth .modal__text p:nth-child(2) {
    margin-bottom: 0;
}

.modal--change-fourth .modal__text ul {
    padding-left: 17px;
    max-width: 345px;
}

@media (max-width: 479px) {
    .modal--change-fourth .modal__text ul {
        padding-left: 14px;
    }
}

.modal--change-fourth .modal__text ul li {
    font-size: 17px;
    color: var(--iq-pink);
}

@media (max-width: 479px) {
    .modal--change-fourth .modal__text ul li {
        font-size: 13px;
    }
}

.modal--change-fourth .modal__text .form-group__buttons a {
    width: 195px;
    border-radius: 10px;
    height: 45px;
}

.modal--change-fourth .modal__text .form-group__buttons p {
    color: var(--iq-black);
    font-size: 14px;
}

.modal--find {
    padding: 40px 30px 25px;
}

@media only screen and (min-width: 641px) {
    .modal--find {
        max-width: 620px;
    }
}

.modal--find iframe {
    max-width: 100%;
}

.modal--purchase {
    padding: 25px 20px;
    border-radius: 15px;
    border: 1px solid var(--iq-pink);
    max-width: 600px;
}

@media (max-width: 479px) {
    .modal--purchase {
        padding: 20px 10px;
    }
}

.modal--purchase .modal__heading {
    color: #3f3f3f;
    margin-bottom: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #ededed;
}

.modal--purchase .modal__purchase--row {
    display: -webkit-box;
    display: flex;
    width: 100%;
    max-width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ededed;
}

@media (max-width: 479px) {
    .modal--purchase .modal__purchase--row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.modal--purchase .modal__purchase--numbers {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    margin-right: 10px;
}

.modal--purchase .modal__purchase--numbers-row {
    display: -webkit-box;
    display: flex;
}

.modal--purchase .modal__purchase--info {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.modal--purchase .modal__purchase--text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    width: 190px;
    max-width: 100%;
    align-items: flex-start;
    text-align: left;
}

.modal--purchase .modal__purchase--text p {
    color: #3f3f3f;
    font-size: 14px;
}

.modal--purchase .modal__purchase--price p {
    color: var(--iq-black);
}

@media (max-width: 479px) {
    .modal--purchase .modal__purchase--price p {
        font-size: 14px;
    }
}

.modal--purchase .modal__purchase--total {
    display: -webkit-box;
    display: flex;
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;
}

@media (max-width: 479px) {
    .modal--purchase .modal__purchase--total {
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

.modal--purchase .modal__purchase--total p {
    font-size: 17px;
}

@media (max-width: 479px) {
    .modal--purchase .modal__purchase--total p {
        font-size: 14px;
    }
}

.modal--purchase .modal__purchase--total p:nth-child(3) {
    position: absolute;
    right: 0;
}

@media (max-width: 479px) {
    .modal--purchase .modal__purchase--total p:nth-child(3) {
        position: unset;
        right: unset;
    }
}

.modal--purchase .modal__purchase--bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.modal--purchase .modal__purchase--bottom p {
    color: #3f3f3f;
    max-width: 273px;
    margin-bottom: 30px;
    font-size: 13px;
}

@media (max-width: 479px) {
    .modal--purchase .modal__purchase--bottom p {
        margin-bottom: 20px;
    }
}

.modal--purchase .modal__purchase--bottom .modal__purchase--button {
    width: 130px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.modal--purchase .modal__purchase--bottom .modal__purchase--button a.btn {
    text-decoration: none;
    height: 30px;
}

.modal--purchase .modal__purchase--spacediv {
    width: 215px;
}

@media (max-width: 479px) {
    .modal--purchase .modal__purchase--spacediv {
        display: none;
    }
}

.modal--purchase.modal--purchase-details-confirm {
    max-width: 500px;
}

.modal--purchase.modal--purchase-details-confirm .modal__purchase--info {
    width: unset;
}

.modal--purchase.modal--purchase-details-confirm .modal__purchase--row {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.modal--purchase.modal--purchase-details-confirm .modal__purchase--bottom {
    margin-top: 10px;
}

.modal--purchase.modal--purchase-details-confirm .modal__purchase--button {
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.modal--purchase.modal--purchase-details-confirm
    .modal__purchase--button
    a.btn {
    width: 130px;
    max-width: 100%;
}

.modal--purchase.modal--purchase-details-confirm
    .modal__purchase--button
    button.btn {
    width: 130px;
    height: 30px;
}

.modal--purchase.modal--purchase-details-confirm-balance {
    max-width: 540px;
}

.modal--purchase.modal--purchase-details-confirm-balance
    .modal__purchase--info {
    width: unset;
}

.modal--purchase.modal--purchase-details-confirm-balance .modal__purchase--row {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.modal--purchase.modal--purchase-details-confirm-balance
    .modal__purchase--bottom {
    margin-top: 10px;
}

.modal--purchase.modal--purchase-details-confirm-balance
    .modal__purchase--bottom
    p {
    max-width: 100%;
}

@media (max-width: 479px) {
    .modal--purchase.modal--purchase-details-confirm-balance
        .modal__purchase--bottom
        p:nth-child(2) {
        font-size: 14px;
    }
}

.modal--purchase.modal--purchase-details-confirm-balance
    .modal__purchase--button {
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.modal--purchase.modal--purchase-details-confirm-balance
    .modal__purchase--button
    a.btn {
    width: 130px;
    max-width: 100%;
}

.modal--purchase.modal--purchase-details-confirm-balance
    .modal__purchase--button
    button.btn {
    width: 130px;
    height: 30px;
}

.modal--purchase.modal--purchase-info .remodal-close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--iq-pink);
    color: var(--iq-white);
    right: -20px;
    top: -20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 575px) {
    .modal--purchase.modal--purchase-info .remodal-close {
        right: -5px;
    }
}

.modal--purchase.modal--purchase-info .remodal-close:hover i {
    color: var(--iq-white);
}

.modal--purchase.modal--purchase-info .modal__purchase-info {
    max-width: 395px;
    margin: 0 auto;
}

.modal--purchase.modal--purchase-info .modal__purchase-info p {
    color: var(--iq-pink);
    font-size: 14px;
}

@media (max-width: 575px) {
    .modal--purchase.modal--purchase-info .modal__purchase-info p {
        font-size: 14px;
    }
}

.modal--purchase.modal--purchase-info .modal__purchase-info p:not(:last-child) {
    margin-bottom: 20px;
}

@media (max-width: 575px) {
    .modal--purchase.modal--purchase-info
        .modal__purchase-info
        p:not(:last-child) {
        margin-bottom: 10px;
    }
}

.modal--purchase.modal--purchase-info .modal__purchase-info span {
    font-size: 14px;
    color: var(--iq-pink);
}

.modal--purchase.modal--purchase-info .modal__purchase-info a {
    font-size: 14px;
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.modal--purchase.modal--purchase-info .modal__purchase-info a:hover {
    color: #7bb8de;
}

.modal--purchase.modal--cart-delete {
    padding: 15px 20px 10px;
    max-width: 450px;
}

.modal--purchase.modal--cart-delete p {
    color: #3f3f3f;
    max-width: 325px;
    margin: 0 auto 10px;
}

.modal--purchase.modal--cart-delete a {
    height: 30px;
    width: 100px;
    text-decoration: none;
    margin: 0 auto;
    padding: 12px 20px;
}

.modal--purchase.modal--purchase-delete {
    max-width: 460px;
    padding: 20px;
}

.modal--purchase.modal--purchase-delete p {
    color: #3f3f3f;
    margin-bottom: 30px;
}

@media (max-width: 479px) {
    .modal--purchase.modal--purchase-delete p {
        font-size: 14px;
        margin-bottom: 20px;
    }
}

.modal--purchase.modal--purchase-delete form .form-group__buttons {
    max-width: 260px;
    margin: 0 auto;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.modal--purchase.modal--purchase-delete form .form-group__buttons a {
    width: 100px;
    height: 30px;
}

.modal--numberpicker {
    padding: 25px 20px;
    border-radius: 15px;
    border: 1px solid var(--iq-pink);
    max-width: 625px;
}

@media (max-width: 479px) {
    .modal--numberpicker {
        padding: 20px 10px;
    }
}

.modal--numberpicker .modal__heading {
    color: #3f3f3f;
    margin-bottom: 20px;
}

.modal--numberpicker .modal__numberpicker--numberpicker {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 30px;
}

@media (max-width: 479px) {
    .modal--numberpicker .modal__numberpicker--numberpicker {
        margin-bottom: 10px;
    }
}

.modal--numberpicker .modal__numberpicker--numberpicker .number-picker__picker {
    margin: 0 0 10px;
    width: 475px;
}

.modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers {
    max-width: 310px;
    margin: 0 auto;
    padding: 30px 20px;
}

@media (max-width: 575px) {
    .modal--numberpicker
        .modal__numberpicker--numberpicker
        .number-picker__numbers {
        width: 315px;
    }
}

@media (max-width: 380px) {
    .modal--numberpicker
        .modal__numberpicker--numberpicker
        .number-picker__numbers {
        max-width: 100%;
        width: 240px;
    }
}

.modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers
    .betNum:nth-child(5n) {
    margin: 0 7px 7px 0;
}

@media (max-width: 380px) {
    .modal--numberpicker
        .modal__numberpicker--numberpicker
        .number-picker__numbers
        .betNum:nth-child(5n) {
        margin-right: 0;
    }
}

.modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers
    .betNum:nth-child(7n) {
    margin-right: 0;
}

@media (max-width: 380px) {
    .modal--numberpicker
        .modal__numberpicker--numberpicker
        .number-picker__numbers
        .betNum:nth-child(7n) {
        margin: 0 7px 7px 0;
    }
}

.modal--numberpicker .modal__numberpicker--button {
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.modal--numberpicker .modal__numberpicker--button a.btn {
    width: 250px;
    max-width: 100%;
    height: 30px;
    text-decoration: none;
}

@media (max-width: 575px) {
    .modal--numberpicker .modal__numberpicker--button a.btn {
        width: 130px;
    }
}

.modal--numberpicker .modal__numberpicker--button button.btn {
    width: 250px;
    height: 30px;
}

@media (max-width: 575px) {
    .modal--numberpicker .modal__numberpicker--button button.btn {
        width: 130px;
    }
}

.modal--numberpicker .modal__selectboxes {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    margin-bottom: 30px;
}

@media (max-width: 575px) {
    .modal--numberpicker .modal__selectboxes {
        margin-bottom: 10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.modal--numberpicker .modal__selectboxes--select {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
}

.modal--numberpicker .modal__selectboxes--select:not(:last-child) {
    margin-right: 10px;
}

@media (max-width: 575px) {
    .modal--numberpicker .modal__selectboxes--select:not(:last-child) {
        margin-right: 0;
    }
}

.modal--numberpicker .modal__selectboxes--select .selectize .selectize-input {
    font-size: 12px !important;
    height: 40px;
    text-align: left;
}

.modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input:after {
    line-height: 43px;
}

.modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.dropdown-active
    input {
    top: -7px;
}

.modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.dropdown-active:after {
    margin-top: -3px;
}

.modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-dropdown {
    font-size: 12px !important;
}

.modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-dropdown
    .option {
    text-align: left;
}

.modal--numberpicker .modal__selectboxes--select .selectize .selectize-control {
    height: 40px;
}

.modal--numberpicker .modal__selectboxes--select .selectize input,
.modal--numberpicker
    .modal__selectboxes--select
    .selectize
    input::-webkit-input-placeholder {
    font-size: 12px !important;
}

.modal--important-update {
    background-color: #7bb8de;
}

@media only screen and (min-width: 641px) {
    .modal--important-update {
        max-width: 560px;
    }
}

.modal--important-update h2,
.modal--important-update p {
    color: var(--iq-white);
}

.modal--important-update p:last-of-type {
    margin-bottom: 40px;
}

@media (max-width: 479px) {
    .modal--important-update p:last-of-type {
        margin-bottom: 20px;
    }
}

.modal--important-update .remodal-close i {
    color: var(--iq-white);
}

.modal--important-update .form-group__buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.modal--important-update .form-group__buttons a {
    text-decoration: none;
    width: 230px;
}

@media (max-width: 479px) {
    .modal--important-update .form-group__buttons a {
        width: 100%;
    }
}

.modal--new-game {
    max-width: 700px;
    padding: 30px 60px;
    overflow: hidden;
}

@media (max-width: 400px) {
    .modal--new-game {
        padding: 30px 40px;
    }
}

.modal--new-game .modal__heading {
    margin-bottom: 10px;
    font-size: 38px;
}

@media (max-width: 575px) {
    .modal--new-game .modal__heading {
        font-size: 33px;
    }
}

@media (max-width: 479px) {
    .modal--new-game .modal__heading {
        font-size: 21px;
    }
}

@media (max-width: 360px) {
    .modal--new-game .modal__heading {
        font-size: 17px;
    }
}

.modal--new-game .modal__boxes {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin-bottom: 60px;
    }
}

@media (max-width: 360px) {
    .modal--new-game .modal__boxes {
        margin-bottom: 35px;
    }
}

.modal--new-game .modal__boxes--box {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 15px;
    z-index: 1;
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes--box {
        height: 184px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.modal--new-game .modal__boxes--box h3 {
    margin-bottom: 10px;
    font-size: 17px;
}

@media (max-width: 479px) {
    .modal--new-game .modal__boxes--box h3 {
        margin-bottom: 0;
    }
}

@media (max-width: 360px) {
    .modal--new-game .modal__boxes--box h3 {
        font-size: 13px;
    }
}

.modal--new-game .modal__boxes--box p {
    font-size: 12px;
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes--box p {
        font-size: 17px;
    }
}

@media (max-width: 479px) {
    .modal--new-game .modal__boxes--box p {
        font-size: 14px;
    }
}

@media (max-width: 360px) {
    .modal--new-game .modal__boxes--box p {
        font-size: 12px;
    }
}

.modal--new-game .modal__boxes--box p strong {
    font-size: 22px;
    line-height: 25px;
}

@media (max-width: 479px) {
    .modal--new-game .modal__boxes--box p strong {
        font-size: 18px;
    }
}

@media (max-width: 360px) {
    .modal--new-game .modal__boxes--box p strong {
        font-size: 14px;
        line-height: 18px;
    }
}

.modal--new-game .modal__boxes--box p:nth-child(2) {
    margin-bottom: 10px;
}

@media (max-width: 479px) {
    .modal--new-game .modal__boxes--box p:nth-child(2) {
        margin-bottom: 5px;
    }
}

.modal--new-game .modal__boxes--saturday {
    background-color: #f9f288;
    padding: 20px;
    max-width: 320px;
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes--saturday {
        max-width: 100%;
    }
}

.modal--new-game .modal__boxes--saturday h3 {
    color: var(--iq-pink);
}

.modal--new-game .modal__boxes--saturday p:nth-child(2) {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes--saturday p:nth-child(2) {
        max-width: 280px;
    }
}

.modal--new-game .modal__boxes--saturday p:last-child {
    max-width: 190px;
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes--saturday p:last-child {
        max-width: 236px;
    }
}

@media (max-width: 479px) {
    .modal--new-game .modal__boxes--saturday p:last-child {
        max-width: 205px;
    }
}

.modal--new-game .modal__boxes--friday {
    background-color: #1e6eb7;
    padding: 20px;
    max-width: 260px;
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes--friday {
        max-width: 100%;
    }
}

.modal--new-game .modal__boxes--friday h3 {
    color: #f9f288;
}

.modal--new-game .modal__boxes--friday p {
    color: var(--iq-white);
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes--friday p:last-child {
        max-width: 195px;
    }
}

.modal--new-game .modal__boxes--line-top {
    width: 222px;
    height: 123px;
    background: var(--iq-pink);
    position: absolute;
    left: -23%;
    top: 0;
    -webkit-transform: rotate(44deg);
    transform: rotate(44deg);
    z-index: 0;
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes--line-top {
        left: -32%;
        top: 3px;
    }
}

@media (max-width: 479px) {
    .modal--new-game .modal__boxes--line-top {
        left: -54%;
        top: -5px;
        width: 307px;
    }
}

@media (max-width: 440px) {
    .modal--new-game .modal__boxes--line-top {
        top: 15px;
    }
}

@media (max-width: 360px) {
    .modal--new-game .modal__boxes--line-top {
        top: 35px;
    }
}

.modal--new-game .modal__boxes--line-bottom {
    width: 222px;
    height: 136px;
    background: #f9f288;
    position: absolute;
    right: -23%;
    bottom: -15px;
    -webkit-transform: rotate(44deg);
    transform: rotate(44deg);
    z-index: 0;
}

@media (max-width: 575px) {
    .modal--new-game .modal__boxes--line-bottom {
        height: 126px;
        right: -44%;
        bottom: -18px;
        width: 282px;
    }
}

@media (max-width: 479px) {
    .modal--new-game .modal__boxes--line-bottom {
        right: -56%;
        bottom: -18px;
    }
}

@media (max-width: 360px) {
    .modal--new-game .modal__boxes--line-bottom {
        bottom: 15px;
    }
}

.modal--new-game .modal__newgame .form-group__buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .modal--new-game .modal__newgame .form-group__buttons {
        -webkit-box-align: start;
        align-items: flex-start;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
    }
}

.modal--new-game .modal__newgame .form-group__buttons a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: none;
    padding: 15px 15px 12px;
    margin-bottom: 10px;
}

@media (max-width: 575px) {
    .modal--new-game .modal__newgame .form-group__buttons a {
        font-size: 20px;
    }
}

@media (max-width: 360px) {
    .modal--new-game .modal__newgame .form-group__buttons a {
        font-size: 17px;
    }
}

.modal--new-game .modal__newgame .form-group__buttons p {
    color: var(--iq-pink);
    font-size: 10px;
}

.modal--game-soon {
    max-width: 670px;
    padding: 30px 50px 30px 80px;
    overflow: hidden;
}

@media (max-width: 479px) {
    .modal--game-soon {
        padding: 30px 50px 80px 70px;
    }
}

.modal--game-soon .modal__game-soon {
    display: -webkit-box;
    display: flex;
    position: relative;
}

@media (max-width: 479px) {
    .modal--game-soon .modal__game-soon {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.modal--game-soon .modal__game-soon--heading {
    margin-left: -30px;
    position: relative;
    margin-bottom: 55px;
    z-index: 1;
}

@media (max-width: 479px) {
    .modal--game-soon .modal__game-soon--heading {
        margin-bottom: 45px;
    }
}

.modal--game-soon .modal__game-soon--heading h2 {
    color: #1971b7;
    background-color: #f9f288;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 30px;
    padding: 5px 25px;
    border-radius: 5px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
}

@media (max-width: 479px) {
    .modal--game-soon .modal__game-soon--heading h2 {
        padding: 5px 25px;
        font-size: 40px;
    }
}

@media (max-width: 415px) {
    .modal--game-soon .modal__game-soon--heading h2 {
        font-size: 34px;
    }
}

@media (max-width: 375px) {
    .modal--game-soon .modal__game-soon--heading h2 {
        font-size: 25px;
    }
}

.modal--game-soon .modal__game-soon--line {
    width: 100px;
    height: 53px;
    background: var(--iq-pink);
    position: absolute;
    left: -22%;
    top: 25px;
    -webkit-transform: rotate(-34deg);
    transform: rotate(-34deg);
    z-index: 0;
}

@media (max-width: 575px) {
    .modal--game-soon .modal__game-soon--line {
        left: -32%;
        top: 3px;
    }
}

@media (max-width: 479px) {
    .modal--game-soon .modal__game-soon--line {
        left: -29%;
        top: 32px;
        width: 128px;
        height: 60px;
    }
}

@media (max-width: 375px) {
    .modal--game-soon .modal__game-soon--line {
        left: -37%;
        top: 32px;
        width: 128px;
        height: 46px;
    }
}

.modal--game-soon .modal__game-soon--text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: left;
    max-width: 305px;
}

.modal--game-soon .modal__game-soon--text p {
    color: #1971b7;
    margin-bottom: 30px;
    font-size: 17px;
}

@media (max-width: 479px) {
    .modal--game-soon .modal__game-soon--text p {
        font-size: 20px;
        max-width: 265px;
        line-height: 25px;
    }
}

@media (max-width: 415px) {
    .modal--game-soon .modal__game-soon--text p {
        font-size: 17px;
        max-width: 212px;
    }
}

@media (max-width: 375px) {
    .modal--game-soon .modal__game-soon--text p {
        font-size: 14px;
    }
}

.modal--game-soon .modal__game-soon--img img {
    max-width: 100%;
}

@media (max-width: 479px) {
    .modal--game-soon .modal__game-soon--img img {
        width: 150px;
    }
}

.modal--game-soon .modal__game-soon--btn {
    display: -webkit-box;
    display: flex;
    position: absolute;
    bottom: 0;
}

@media (max-width: 479px) {
    .modal--game-soon .modal__game-soon--btn {
        bottom: -50px;
    }
}

.modal--game-soon .modal__game-soon--btn a {
    text-decoration: none;
    height: 30px;
}

@media (max-width: 479px) {
    .modal--game-soon .modal__game-soon--btn a {
        height: 35px;
        font-size: 20px;
    }
}

.modal--449 {
    background: transparent;
    padding: 0;
    max-width: 380px;
}

.modal--449 .remodal-close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #d5d8df;
    color: var(--iq-white);
    right: 10px;
    top: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 575px) {
    .modal--449 .remodal-close {
        right: -5px;
    }
}

.modal--449 .remodal-close:hover i {
    color: var(--iq-white);
}

.modal--449 .remodal-close i {
    font-size: 17px;
}

.modal--449 a {
    text-decoration: none;
}

.modal--449 img {
    max-width: 100%;
}

.page-rtl .modal--449 .remodal-close {
    right: unset;
    left: 10px;
}

body .selectize-control,
body .selectize-country-control {
    width: 100%;
    height: 50px;
}

body .selectize-control.rtl .selectize-input:after,
body .selectize-country-control.rtl .selectize-input:after {
    right: unset !important;
    left: 30px !important;
}

body .selectize-country .selectize-input,
body .selectize .selectize-input {
    font-size: 17px !important;

    line-height: 30px !important;
    color: var(--iq-darkgrey) !important;
    background: #f6f6f6;
    border: 1px solid var(--iq-orange);
    padding: 10px;
    border-radius: 5px;
    width: 70%;
    height: 50px;
    display: block;
    outline: 0 !important;
    box-shadow: none;
    cursor: pointer !important;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

body .selectize-country .selectize-input:after,
body .selectize .selectize-input:after {
    content: "\f078" !important;
    font-family: "Font Awesome 5 Pro";
    font-size: 17px;
    position: absolute !important;
    right: 30px !important;
    line-height: 53px;
    top: 0 !important;
    bottom: 0;
    margin: auto;
    color: var(--iq-black);
    display: block;
    z-index: 2;
    pointer-events: none;
    border: none !important;
}

body.page-rtl .selectize.drawSelectize .selectize-input:after {
    left: -70px !important;
    right: auto;
}

body .selectize-country .selectize-input input,
body .selectize .selectize-input input {
    cursor: pointer !important;
    font-size: 17px !important;

    height: 29px !important;
    line-height: 29px !important;
    pointer-events: none;
}

body .selectize-country .selectize-input input[disabled],
body .selectize .selectize-input input[disabled] {
    opacity: 1;
}

body .selectize-country .selectize-input input::-webkit-input-placeholder,
body .selectize .selectize-input input::-webkit-input-placeholder,
body .selectize-country .selectize-input input::-moz-placeholder,
body .selectize .selectize-input input::-moz-placeholder,
body .selectize-country .selectize-input input:-ms-input-placeholder,
body .selectize .selectize-input input:-ms-input-placeholder,
body .selectize-country .selectize-input input::-ms-input-placeholder,
body .selectize .selectize-input input::-ms-input-placeholder,
body .selectize-country .selectize-input input::placeholder,
body .selectize .selectize-input input::placeholder,
.hp-navigation__box.old .hp-navigation__info--box p:first-child,
.form .form-group--download a:hover,
.number-picker__select .selectize .selectize-input:after,
.total-balance__bottom a:first-child:hover,
.verification__progress ul li.active,
.login__new .form a.forgot-pw:hover,
.login__new .login__content--link:hover,
.skin_449
    .hp-navigation__box.box-draw-info
    .countdown__pricebubble--content
    h5:not(:first-child),
.skin_449 .previous-drawn__text h2,
.skin_449.page-rtl
    .hp-navigation__box.box-draw-info
    .countdown__pricebubble--content
    h5:not(:first-child),
.header__login-menu.logged ul li.highlighted a,
.header__login-menu.logged ul li:hover > a,
.header__login-menu--mobile nav ul li .accordion-top.accordion-logged a,
.header__login-menu--button.primary a,
.header__balance--top span,
.header__nav--menu li:hover .droplink,
.header__nav--menu li a:hover,
.header__currency:hover .droplink,
.header__currency a:hover .countdown .base-timer__path-color {
    color: var(--iq-pink);
}

body .selectize-country .selectize-dropdown,
body .selectize .selectize-dropdown {
    font-size: 17px;
    border: 1px solid var(--iq-orange) !important;
    color: var(--iq-pink);
}

body .selectize-country .selectize-dropdown .subflex,
body .selectize .selectize-dropdown .subflex {
    font-size: 17px;
    color: var(--iq-darkgrey);
}

body .selectize-country .selectize-dropdown .option,
body .selectize-country .selectize-dropdown .subflex,
body .selectize .selectize-dropdown .option,
body .selectize .selectize-dropdown .subflex {
    padding: 10px;
}

body .selectize-country .selectize-dropdown .option sup,
body .selectize-country .selectize-dropdown .subflex sup,
body .selectize .selectize-dropdown .option sup,
body .selectize .selectize-dropdown .subflex sup {
    color: #eb1c23;
}

body .selectize-country .selectize-dropdown .active,
body .selectize .selectize-dropdown .active {
    background-color: var(--iq-pink) !important;
    color: var(--iq-white) !important;
}

body .selectize-country .selectize-dropdown .active .subflex,
body .selectize .selectize-dropdown .active .subflex {
    color: var(--iq-white) !important;
}

body .selectize-country .selectize-dropdown [data-selectable] .highlight,
body .selectize .selectize-dropdown [data-selectable] .highlight {
    position: unset;
    background-color: none;
    width: unset;
    height: unset;
    background: rgba(125, 168, 208, 0.2);
    display: inline-block;
    color: var(--iq-darkgrey);
}

body .selectize-country .selectize-input {
    background-color: #f7f7f7;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    width: 85px;
}

body .selectize-country .selectize-input.dropdown-active {
    border: 5px 5px 0 0;
}

body .selectize-country .selectize-dropdown-content > div {
    padding: 5px;
}

body .selectize-country .selectize-dropdown {
    width: 165px !important;
}

body .selectize-country .flag {
    margin-bottom: 5px;
}

.flag-picker .selectize-input {
    height: 50px;
    display: -webkit-box !important;
    display: flex !important;
    -webkit-box-align: center;
    align-items: center;
}

.flag-picker .selectize-input input::-webkit-input-placeholder {
    color: transparent !important;
}

.flag-picker .selectize-input input::-moz-placeholder {
    color: transparent !important;
}

.flag-picker .selectize-input input:-ms-input-placeholder {
    color: transparent !important;
}

.flag-picker .selectize-input input::-ms-input-placeholder {
    color: transparent !important;
}

.flag-picker .selectize-input input::placeholder {
    color: transparent !important;
}

.flag-picker .selectize-input > * {
    color: var(--iq-darkblack);
}

.flag-picker .selectize-dropdown [data-selectable] {
    color: var(--iq-darkblack);
    padding: 5px 0 5px 5px;
}

.flag-picker .selectize-dropdown [data-selectable] .highlight {
    position: unset;
    background-color: none;
    width: unset;
    height: unset;
    background: rgba(125, 168, 208, 0.2);
    display: inline-block;
    color: var(--iq-darkgrey);
}

.flag-picker .flag {
    height: 18px;
}

.countdown__inner {
    display: -webkit-box;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 27px 30px;
}

.countDown_margin {
    margin-top: 10px;
}

@media (max-width: 1199px) {
    .countdown__inner {
        margin-bottom: 15px;
    }
}

@media (max-width: 991px) {
    .countdown__inner {
        margin-bottom: 15px;
        background-position-x: 99%;
    }
}

@media (max-width: 575px) {
    .countdown__inner {
        background-image: url(../img/counter-bg-newest-mobile.jpg);
        height: 280px;
        background-position: 50%;
    }
    .countDown_margin {
        margin-top: 20px;
    }
}

@media (max-width: 479px) {
    .countdown__inner {
        background-position: 50%;
        height: 255px;
        padding: 20px 30px;
    }
}

@media (max-width: 400px) {
    .countdown__inner {
        height: 213px;
        padding: 17px 24px;
    }
}

@media (max-width: 350px) {
    .countdown__inner {
        height: 183px;
        padding: 15px 20px;
    }
}

.countdown .base-timer {
    position: relative;
    height: 115px;
    width: 115px;
}

@media (max-width: 479px) {
    .countdown .base-timer {
        width: 79px;
        height: 79px;
    }
}

@media (max-width: 400px) {
    .countdown .base-timer {
        width: 66px;
        height: 66px;
    }
}

@media (max-width: 350px) {
    .countdown .base-timer {
        width: 58px;
        height: 58px;
    }
}

.countdown .base-timer__circle {
    fill: none;
    stroke: none;
}

.countdown .base-timer__path-elapsed {
    stroke-width: 2px;
}

.countdown .base-timer__path-remaining {
    stroke-width: 7px;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
    stroke: currentColor;
    -webkit-transform: none;
    transform: none;
}

.countdown .svg-wrapp {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.countdown .base-timer__svg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.countdown__timer {
    width: unset;
    max-width: 100%;
    border-radius: 5px;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 1199px) {
    .countdown__timer {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin: 0 10px;
    }
}

@media (max-width: 1200px) {
    .countdown__timer {
        width: 341px;
    }
}

@media (max-width: 991px) {
    .countdown__timer.desktop {
        display: none;
    }
}

.countdown__timer.mobile {
    margin: 0 0 15px;
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (min-width: 992px) {
    .countdown__timer.mobile {
        display: none;
    }
}

@media (max-width: 479px) {
    .countdown__timer span.countdown__timer--value {
        font-size: 37px;
    }
}

@media (max-width: 400px) {
    .countdown__timer span.countdown__timer--value {
        font-size: 28px;
    }
}

@media (max-width: 350px) {
    .countdown__timer span.countdown__timer--value {
        font-size: 25px;
    }
}

.countdown__timer > div:not(:last-child) {
    margin-right: 5px;
}

.countdown__timer.mobile {
    background-color: #faf188;
    padding: 20px;
}

@media (min-width: 1200px) {
    .countdown__timer.mobile {
        display: none;
    }
}

@media (max-width: 1199px) {
    .countdown__timer.mobile {
        display: -webkit-box;
        display: flex;
    }
}

@media (max-width: 1199px) {
    .countdown__timer.desktop {
        display: none;
    }
}

.countdown__timer--wrapper .base-timer {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.countdown__timer--valuewrapper {
    height: 67px;
    display: -webkit-box;
    display: flex;
}

@media (max-width: 479px) {
    .countdown__timer--valuewrapper {
        height: 43px;
    }
}

@media (max-width: 400px) {
    .countdown__timer--valuewrapper {
        height: 31px;
    }
}

@media (max-width: 350px) {
    .countdown__timer--valuewrapper {
        height: 28px;
    }
}

.countdown__timer--boxes-row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    left: 50%;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .countdown__timer--boxes-row {
        top: 0;
    }
}

@media (max-width: 479px) {
    .countdown__timer--boxes-row {
        top: 5px;
    }
}

@media (max-width: 479px) {
    .countdown__timer--boxes-row p {
        font-size: 12px;
    }
}

@media (max-width: 350px) {
    .countdown__timer--boxes-row p {
        font-size: 10px;
    }
}

@media (min-width: 1200px) {
    .countdown__mobile {
        display: none;
    }
}

@media (max-width: 1199px) {
    .countdown__mobile {
        display: block;
    }
}

@media (max-width: 575px) {
    .countdown--smalltext {
        font-size: 12px;
    }
}

@media (max-width: 479px) {
    .countdown--smalltext {
        font-size: 13px;
        font-size: 8px;
        bottom: -15px;
        position: absolute;
        left: 0;
        text-align: left;
    }
}

@media (min-width: 576px) {
    .countdown--smalltext.mobile {
        display: none;
    }
}

@media (max-width: 575px) {
    .countdown--smalltext.desktop {
        display: none;
    }
}

.countdown__button {
    -webkit-box-align: center;
    align-items: center;
}

.countdown__text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: end;
    align-items: flex-end;
    position: relative;
    align-self: flex-start;
}

@media (max-width: 575px) {
    .countdown__text {
        position: relative;
        height: 100%;
        width: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-align: start;
        align-items: flex-start;
        align-self: unset;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

.countdown__text--date {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--iq-pink);
    padding: 10px 15px 5px;
    border-radius: 10px;
    margin-right: -30px;
}

@media (max-width: 1199px) {
    .countdown__text--date {
        margin-right: 0;
    }
}

.countdown__text--date p {
    font-size: 19px;
    color: var(--iq-white);
    max-width: 100%;
}

@media (max-width: 1200px) {
    .countdown__text--date p {
        margin-right: 0;
    }
}

@media (max-width: 479px) {
    .countdown__text--date p {
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .countdown__text--date p {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 415px) {
    .countdown__text--date p {
        font-size: 17px;
    }
}

@media (max-width: 380px) {
    .countdown__text--date p {
        font-size: 13px;
    }
}

@media (max-width: 575px) {
    .countdown__text--text {
        position: unset;
        -webkit-transform: unset;
        transform: unset;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 10px 15px;
        height: 100%;
    }
}

@media (max-width: 479px) {
    .countdown__text--text {
        width: auto;
        padding: 10px;
    }
}

.countdown__text--info {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 0;
}

.countdown__text--info p {
    color: var(--iq-white);
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 5px;
}

@media (min-width: 1200px) {
    .countdown__text--info p {
        margin-bottom: 0;
    }
}

@media (max-width: 575px) {
    .countdown__text--info p {
        text-align: left;
    }
}

.countdown__text--info p strong {
    font-size: 25px;
}

@media (max-width: 479px) {
    .countdown__text--info p strong {
        font-size: 18px;
    }
}

@media (max-width: 400px) {
    .countdown__text--info p strong {
        font-size: 17px;
    }
}

@media (max-width: 380px) {
    .countdown__text--info p strong {
        font-size: 13px;
    }
}

@media (max-width: 1200px) {
    .countdown__text--info p {
        font-size: 10px;
        margin-bottom: -7px;
    }
}

@media (max-width: 479px) {
    .countdown__text--info p {
        line-height: 20px;
    }
}

@media (max-width: 415px) {
    .countdown__text--info p {
        margin-bottom: 0;
        line-height: 5px;
    }
}

@media (max-width: 380px) {
    .countdown__text--info p {
        line-height: 10px;
    }
}

@media (max-width: 1199px) {
    .countdown__text--info {
        margin-bottom: 0;
    }
}

@media (max-width: 479px) {
    .countdown__text--info {
        height: 50px;
    }
}

@media (max-width: 400px) {
    .countdown__text--info {
        height: 43px;
    }
}

@media (max-width: 380px) {
    .countdown__text--info {
        height: 43px;
        margin-bottom: 0;
    }
}

.countdown__text--sign {
    font-size: 35px;
    color: var(--iq-white);
    position: unset;
    -webkit-transform: unset;
    transform: unset;
    left: unset;
    top: unset;
    margin: 0 15px;
}

@media (max-width: 1199px) {
    .countdown__text--sign {
        font-size: 35px;
        left: 41%;
        top: 33px;
    }
}

@media (max-width: 575px) {
    .countdown__text--sign {
        margin: 0;
    }
}

@media (max-width: 479px) {
    .countdown__text--sign {
        font-size: 20px;
        left: 50%;
        top: 25px;
    }
}

@media (max-width: 480px) {
    .countdown__text--sign {
        left: 47%;
        top: 14px;
    }
}

@media (max-width: 415px) {
    .countdown__text--sign {
        top: 11px;
        font-size: 29px;
    }
}

@media (max-width: 400px) {
    .countdown__text--sign {
        top: 5px;
        font-size: 29px;
    }
}

@media (max-width: 380px) {
    .countdown__text--sign {
        top: 8px;
        font-size: 25px;
    }
}

.countdown__text--amount {
    display: -webkit-box;
    display: flex;
}

.countdown__text--amount p {
    color: var(--iq-white);
    line-height: 34px;
    font-size: 36px;
}

@media (min-width: 992px) {
    .countdown__text--amount p {
        font-size: 29px;
    }
}

@media (max-width: 575px) {
    .countdown__text--amount p {
        width: 100%;
        display: -webkit-box;
        display: flex;
        font-size: 34px;
        line-height: 40px;
    }
}

@media (max-width: 479px) {
    .countdown__text--amount p {
        font-size: 24px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        align-items: center;
    }
}

@media (max-width: 400px) {
    .countdown__text--amount p {
        font-size: 17px;
        line-height: 40px;
    }
}

@media (max-width: 350px) {
    .countdown__text--amount p {
        line-height: 30px;
    }
}

.countdown__text--amount p span {
    color: var(--iq-white);
    line-height: 34px;
    font-size: 29px;
}

@media (max-width: 575px) {
    .countdown__text--amount p span {
        font-size: 34px;
        line-height: 40px;
    }
}

@media (max-width: 479px) {
    .countdown__text--amount p span {
        font-size: 24px;
    }
}

@media (max-width: 400px) {
    .countdown__text--amount p span {
        font-size: 17px;
    }
}

@media (max-width: 350px) {
    .countdown__text--amount p span {
        line-height: 30px;
    }
}

.countdown__button {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    position: absolute;
    left: 0;
    bottom: -35px;
}

@media (max-width: 991px) {
    .countdown__button.desktop {
        display: none;
    }
}

@media (min-width: 1200px) {
    .countdown__button.mobile {
        display: block;
    }
}

@media (max-width: 1199px) {
    .countdown__button.mobile {
        display: block;
        position: unset;
        left: unset;
        bottom: 0;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
}

.countdown__button a {
    margin-bottom: 5px;
    border-radius: 10px;
}

.countdown__button a.btn {
    width: 130px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 15px 20px 13px;
}

@media (max-width: 1199px) {
    .countdown__button a.btn {
        width: 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .countdown__mobile {
        display: none;
    }
}

@media (max-width: 575px) {
    .countdown-noraffle .countdown__text--text {
        top: -34px;
        left: 64%;
    }
}

@media (max-width: 479px) {
    .countdown-noraffle .countdown__text--text {
        top: -34px;
    }
}

@media (max-width: 415px) {
    .countdown-noraffle .countdown__text--text {
        top: -30px;
    }
}

@media (max-width: 350px) {
    .countdown-noraffle .countdown__text--text {
        top: -17px;
    }
}

.countdown-noraffle .countdown__text--info {
    margin-bottom: 0;
}

@media (max-width: 479px) {
    .countdown-noraffle .countdown__text--info {
        height: unset;
        margin-bottom: 0;
    }
}

.countdown-noraffle .countdown__text--info p {
    color: var(--iq-white);
}

@media (min-width: 1200px) {
    .countdown-noraffle .countdown__text--info p {
        margin-bottom: 0;
    }
}

@media (max-width: 575px) {
    .countdown-noraffle .countdown__text--info p {
        font-size: 29px;
        text-align: left;
    }
}

@media (max-width: 479px) {
    .countdown-noraffle .countdown__text--info p {
        font-size: 23px;
        margin-bottom: 10px;
    }
}

.countdown-noraffle .countdown__text--info p strong {
    font-size: 25px;
}

@media (max-width: 575px) {
    .countdown-noraffle .countdown__text--info p strong {
        font-size: 29px;
    }
}

@media (max-width: 479px) {
    .countdown-noraffle .countdown__text--info p strong {
        font-size: 18px;
    }
}

@media (max-width: 400px) {
    .countdown-noraffle .countdown__text--info p strong {
        font-size: 17px;
    }
}

@media (max-width: 575px) {
    .countdown-noraffle .countdown__text--amount p {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        font-size: 50px;
        line-height: 34px;
    }
}

@media (max-width: 479px) {
    .countdown-noraffle .countdown__text--amount p {
        font-size: 40px;
    }
}

@media (max-width: 415px) {
    .countdown-noraffle .countdown__text--amount p {
        font-size: 33px;
    }
}

@media (max-width: 350px) {
    .countdown-noraffle .countdown__text--amount p {
        font-size: 23px;
    }
}

@media (max-width: 575px) {
    .countdown-noraffle .countdown__text--amount p span {
        line-height: 40px;
        font-size: 25px;
        margin-bottom: 10px;
    }
}

@media (max-width: 479px) {
    .countdown-noraffle .countdown__text--amount p span {
        font-size: 20px;
        line-height: unset;
        margin-bottom: 0;
    }
}

@media (max-width: 415px) {
    .countdown-noraffle .countdown__text--amount p span {
        font-size: 17px;
        line-height: 20px;
    }
}

@media (max-width: 350px) {
    .countdown-noraffle .countdown__text--amount p span {
        font-size: 14px;
        line-height: 10px;
    }
}

.hp-navigation {
    margin-bottom: 20px;
}

@media (max-width: 991px) {
    .hp-navigation {
        margin-bottom: 0px;
    }
}

.hp-navigation__inner--top {
    margin-bottom: 25px;
}

@media (max-width: 991px) {
    .hp-navigation__inner--top,
    .hp-navigation__inner--top [class*="col-"]:not(:last-child) {
        margin-bottom: 20px;
    }
}

.hp-navigation__inner--top .hp-navigation__box--content {
    top: 23px;
    left: 30px;
}

@media (max-width: 479px) {
    .hp-navigation__inner--top .hp-navigation__box--content {
        top: 16px;
        left: 25px;
    }
}

@media (max-width: 375px) {
    .hp-navigation__inner--top .hp-navigation__box--content {
        left: 20px;
    }
}

.hp-navigation__inner--top .hp-navigation__box--content h2 {
    color: var(--iq-white);
    margin-bottom: 0;
    line-height: 40px;
}

@media (max-width: 479px) {
    .hp-navigation__inner--top .hp-navigation__box--content h2 {
        font-size: 32px;
    }
}

@media (max-width: 375px) {
    .hp-navigation__inner--top .hp-navigation__box--content h2 {
        font-size: 29px;
        line-height: 28px;
    }
}

.hp-navigation__inner--top .hp-navigation__box--textbg {
    background-color: var(--iq-pink);
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 40px;
}

@media (max-width: 479px) {
    .hp-navigation__inner--top .hp-navigation__box--textbg {
        margin-bottom: 23px;
    }
}

@media (max-width: 375px) {
    .hp-navigation__inner--top .hp-navigation__box--textbg {
        margin-bottom: 12px;
    }
}

@media (max-width: 1200px) {
    .hp-navigation__inner--mid .row [class*="col-"]:first-child {
        margin-bottom: 20px;
    }
}

@media (max-width: 1199px) {
    .hp-navigation__inner--right {
        margin-top: 20px;
    }
}

@media (min-width: 1200px) {
    .hp-navigation__inner--right [class*="col-"]:first-child {
        margin-bottom: 19px;
    }
}

@media (max-width: 991px) {
    .hp-navigation__inner--right [class*="col-"]:first-child {
        margin-bottom: 20px;
    }
}

.hp-navigation__box {
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 235px;
    position: relative;
    border-radius: 5px;
    border: 1px solid transparent;
}

@media (max-width: 1199px) {
    .hp-navigation__box {
        background-position: 50%;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box {
        padding-bottom: 190px;
    }
}

.hp-navigation__box--content {
    position: absolute;
    top: 54px;
    left: 34px;
}

@media (max-width: 1199px) {
    .hp-navigation__box--content {
        top: 30px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box--content {
        left: 20px;
    }
}

@media (max-width: 350px) {
    .hp-navigation__box--content h2 {
        margin-bottom: 10px;
    }
}

.hp-navigation__box--content a {
    width: 137px;
    height: 40px;
}

.hp-navigation__box.first {
    background-image: url(../img/box1-newest.jpg);
}

@media (max-width: 479px) {
    .hp-navigation__box.first {
        background-image: url(../img/box1-newest-mobile.jpg);
    }
}

@media (max-width: 375px) {
    .hp-navigation__box.first {
        padding-bottom: 150px;
    }
}

@media (max-width: 350px) {
    .hp-navigation__box.first .hp-navigation__box--content {
        left: 20px;
        top: 15px;
    }
}

.hp-navigation__box.first .hp-navigation__box--content h2 {
    max-width: 135px;
}

.hp-navigation__box.first .hp-navigation__box--textbg {
    width: 210px;
}

@media (max-width: 479px) {
    .hp-navigation__box.first .hp-navigation__box--textbg {
        width: 180px;
    }
}

@media (max-width: 375px) {
    .hp-navigation__box.first .hp-navigation__box--textbg {
        width: 150px;
    }
}

.hp-navigation__box.first h2 {
    max-width: 150px;
}

@media (max-width: 479px) {
    .hp-navigation__box.first h2 {
        max-width: 140px;
    }
}

.hp-navigation__box.second {
    background-image: url(../img/box2-newest.jpg);
}

@media (max-width: 479px) {
    .hp-navigation__box.second {
        background-image: url(../img/box2-newest-mobile.jpg);
    }
}

@media (max-width: 375px) {
    .hp-navigation__box.second {
        padding-bottom: 150px;
    }
}

.hp-navigation__box.second .hp-navigation__box--content h2 {
    max-width: 195px;
}

@media (max-width: 479px) {
    .hp-navigation__box.second .hp-navigation__box--content h2 {
        max-width: unset;
    }
}

@media (max-width: 350px) {
    .hp-navigation__box.second .hp-navigation__box--content h2 {
        left: 20px;
        top: 15px;
    }
}

.hp-navigation__box.second .hp-navigation__box--textbg {
    width: 225px;
}

@media (max-width: 479px) {
    .hp-navigation__box.second .hp-navigation__box--textbg {
        width: 200px;
    }
}

@media (max-width: 375px) {
    .hp-navigation__box.second .hp-navigation__box--textbg {
        width: 180px;
    }
}

.hp-navigation__box.second h2 {
    max-width: 200px;
}

.hp-navigation__box.third {
    background-image: url(../img/box3-newest.jpg);
}

@media (max-width: 479px) {
    .hp-navigation__box.third {
        background-image: url(../img/box3-newest-mobile.jpg);
    }
}

@media (max-width: 375px) {
    .hp-navigation__box.third {
        padding-bottom: 150px;
    }
}

@media (max-width: 350px) {
    .hp-navigation__box.third .hp-navigation__box--content {
        left: 20px;
        top: 15px;
    }
}

.hp-navigation__box.third .hp-navigation__box--content h2 {
    max-width: 180px;
}

.hp-navigation__box.third .hp-navigation__box--textbg {
    width: 210px;
}

@media (max-width: 479px) {
    .hp-navigation__box.third .hp-navigation__box--textbg {
        width: 180px;
    }
}

@media (max-width: 375px) {
    .hp-navigation__box.third .hp-navigation__box--textbg {
        width: 165px;
    }
}

.hp-navigation__box.third h2 {
    max-width: 200px;
}

.hp-navigation__box.fifth {
    background-image: none;
    background-color: var(--iq-pink);
    border-radius: 5px;
}

@media (max-width: 479px) {
    .hp-navigation__box.fifth {
        padding-bottom: 190px;
    }
}

.hp-navigation__box.fifth h2 {
    font-size: 28px;
    color: var(--iq-white);
    margin-bottom: 5px;
}

@media (max-width: 479px) {
    .hp-navigation__box.fifth h2 {
        font-size: 25px;
        max-width: 124px;
        margin-bottom: 15px;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.fifth h2 {
        font-size: 23px;
        max-width: 100px;
    }
}

.hp-navigation__box.fifth h3 {
    color: #faf188;
    font-size: 35px;
}

@media (max-width: 340px) {
    .hp-navigation__box.fifth h3 {
        font-size: 25px;
        max-width: 100px;
    }
}

.hp-navigation__box.fifth .hp-navigation__box--content {
    top: 40px;
    max-width: 140px;
}

@media (max-width: 479px) {
    .hp-navigation__box.fifth .hp-navigation__box--content {
        top: 30px;
    }
}

.hp-navigation__box.fifth .hp-navigation__box--img {
    position: absolute;
    width: 165px;
    right: 22px;
    top: 20px;
}

@media (max-width: 479px) {
    .hp-navigation__box.fifth .hp-navigation__box--img {
        top: 10px;
        width: 150px;
        right: 20px;
    }
}

.hp-navigation__box.fifth .hp-navigation__box--img img {
    max-width: 100%;
}

@media (max-width: 1199px) {
    .hp-navigation__box.sixth {
        padding-bottom: 228px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.sixth {
        padding-bottom: 170px;
    }
}

@media (max-width: 390px) {
    .hp-navigation__box.sixth {
        padding-bottom: 150px;
    }
}

.hp-navigation__box.sixth h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: var(--iq-white);
}

@media (max-width: 479px) {
    .hp-navigation__box.sixth h2 {
        margin-bottom: 20px;
    }
}

@media (max-width: 390px) {
    .hp-navigation__box.sixth h2 {
        font-size: 40px;
    }
}

.hp-navigation__box.sixth .hp-navigation__box--content {
    top: 23px;
}

.hp-navigation__box.sixth .hp-navigation__box--img {
    position: absolute;
    right: 34px;
    top: 23px;
}

.hp-navigation__box.sixth .hp-navigation__box--img img {
    width: 60px;
}

.hp-navigation__box.fourth {
    background-image: none;
}

@media (min-width: 1200px) {
    .hp-navigation__box.fourth {
        padding: 26px 40px;
    }
}

@media (max-width: 1199px) {
    .hp-navigation__box.fourth {
        padding: 26px 40px 30px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.fourth {
        background-image: url(../img/box4-new-mobile.jpg);
    }
}

@media (max-width: 412px) {
    .hp-navigation__box.fourth {
        padding: 200px 30px 110px;
    }
}

@media (max-width: 350px) {
    .hp-navigation__box.fourth {
        background-image: url(../img/box4-new-mobile-xs.jpg);
    }
}

@media (max-width: 412px) {
    .hp-navigation__box.fourth h2 {
        font-size: 32px !important;
        max-width: 250px;
    }
}

.hp-navigation__box.fourth h3 {
    margin-bottom: 71px;
}

@media (max-width: 479px) {
    .hp-navigation__box.fourth h3 {
        font-size: 26px;
        margin-bottom: 30px;
    }
}

@media (max-width: 412px) {
    .hp-navigation__box.fourth h3 {
        font-size: 19px;
    }
}

.hp-navigation__box.fourth .hp-navigation__box--content {
    position: relative;
    top: unset !important;
    left: unset;
}

@media (max-width: 991px) {
    .hp-navigation__box.fourth .hp-navigation__box--content {
        padding: 0;
        left: 0;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.fourth .hp-navigation__box--content {
        max-width: 290px !important;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.fourth .hp-navigation__box--btn a {
        width: 100% !important;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.fourth {
        background-image: none;
    }
}

@media (min-width: 576px) {
    .hp-navigation__box.seventh {
        padding: 26px 40px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.seventh .hp-navigation__box--content {
        top: unset;
    }
}

.hp-navigation__box.seventh .hp-navigation__box--content h2 {
    max-width: 100%;
}

@media (max-width: 412px) {
    .hp-navigation__box.seventh .hp-navigation__box--content h2 {
        font-size: 32px !important;
        max-width: 250px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.seventh .hp-navigation__box--content h3 {
        font-size: 26px;
        margin-bottom: 30px;
    }
}

@media (max-width: 412px) {
    .hp-navigation__box.seventh .hp-navigation__box--content h3 {
        font-size: 19px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.seventh .hp-navigation__info--box:after {
        left: 50%;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.box-results {
        padding: 40px 20px;
    }
}

.hp-navigation__box.box-results .hp-navigation__box--content h2 {
    font-size: 38px;
    margin-bottom: 5px;
    color: var(--iq-white);
}

@media (max-width: 1199px) {
    .hp-navigation__box.box-results .hp-navigation__box--content h2 {
        font-size: 28px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.box-results .hp-navigation__box--content h2 {
        font-size: 50px;
        margin-bottom: 0;
    }
}

@media (max-width: 431px) {
    .hp-navigation__box.box-results .hp-navigation__box--content h2 {
        font-size: 38px !important;
    }
}

@media (max-width: 354px) {
    .hp-navigation__box.box-results .hp-navigation__box--content h2 {
        font-size: 32px !important;
    }
}

.hp-navigation__box.box-results .hp-navigation__box--content h3 {
    font-size: 22px;
    color: var(--iq-white);
}

@media (max-width: 1199px) {
    .hp-navigation__box.box-results .hp-navigation__box--content h3 {
        font-size: 18px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.box-results .hp-navigation__box--content h3 {
        font-size: 30px;
        margin-bottom: 30px;
    }
}

@media (max-width: 412px) {
    .hp-navigation__box.box-results .hp-navigation__box--content h3 {
        font-size: 26px;
    }
}

@media (max-width: 350px) {
    .hp-navigation__box.box-results .hp-navigation__box--content h3 {
        font-size: 19px;
    }
}

.hp-navigation__box.box-results .hp-navigation__box--btn {
    display: -webkit-box;
    display: flex;
}

.hp-navigation__box.box-results .hp-navigation__box--btn a {
    font-size: 14px;
    max-width: 100%;
    height: 40px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 15px 20px 12px;
    width: auto;
}

@media (max-width: 479px) {
    .hp-navigation__box.box-results .hp-navigation__box--btn a {
        font-size: 20px;
        width: 220px;
        height: 45px;
    }
}

@media (max-width: 412px) {
    .hp-navigation__box.box-results .hp-navigation__box--btn a {
        font-size: 17px;
        width: 200px;
        height: 35px;
    }
}

.hp-navigation__box.box-results .hp-navigation__box--content {
    position: relative;
    top: unset;
    left: unset;
}

@media (max-width: 991px) {
    .hp-navigation__box.box-results .hp-navigation__box--content {
        padding: 0;
        left: 0;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.box-results .hp-navigation__box--content {
        max-width: 290px !important;
    }
}

.hp-navigation__box.old {
    background-image: url(../img/box4-old.jpg);
    padding: 76px 40px 95px;
    border: none;
}

@media (max-width: 1199px) {
    .hp-navigation__box.old {
        padding: 40px;
    }
}

@media (max-width: 991px) {
    .hp-navigation__box.old {
        background-image: url(../img/box4-old-mobile.jpg);
        padding: 40px 40px 100px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.old {
        padding: 40px 30px 100px;
    }
}

@media (max-width: 575px) {
    .hp-navigation__box.old [class*="col-"] {
        margin-bottom: 0 !important;
    }
}

.hp-navigation__box.old .hp-navigation__box--content {
    position: relative;
    top: unset;
    left: unset;
}

@media (max-width: 991px) {
    .hp-navigation__box.old .hp-navigation__box--content {
        padding: 0;
        left: 0;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.old .hp-navigation__box--content {
        max-width: 290px !important;
    }
}

.hp-navigation__box.old .hp-navigation__box--btn {
    position: absolute;
    bottom: 110px;
    left: 40px;
}

@media (max-width: 1199px) {
    .hp-navigation__box.old .hp-navigation__box--btn {
        bottom: 55px;
    }
}

@media (max-width: 991px) {
    .hp-navigation__box.old .hp-navigation__box--btn {
        bottom: 34px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.old .hp-navigation__box--btn {
        left: 30px;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.old .hp-navigation__box--btn a {
        width: 100% !important;
    }
}

.hp-navigation__box.old.box-results .hp-navigation__box--content {
    max-width: 750px;
}

.hp-navigation__box.old.box-results .hp-navigation__box--content h2 {
    font-size: 32px;
    margin-bottom: 5px;
}

@media (max-width: 1199px) {
    .hp-navigation__box.old.box-results .hp-navigation__box--content h2 {
        font-size: 28px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.old.box-results .hp-navigation__box--content h2 {
        font-size: 26px;
    }
}

@media (max-width: 431px) {
    .hp-navigation__box.old.box-results .hp-navigation__box--content h2 {
        font-size: 26px !important;
    }
}

@media (max-width: 354px) {
    .hp-navigation__box.old.box-results .hp-navigation__box--content h2 {
        font-size: 26px !important;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.old.box-results .hp-navigation__box--content h2 {
        font-size: 23px !important;
    }
}

.hp-navigation__box.old.box-results .hp-navigation__box--content h3 {
    font-size: 22px;
    color: var(--iq-white);
    margin-bottom: 0;
}

@media (max-width: 1199px) {
    .hp-navigation__box.old.box-results .hp-navigation__box--content h3 {
        font-size: 18px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.old.box-results .hp-navigation__box--content h3 {
        font-size: 17px;
    }
}

.hp-navigation__box.old.box-results .hp-navigation__box--btn a {
    font-size: 17px;
    width: 236px;
    max-width: 100%;
    height: 48px;
    padding: 15px 20px 12px;
}

@media (max-width: 340px) {
    .hp-navigation__box.old.box-results .hp-navigation__box--btn a {
        font-size: 17px;
    }
}

.hp-navigation__box.old .hp-navigation__draws {
    display: -webkit-box;
    display: flex;
    margin: 10px 0;
}

@media (min-width: 480px) {
    .hp-navigation__box.old .hp-navigation__draws {
        margin: 13px 0;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.old .hp-navigation__draws {
        margin: 10px 0;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
}

.hp-navigation__box.old .hp-navigation__draws > div:not(:last-child) {
    margin-right: 10px;
}

.hp-navigation__box.old .hp-navigation__draws--number {
    width: 60px;
    height: 60px;
    background-color: var(--iq-white);
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    border: 12px solid #fce300;
    background-image: none;
}

@media (max-width: 479px) {
    .hp-navigation__box.old .hp-navigation__draws--number {
        width: 44px;
        height: 44px;
        border: 8px solid #fce300;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.old .hp-navigation__draws--number {
        height: 38px;
        border: 7px solid #fce300;
    }
}

.hp-navigation__box.old .hp-navigation__draws span {
    color: var(--iq-black);
    font-size: 21px;
    padding: 10px 0 6px;
}

@media (max-width: 479px) {
    .hp-navigation__box.old .hp-navigation__draws span {
        font-size: 18px;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.old .hp-navigation__draws span {
        font-size: 17px;
    }
}

@media (max-width: 1199px) {
    .hp-navigation__box.old .hp-navigation__info {
        margin-bottom: 15px;
    }
}

@media (min-width: 1200px) {
    .hp-navigation__box.old .hp-navigation__info [class*="col-"] {
        margin-bottom: 15px;
    }
}

@media (max-width: 1199px) {
    .hp-navigation__box.old
        .hp-navigation__info
        [class*="col-"]:not(:last-child) {
        margin-bottom: 15px;
    }
}

.hp-navigation__box.old .hp-navigation__info--box {
    padding: 15px 15px 12px;
    background-color: var(--iq-white);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    border-radius: 5px;
    height: unset;
}

.hp-navigation__box.old .hp-navigation__info--box:after {
    content: none;
}

.hp-navigation__box.old .hp-navigation__info--box p {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.hp-navigation__box.old.old-six {
    padding: 76px 40px 32px;
}

@media (max-width: 479px) {
    .hp-navigation__box.old.old-six {
        padding: 76px 30px 32px;
    }
}

.hp-navigation__box.old.old-six .hp-navigation__box--content {
    top: -45px;
}

.hp-navigation__box.old.old-six .hp-navigation__box--btn {
    bottom: unset;
    left: unset;
}

@media (max-width: 400px) {
    .hp-navigation__box.old.old-six .hp-navigation__draws--number {
        width: 30px;
        height: 30px;
        border-width: 5px;
    }
}

@media (max-width: 400px) {
    .hp-navigation__box.old.old-six .hp-navigation__draws span {
        font-size: 12px;
    }
}

.hp-navigation__box.old2 {
    background-image: url(../img/box4.jpg);
    padding: 0 0 422px;
}

@media (max-width: 1199px) {
    .hp-navigation__box.old2 {
        padding: 0 0 440px;
    }
}

@media (max-width: 991px) {
    .hp-navigation__box.old2 {
        padding: 0 0 585px;
    }
}

.hp-navigation__box.old2 .hp-navigation__box--content {
    position: absolute;
    top: 35px;
    left: 34px;
}

@media (max-width: 991px) {
    .hp-navigation__box.old2 .hp-navigation__box--content {
        padding: 0 20px;
        left: 0;
    }
}

.hp-navigation__box.old2 .hp-navigation__box--content h2 {
    font-size: 28px;
}

@media (max-width: 1199px) {
    .hp-navigation__box.old2 .hp-navigation__box--content h2 {
        font-size: 28px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.old2 .hp-navigation__box--content h2 {
        font-size: 28px;
    }
}

.hp-navigation__box.old2 .hp-navigation__box--content h3 {
    font-size: 17px;
}

@media (max-width: 1199px) {
    .hp-navigation__box.old2 .hp-navigation__box--content h3 {
        font-size: 17px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.old2 .hp-navigation__box--content h3 {
        font-size: 17px;
    }
}

.hp-navigation__box.old2 .hp-navigation__box--btn {
    position: unset;
    bottom: unset;
    left: unset;
}

@media (max-width: 1199px) {
    .hp-navigation__box.old2 .hp-navigation__box--btn {
        bottom: unset;
    }
}

@media (max-width: 991px) {
    .hp-navigation__box.old2 .hp-navigation__box--btn {
        bottom: unset;
    }
}

@media (max-width: 479px) {
    .hp-navigation__box.old2 .hp-navigation__box--btn {
        left: unset;
    }
}

.hp-navigation__box.old2 .hp-navigation__draws {
    margin: 0 0 30px;
}

@media (max-width: 479px) {
    .hp-navigation__box.old2 .hp-navigation__draws {
        margin-bottom: 30px;
    }
}

.hp-navigation__box.old2 .hp-navigation__draws > div:not(:last-child) {
    margin-right: 5px;
}

.hp-navigation__box.old2 .hp-navigation__draws--number {
    width: 41px;
    height: 41px;
    background-image: url(../img/yellow-ball.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    border: none;
}

@media (max-width: 479px) {
    .hp-navigation__box.old2 .hp-navigation__draws--number {
        width: 41px;
        height: 41px;
        border: none;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.old2 .hp-navigation__draws--number {
        height: 35px;
    }
}

.hp-navigation__box.old2 .hp-navigation__draws--number span {
    font-size: 14px;
}

@media (max-width: 479px) {
    .hp-navigation__box.old2 .hp-navigation__draws--number span {
        font-size: 14px;
    }
}

@media (max-width: 340px) {
    .hp-navigation__box.old2 .hp-navigation__draws--number span {
        font-size: 14px;
    }
}

.hp-navigation__box.old2 .hp-navigation__info {
    margin-bottom: 28px;
}

@media (min-width: 1200px) {
    .hp-navigation__box.old2 .hp-navigation__info [class*="col-"] {
        margin-bottom: 0;
    }

    .hp-navigation__box.old2 .hp-navigation__info [class*="col-"]:first-child {
        padding-right: 7px;
        padding-bottom: 14px;
    }

    .hp-navigation__box.old2 .hp-navigation__info [class*="col-"]:nth-child(2) {
        padding-left: 7px;
        padding-bottom: 14px;
    }

    .hp-navigation__box.old2 .hp-navigation__info [class*="col-"]:nth-child(3) {
        padding-right: 7px;
    }

    .hp-navigation__box.old2 .hp-navigation__info [class*="col-"]:nth-child(4) {
        padding-left: 7px;
    }
}

@media (max-width: 1199px) {
    .hp-navigation__box.old2
        .hp-navigation__info
        [class*="col-"]:not(:last-child) {
        margin-bottom: 15px;
    }
}

.hp-navigation__box.old2 .hp-navigation__info--box p {
    color: var(--iq-black);
}

.hp-navigation__draws {
    display: -webkit-box;
    display: flex;
    margin: 15px 0;
}

@media (min-width: 480px) {
    .hp-navigation__draws {
        margin: 12px 0;
    }
}

@media (max-width: 479px) {
    .hp-navigation__draws {
        margin: 25px 0;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.hp-navigation__draws > div:not(:last-child) {
    margin-right: 10px;
}

@media (max-width: 479px) {
    .hp-navigation__draws > div:not(:last-child) {
        margin-right: 15px;
    }
}

@media (max-width: 479px) {
    .hp-navigation__draws
        > div:not(:last-child):not(:nth-last-child(2)):not(:last-child) {
        margin-bottom: 15px;
    }
}

@media (max-width: 435px) {
    .hp-navigation__draws > div:not(:last-child):nth-child(3) {
        margin-right: 0;
    }
}

.hp-navigation__draws--number {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    border: 2px solid #faf188;
}

@media (max-width: 479px) {
    .hp-navigation__draws--number {
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 376px) {
    .hp-navigation__draws--number {
        width: 65px;
        height: 65px;
    }
}

.hp-navigation__draws span {
    color: #faf188;
    font-size: 21px;
    padding: 10px 0 6px;
}

.hp-navigation__info--price,
.hp-navigation__info--price span {
    font-size: 24px !important;
    font-family: Product-Sans-Bold !important;
}

@media (max-width: 376px) {
    .hp-navigation__draws span {
        font-size: 27px;
    }
}

@media (max-width: 1199px) {
    .hp-navigation__info {
        margin-bottom: 15px;
    }
}

@media (max-width: 575px) {
    .hp-navigation__draws span {
        font-size: 33px;
    }

    .hp-navigation__info {
        margin-bottom: 0;
    }

    .hp-navigation__info--price {
        font-size: 18px !important;
    }

    .hp-navigation__info--box > div span:last-child {
        font-size: 18px !important;
    }
}

@media (min-width: 1200px) {
    .hp-navigation__info [class*="col-"] {
        margin-bottom: 10px;
    }
}

@media (max-width: 1199px) {
    .hp-navigation__info [class*="col-"]:not(:last-child) {
        margin-bottom: 10px !important;
    }
}

.hp-navigation__info--box {
    padding: 10px 25px 6px;
    background-color: var(--iq-pink);
    display: -webkit-box;
    display: flex;
    border-radius: 10px;
    height: 35px;
    position: relative;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 479px) {
    .hp-navigation__info--box {
        padding: 14px 14px 12px;
        height: unset;
    }
}

.hp-navigation__info--box:after {
    content: "";
    display: block;
    height: 18px;
    width: 1px;
    background-color: var(--iq-white);
    position: absolute;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    left: 50%;
    top: 0;
}

@media (max-width: 479px) {
    .hp-navigation__info--box:after {
        left: 50%;
        top: 5px;
    }
}

@media (max-width: 420px) {
    .hp-navigation__info--box:after {
        left: 55%;
    }
}

.hp-navigation__info--box p {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 13px;
    color: var(--iq-white);
}

@media (max-width: 479px) {
    .hp-navigation__info--box p {
        font-size: 17px;
    }
}

@media (max-width: 350px) {
    .hp-navigation__info--box p {
        font-size: 12px;
    }
}

.hp-navigation__info--box p strong {
    margin-right: 5px;
}

@media (max-width: 479px) {
    .hp-navigation__info--box p strong {
        font-size: 18px;
    }
}

.download {
    margin-bottom: 90px;
}

@media (max-width: 575px) {
    .download {
        margin-bottom: 50px;
    }
}

@media (max-width: 479px) {
    .download {
        margin-bottom: 30px;
    }
}

.download__bg {
    background-image: url(../img/download-bg-new.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 278px;
    position: relative;
    border-radius: 5px;
}

@media (max-width: 479px) {
    .download__bg {
        background-image: url(../img/download-bg-mobile-new-raffle.jpg);
        padding-bottom: 415px;
        background-position: 50%;
    }
}

@media (max-width: 375px) {
    .download__bg {
        padding-bottom: 445px;
        background-position-y: -20px;
    }
}

.download__content {
    position: absolute;
    max-width: 100%;
    top: 54px;
    left: 31px;
}

@media (max-width: 767px) {
    .download__content {
        padding-right: 20px;
    }
}

@media (max-width: 479px) {
    .download__content {
        top: 30px;
        left: 20px;
        padding-right: 20px;
        height: 100%;
        width: 100%;
        max-width: 100%;
    }
}

.download__content--text {
    margin-bottom: 35px;
    max-width: 500px;
}

@media (max-width: 479px) {
    .download__content--text {
        margin-bottom: 20px;
        max-width: 400px;
        padding-right: 20px;
    }
}

.download__content--text h2 {
    font-size: 40px;
}

@media (max-width: 479px) {
    .download__content--text h2 {
        font-size: 26px;
        text-align: center;
    }
}

@media (max-width: 375px) {
    .download__content--text h2 {
        font-size: 22px;
    }
}

.download__platforms {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 479px) {
    .download__platforms {
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: unset;
        align-items: unset;
        position: absolute;
        bottom: 60px;
        width: 100%;
        max-width: 100%;
        padding-right: 20px;
    }
}

@media (max-width: 375px) {
    .download__platforms {
        bottom: 67px;
    }
}

@media (max-width: 479px) {
    .download__platforms p {
        margin-right: 0;
        margin-bottom: 10px;
        text-align: center;
        padding-right: 20px;
    }
}

@media (max-width: 375px) {
    .download__platforms p {
        margin-bottom: 0;
        font-size: 18px;
    }
}

.download__buttons {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 479px) {
    .download__buttons {
        width: 100%;
        max-width: 100%;
        padding-right: 20px;
    }
}

@media (max-width: 375px) {
    .download__buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 479px) {
    .download__buttons a {
        width: 50%;
    }
}

@media (max-width: 375px) {
    .download__buttons a {
        width: 100%;
    }
}

.download__buttons a:first-child {
    margin-right: 20px;
}

@media (max-width: 479px) {
    .download__buttons a:first-child {
        margin: 0 15px 0 0;
    }
}

@media (max-width: 375px) {
    .download__buttons a:first-child {
        margin: 0 0 5px;
    }
}

.download__buttons a h3 strong {
    font-size: 18px;
}

@media (max-width: 479px) {
    .download__buttons a h3 strong {
        font-size: 17px;
    }
}

@media (max-width: 479px) {
    .download__buttons a > div {
        text-align: left;
        width: 100%;
    }
}

.download__buttons a > div img {
    max-width: 100%;
    margin-right: 13px;
    width: 30px;
    height: 33px;
}

@media (max-width: 479px) {
    .download__buttons a > div img {
        margin-right: 5px;
        width: 25px;
        height: 28px;
    }
}

.downloadbanner {
    margin-bottom: 28px;
}

@media (max-width: 991px) {
    .downloadbanner {
        margin-bottom: 20px;
    }
}

.downloadbanner__inner {
    position: relative;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.downloadbanner__inner:hover {
    opacity: 0.9;
}

.downloadbanner__img img {
    max-width: 100%;
}

@media (max-width: 479px) {
    .downloadbanner__img.desktop {
        display: none;
    }
}

@media (min-width: 480px) {
    .downloadbanner__img.mobile {
        display: none;
    }
}

.downloadbanner__buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.downloadbanner__buttons a {
    width: 50%;
    height: 100%;
    display: block;
}

.slick-slider {
    margin-bottom: 25px;
}

.login {
    margin: 93px 0 73px;
}

@media (max-width: 575px) {
    .login {
        margin: 30px 0 70px;
    }
}

.login__content {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.login__content .form {
    width: 621px;
    max-width: 100%;
}

@media (max-width: 575px) {
    .login__content .form h1 {
        text-align: left;
        margin-bottom: 30px;
    }
}

@media (max-width: 479px) {
    .login__content .form h1 {
        font-size: 18px;
    }
}

.login__content .form form .form-group:first-of-type {
    margin-bottom: 30px;
}

@media (max-width: 479px) {
    .login__content .form form .form-group:first-of-type {
        margin-bottom: 15px;
    }
}

@media (max-width: 575px) {
    .login__content .form form .form-group .input-row .info-icon,
    .login__content .form form .form-group .input-row span {
        top: 106px;
    }
}

@media (max-width: 479px) {
    .login__content .form form {
        padding: 20px 15px;
    }
}

@media (max-width: 479px) {
    .login__content .form form .form-group label {
        font-size: 14px;
    }
}

.login__content .form form button[type="submit"] {
    width: 236px;
    height: 60px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
}

@media (max-width: 479px) {
    .login__content .form form button[type="submit"] {
        width: 100%;
        max-width: 100%;
    }
}

.login__content .form form .alert__img img {
    max-width: 81px;
}

@media (max-width: 479px) {
    .login__content .form form .alert__img img {
        max-width: 67px;
    }
}

.login__content--link {
    font-size: 17px;
    display: block;
    color: var(--iq-pink);
    margin-top: 10px;
}

.register {
    margin: 77px 0 73px;
}

@media (max-width: 575px) {
    .register {
        margin: 30px 0 70px;
    }
}

.register__content .form form .form-group {
    margin-bottom: 30px;
}

@media (max-width: 479px) {
    .register__content .form form .form-group {
        margin-bottom: 15px;
    }
}

.register__content .form form .form-group.check,
.register__content .form form .form-group.last {
    margin-bottom: 20px;
}

.register__content .form form .form-group .information-text {
    font-size: 13px;
    color: var(--iq-darkgrey);
    line-height: 23px;
    margin-top: 13px;
}

.register__content .form form .form-group .information-text.secondary {
    margin-top: 0;
    margin-bottom: 5px;
}

@media (max-width: 479px) {
    .register__content .form form {
        padding: 20px 15px;
    }
}

.register__content .form form .info-box {
    background: hsla(0, 0%, 78.4%, 0.25);
    padding: 15px 20px;
    border-radius: 5px;
    margin-bottom: 40px;
}

@media (max-width: 479px) {
    .register__content .form form .info-box {
        margin-bottom: 20px;
    }
}

.register__content .form form .info-box p {
    font-size: 14px;
    line-height: 25px;
    color: var(--iq-darkblack);
}

.register__content .form form .alert__img img {
    max-width: 124px;
}

@media (max-width: 479px) {
    .register__content .form form .alert__img img {
        max-width: 87px;
    }
}

.form {
    max-width: 100%;
}

.form h1,
.form h2 {
    color: var(--iq-darkblack);
    text-align: center;
    margin-bottom: 40px;
}

.form form {
    border: 1px solid var(--iq-dullgrey);
    padding: 40px 47px;
    border-radius: 5px;
}

.form form.style-reset {
    padding: 0;
    border: none;
    border-radius: none;
}

.form form .error-text {
    display: block;
}

.form form .error-text p {
    font-size: 14px;
    color: var(--iq-darkred);
}

.form form .information-text {
    font-size: 13px;
    color: var(--iq-darkgrey);
    line-height: 23px;
    margin-top: 13px;
}

.form form .information-text.secondary {
    margin-top: 0;
    margin-bottom: 5px;
}

.form form#form_myprofile .form-group {
    margin-bottom: 30px;
}

@media (max-width: 479px) {
    .form form#form_myprofile .form-group {
        margin-bottom: 18px;
    }
}

.form form#form_myprofile #form_myprofile_email-lang,
.form form#form_myprofile #form_myprofile_newsletter {
    margin-bottom: 25px;
}

.form form#form_myprofile button {
    width: 200px;
}

.form form#form_changepasswd {
    max-width: 520px;
}

.form form#form_changepasswd .form-group {
    margin-bottom: 30px;
}

@media (max-width: 479px) {
    .form form#form_changepasswd .form-group {
        margin-bottom: 18px;
    }
}

.form form#form_manage-account .form-group {
    padding-bottom: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--iq-dullgrey);
}

.form form#form_manage-account .form-group.success {
    padding-bottom: 0;
    border: none;
}

.form form#form_manage-account .form-group.last {
    padding-bottom: 0;
    margin: 30px 0;
    border-bottom: none;
    display: -webkit-box;
    display: flex;
}

@media (max-width: 479px) {
    .form form#form_manage-account .form-group.last {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.form form#form_manage-account .form-group.last input {
    width: 283px;
    margin-right: 15px;
}

@media (max-width: 479px) {
    .form form#form_manage-account .form-group.last input {
        width: 100%;
        max-width: 100%;
        margin-bottom: 14px;
    }
}

.form form#form_manage-account .form-group.last a {
    width: 200px;
}

@media (max-width: 479px) {
    .form form#form_manage-account .form-group.last a {
        width: 100%;
        max-width: 100%;
    }
}

.form form#form_manage-account .form-group__bottom {
    margin: 15px 0 0 38px;
    display: none;
}
.page-rtl .form form#form_manage-account .form-group__bottom {
    margin-right: 38px;
}

.form form#form_manage-account .form-group__bottom--text {
    font-size: 14px;
    line-height: 25px;
    color: var(--iq-darkgrey);
    margin-bottom: 13px;
}

.form form#form_manage-account .form-group__bottom label {
    margin-bottom: 10px;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 479px) {
    .form form#form_manage-account .form-group__bottom label {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.form
    form#form_manage-account
    .form-group__bottom
    label
    input[type="checkbox"] {
    width: 19px;
    height: 19px;
    -webkit-box-flex: 0;
    flex: 0 0 19px;
}

.form
    form#form_manage-account
    .form-group__bottom
    label
    input[type="checkbox"]:after {
    width: 7px;
    height: 7px;
    left: 5px;
    top: 5px;
}

@media (max-width: 479px) {
    .form
        form#form_manage-account
        .form-group__bottom
        label
        input[type="checkbox"] {
        margin-top: 4px;
    }
}

.form form#form_manage-account .form-group__bottom--options {
    margin-top: 25px;
}

@media (max-width: 767px) {
    .form form#form_manage-account .form-group__bottom--options {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.form form#form_manage-account .form-group__bottom.active {
    display: block;
}

.form form#form_manage-account textarea {
    margin-top: 35px;
    border-radius: 5px;
}

@media (max-width: 575px) {
    .form form#form_changepasswd button {
        width: 100%;
        max-width: 100%;
    }
}

.form form #form_deposit_amount .deposit--left,
.form form #form_deposit_amount .payment--box {
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 479px) {
    .form form #form_deposit_amount .input-box {
        margin-right: 10px;
    }
}

.form form #form_deposit_amount .input-box p {
    top: 16px;
}

.form form__close-account {
    padding-bottom: 35px;
}

.form form#form_new-password {
    border: none;
    padding: 0;
}

.form .form-group {
    position: relative;
}

.form .form-group.disabled input {
    opacity: 0.5;
}

.form .form-group label {
    color: var(--iq-darkblack);
    font-family: "Product-Sans-Bold";
}

.favourite-numbers__content .form .form-group label {
    color: #434343;
}

.form .form-group label sup {
    color: var(--iq-darkred);
}

.form .form-group .info-icon {
    position: absolute;
    right: 15px;
    top: 45px;
}

.form .form-group span {
    width: 103px;
    height: 32px;
    color: var(--iq-white);
    background-color: var(--iq-orange);
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    top: 43px;
    right: 40px;
    padding: 10px 0 5px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.form .form-group span:hover {
    background-color: var(--iq-orange);
}

@media (max-width: 575px) {
    .form .form-group span {
        width: 82px;
    }

    .page-rtl .form .form-group label {
        font-size: 14px;
    }

    .favourite-numbers__content .form .form-group label {
        color: #212529;
    }
}

.form .form-group .captcha-wrapper {
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.form .form-group .captcha-wrapper,
.form .form-group .captcha-wrapper .send_otp {
    margin-top: 20px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.form .form-group .captcha-wrapper .send_otp {
    width: 100%;
    max-width: 100%;
    height: 50px;
    background-color: var(--iq-pink);
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: unset;
    top: unset;
    right: unset;
    left: unset;
}

@media (max-width: 767px) {
    .form .form-group .captcha-wrapper .send_otp {
        margin-left: 0;
    }
}

.form .form-group .captcha-wrapper .send_otp p {
    color: var(--iq-white);
}

.form .form-group .captcha-wrapper .send_otp.disabled {
    background-color: var(--iq-dullgrey);
    cursor: default;
    pointer-events: none;
}

.form .form-group .captcha-wrapper .send_otp.disabled:hover {
    background-color: #797979;
}

.form .form-group .captcha-wrapper .send_otp_timer {
    display: none;
}

.form .form-group .captcha-wrapper .send_otp span {
    width: unset;
    height: unset;
    display: inline-block;
    background-color: unset;
    top: unset;
    right: unset;
    left: unset;
    padding: 0;
    position: unset;
    border-radius: 0;
    cursor: default;
}

.form .form-group.check label {
    display: -webkit-box;
    display: flex;
    cursor: pointer;
}

.form .form-group.check label p {
    font-size: 14px;
    line-height: 25px;
    color: var(--iq-darkgrey);
}

.form .form-group.check label a {
    color: #fa9b63;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.form .form-group.check label a:hover {
    color: #fa9b63;
}

.form .form-group.check label .check-text h3 {
    color: var(--iq-darkblack);
    margin: 5px 0;
}

.form .form-group.check label .check-text p:not(:last-child) {
    margin-bottom: 10px;
}

.form .form-group.error input {
    border-color: var(--iq-darkred) !important;
}

.form .form-group.error .input-box input {
    border-color: var(--iq-dullgrey);
}

.form .form-group.error .selectize-input {
    border-color: var(--iq-darkred);
}

.form .form-group.error .flag-picker .selectize-input {
    border-color: #d0d0d0;
}

.form .form-group.error .error-text {
    display: block;
}

.form .form-group.error .error-text p {
    font-size: 14px;
    color: var(--iq-darkred);
    margin-top: 15px;
}

.form .form-group.success .success-text {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.form .form-group .error-text {
    display: none;
}

.form .form-group .success-text {
    display: none;
    padding: 10px 20px;
    background-color: rgba(132, 189, 0, 0.25);
    margin-top: 15px;
}

.form .form-group .success-text p {
    font-size: 14px;
    color: #84bd00;
}

.form .form-group .success-text-secondary {
    padding: 0;
    background-color: unset;
    margin-bottom: 10px;
}

.form .form-group .success-text-secondary p {
    font-size: 14px;
    color: #84bd00;
}

.form .form-group .input-row {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 575px) {
    .form .form-group .input-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.form .form-group .input-row .flag-picker {
    min-width: 165px;
    width: 165px;
    max-width: 100%;
    margin-right: 15px;
    position: relative;
}

@media (max-width: 575px) {
    .form .form-group .input-row .flag-picker {
        margin-bottom: 13px;
    }
}

.form .form-group .input-row .flag-picker-number {
    width: 81px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 5px 5px 0;
    padding: 0 10px;
}

.form
    .form-group
    .input-row
    .flag-picker
    .selectize-control.single
    .selectize-input.input-active {
    cursor: pointer;
}

.form .form-group .flag-picker-input {
    max-width: 100%;
}

@media (max-width: 575px) {
    .form .form-group#form_myprofile_phone .info-icon,
    .form .form-group#form_myprofile_phone span {
        top: 106px;
    }
}

.form .form-group--download {
    margin-bottom: 0;
    padding-top: 10px;
    border-top: 1px solid var(--iq-dullgrey);
}

.form .form-group__bottom--options {
    display: -webkit-box;
    display: flex;
}

.form .form-group__bottom--select {
    max-width: 285px;
}

.form .form-group__options h4 {
    font-size: 14px !important;
    color: var(--iq-darkblack);
}

.form .form-group__options:first-child {
    margin-right: 140px;
}

@media (max-width: 575px) {
    .form .form-group__options:first-child {
        width: 100%;
        max-width: 100%;
    }
}

.form .form-group__options ul {
    padding-left: 0;
    list-style-type: none;
}

.form .form-group__options ul li {
    font-size: 14px;
    color: var(--iq-darkgrey);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.form .form-group__options ul li:not(:last-child) {
    margin-bottom: 7px;
}

.form .form-group__options--red {
    background-color: var(--iq-darkred);
}
.form .form-group__options--green {
    background-color: green;
}

.form .form-group__options--green,
.form .form-group__options--red {
    width: 17px;
    height: 17px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 6px;
}

.form .form-group .input-box {
    width: 170px;
    max-width: 100%;
    position: relative;
}

@media (max-width: 479px) {
    .form .form-group .input-box {
        width: 170px;
    }
}

.form .form-group .input-box input {
    text-align: right;
    height: 43px;
}

.form .form-group .input-box p {
    color: var(--iq-darkblack);
    position: absolute;
    top: 16px;
    left: 10px;
}

@media (max-width: 479px) {
    .form .form-group .input-box p {
        font-size: 14px;
    }
}

.form .form-group .input-box--currency {
    display: none;
    font-size: 14px;
}

@media (max-width: 479px) {
    .form .form-group .input-box--currency {
        font-size: 12px;
    }
}

.form .form-group .input-box--currency.active {
    display: block;
}

@media (max-width: 991px) {
    .form .form-group .input-box--currency {
        display: none;
    }
}

.form .form-group .deposit--left {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media (max-width: 991px) {
    .form .form-group .deposit--left {
        margin-right: 0;
    }
}

.form .form-group .deposit--left p.input-box--currency {
    margin-top: 5px;
    font-size: 12px;
    font-style: italic;
}

.form a.forgot-pw {
    font-size: 17px;
    display: block;
    color: var(--iq-pink);
    margin: 10px 0 30px;
}

.form .alert,
.form .login-alert {
    display: -webkit-box;
    display: flex;
    padding: 25px;
    background: var(--iq-white);
    border-radius: 5px;
    margin-top: 40px;
    display: none;
}

@media (max-width: 575px) {
    .form .alert,
    .form .login-alert {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 25px 15px;
        margin-top: 20px;
    }
}

.form .alert__img,
.form .login-alert__img {
    margin-right: 40px;
}

@media (max-width: 575px) {
    .form .alert__img,
    .form .login-alert__img {
        margin-right: 0;
    }
}

.form .alert__text,
.form .login-alert__text {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .form .alert__text,
    .form .login-alert__text {
        margin-top: 15px;
    }
}

.form .alert__text p,
.form .login-alert__text p {
    color: var(--iq-brown);
    margin-bottom: 20px;
}

@media (max-width: 575px) {
    .form .alert__text p,
    .form .login-alert__text p {
        font-size: 17px;
    }
}

.form .alert__text--button,
.form .login-alert__text--button {
    width: 145px;
}

@media (max-width: 575px) {
    .form .alert__text--button,
    .form .login-alert__text--button {
        width: 100%;
    }
}

.form .password-alert {
    display: -webkit-box;
    display: flex;
    margin-top: 18px;
}

.form .password-alert .alert__text p {
    font-size: 14px;
    margin-bottom: 0;
}

.forgotpw {
    margin: 77px 0 73px;
}

@media (max-width: 575px) {
    .forgotpw {
        margin: 30px 0 70px;
    }
}

.forgotpw__reset {
    margin-top: 10px;
}

.forgotpw__content,
.forgotpw__reset {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.forgotpw__content .form {
    width: 621px;
    max-width: 100%;
}

.forgotpw__content .form h1 {
  margin-bottom: 15px;
  font-weight: normal;
}

@media (max-width: 575px) {
    .forgotpw__content .form h1 {
        text-align: center;
    }
}

.forgotpw__content .form p {
    color: var(--iq-darkblack);
    line-height: 28px;
    text-align: center;
    margin-bottom: 38px;
}

@media (max-width: 479px) {
    .forgotpw__content .form p {
        font-size: 14px;
        margin-bottom: 30px;
    }
}

.forgotpw__content .form p.phone-labeltext {
    color: #797979;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: unset;
    text-align: unset;
}

.forgotpw__content .form form #form_forgotpw_email span {
    width: 185px;
    height: 50px;
    position: unset;
    right: unset;
}

.forgotpw__content .form form #form_forgot_mobile-number {
    margin-bottom: 23px;
}

.forgotpw__content .form form .form-divider {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    margin: 15px 0;
}

.forgotpw__content .form form .form-divider:before {
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    border-bottom: 1px dashed var(--iq-darkblack);
    position: absolute;
    z-index: 0;
    top: 10px;
}

.forgotpw__content .form form .form-divider span {
    color: var(--iq-pink);
    text-align: center;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    background-color: var(--iq-white);
}

.forgotpw__content .form form .form-group .error-text p {
    text-align: left;
    margin-bottom: 0;
}

.forgotpw__content .form form .form-group .success-text p {
    margin-bottom: 0;
}

.forgotpw__content .form form button[type="submit"] {
    margin-top: 30px;
    width: 236px;
    height: 60px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 479px) {
    .forgotpw__content .form form button[type="submit"] {
        width: 100%;
        max-width: 100%;
    }
}

.number-picker {
    margin-bottom: 80px;
}

@media (max-width: 479px) {
    .number-picker {
        margin-bottom: 50px;
    }
}

.number-picker__content {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
}

.number-picker .error-text {
    background-color: rgba(213, 0, 50, 0.1);
    border-radius: 5px;
    padding: 15px 20px;
    display: none;
}

@media (max-width: 479px) {
    .number-picker .error-text {
        padding: 15px 12px;
    }
}

.number-picker .error-text.active {
    display: block;
}

.number-picker .error-text p {
    font-size: 14px;
    color: var(--iq-darkred);
}

.number-picker .blue-box {
    background-color: var(--iq-mildgrey);
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 15px 20px;
}

@media (max-width: 479px) {
    .number-picker .blue-box {
        padding: 15px 12px;
    }
}

@media (max-width: 479px) {
    .number-picker .blue-box p {
        font-size: 14px;
    }
}

.number-picker__picker {
    width: 234px;
    background-color: var(--iq-white);
    border: 2px solid var(--iq-pink);
    border-radius: 5px;
    margin: 0 14px 14px 0;
}

@media (max-width: 575px) {
    .number-picker__picker {
        margin-right: 0;
        width: 100%;
    }
}

.number-picker__picker:nth-child(5n) {
    margin-right: 0;
}

@media (max-width: 1255px) {
    .number-picker__picker:nth-child(5n) {
        margin-right: 14px;
    }
}

@media (max-width: 575px) {
    .number-picker__picker:nth-child(5n) {
        margin-right: 0;
    }
}

@media (max-width: 1255px) {
    .number-picker__picker:nth-child(4n) {
        margin-right: 0;
    }
}

@media (max-width: 1200px) {
    .number-picker__picker:nth-child(4n) {
        margin-right: 14px;
    }
}

@media (max-width: 991px) {
    .number-picker__picker:nth-child(4n) {
        margin-right: 0;
    }
}

@media (max-width: 1200px) {
    .number-picker__picker:nth-child(3n) {
        margin-right: 0;
    }
}

@media (max-width: 991px) {
    .number-picker__picker:nth-child(3n) {
        margin-right: 14px;
    }
}

@media (max-width: 575px) {
    .number-picker__picker:nth-child(3n) {
        margin-right: 0;
    }
}

@media (max-width: 991px) {
    .number-picker__picker:nth-child(2n) {
        margin-right: 0;
    }
}

.number-picker__picker.pickerFull .number-picker__numbers {
    background-image: url(../img/background-check.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-position-y: 44%;
}

.number-picker__picker.pickerFull .number-picker__picker--bottom.private {
    background-color: var(--iq-white);
}

.number-picker__picker.pickerFull
    .number-picker__picker--bottom.private
    a.number-picker__picker--favourites {
    display: -webkit-box;
    display: flex;
}

.number-picker__picker.disabled {
    border-color: var(--iq-dullgrey);
}

@media (max-width: 575px) {
    .number-picker__picker.disabled {
        display: none;
    }
}

.number-picker__picker.disabled .number-picker__picker--name {
    background-color: var(--iq-dullgrey);
}

.number-picker__picker.disabled .number-picker__picker--bottom {
    border-color: var(--iq-dullgrey);
    background: var(--iq-dullgrey);
    display: none;
}

.number-picker__picker.disabled .number-picker__picker--bottom.private {
    background-color: var(--iq-white);
}

.number-picker__picker.disabled
    .number-picker__picker--bottom.private
    a.number-picker__picker--favourites {
    display: -webkit-box;
    display: flex;
}

.number-picker__picker.disabled .number-picker__numbers div {
    cursor: auto;
}

.number-picker__picker.disabled .number-picker__numbers div:hover {
    border-color: rgba(0, 0, 0, 0.1);
}

.number-picker__picker--name {
    background-color: var(--iq-pink);
    display: -webkit-box;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 12px 0;
}

.number-picker__picker--name p,
.number-picker__picker--name span {
    color: var(--iq-white);
    text-transform: uppercase;
}

.number-picker__picker--name span {
    margin-left: 5px;
}

.number-picker__picker--bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 10px 20px;
    background: rgba(0, 169, 224, 0.24);
    border-top: 2px solid var(--iq-pink);
}

.number-picker__picker--bottom div {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
}

.number-picker__picker--bottom div:hover,
.number-picker__picker--favourite-numbers:hover,
.number-picker__picker--favourites:hover {
    opacity: 0.7;
}

.number-picker__picker--bottom.private {
    border-top: none;
    background-color: var(--iq-white);
    padding: 0;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.number-picker__picker--bottom.private a {
    width: 50%;
    padding: 12px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.number-picker__picker--bottom.private a:first-child {
    border-right: 1px solid var(--iq-dullgrey);
}

.number-picker__picker--bottom.private a.number-picker__picker--favourites {
    display: none;
}

.number-picker__numbers {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

@media (max-width: 575px) {
    .number-picker__numbers {
        width: 293px;
        padding: 20px 10px;
        margin: 0 auto;
    }
}

.number-picker__numbers .betNum:nth-child(5n) {
    margin-right: 0;
}

@media (max-width: 575px) {
    .number-picker__numbers .betNum:nth-child(5n) {
        margin-right: 7px;
    }
}

.number-picker__numbers .betNum:hover {
    border-color: #fce300;
}

.number-picker__line {
    height: 1px;
    width: 100%;
    max-width: 100%;
    background-color: var(--iq-dullgrey);
    margin: 40px 0 25px;
}

@media (max-width: 575px) {
    .number-picker__line.desktop {
        display: none;
    }
}

.number-picker__line.mobile {
    margin: 20px 0;
}

@media (min-width: 576px) {
    .number-picker__line.mobile {
        display: none;
    }
}

.number-picker__bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    align-items: flex-end;
}

@media (max-width: 1199px) {
    .number-picker__bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.number-picker__bottom--right.tablet {
    width: 100%;
    max-width: 100%;
}

@media (min-width: 992px) {
    .number-picker__bottom--right.tablet {
        display: none;
    }
}

@media (max-width: 575px) {
    .number-picker__bottom--left {
        width: 100%;
        max-width: 100%;
    }
}

.number-picker .selectize-input.items.not-full.has-options input {
    color: var(--iq-orange);
}

.number-picker__select {
    width: 260px;
    max-width: 100%;
}

@media (max-width: 1199px) {
    .number-picker__select {
        margin-bottom: 20px;
    }
}

.number-picker__select p {
    color: var(--iq-orange);
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 18px;
}
.number-picker__select p span {
    font-size: 18px;
}

@media (max-width: 575px) {
    .number-picker__select.desktop,
    .number-picker__select .selectize {
        width: 100%;
        max-width: 100%;
    }
}

.number-picker__buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 991px) {
    .number-picker__buttons {
        flex-wrap: wrap;
    }
}

.number-picker__buttons span {
    cursor: pointer;
    margin-right: 8px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 767px) {
    .number-picker__buttons span {
        margin-bottom: 10px;
    }
}

@media (max-width: 575px) {
    .number-picker__buttons span {
        width: 100% !important;
        -webkit-box-flex: 0 !important;
        flex: none !important;
        margin-right: 0;
        text-align: center;
    }
}

.number-picker__buttons span:hover {
    opacity: 0.9;
}

.number-picker__buttons span img {
    margin-right: 14px;
}

@media (max-width: 575px) {
    .number-picker__buttons span img {
        display: none;
    }
}

@media (max-width: 991px) {
    .number-picker__buttons span:first-child {
        -webkit-box-flex: 0;
    }
}

@media (max-width: 767px) {
    .number-picker__buttons span:first-child {
        -webkit-box-flex: 0;
        flex: 0 0 49%;
        width: 49%;
    }
}

.number-picker__buttons span:nth-child(2) {
    background-color: var(--iq-pink);
    color: var(--iq-white);
}

@media (max-width: 991px) {
    .number-picker__buttons span:nth-child(2) {
        -webkit-box-flex: 0;
        flex: 0 0 33%;
        width: 33%;
    }
}

@media (max-width: 767px) {
    .number-picker__buttons span:nth-child(2) {
        -webkit-box-flex: 0;
        flex: 0 0 49%;
        width: 49%;
        margin-right: 0;
    }
}

.number-picker__buttons span:nth-child(3) {
    background-color: #dedede;
    color: var(--iq-black);
}

@media (max-width: 991px) {
    .number-picker__buttons span:nth-child(3) {
        -webkit-box-flex: 0;
        flex: 0 0 31%;
        width: 31%;
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .number-picker__buttons span:nth-child(3) {
        -webkit-box-flex: 0;
        flex: 0 0 49%;
        width: 49%;
        margin-right: 8px;
        margin-bottom: 0;
    }
}

.number-picker__buttons button {
    padding: 15px 20px;
    height: unset;
}

@media (max-width: 575px) {
    .number-picker__buttons button {
        margin: 0 0 8px;
        width: 100%;
        max-width: 100%;
    }
}

.number-picker__buttons a {
    padding: 15px 20px;
    height: unset;
    margin-right: 8px;
}

@media (max-width: 575px) {
    .number-picker__buttons a {
        margin: 0 0 8px;
        width: 100%;
        max-width: 100%;
    }
}

.number-picker__buttons--submit {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 4px 4px 4px 15px;
    border: 1px solid var(--iq-dullgrey);
    border-radius: 5px;
}

@media (max-width: 991px) {
    .number-picker__buttons--submit {
        max-width: 100%;
        margin-top: 20px;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-flex: 0;
        flex: 0 0 35%;
        width: 35%;
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .number-picker__buttons--submit {
        -webkit-box-flex: 0;
        flex: 0 0 49%;
        width: 49%;
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    .number-picker__buttons--submit {
        width: 100%;
        -webkit-box-flex: 0;
        flex: none;
        border: none;
        padding: 0;
    }
}

.number-picker__buttons--submit p {
    margin-right: 16px;
    margin-top: 5px;
}

@media (max-width: 575px) {
    .number-picker__buttons--submit p {
        padding: 0 15px;
        border: 1px solid var(--iq-dullgrey);
        border-radius: 5px;
        height: 48px;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin-top: 0;
        min-width: 110px;
    }
}

.number-picker__buttons--submit p span {
    background-color: transparent !important;
    color: initial !important;
    margin-left: 0;
    margin-right: 5px;
}

@media (max-width: 575px) {
    .number-picker__buttons--submit button {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 575px) {
    .number-picker #number-picker__price {
        min-width: 140px;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

@media (max-width: 575px) {
    .number-picker #number-picker__price span {
        max-width: 50px;
        margin-bottom: 0;
    }
}

.loading-wrapper {
    display: none;
    position: fixed;
    z-index: 10010;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loading-wrapper:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.loading-wrapper.active {
    display: block;
}

.loading {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 10px solid var(--iq-white);
    border-top-color: var(--iq-pink);
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 999;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

.loading-secondary {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--iq-white);
    display: none;
    z-index: 10010;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loading-secondary:before {
    content: none;
}

.loading-secondary.active {
    display: block;
}

.loading-secondary .loading {
    position: absolute;
}

.shopping-cart {
    margin: 0 0 100px;
}

@media (max-width: 991px) {
    .shopping-cart {
        margin: 0 0 50px;
    }
}

.shopping-cart iframe {
    border: none;
}

.shopping-cart__content--top {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 64px;
}

@media (max-width: 991px) {
    .shopping-cart__content--top {
        margin-bottom: 20px;
    }
}

.shopping-cart__content--top h1 {
    color: var(--iq-darkblack);
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .shopping-cart__content--top h1 {
        font-size: 18px;
    }
}

.shopping-cart__content--top-img img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.shopping-cart__content--top-img img:not(:last-child) {
    margin-right: 18px;
}

@media (max-width: 380px) {
    .shopping-cart__content--top-img img:not(:last-child) {
        margin-right: 8px;
    }
}

@media (max-width: 991px) {
    .shopping-cart__content--top-img img:first-child {
        width: 34px;
    }

    .shopping-cart__content--top-img img:nth-child(2) {
        width: 27px;
    }

    .shopping-cart__content--top-img img:nth-child(3) {
        width: 34px;
    }
}

@media (max-width: 360px) {
    .shopping-cart__content--top-img img:first-child {
        width: 28px;
    }

    .shopping-cart__content--top-img img:nth-child(3) {
        width: 70px;
        width: 28px;
    }
}

.shopping-cart__content--bottom p {
    color: var(--iq-black);
    text-align: center;
}

@media (max-width: 479px) {
    .shopping-cart__content--bottom p {
        font-size: 14px;
    }
}

.shopping-cart__content--btn {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.shopping-cart__content--btn a {
    width: 135px;
    max-width: 100%;
    height: 30px;
    padding: 15px 20px;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .shopping-cart__content .cart > div {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }

    .shopping-cart__content .cart > div:last-child {
        -webkit-box-ordinal-group: 4;
        order: 3;
    }

    .shopping-cart__content .cart > div.cart__purchase-note {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}

.shopping-cart__content .cart .error-text {
    margin-top: 15px;
}

.shopping-cart__content .cart .error-text p {
    font-size: 13px;
}

@media (max-width: 575px) {
    .shopping-cart__content .cart .error-text {
        margin-top: 5px;
    }
}

.shopping-cart__content .cart__top {
    display: -webkit-box;
    display: flex;
    padding-bottom: 17px;
    border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__top {
        display: none;
    }
}

.shopping-cart__content .cart__top--title {
    position: relative;
}

.shopping-cart__content .cart__top--title:first-child {
    width: 25%;
}

.shopping-cart__content .cart__top--title:nth-child(2) {
    width: 45%;
}

.shopping-cart__content .cart__top--title:nth-child(3) {
    width: 15%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.shopping-cart__content .cart__top--title:last-child {
    width: 15%;
}

.shopping-cart__content .cart__top--title h3 {
    color: #797979;
    margin-bottom: 0;
}

.shopping-cart__content .cart__top--title span {
    color: #797979;
    margin-bottom: 0;
    width: 15px;
    height: 15px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid #797979;
    border-radius: 50%;
    font-size: 11px;
    padding-top: 4px;
    margin-left: 5px;
    cursor: pointer;
}

.shopping-cart__content .cart__top--title span:hover + .cart__top--titlehover {
    display: block;
}

.shopping-cart__content .cart__top--titlehover {
    position: absolute;
    background-color: var(--iq-white);
    width: 310px;
    text-align: center;
    z-index: 2;
    border: 1px solid #797979;
    border-radius: 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    top: 22px;
    left: -144px;
    display: none;
}

.shopping-cart__content .cart__top--titlehover p {
    color: #797979;
    line-height: 20px;
    padding: 10px 0;
}

.shopping-cart__content .cart__content {
    display: -webkit-box;
    display: flex;
    padding: 23px 0;
    border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        border-bottom: none;
        padding: 20px 0 0;
        background-color: var(--iq-white);
        box-shadow: 0 0 15px rgba(15, 15, 15, 0.09);
        margin-bottom: 20px;
    }
}

.shopping-cart__content .cart__details {
    width: 25%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__details {
        width: 100%;
        max-width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
        margin-bottom: 16px;
        padding: 0 18px;
    }
}

.shopping-cart__content .cart__details--wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.shopping-cart__content .cart__details--heading {
    font-size: 14px;
    color: #797979;
    margin-bottom: 15px;
}

@media (min-width: 992px) {
    .shopping-cart__content .cart__details--heading {
        display: none;
    }
}

.shopping-cart__content .cart__details--img {
    width: 95px;
    height: 95px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
}

.shopping-cart__content .cart__details--img img {
    max-width: 100%;
    width: 35px;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__details--img {
        width: 70px;
        height: 70px;
        margin-right: 15px;
    }

    .shopping-cart__content .cart__details--img img {
        width: 27px;
    }
}

.shopping-cart__content .cart__details--ticket {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.shopping-cart__content .cart__details--ticket p {
    color: var(--iq-black);
    margin-bottom: 0;
    margin-right: 5px;
}

.shopping-cart__content .cart__details--ticket span {
    color: var(--iq-black);
}

.shopping-cart__content .cart__numbers {
    width: 60%;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__numbers {
        width: 100%;
        max-width: 100%;
        padding: 0 18px;
    }
}

.shopping-cart__content .cart__numbers--heading {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.shopping-cart__content .cart__numbers--heading h3 {
    font-size: 14px;
    color: #797979;
    margin-bottom: 15px;
}

@media (max-width: 400px) {
    .shopping-cart__content .cart__numbers--heading h3 {
        font-size: 12px;
    }
}

.shopping-cart__content .cart__numbers--heading .cart__top--title {
    width: unset;
    display: -webkit-box;
    display: flex;
}

.shopping-cart__content .cart__numbers--heading .cart__top--titlehover {
    z-index: 2;
}

@media (min-width: 992px) {
    .shopping-cart__content .cart__numbers--heading {
        display: none;
    }
}

.shopping-cart__content .cart__numbers--row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.shopping-cart__content .cart__numbers--numbers {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__numbers--numbers {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.shopping-cart__content .cart__numbers--numbers:not(:last-child) {
    margin-bottom: 5px;
}

.shopping-cart__content .cart__numbers--numbers .betNum {
    margin-bottom: 0;
}

.shopping-cart__content .cart__numbers--numbers .betNum:not(:last-child) {
    margin-right: 5px;
}

.shopping-cart__content .cart__numbers--number-row {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-right: 90px;
}

@media (max-width: 575px) {
    .shopping-cart__content .cart__numbers--number-row {
        padding-right: 65px;
    }
}

@media (max-width: 400px) {
    .shopping-cart__content .cart__numbers--number-row {
        padding-right: 0;
    }
}

.shopping-cart__content .cart__selectboxes {
    display: -webkit-box;
    display: flex;
    margin-top: 10px;
}

@media (max-width: 575px) {
    .shopping-cart__content .cart__selectboxes {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.shopping-cart__content .cart__selectboxes--select {
    width: 190px;
}

@media (max-width: 575px) {
    .shopping-cart__content .cart__selectboxes--select {
        width: 100%;
        max-width: 100%;
    }
}

.shopping-cart__content
    .cart__selectboxes--select.error
    .selectize
    .selectize-input {
    border-color: var(--iq-darkred);
}

.shopping-cart__content .cart__selectboxes--select .selectize-control {
    height: 30px;
}

.shopping-cart__content .cart__selectboxes--select .selectize .selectize-input {
    font-size: 12px !important;
    height: 30px;
    border: 1px solid var(--iq-pink);
    color: var(--iq-pink) !important;
}

.shopping-cart__content
    .cart__selectboxes--select
    .selectize
    .selectize-input.input-active {
    display: -webkit-box;
    display: flex;
}

.shopping-cart__content
    .cart__selectboxes--select
    .selectize
    .selectize-input:after {
    line-height: 30px;
    color: var(--iq-pink);
    content: "" !important;
    font-size: 22px;
}

.shopping-cart__content
    .cart__selectboxes--select
    .selectize
    .selectize-dropdown {
    font-size: 12px !important;
    color: var(--iq-pink);
}

.shopping-cart__content
    .cart__selectboxes--select
    .selectize
    .selectize-control {
    height: 30px;
}

.shopping-cart__content .cart__selectboxes--select .selectize input,
.shopping-cart__content
    .cart__selectboxes--select
    .selectize
    input::-webkit-input-placeholder {
    font-size: 12px !important;
}

.shopping-cart__content .cart__selectboxes--select:not(:last-child) {
    margin-right: 5px;
}

@media (max-width: 575px) {
    .shopping-cart__content .cart__selectboxes--select:not(:last-child) {
        margin-right: 0;
        margin-bottom: 5px;
    }
}

.shopping-cart__content .cart__purchase--check {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.shopping-cart__content .cart__purchase--check input {
    margin-right: 0;
}

.shopping-cart__content .cart__purchase-note {
    padding: 23px 0;
    border-bottom: 1px solid var(--iq-dullgrey);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .shopping-cart__content .cart__purchase-note {
        display: inline-block;
    }
}

.shopping-cart__content .cart__purchase-note p {
    color: var(--iq-black);
    display: inline;
}

@media (max-width: 479px) {
    .shopping-cart__content .cart__purchase-note p {
        font-size: 14px;
    }
}

.shopping-cart__content .cart__purchase-note--btn {
    margin-left: 10px;
}

@media (max-width: 575px) {
    .shopping-cart__content .cart__purchase-note--btn {
        display: inline-block;
        margin: 0;
    }
}

.shopping-cart__content .cart__purchase-note--btn a {
    height: 30px;
    padding: 15px 10px 12px;
}

@media (max-width: 479px) {
    .shopping-cart__content .cart__purchase-note--btn a {
        font-size: 14px;
    }
}

.shopping-cart__content .cart__edit {
    width: 15%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__edit {
        width: 100%;
        max-width: 100%;
        padding: 5px 18px 12px;
        background-color: var(--iq-white);
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        margin-top: 0;
    }
}

.shopping-cart__content .cart__edit--buttons {
    display: -webkit-box;
    display: flex;
}

.shopping-cart__content .cart__edit button {
    background-color: transparent;
    border: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.shopping-cart__content .cart__edit button:hover {
    opacity: 0.8;
}

.shopping-cart__content .cart__info {
    padding: 22px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid var(--iq-dullgrey);
    position: relative;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__info {
        padding: 18px 0 0;
        border-bottom: 0;
        -webkit-box-pack: end;
        justify-content: flex-end;
    }
}

.shopping-cart__content .cart__info--img {
    display: none;
    width: 107px;
    height: 35px;
    position: absolute;
    top: 10px;
    left: 0;
}

.shopping-cart__content .cart__info--img img {
    max-width: 100%;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__info--img {
        display: block;
    }
}

.shopping-cart__content .cart__info--text {
    max-width: 533px;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__info--text {
        display: none;
    }
}

.shopping-cart__content .cart__info--text p {
    font-size: 13px;
    color: #797979;
    line-height: 26px;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__info--text p {
        font-size: 12px;
    }
}

.shopping-cart__content .cart__info--text p a {
    color: var(--iq-orange);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.shopping-cart__content .cart__info--text p a:hover {
    color: var(--iq-orange);
}

.shopping-cart__content .cart__summary {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: end;
    align-items: flex-end;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__summary {
        width: 100%;
        max-width: 100%;
    }
}

.shopping-cart__content .cart__summary--desktop {
    margin-top: 27px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: end;
    justify-content: flex-end;
    position: relative;
}

@media (min-width: 991px) {
    .shopping-cart__content .cart__summary--desktop {
        display: none;
    }
}

.shopping-cart__content .cart__summary--desktop .cart__summary--img {
    width: 107px;
    height: 35px;
    position: absolute;
    top: -7px;
    left: 0;
}

.shopping-cart__content .cart__summary--desktop .cart__summary--img img {
    max-width: 100%;
}

.shopping-cart__content .cart__summary > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.shopping-cart__content .cart__summary > div:not(:last-child) {
    margin-bottom: 12px;
}

.shopping-cart__content .cart__summary > div:first-child {
    margin-bottom: 5px;
}

.shopping-cart__content .cart__summary--price {
    -webkit-box-align: end;
    align-items: flex-end;
}

.shopping-cart__content .cart__summary--price p,
.shopping-cart__content .cart__summary--price span {
    color: var(--iq-darkblack);
}

.shopping-cart__content .cart__summary--discount {
    -webkit-box-align: end;
    align-items: flex-end;
}

.shopping-cart__content .cart__summary--discount p {
    color: var(--iq-darkblack);
    margin-right: 5px;
}

.shopping-cart__content .cart__summary--discount span {
    color: var(--iq-darkblack);
}

.shopping-cart__content .cart__summary--total {
    -webkit-box-align: end;
    align-items: flex-end;
}

.shopping-cart__content .cart__summary--total p {
    color: var(--iq-darkblack);
    margin-right: 5px;
}

.shopping-cart__content .cart__summary--total span {
    color: var(--iq-darkblack);
}

.shopping-cart__content .cart__summary--balance {
    -webkit-box-align: center;
    align-items: center;
}

.shopping-cart__content .cart__summary--balance p,
.shopping-cart__content .cart__summary--balance span {
    font-size: 14px;
    color: var(--iq-darkgrey);
}

.shopping-cart__content .cart__summary--balance p {
    margin-right: 5px;
}

.shopping-cart__content .cart__summary--topup a {
    font-size: 14px;
    color: var(--iq-pink);
    border-bottom: 1px solid var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__summary--topup a {
        text-align: right;
        border-bottom: 0;
    }
}

.shopping-cart__content .cart__summary--tbpaid {
    -webkit-box-align: end;
    align-items: flex-end;
}

.shopping-cart__content .cart__summary--tbpaid span {
    color: var(--iq-darkblack);
}

.shopping-cart__content .cart__summary--voucher {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: end;
    align-items: flex-end;
    width: 195px;
    max-width: 100%;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__summary--voucher {
        width: 100%;
        max-width: 100%;
    }
}

.shopping-cart__content .cart__summary--voucher.error .error-text,
.shopping-cart__content .cart__summary--voucher.success .success-text {
    display: block;
}

.shopping-cart__content .cart__summary--voucher input {
    padding: 10px !important;
    height: 40px !important;
    margin-bottom: 10px;
    text-align: center;
}

.shopping-cart__content
    .cart__summary--voucher
    input::-webkit-input-placeholder {
    opacity: 0.5;
    font-size: 13px;
    text-align: left;
}

.shopping-cart__content .cart__summary--voucher input::-moz-placeholder {
    opacity: 0.5;
    font-size: 13px;
    text-align: left;
}

.shopping-cart__content .cart__summary--voucher input:-ms-input-placeholder {
    opacity: 0.5;
    font-size: 13px;
    text-align: left;
}

.shopping-cart__content .cart__summary--voucher input::-ms-input-placeholder {
    opacity: 0.5;
    font-size: 13px;
    text-align: left;
}

.shopping-cart__content .cart__summary--voucher input::placeholder {
    opacity: 0.5;
    font-size: 13px;
    text-align: left;
}

.shopping-cart__content .cart__summary--voucher button {
    height: 35px;
    min-width: 100px;
    background-color: var(--iq-orange);
    color: var(--iq-white);
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__summary--voucher button {
        width: 100%;
        max-width: 100%;
    }
}

.shopping-cart__content .cart__summary--voucher .success-text {
    display: none;
    margin-top: 10px;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__summary--voucher .error-text {
        width: 100%;
        max-width: 100%;
        text-align: center;
    }
}

.shopping-cart__content .cart__summary--voucher .error-text p {
    font-size: 14px;
    color: var(--iq-darkred);
}

.shopping-cart__content .cart__summary--voucherinput {
    position: relative;
    width: 100%;
    max-width: 100%;
}

.shopping-cart__content .cart__summary--voucherinput .cart__summary--delete {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.shopping-cart__content .cart__summary--voucherinput i {
    color: grey;
    font-size: 21px;
}

.shopping-cart__content .cart__summary--wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.shopping-cart__content .cart__summary--wrapper > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.shopping-cart__content .cart__summary--wrapper > div:not(:last-child) {
    margin-bottom: 12px;
}

.shopping-cart__content .cart__summary--wrapper > div:first-child {
    margin-bottom: 5px;
}

.shopping-cart__content .cart__bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 34px;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin-top: 27px;
    }
}

.shopping-cart__content .cart__bottom--buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__bottom--buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
        margin-bottom: 15px;
    }
}

.shopping-cart__content .cart__bottom--checkout {
    display: -webkit-box;
    display: flex;
}

.shopping-cart__content .cart__bottom--checkout button {
    width: 180px;
    max-width: 100%;
    margin-left: 15px;
}
.shopping-cart__content .cart__bottom--checkout button:disabled {
    pointer-events: none;
    background-color: var(--iq-dullgrey) !important;
}
@media (max-width: 991px) {
    .shopping-cart__content .cart__bottom--checkout button {
        margin-left: 0;
        width: 100%;
        margin-bottom: 7px;
    }
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__bottom--img {
        display: none;
    }
}

.shopping-cart__content .cart__bottom--text {
    max-width: 533px;
}

@media (min-width: 992px) {
    .shopping-cart__content .cart__bottom--text {
        display: none;
    }
}

.shopping-cart__content .cart__bottom--text p {
    font-size: 13px;
    color: #797979;
    line-height: 26px;
}

@media (max-width: 991px) {
    .shopping-cart__content .cart__bottom--text p {
        font-size: 12px;
    }
}

.shopping-cart__content .cart__bottom--text p a {
    color: var(--iq-orange);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.shopping-cart__content .cart__bottom--text p a:hover {
    color: var(--iq-orange);
}

.shopping-cart__card .shopping-cart__box {
    -webkit-box-pack: unset;
    justify-content: unset;
}

.shopping-cart__card .shopping-cart__box--success {
    margin-bottom: 40px;
}

@media (max-width: 479px) {
    .shopping-cart__card .shopping-cart__box--success {
        margin-bottom: 20px;
    }
}

.shopping-cart__recurring {
    max-width: 950px;
    margin: 0 auto;
}

.shopping-cart__recurring .shopping-cart__box {
    margin-bottom: 40px;
}

@media (max-width: 479px) {
    .shopping-cart__recurring .shopping-cart__box {
        margin-bottom: 20px;
    }
}

.shopping-cart__recurring .shopping-cart__content--bottom p {
    margin-bottom: 40px;
}

@media (max-width: 479px) {
    .shopping-cart__recurring .shopping-cart__content--bottom p {
        margin-bottom: 20px;
    }
}

.shopping-cart__box {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 13px 35px;
}

@media (max-width: 991px) {
    .shopping-cart__box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        padding: 15px;
    }
}

.shopping-cart__box--img img {
    max-width: 100%;
}

.shopping-cart__box--empty .shopping-cart__box--img {
    max-width: 55px;
}

@media (max-width: 991px) {
    .shopping-cart__box--empty .shopping-cart__box--img {
        margin-bottom: 15px;
    }
}

.shopping-cart__box--empty .shopping-cart__box--right {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 991px) {
    .shopping-cart__box--empty .shopping-cart__box--right {
        width: 100%;
        max-width: 100%;
    }
}

.shopping-cart__box--empty .shopping-cart__box--right a {
    width: 163px;
    max-width: 100%;
}

@media (max-width: 991px) {
    .shopping-cart__box--empty .shopping-cart__box--right a {
        width: 100%;
        max-width: 100%;
    }
}

.shopping-cart__box--empty p {
    text-transform: uppercase;
    font-size: 30px;
    line-height: 35px;
    color: var(--iq-white);
    text-align: center;
}

@media (max-width: 991px) {
    .shopping-cart__box--empty p {
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        margin-bottom: 20px;
    }
}

.shopping-cart__box--empty a {
    color: var(--iq-white);
    font-size: 18px;
    letter-spacing: 0.5px;
    width: unset;
    border-radius: 15px;
    background: none;
    border: 1px solid var(--iq-white);
    text-transform: uppercase;
    padding: 9px 21px;
    margin: 15px 20px;
}

@media (max-width: 991px) {
    .shopping-cart__box--empty a {
        margin: 0;
        font-size: 20px;
        padding: 4px 15px;
    }
}

@media (max-width: 991px) {
    .shopping-cart__box--success .shopping-cart__box--img {
        margin-bottom: 20px;
    }
}

.shopping-cart__box--success .shopping-cart__box--right {
    display: -webkit-box;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media (max-width: 991px) {
    .shopping-cart__box--success .shopping-cart__box--right {
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
        max-width: 100%;
    }
}

.shopping-cart__box--success .shopping-cart__box--right a {
    width: 237px;
    max-width: 100%;
}

@media (max-width: 991px) {
    .shopping-cart__box--success .shopping-cart__box--right a {
        width: 100%;
    }
}

.shopping-cart__box--success .shopping-cart__box--right p {
    font-size: 30px;
    line-height: 35px;
    color: var(--iq-black);
    margin-bottom: 11px;
    text-align: center;
}

@media (max-width: 991px) {
    .shopping-cart__box--success .shopping-cart__box--right p {
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        margin-bottom: 20px;
    }
}

.shopping-cart__box--success .shopping-cart__box--right p span {
    color: var(--iq-yellow);
}

.shopping-cart__box--success .shopping-cart__box--right a {
    color: var(--iq-white);
    font-size: 16px;
    letter-spacing: 0.5px;
    width: unset;
    border-radius: 15px;
    background: none;
    border: 1px solid var(--iq-white);
    text-transform: uppercase;
    padding: 9px 21px;
    margin: 15px 20px;
}

@media (max-width: 991px) {
    .shopping-cart__box--success .shopping-cart__box--right a {
        margin: 0;
        font-size: 16px;
        padding: 4px 15px;
    }
}

@media (max-width: 575px) {
    .shopping-cart__box--success.secondary {
        padding: 15px 0 0;
    }
}
.shopping-cart__box .shopping-cart__box--right p.description {
    font-size: 18px;
}
.shopping-cart__box--right a {
    background-color: var(--iq-orange) !important;
}

.shopping-cart__box--withdraw-success {
    max-width: 950px;
    margin: 0 auto;
    background-color: var(--iq-mildgrey);
    border-radius: 32px;
    padding: 35px 60px 20px;
}

@media (max-width: 991px) {
    .shopping-cart__box--withdraw-success {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        padding: 15px;
    }
}

.shopping-cart__box--withdraw-success .shopping-cart__box--img {
    max-width: 155px;
}

@media (max-width: 991px) {
    .shopping-cart__box--withdraw-success .shopping-cart__box--img {
        margin-bottom: 20px;
    }
}

.shopping-cart__box--withdraw-success .shopping-cart__box--right {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media (max-width: 991px) {
    .shopping-cart__box--withdraw-success .shopping-cart__box--right {
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
    }
}

.shopping-cart__box--withdraw-success .shopping-cart__box--right a {
    width: 237px;
    max-width: 100%;
}

@media (max-width: 991px) {
    .shopping-cart__box--withdraw-success .shopping-cart__box--right a {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .shopping-cart__box--withdraw-success .shopping-cart__box--right p {
        font-size: 17px;
        max-width: 315px;
        text-align: center;
        margin-bottom: 20px;
        font-size: 17px;
    }
}

.shopping-cart__box--buttons {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 575px) {
    .shopping-cart__box--buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        background-color: var(--iq-white);
        width: 100%;
        padding-top: 15px;
    }
}

.shopping-cart__box--buttons a:first-child {
    margin-right: 30px;
}

@media (max-width: 575px) {
    .shopping-cart__box--buttons a:first-child {
        margin-bottom: 15px;
        margin-right: 0;
    }
}

.betNum {
    width: 32px;
    height: 32px;
    background-color: var(--iq-white);
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    margin: 0 7px 7px 0;
    cursor: pointer;
}

.betNum:hover {
    border-color: #fce300;
}

.betNumMarked {
    background-image: url(../img/yellow-ball-small.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    border-color: var(--iq-black);
}

.my-profile__nav {
    border-bottom: 1px solid var(--iq-orange);
}

@media (max-width: 575px) {
    .my-profile__nav {
        display: none;
    }
}

.my-profile__nav ul {
    padding-left: 0;
    list-style-type: none;
    display: -webkit-box;
    display: flex;
    margin-bottom: 0;
}

.my-profile__nav ul li {
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.my-profile__nav ul li:first-child a {
    padding-left: 35px;
}

.my-profile__nav ul li a {
    color: var(--iq-darkgrey);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    padding: 17px 20px 16px;
    display: block;
    border-bottom: 1px solid transparent;
}

.my-profile__nav ul li a.active,
.my-profile__nav ul li a:hover {
    color: var(--iq-white);
    background-color: var(--iq-orange);
}
.my-profile__nav ul li a:hover {
    color: var(--iq-darkblack) !important;
}
.my-profile__content {
    padding: 35px 35px 60px;
}

@media (max-width: 575px) {
    .my-profile__content {
        padding: 15px;
    }
}

.my-profile__content--bottom-text {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid var(--iq-dullgrey);
}

.my-profile__content--bottom-text p {
    font-size: 14px;
    color: var(--iq-darkgrey);
    line-height: 25px;
    margin-bottom: 15px !important;
}

.my-profile__content--bottom-text a {
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.myaccount {
    margin: 60px 0 145px;
}

@media (max-width: 992px) {
    .myaccount {
        margin: 10px 0 70px;
    }

    .myaccount__wrapper h2,
    .myaccount__content--heading h3 {
        font-size: 20px;
    }

    .myaccount__wrapper h2 {
        margin-bottom: 25px;
    }
}

@media (max-width: 575px) {
    .myaccount {
        margin: 25px 0 60px;
    }
}

.myaccount__content {
    border-radius: 0 4px 4px 0;
    min-height: 800px;
}

@media (max-width: 991px) {
    .myaccount__content {
        min-height: unset;
    }
}

@media (max-width: 575px) {
    .myaccount__content {
        border: none !important;
    }
}

.myaccount__content--heading h3 {
    color: #434343;
    margin-bottom: 33px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--iq-orange);
    font-size: 24px;
}

.myaccount__wrapper h2 {
    color: var(--iq-black);
    margin-bottom: 20px;
    text-transform: capitalize;
}

@media (max-width: 575px) {
    .myaccount__content--heading h3 {
        display: none;
        font-size: 18px;
        margin-bottom: 18px;
    }

    .myaccount__wrapper h2 {
        display: none;
    }
}

.myaccount__wrapper .myaccount--col:first-child {
    padding-right: 0;
}

@media (max-width: 991px) {
    .myaccount__wrapper .myaccount--col:first-child {
        display: none;
    }
}

.myaccount__wrapper .myaccount--col:last-child {
    padding-left: 0;
}

@media (max-width: 991px) {
    .myaccount__wrapper .myaccount--col:last-child {
        padding-left: 15px;
    }
}

@media (max-width: 575px) {
    .myaccount__wrapper .myaccount--col.mobile:first-child {
        display: block;
        padding-right: 15px;
    }
}

@media (min-width: 576px) {
    .myaccount__heading {
        display: none;
    }
}

.myaccount__heading h2 {
    color: var(--iq-black);
    font-size: 18px;
    display: block;
    margin-bottom: 30px;
}

.myaccount__sidebar {
    background: #f6f6f6;
    border-radius: 4px 0 0 4px;
    height: 100%;
    max-height: 100%;
    border: 1px solid var(--iq-orange);
    border-right: none;
}

.myaccount__sidebar ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.myaccount__sidebar ul li:first-child a {
    border-top-left-radius: 4px;
}

.myaccount__sidebar ul li a {
    color: var(--iq-darkblack);
    padding: 21px 20px 14px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-transform: capitalize;
    border-right: 1px solid var(--iq-white);
}

.myaccount__sidebar ul li a.active {
    color: var(--iq-white);
    background-color: var(--iq-orange);
    border-bottom: 1px solid var(--iq-white);
    padding: 21px 20px 14px;
}

.myaccount__sidebar ul li a.active:after {
    opacity: 1;
}

.myaccount__sidebar ul li a:hover {
    color: var(--iq-darkblack);
    background-color: var(--iq-orange);
    padding: 21px 20px 14px;
}

html[lang="ar"] .myaccount__sidebar ul li a {
    border-left: 1px solid var(--iq-white);
}

.myaccount__sidebar ul li a:hover:after {
    opacity: 1;
}

.myaccount__sidebar.mobile {
    border: none;
    background-color: var(--iq-white);
    height: unset;
}

.myaccount__sidebar.mobile ul li {
    border-bottom: none;
}

.myaccount__sidebar.mobile ul li a {
    background-color: var(--iq-white);
    border-bottom: 1px solid var(--iq-dullgrey);
}

.myaccount__content {
    border: 1px solid var(--iq-orange);
}

.myaccount__page-top {
    background-color: #f7f7f7;
    padding: 25px 20px 20px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px 4px 0 0;
}

.myaccount__page-top h3 {
    font-size: 18px;
    color: var(--iq-darkgrey);
    margin-bottom: 10px;
}

.myaccount__page-top h3 span {
    font-size: 18px;
    margin-left: 5px;
}

.myaccount__page-top p {
    font-size: 18px;
    color: var(--iq-darkblack);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
}

.myaccount__page-top p span {
    font-size: 18px;
    color: var(--iq-pink);
}

.manage-account .my-profile__content--bottom-text {
    border-top: none;
    padding-top: 0;
}

.manage-account .my-profile__content--bottom-text p:not(:last-child) {
    margin-bottom: 15px;
}

.ticket-history {
    padding: 32px 40px 40px;
}

.ticket-history .myaccount__content--heading h3 {
    margin-bottom: 0;
}

@media (max-width: 575px) {
    .ticket-history {
        padding: 20px 12px;
    }
}

@media (max-width: 575px) {
    .ticket-history--empty {
        padding: 0;
        border: none;
    }
}

.ticket-history__item {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 25px 0;
}

@media (max-width: 1199px) {
    .ticket-history__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 575px) {
    .ticket-history__item {
        padding: 20px 0;
    }
}

.ticket-history__item:first-child {
    border-bottom: 1px solid var(--iq-dullgrey);
}

.ticket-history__item:not(:last-child) {
    border-bottom: 1px solid var(--iq-dullgrey);
}

.ticket-history__raffle {
    text-align: center;
}

@media (max-width: 1199px) {
    .ticket-history__raffle {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.ticket-history__raffle h4 {
    color: var(--iq-black);
    margin-bottom: 15px;
}

@media (max-width: 370px) {
    .ticket-history__raffle h4 {
        font-size: 13px;
    }
}

@media (max-width: 370px) {
    .ticket-history__raffle--col {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
    }
}

.ticket-history__raffle--col .ticket-history__raffle--raffleid {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 4px 10px;
    border: 2px solid var(--iq-white);
    border-radius: 50%;
}

.ticket-history__raffle--col
    .ticket-history__raffle--raffleid:not(:last-child) {
    margin-bottom: 18px;
}

.ticket-history__raffle--col .ticket-history__raffle--raffleid.raffleid-win {
    border: 2px solid var(--iq-black);
}

.ticket-history__raffle--col p {
    color: var(--iq-black);
    letter-spacing: 1px;
    font-size: 17px;
}

.ticket-history__details {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 1199px) {
    .ticket-history__details {
        margin-bottom: 20px;
    }
}

.ticket-history__details--img {
    width: 95px;
    height: 95px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 18px;
}

.ticket-history__details--img img {
    width: 85px;
}

@media (max-width: 575px) {
    .ticket-history__details--img img {
        width: 60px;
    }
}

.ticket-history__details--img img img {
    max-width: 100%;
}

@media (max-width: 479px) {
    .ticket-history__details--img {
        width: 70px;
        height: 70px;
        margin-right: 15px;
    }
}

.ticket-history__details--ticket-wrapper h4 {
    color: var(--iq-pink);
    margin-bottom: 15px;
}

.ticket-history__details--ticket {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.ticket-history__details--ticket.ticket-id {
    margin: 15px 0;
}

@media (max-width: 350px) {
    .ticket-history__details--ticket.ticket-id span {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.ticket-history__details--ticket p {
    color: var(--iq-black);
    margin-bottom: 0;
    margin-right: 5px;
}

@media (max-width: 370px) {
    .ticket-history__details--ticket p {
        font-size: 13px;
    }
}

.ticket-history__details--ticket span {
    color: var(--iq-black);
}

@media (max-width: 370px) {
    .ticket-history__details--ticket span {
        font-size: 13px;
    }
}

.ticket-history__details--ticket span.win {
    color: #84bd00;
}

.ticket-history__details--ticket a {
    font-size: 14px;
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 370px) {
    .ticket-history__details--ticket a {
        font-size: 13px;
    }
}

.ticket-history__details--ticket a.btn {
    width: 224px;
    max-width: 100%;
    height: 40px;
    padding: 10px 20px 8px;
    font-size: 17px;
    color: var(--iq-white);
}

@media (max-width: 370px) {
    .ticket-history__details--ticket a.btn {
        width: 197px;
    }
}

@media (max-width: 350px) {
    .ticket-history__details--ticket a.btn {
        width: 100%;
    }
}

@media (max-width: 1199px) {
    .ticket-history__numbers {
        margin-bottom: 20px;
    }
}

.ticket-history__numbers h4 {
    color: var(--iq-black);
    text-align: center;
    margin-bottom: 15px;
}

@media (max-width: 370px) {
    .ticket-history__numbers h4 {
        font-size: 13px;
    }
}

.ticket-history__numbers--row {
    display: -webkit-box;
    display: flex;
}

.ticket-history__numbers--row:not(:last-child) {
    margin-bottom: 20px;
}

.ticket-history__numbers--row .betNum {
    margin-bottom: 0;
}

.ticket-history__numbers--row .betNum:not(:last-child) {
    margin-right: 5px;
}

.ticket-history__number-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 46%;
}

@media (max-width: 1199px) {
    .ticket-history__number-wrapper {
        width: 100%;
        max-width: 100%;
    }
}

.ticket-history__bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 40px;
}

@media (max-width: 575px) {
    .ticket-history__bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 575px) {
    .ticket-history__bottom--text {
        margin-top: 20px;
    }
}

.ticket-history__bottom--text p {
    color: var(--iq-black);
}

@media (max-width: 479px) {
    .ticket-history__bottom--text p {
        font-size: 13px;
    }
}

.ticket-history__box {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 13px;

    background: var(--iq-pink);
    border-radius: 5px;
    margin-top: 40px;
}

@media (max-width: 575px) {
    .ticket-history__box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        padding: 15px;
        margin-top: 0;
    }
}

.ticket-history__box p {
    font-size: 20px;
    color: var(--iq-white);
    margin-left: 30px;
}

@media (max-width: 575px) {
    .ticket-history__box p {
        margin-left: 0;
        text-align: center;
    }
}

@media (max-width: 479px) {
    .ticket-history__box p {
        font-size: 18px;
    }
}

.ticket-history__box--img {
    max-width: 55px;
}

@media (max-width: 575px) {
    .ticket-history__box--img {
        margin-bottom: 15px;
    }
}

.ticket-history__box--img img {
    max-width: 100%;
}

.ticket-history__box--right {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .ticket-history__box--right {
        -webkit-box-pack: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
    }
}

.ticket-history__box--right a {
    color: var(--iq-white);
    font-size: 17px;
    letter-spacing: 0.5px;
    width: unset;
    border-radius: 5px;
    background: none;
    border: 1px solid var(--iq-white);
    text-transform: uppercase;
    padding: 9px 21px;
}

@media (max-width: 991px) {
    .ticket-history__box--right a {
        font-size: 17px;
        padding: 4px 15px;
    }
}

.pagination ul {
    padding-left: 0;
    display: -webkit-box;
    display: flex;
    margin-bottom: 0;
}

.pagination ul li {
    list-style-type: none;
    width: 34px;
    height: 34px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
}

.pagination ul li:not(:last-child) {
    margin-right: 7px;
}

@media (max-width: 479px) {
    .pagination ul li:not(:last-child) {
        margin-right: 5px !important;
    }
    .pagination ul li {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 350px) {
    .pagination ul li {
        width: 25px;
        height: 25px;
    }
}

.pagination ul li a {
    color: var(--iq-darkblack);
    padding: 11px 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: var(--iq-white);
    border: 1px solid var(--iq-dullgrey);
    font-family: Product-Sans-Regular;
}

@media (max-width: 479px) {
    .pagination ul li a {
        font-size: 13px;
    }
}

@media (max-width: 350px) {
    .pagination ul li a {
        font-size: 12px;
    }
}

.pagination ul li a.current {
    background: rgba(252, 227, 0, 0.3);
    border-color: #fce300;
}

.pagination ul li a i {
    color: var(--iq-darkblack);
    margin-bottom: 5px;
}

.favourite-numbers {
    padding: 32px 40px 40px;
}

@media (max-width: 575px) {
    .favourite-numbers {
        padding: 0;
        border: none;
    }
}

@media (max-width: 575px) {
    .favourite-numbers .myaccount__content--heading h3,
    .favourite-numbers__favourites h4 {
        display: block;
        border-bottom: none;
        background-color: var(--iq-orange);
        padding: 5px;
        font-size: 16px !important;
        color: var(--iq-white);
    }

    .favourite-numbers__favourites h4 {
        margin-bottom: 15px;
    }

    .favourite-numbers__content input:not([type="text"]) {
        height: 35px;
        padding: 10px !important;
        font-size: 14px;
    }
}

.favourite-numbers__content .form form .form-group {
    margin-bottom: 18px;
}

.favourite-numbers__content .form form .form-group__input-row {
    display: -webkit-box;
    display: flex;
}

.favourite-numbers__content .form form .form-group__input-row input {
    width: 85px;
    text-align: center;
    min-width: 1px;
}

.favourite-numbers__content
    .form
    form
    .form-group__input-row
    input:not(:last-child) {
    margin-right: 13px;
}

@media (max-width: 479px) {
    .favourite-numbers__content
        .form
        form
        .form-group__input-row
        input:not(:last-child) {
        margin-right: 6px;
    }
}

.favourite-numbers__content
    .form
    form
    .form-group__input-row
    input::-webkit-input-placeholder {
    color: #ccc;
}

.favourite-numbers__content
    .form
    form
    .form-group__input-row
    input::-moz-placeholder {
    color: #ccc;
}

.favourite-numbers__content
    .form
    form
    .form-group__input-row
    input:-ms-input-placeholder {
    color: #ccc;
}

.favourite-numbers__content
    .form
    form
    .form-group__input-row
    input::-ms-input-placeholder {
    color: #ccc;
}

.favourite-numbers__content
    .form
    form
    .form-group__input-row
    input::placeholder {
    color: #ccc;
}

@media (max-width: 575px) {
    .favourite-numbers__content .form form .form-group__input-row input {
        width: 43px;
        height: 42px;
        padding: 10px 5px 5px !important;
    }
}

.favourite-numbers__content .form form a {
    width: 200px;
    max-width: 100%;
    margin-top: 40px;
}

@media (max-width: 575px) {
    .favourite-numbers__content .form form a {
        margin-top: 20px;
    }
}

@media (max-width: 479px) {
    .favourite-numbers__content .form form a {
        width: 100%;
    }
}

.favourite-numbers__content .form form .error-text p {
    font-size: 14px;
    color: var(--iq-darkred);
    margin-top: 15px;
}

.favourite-numbers__favourites {
    margin-top: 45px;
}

@media (max-width: 575px) {
    .favourite-numbers__favourites {
        margin-top: 28px;
    }

    .favourite-numbers__content .form form .error-text p {
        font-size: 12px;
    }
}

.favourite-numbers__favourites h4 {
    color: #434343;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 24px;
}

.favourite-numbers__favourites--wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.form_favourite-numbers input {
    border-color: #fdad75;
}

.favourite-numbers__item:nth-child(4),
.favourite-numbers__item:nth-child(8) {
    margin-right: 0;
}

.favourite-numbers__item {
    max-width: 150px;
    background-color: #f6f6f6;
    padding: 6px;
    border-radius: 8px;
    margin-bottom: 25px;
    border: 1px solid var(--iq-pink);
    margin-right: 14px;
    width: calc(25% - 11px);
}

.myaccount__content.favourite-numbers .favourite-numbers__content {
    max-width: 600px;
}

.remodal form .form-group__buttons .btn.disabled,
.form_favourite-numbers .btn.disabled {
    pointer-events: none;
}

.form_favourite-numbers .btn.btn--primary {
    color: var(--iq-white);
    font-family: Product-Sans-Bold;
    margin-top: 40px;
    padding: 0 48px;
    font-size: 18px;
}

.form_favourite-numbers input::-webkit-input-placeholder,
.form_favourite-numbers input::placeholder,
#form_add-favourite input::-webkit-input-placeholder,
#form_add-favourite input::placeholder {
    color: #ccc;
}

@media (max-width: 1199px) {
    .form_favourite-numbers .btn.btn--primary {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .favourite-numbers__item .favourite-numbers__item--numbers span {
        height: 20px;
        width: 20px;
    }

    .form_favourite-numbers .btn.btn--primary {
        font-size: 16px;
    }
}

@media (max-width: 575px) {
    .favourite-numbers__item {
        width: calc(50% - 5px);
        max-width: 45%;
    }

    .favourite-numbers__item:nth-child(odd) {
        margin: 0 auto 20px 0;
    }

    .favourite-numbers__item:nth-child(even) {
        margin: 0 0 20px auto;
    }

    .form_favourite-numbers .btn.btn--primary {
        font-size: 12px;
        margin-top: 20px;
        padding: 7px 15px;
        height: 40px;
        width: 100%;
    }
}

.favourite-numbers__item--heading {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 5px;
}

.favourite-numbers__item--heading h5 {
    margin: 0;
    color: #434343;
    font-size: 15px;
}

.favourite-numbers__item--heading a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.favourite-numbers__item--heading a:hover {
    opacity: 0.5;
}

.favourite-numbers__item--heading a img {
    max-width: 100%;
}

.favourite-numbers__item--numbers span {
    color: #3d3737;
    border: 1px solid var(--iq-pink);
    padding: 5px;
    border-radius: 50%;
    font-size: 10px;
    display: inline-flex;
    height: 28px;
    width: 28px;
    justify-content: center;
    align-items: center;
    background-color: var(--iq-white);
}

.favourite-numbers__item--numbers span:not(:last-child) {
    margin-right: 4px;
}

.page-rtl .favourite-numbers__item--numbers span:not(:last-child) {
    margin: 0 0 0 4px;
}

.my-games {
    padding: 32px 40px 40px;
}

.my-games .myaccount__content--heading h3 {
    margin-bottom: 0;
}

@media (max-width: 575px) {
    .my-games {
        padding: 20px 15px;
    }

    .my-games--empty {
        padding: 0;
        border: none;
    }

    .favourite-numbers__item--numbers span {
        height: 24px;
        width: 24px;
    }
}

.my-games__content .form form .form-group {
    margin-bottom: 18px;
}

.my-games__content .form form .form-group__input-row {
    display: -webkit-box;
    display: flex;
}

.my-games__content .form form .form-group__input-row input {
    width: 85px;
    text-align: center;
}

.my-games__content .form form .form-group__input-row input:not(:last-child) {
    margin-right: 13px;
}

.my-games__content
    .form
    form
    .form-group__input-row
    input::-webkit-input-placeholder {
    color: #ccc;
}

.my-games__content .form form .form-group__input-row input::-moz-placeholder {
    color: #ccc;
}

.my-games__content
    .form
    form
    .form-group__input-row
    input:-ms-input-placeholder {
    color: #ccc;
}

.my-games__content
    .form
    form
    .form-group__input-row
    input::-ms-input-placeholder {
    color: #ccc;
}

.my-games__content .form form .form-group__input-row input::placeholder {
    color: #ccc;
}

.my-games__content .form form button {
    width: 200px;
    margin-top: 40px;
}

@media (max-width: 575px) {
    .my-games__content--item-wrapper:first-child .my-games__item {
        padding-top: 0;
    }
}

.my-games__item {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 25px 0;
}

@media (max-width: 1199px) {
    .my-games__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 575px) {
    .my-games__item {
        padding: 20px 0;
    }
}

.my-games__item:first-child {
    border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 575px) {
    .my-games__item:last-child {
        padding-bottom: 0;
    }
}

.my-games__item:not(:last-child) {
    border-bottom: 1px solid var(--iq-dullgrey);
}

.my-games__details {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 1199px) {
    .my-games__details {
        margin-bottom: 20px;
    }
}

.my-games__details--img {
    width: 95px;
    height: 95px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 18px;
}

.my-games__details--img img {
    width: 85px;
}

@media (max-width: 479px) {
    .my-games__details--img img {
        width: 60px;
    }
}

.my-games__details--img img img {
    max-width: 100%;
}

@media (max-width: 479px) {
    .my-games__details--img {
        width: 70px;
        height: 70px;
        margin-right: 15px;
    }
}

.my-games__details--ticket-wrapper h4 {
    color: var(--iq-pink);
    margin-bottom: 15px;
}

.my-games__details--ticket {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.my-games__details--ticket.ticket-id {
    margin: 15px 0;
}

@media (max-width: 350px) {
    .my-games__details--ticket.ticket-id span {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.my-games__details--ticket p {
    color: var(--iq-black);
    margin-bottom: 0;
    margin-right: 5px;
}

@media (max-width: 375px) {
    .my-games__details--ticket p {
        font-size: 13px;
    }
}

.my-games__details--ticket span {
    color: var(--iq-black);
}

@media (max-width: 375px) {
    .my-games__details--ticket span {
        font-size: 13px;
    }
}

.my-games__details--ticket span.win {
    color: #84bd00;
}

.my-games__details--ticket a {
    font-size: 14px;
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 375px) {
    .my-games__details--ticket a {
        font-size: 13px;
    }
}

.my-games__number-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 46%;
}

@media (max-width: 1199px) {
    .my-games__number-wrapper {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 1199px) {
    .my-games__numbers {
        margin-bottom: 20px;
    }
}

.my-games__numbers h4 {
    color: var(--iq-black);
    text-align: center;
    margin-bottom: 15px;
}

@media (max-width: 370px) {
    .my-games__numbers h4 {
        font-size: 13px;
    }
}

.my-games__numbers--row {
    display: -webkit-box;
    display: flex;
}

.my-games__numbers--row:not(:last-child) {
    margin-bottom: 20px;
}

.my-games__numbers--row .betNum {
    margin-bottom: 0;
}

.my-games__numbers--row .betNum:not(:last-child) {
    margin-right: 5px;
}

.my-games__raffle {
    text-align: center;
}

@media (max-width: 1199px) {
    .my-games__raffle {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.my-games__raffle h4 {
    color: var(--iq-black);
    margin-bottom: 15px;
}

@media (max-width: 370px) {
    .my-games__raffle h4 {
        font-size: 13px;
    }
}

@media (max-width: 370px) {
    .my-games__raffle--col {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
    }
}

.my-games__raffle--col .my-games__raffle--raffleid {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 4px 10px;
    border: 2px solid var(--iq-white);
    border-radius: 50%;
}

.my-games__raffle--col .my-games__raffle--raffleid:not(:last-child) {
    margin-bottom: 13px;
}

.my-games__raffle--col .my-games__raffle--raffleid.raffleid-win {
    border: 2px solid var(--iq-black);
}

.my-games__raffle--col p {
    color: var(--iq-black);
    letter-spacing: 1px;
    font-size: 18px;
}

@media (max-width: 370px) {
    .my-games__raffle--col p {
        font-size: 17px;
    }
}

.my-games__buttons a {
    width: 156px;
    max-width: 100%;
    margin-bottom: 10px;
}

@media (max-width: 575px) {
    .my-games__buttons a {
        margin-bottom: 20px;
    }
}

@media (max-width: 479px) {
    .my-games__buttons a {
        width: 100%;
    }
}

.my-games__bottom {
    margin-top: 40px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 575px) {
    .my-games__bottom {
        margin-top: 15px;
    }
}

@media (max-width: 575px) {
    .my-games__bottom,
    .my-games__bottom--right {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
    }

    .my-games__bottom--right {
        margin-top: 20px;
    }
}

.my-games__bottom--text {
    color: var(--iq-darkgrey);
    margin-bottom: 40px;
}

@media (max-width: 575px) {
    .my-games__bottom--text {
        margin-bottom: 25px;
    }
}

@media (max-width: 479px) {
    .my-games__bottom--text {
        font-size: 13px;
    }
}

.my-games__bottom--button {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.my-games__bottom--button a {
    width: 185px;
}

@media (max-width: 575px) {
    .my-games__bottom--button a {
        width: 100%;
        max-width: 100%;
    }
}

.my-games__banner {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 15px 30px 10px;
    background-color: var(--iq-mildgrey);
    border-radius: 5px;
    margin-top: 45px;
}

@media (max-width: 575px) {
    .my-games__banner {
        margin-top: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        padding: 15px;
    }
}

.my-games__banner--img {
    width: 55px;
    max-width: 100%;
    margin-right: 30px;
}

.my-games__banner--img img {
    max-width: 100%;
}

@media (max-width: 575px) {
    .my-games__banner--img {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.my-games__banner a {
    width: 165px;
    max-width: 100%;
}

.my-games__banner--left {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 1199px) {
    .my-games__banner--left {
        padding-right: 20px;
    }
}

@media (max-width: 575px) {
    .my-games__banner--left {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        text-align: center;
        padding-right: 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 479px) {
    .my-games__banner--right,
    .my-games__banner--right a {
        width: 100%;
        max-width: 100%;
    }
}

.messages {
    padding: 32px 40px 40px;
}

@media (max-width: 575px) {
    .messages {
        padding: 0;
        border: none;
    }
}

.messages__bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .messages__bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 575px) {
    .messages__bottom--text {
        margin-top: 20px;
    }
}

.messages__bottom--text p {
    color: var(--iq-black);
}

@media (max-width: 575px) {
    .messages__table table tbody tr td {
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

@media (min-width: 576px) {
    .messages__table table tbody tr td a {
        text-decoration: none !important;
    }
}

.table-main {
    margin: 20px 0;
}

@media (max-width: 575px) {
    .table-main {
        margin-bottom: 40px;
    }
}

.table-main table {
    width: 100%;
    max-width: 100%;
}

.table-main table thead {
    background-color: var(--iq-orange);
}

@media (max-width: 575px) {
    .table-main table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

.table-main table thead tr th {
    color: var(--iq-white);
    text-align: left;
    font-size: 0.9rem;
    padding: 11px 10px 11px 8px;
    font-family: Product-Sans-Bold;
}

:lang(ar) .table-main table thead tr th {
    font-family: Swissra-Bold;
}

.table-main table thead tr th:not(:last-child) {
    border-right: 2px solid var(--iq-white);
}

html[lang="ar"] .table-main table thead tr th:not(:last-child) {
    border-left: 2px solid var(--iq-white);
}

.table-main table thead tr th:first-child {
    border-radius: 5px 0 0 0;
}

.table-main table thead tr th:last-child {
    border-radius: 0 5px 0 0;
}

@media (max-width: 575px) {
    .table-main table tbody {
        border: none;
        border-radius: none;
    }
}

.table-main table tbody tr {
    background-color: var(--iq-white);
}

.table-main table tbody tr:nth-child(2n) {
    background-color: #f6f6f6;
}

@media (max-width: 575px) {
    .table-main table tbody tr:nth-child(2n) {
        background-color: inherit;
    }
}

@media (max-width: 575px) {
    .table-main table tbody tr {
        display: block;
        border: 1px solid var(--iq-dullgrey);
    }
}

@media (max-width: 575px) {
    .table-main table tbody tr:not(:last-child) {
        margin-bottom: 18px;
    }
}

.table-main table tbody tr td {
    color: var(--iq-darkblack);
    padding: 12px 14px;
    text-align: left;
}

@media (max-width: 575px) {
    .table-main table tbody tr td {
        display: -webkit-box;
        display: flex;
        padding: 15px 15px 12px;
        position: relative;
    }
}

@media (max-width: 575px) {
    .table-main table tbody tr td:nth-child(odd) {
        background-color: #f7f7f7;
    }
}

.table-main table tbody tr td:before {
    content: attr(data-label);
    color: var(--iq-pink);
    display: none;
}

@media (max-width: 575px) {
    .table-main table tbody tr td:before {
        display: block;
    }
}

.table-main table tbody tr td a,
.balance-summary__mobile_table table tbody tr td a {
    color: var(--iq-pink);
    text-decoration: underline;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.table-main table tbody tr td a:hover,
.balance-summary__mobile_table table tbody tr td a:hover {
    color: var(--iq-pink);
    text-decoration: none;
}

.table-main table tbody tr td.amount-decrease {
    color: var(--iq-darkred);
}

.table-main table tbody tr td.amount-increase {
    color: #84bd00;
}

.balance-summary {
    padding: 35px 12px 5px 12px;
}

@media (max-width: 575px) {
    .balance-summary {
        padding: 0;
        border: none;
    }
}

.balance-summary__table table tbody tr td:first-child {
    color: var(--iq-darkblack);
}

.balance-summary__info {
    margin-bottom: 20px;
}

.balance-summary__info--top {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 13px;
    background-color: rgba(132, 189, 0, 0.13);
    border-radius: 5px;
    margin-bottom: 13px;
}

@media (max-width: 575px) {
    .balance-summary__info--top {
        margin-bottom: 8px;
    }
}

.balance-summary__info--top p {
    color: var(--iq-darkblack);
}

.balance-summary__info--top p.balance-summary__currencies--currency {
    font-size: 12px;
}

@media (max-width: 479px) {
    .balance-summary__info--top p {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.balance-summary__info--top p span {
    color: #84bd00;
    margin-left: 5px;
}

@media (max-width: 479px) {
    .balance-summary__info--top p span {
        margin-left: 0;
    }
}

.balance-summary__info--top a {
    width: 200px;
    max-width: 100%;
}

@media (max-width: 479px) {
    .balance-summary__info--top a {
        width: 135px;
        text-align: center;
    }
}

.balance-summary__info--bottom {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 575px) {
    .balance-summary__info--bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.balance-summary__info--bottom > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 15px 13px;
    background-color: var(--iq-mildgrey);
    border-radius: 5px;
    width: 50%;
    max-width: 100%;
}

@media (max-width: 575px) {
    .balance-summary__info--bottom > div {
        -webkit-box-pack: start;
        justify-content: flex-start;
        padding: 15px;
    }
}

.balance-summary__info--bottom > div:first-child {
    margin-right: 10px;
}

@media (max-width: 575px) {
    .balance-summary__info--bottom > div:first-child {
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.balance-summary__info--bottom > div:nth-child(2) {
    width: 585px;
}

@media (max-width: 575px) {
    .balance-summary__info--bottom > div:nth-child(2) {
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.balance-summary__info--bottom > div p {
    color: var(--iq-darkblack);
}

.balance-summary__info--bottom > div p.balance-summary__currencies--currency {
    font-size: 12px;
}

@media (max-width: 479px) {
    .balance-summary__info--bottom > div p {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.balance-summary__info--bottom > div p span {
    margin-left: 5px;
}

@media (max-width: 479px) {
    .balance-summary__info--bottom > div p span {
        margin-left: 0;
    }
}

.balance-summary__info--bottom > div a {
    width: 200px;
    min-width: 200px;
    max-width: 100%;
    margin-left: 20px;
    font-size: 14px !important;
}

@media (max-width: 575px) {
    .balance-summary__info--bottom > div a {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (max-width: 479px) {
    .balance-summary__info--bottom > div a {
        width: 100%;
    }
}

.balance-summary__info > p {
    color: var(--iq-darkblack);
    font-size: 12px;
    margin-top: 10px;
}

.balance-summary__bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .balance-summary__bottom {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

@media (max-width: 575px) {
    .balance-summary__bottom--text {
        margin-top: 20px;
    }
}

.balance-summary__bottom--text p {
    color: var(--iq-black);
}

.balance-summary__currencies--currency {
    font-size: 12px;
    font-style: italic;
}

@media (max-width: 575px) {
    .balance-summary__table table tbody tr td {
        -webkit-box-pack: end;
        justify-content: flex-end;
    }
}

@media (max-width: 575px) {
    .balance-summary__table table tbody tr td:before {
        position: absolute;
        left: 15px;
    }
}

@media (max-width: 575px) {
    .balance-summary__table table tbody tr td:nth-child(4) {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: end;
        align-items: flex-end;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .balance-summary__table table tbody tr td:nth-child(4):before {
        top: 30px;
    }
}

@media (max-width: 575px) {
    .balance-summary__table table tbody tr td .balance-summary__currencies {
        text-align: right;
    }
}

.balance-summary__table table tbody tr td .balance-summary__currencies p {
    white-space: nowrap;
    text-align: left;
}

@media (max-width: 575px) {
    .balance-summary__table table tbody tr td a {
        margin-left: 10px;
    }
}

.back-btn {
    width: 175px;
    background-color: var(--iq-orange);
}

.confirm-btn {
    width: 175px !important;
}

.page--total-balance .myaccount--col.mobile {
    padding: 0 !important;
}

.page--total-balance .myaccount__heading h2 {
    margin-bottom: 20px;
}

.total-balance__top {
    background-color: var(--iq-mildgrey);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 15px;
}

.total-balance__top h3 {
    color: var(--iq-darkblack);
    margin-bottom: 0;
}

.total-balance__top span {
    font-size: 18px;
    color: var(--iq-pink);
}

.total-balance__wrapper {
    padding: 0 15px;
}

.total-balance__balance {
    padding: 20px 0;
}

.total-balance__balance h4 {
    color: var(--iq-darkblack);
}

.total-balance__balance:not(:last-child) {
    border-bottom: 1px solid var(--iq-dullgrey);
}

.total-balance__balance--number {
    background: hsla(0, 0%, 78.4%, 0.25);
    border-radius: 6px;
    padding: 15px 15px 10px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.total-balance__balance--number span {
    color: var(--iq-darkblack);
    font-size: 17px;
}

.total-balance__bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: end;
    align-items: flex-end;
}

.total-balance__bottom a:first-child {
    margin-bottom: 27px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.total-balance__bottom a:last-child {
    width: 100%;
    max-width: 100%;
}

.payment {
    margin: 60px 0 250px;
}

@media (max-width: 575px) {
    .payment {
        margin: 25px 0 100px;
    }
}

.payment__wrapper {
    max-width: 620px;
    margin: 0 auto;
    position: relative;
}

.payment__wrapper h2 {
    color: var(--iq-darkblack);
    margin-bottom: 20px;
    padding-left: 2px;
    line-height: 40px;
}

@media (max-width: 575px) {
    .payment__wrapper h2 {
        font-size: 18px;
    }
}

.payment__wrapper--previous {
    margin-bottom: 20px;
}

.payment__wrapper--previous,
.payment__wrapper--previous a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.payment__wrapper--previous a {
    width: 33px;
    height: 33px;
    border: 1px solid var(--iq-dullgrey);
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 50%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.payment__wrapper--previous a i {
    color: var(--iq-darkblack);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.payment__wrapper--previous a:hover {
    background-color: #fce300;
}

.payment__wrapper--previous h2 {
    margin-top: 3px;
    margin-bottom: 0;
}

.payment__cards {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 25px 0 30px;
    border-bottom: 1px solid var(--iq-dullgrey);
}

.payment__cards--wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    border-bottom: none;
    padding: 0;
}

.payment__cards--img {
    width: 135px;
}

@media (max-width: 479px) {
    .payment__cards--img {
        width: 100px;
    }
}

.payment__cards--img img {
    max-width: 100%;
    height: 28px;
}

@media (max-width: 479px) {
    .payment__cards--img img {
        height: 24px;
    }
}

.payment__cards--right,
.payment__cards--right a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.payment__cards--right a {
    width: 25px;
    height: 25px;
    background-color: #fce300;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.payment__cards--right a i {
    color: var(--iq-darkblack);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.payment__cards--right a:hover {
    background-color: #fee555;
}

.payment__cards--right a:hover i {
    color: var(--iq-white);
}

.payment__cards--text {
    margin-right: 25px;
    text-align: right;
}

@media (max-width: 575px) {
    .payment__cards--text {
        margin-right: 10px;
    }
}

.payment__cards--text h3 {
    color: var(--iq-darkblack);
    margin-bottom: 0;
}

.payment__cards--text p {
    font-size: 14px;
    color: var(--iq-darkgrey);
}

.payment__card {
    width: 100%;
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 20px 10px;
    border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 575px) {
    .payment__card {
        padding: 20px 0;
    }
}

.payment__card--img {
    width: 46px;
    max-width: 100%;
}

.payment__card--img img {
    max-width: 100%;
}

.payment__card--right,
.payment__card--right a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.payment__card--right a {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.payment__card--right a i {
    color: #b50023;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 23px;
}

.payment__card--right a:hover i {
    color: var(--iq-darkred);
}

.payment__card--text {
    margin-right: 13px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.payment__card--text p {
    color: var(--iq-black);
}

@media (max-width: 575px) {
    .payment__card--text p {
        font-size: 17px;
    }
}

@media (max-width: 479px) {
    .payment__card--text p {
        font-size: 12px;
    }
}

.payment__card--card,
.payment__card--checkbox {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.payment__card--checkbox {
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
}

.payment__card--checkbox input {
    position: absolute;
    cursor: pointer;
}

.payment__card--checkbox input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 28px;
    height: 28px;
    -webkit-box-flex: 0;
    flex: 0 0 28px;
    border-radius: 0;
    background-color: var(--iq-white);
    border: 1px solid var(--iq-dullgrey);
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 40px;
}

@media (max-width: 479px) {
    .payment__card--checkbox input[type="radio"] {
        margin-right: 10px;
    }
}

.payment__card--checkbox .checkmark {
    position: absolute;
    left: 7px;
    top: 7px;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: var(--iq-pink);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.payment__card--checkbox input:checked ~ .checkmark {
    opacity: 1;
}

.payment__card--checkbox.empty {
    width: 28px;
    height: 28px;
    margin-right: 40px;
}

@media (max-width: 479px) {
    .payment__card--checkbox.empty {
        margin-right: 10px;
    }
}

.payment__card--new-card {
    border-bottom: none;
}

.payment__card--new-card .payment__card--right {
    -webkit-box-pack: end;
    justify-content: flex-end;
    width: 100%;
    max-width: 100%;
}

.payment__empty {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    padding: 10px;
}

@media (max-width: 479px) {
    .payment__empty {
        margin-top: 0;
        border: 1px solid var(--iq-orange);
        border-radius: 4px;
    }
}

.payment__empty p {
    color: var(--iq-black);
}

.payment__button {
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
}

@media (max-width: 479px) {
    .payment__button {
        margin-top: 30px;
    }
}

.payment__button button {
    height: 35px;
    padding: 8px 20px;
    width: 140px;
    max-width: 100%;
}

.payment__bottom p {
    font-size: 14px;
    color: #797979;
    margin-top: 18px;
}

.payment--box {
    background-color: var(--iq-pink);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    padding: 10px;
    border-radius: 5px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    align-items: center;
}

.payment--box,
.payment--box p {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.payment--box p {
    color: var(--iq-darkblack);
    max-width: 100%;
}

@media (max-width: 479px) {
    .payment--box p {
        font-size: 14px;
    }
}

@media (min-width: 480px) {
    .payment--box p br {
        display: none;
    }
}

.payment--box .input-box--currency {
    font-size: 12px !important;
    font-style: italic;
    width: unset;
}

@media (max-width: 479px) {
    .payment--box-text {
        max-width: 100px;
    }
}

.payment #form_credit-cards_amount p,
.payment #form_payment-method_amount p {
    display: -webkit-box;
    display: flex;
}

.payment #form_credit-cards_amount p span,
.payment #form_payment-method_amount p span {
    width: unset;
    height: unset;
    position: unset;
    background-color: unset;
    color: var(--iq-darkblack);
    padding: 0;
    margin-left: 5px;
    margin-top: 2px;
}

.deposit .payment--box {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.deposit .payment--box-text {
    margin-top: 14px;
    width: unset;
}

@media (max-width: 479px) {
    .deposit .payment--box-text {
        margin-top: 5px;
    }
}

.transfer {
    margin: 60px 0 114px;
}

@media (max-width: 575px) {
    .transfer {
        margin: 25px 0 60px;
    }
}

.transfer--iframe {
    margin: 0;
}

.transfer__wrapper {
    max-width: 620px;
    margin: 0 auto;
}

.transfer__wrapper h2 {
    color: var(--iq-darkblack);
}

@media (max-width: 575px) {
    .transfer__wrapper h2 {
        font-size: 18px;
    }
}

.transfer__wrapper h2 span {
    margin-left: 5px;
}

@media (max-width: 575px) {
    .transfer__wrapper h2 span {
        font-size: 18px;
    }
}

.transfer__wrapper > p {
    font-size: 17px;
    color: var(--iq-darkblack);
    margin-bottom: 20px;
}

@media (max-width: 479px) {
    .transfer__wrapper > p {
        font-size: 14px;
    }
}

.transfer__wrapper > p b {
    font-size: 17px;
}

@media (max-width: 479px) {
    .transfer__wrapper > p b {
        font-size: 14px;
    }
}

.transfer #payment_form .error-text {
    margin-bottom: 15px;
}

.transfer__top {
    background-color: var(--iq-mildgrey);
    padding: 18px;
    border-radius: 5px;
    margin-bottom: 12px;
}

@media (max-width: 575px) {
    .transfer__top {
        padding: 18px 10px;
    }
}

.transfer__top--row {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.transfer__top--row:not(:last-child) {
    margin-bottom: 15px;
}

.transfer__top--row h3 {
    color: var(--iq-darkblack);
    margin-bottom: 0;
}

@media (max-width: 479px) {
    .transfer__top--row h3 {
        font-size: 12px;
    }
}

.transfer__top--row p {
    color: var(--iq-darkblack);
}

@media (max-width: 479px) {
    .transfer__top--row p {
        font-size: 12px;
    }
}

.transfer__input-wrapper {
    padding: 30px 18px;
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 30px;
}

@media (max-width: 575px) {
    .transfer__input-wrapper {
        margin-bottom: 15px;
    }
}

.transfer__input-wrapper [class*="col-"]:not(:last-child) {
    margin-bottom: 20px;
}

.transfer__button {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-bottom: 30px;
}

@media (max-width: 575px) {
    .transfer__button {
        margin-bottom: 15px;
    }
}

.transfer__button button {
    width: 236px;
}

@media (max-width: 575px) {
    .transfer__button button {
        width: 100%;
        max-width: 100%;
    }
}

.transfer__bottom {
    background-color: var(--iq-orange);
    padding: 18px 25px;
    border-radius: 5px;
    margin-bottom: 5px;
}

@media (max-width: 575px) {
    .transfer__bottom {
        padding: 18px 10px;
    }
}

.transfer__bottom h3 {
    color: var(--iq-black);
    margin-bottom: 0;
}

.transfer__bottom h3:first-child {
    color: var(--iq-white);
}

.transfer__bottom h3:nth-of-type(2) {
    margin-bottom: 10px;
}

.transfer__bottom > div:first-of-type {
    margin: 20px 0;
}

.transfer__bottom > div:nth-of-type(2) {
    margin-bottom: 10px;
}

.transfer__bottom--row {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.transfer__bottom--row h3 {
    color: var(--iq-black) !important;
}

.transfer__right {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media (max-width: 479px) {
    .transfer__right p {
        font-size: 12px !important;
    }
}

.transfer__right--amount {
    display: -webkit-box;
    display: flex;
}

.transfer__right--currency {
    margin-top: 10px;
    font-size: 12px !important;
    display: none;
    font-style: italic;
}

@media (max-width: 479px) {
    .transfer__right--currency {
        font-size: 12px;
    }
}

.transfer__right--currency.active {
    display: block;
}

.verification {
    margin: 88px 0;
}

@media (max-width: 479px) {
    .verification {
        margin: 36px 0 80px;
    }
}

.verification__wrapper {
    max-width: 1000px;
    margin: 0 auto;
}

.verification__banner {
    width: 100%;
    max-width: 100%;
    position: relative;
    margin-bottom: 20px;
}

@media (max-width: 575px) {
    .verification__banner.desktop {
        display: none;
    }
}

.verification__banner.mobile {
    display: block;
}

@media (min-width: 576px) {
    .verification__banner.mobile {
        display: none;
    }
}

.verification__banner--bg {
    padding-bottom: 277px;
    border-radius: 5px;
}

@media (max-width: 1199px) {
    .verification__banner--bg {
        background-position: 50%;
    }
}

@media (max-width: 575px) {
    .verification__banner--bg {
        padding-bottom: 530px;
        background-position: unset;
    }
}

@media (max-width: 479px) {
    .verification__banner--bg {
        padding-bottom: 470px;
    }
}

@media (max-width: 400px) {
    .verification__banner--bg {
        padding-bottom: 450px;
    }
}

@media (max-width: 350px) {
    .verification__banner--bg {
        padding-bottom: 435px;
    }
}

.verification__banner--bg-success {
    background-image: url(../img/verification-success.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 575px) {
    .verification__banner--bg-success {
        background-image: url(../img/verification-success-mobile.jpg);
    }
}

.verification__banner--bg-rejected {
    background-image: url(../img/verification-rejected.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 575px) {
    .verification__banner--bg-rejected {
        background-image: url(../img/verification-rejected-mobile.jpg);
    }
}

.verification__banner img {
    max-width: 100%;
}

.verification__banner h2 {
    color: var(--iq-white);
    position: absolute;
    bottom: 60px;
    left: 70px;
}

@media (max-width: 575px) {
    .verification__banner h2 {
        left: 20px;
        top: 20px;
        max-width: 100px;
    }
}

@media (max-width: 350px) {
    .verification__banner h2 {
        font-size: 22px;
    }
}

.verification__banner--text {
    max-width: 655px;
    position: absolute;
    left: 50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 575px) {
    .verification__banner--text {
        -webkit-transform: unset;
        transform: unset;
        top: unset;
        bottom: 20px;
        max-width: 400px;
        left: 20px;
    }
}

@media (max-width: 479px) {
    .verification__banner--text {
        padding-right: 20px;
    }
}

@media (max-width: 400px) {
    .verification__banner--text {
        max-width: 252px;
        padding-right: 0;
    }
}

.verification__banner--text h3 {
    font-size: 23px;
    color: var(--iq-pink);
    margin-bottom: 20px;
}

@media (max-width: 479px) {
    .verification__banner--text h3 {
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    .verification__banner--text h3 {
        margin-bottom: 15px;
    }
}

.verification__banner--text p {
    color: var(--iq-black);
    line-height: 30px;
    margin-bottom: 20px;
}

@media (max-width: 479px) {
    .verification__banner--text p {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .verification__banner--text p {
        margin-bottom: 15px;
    }
}

.verification__banner--text a {
    max-width: 200px;
}

@media (max-width: 479px) {
    .verification__banner--text a {
        max-width: 100%;
    }
}

.verification__photo {
    padding-bottom: 46px;
    margin-bottom: 25px;
    /* border-bottom: 1px solid var(--iq-dullgrey); */
}

@media (max-width: 479px) {
    .verification__photo {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}

.verification__photo h3 {
    color: var(--iq-darkblack);
    margin-bottom: 30px;
}

@media (max-width: 479px) {
    .verification__photo h3 {
        font-size: 17px;
        margin-bottom: 25px;
    }
}

.verification__photo p.text-alert {
    font-size: 13px;
    color: var(--iq-darkred);
    line-height: 29px;
    margin-bottom: 15px;
    max-width: 730px;
}

.verification__photo h4 {
    color: var(--iq-darkblack);
    margin-bottom: 30px;
}

@media (max-width: 479px) {
    .verification__photo h4 {
        line-height: 25px;
        margin-bottom: 25px;
    }
}

.verification__photo--img {
    max-width: 730px;
    margin-bottom: 20px;
}

@media (max-width: 479px) {
    .verification__photo--img {
        margin-bottom: 12px;
    }
}

.verification__photo--img img {
    max-width: 100%;
}

.verification__photo--buttons {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 479px) {
    .verification__photo--buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.verification__photo--buttons a {
    margin-left: 10px;
}

@media (max-width: 479px) {
    .verification__photo--buttons a {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (max-width: 479px) {
    .verification__photo--buttons input[type="file"] {
        width: 100%;
        max-width: 100%;
    }
}

.verification__progress {
    padding: 30px 70px;
    background-color: #f8f8f8;
    margin-bottom: 40px;
    border-radius: 5px;
}

@media (max-width: 575px) {
    .verification__progress {
        padding: 30px 12px;
        margin-bottom: 25px;
    }
}

.verification__progress ul {
    padding: 0;
    list-style-type: none;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    max-width: 650px;
    margin: 0 auto;
}

.verification__progress ul li {
    text-align: center;
    min-width: 140px;
    color: var(--iq-darkgrey);
}

@media (max-width: 575px) {
    .verification__progress ul li {
        max-width: 106px;
        min-width: unset;
        font-size: 14px;
        align-self: flex-end;
    }
}

@media (max-width: 479px) {
    .verification__progress ul li {
        max-width: 75px;
    }
}

.verification__progress--lines {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 10px;
}

.verification__progress--lines .line {
    height: 2px;
}

.verification__progress--lines .line--primary {
    width: 18%;
}

@media (max-width: 575px) {
    .verification__progress--lines .line--primary {
        width: 7%;
    }
}

.verification__progress--lines .line--secondary {
    width: 27.1%;
}

@media (max-width: 575px) {
    .verification__progress--lines .line--secondary {
        width: 33.1%;
    }
}

.verification__progress--lines .line.grey {
    background-color: #e4e4e4;
}

.verification__progress--lines .line.blue {
    background-color: var(--iq-lite-orange);
}

.verification__progress--lines .circle {
    width: 27px;
    height: 27px;
    border: 6px solid var(--iq-white);
    border-radius: 50%;
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.11);
}

.verification__progress--lines .circle--blue {
    background-color: var(--iq-lite-orange);
}

.verification__progress--lines .circle--grey {
    background-color: #e4e4e4;
}

.verification__text {
    padding-bottom: 50px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--iq-light-pink);
}

@media (max-width: 479px) {
    .verification__text {
        padding-bottom: 25px;
        margin-bottom: 15px;
    }
}

.verification__text p {
    color: var(--iq-black);
}

@media (max-width: 479px) {
    .verification__text p strong {
        font-size: 14px;
    }
}

@media (max-width: 479px) {
    .verification__text p {
        font-size: 14px;
        line-height: 27px;
    }
}

.verification__text p:first-child {
    color: var(--iq-darkgrey);
    line-height: 30px;
    margin-bottom: 1.5rem;
}

@media (max-width: 479px) {
    .verification__text p:first-child {
        line-height: 27px;
        margin-bottom: 20px;
    }
}

.verification__text p:nth-child(2) {
    color: var(--iq-darkblack);
    margin-bottom: 15px;
}

.verification__text p:nth-child(3) {
    margin-bottom: 10px;
}

@media (max-width: 479px) {
    .verification__text p:nth-child(3) {
        margin-bottom: 20px;
    }
}

.verification__text p span {
    color: var(--iq-darkred);
}

.verification__bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 575px) {
    .verification__bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
    }
}

.verification__bottom--text {
    max-width: 638px;
}

@media (max-width: 575px) {
    .verification__bottom--text {
        margin-top: 15px;
    }
}

.verification__bottom--text p {
    font-size: 13px;
    color: var(--iq-darkgrey);
    line-height: 25px;
}

@media (max-width: 479px) {
    .verification__bottom input[type="file"] {
        width: 100%;
        max-width: 100%;
    }
}

.verification__section {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 575px) {
    .verification__section--img {
        display: none;
    }
}

.verification__section--img img {
    max-width: 100%;
}

.verification__box {
    width: 425px;
    max-width: 100%;
    border: 1px solid var(--iq-dullgrey);
    border-radius: 5px;
    padding: 20px 10px;
    margin-left: 15px;
}

@media (max-width: 575px) {
    .verification__box {
        margin-left: 0;
        width: 100%;
    }
}

.verification__box ul {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.verification__box ul li {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    max-width: 100%;
}

@media (max-width: 575px) {
    .verification__box ul li {
        max-width: 100%;
    }
}

.verification__box ul li:not(:last-child) {
    margin-bottom: 10px;
}

.verification__box ul li p {
    color: var(--iq-darkgrey);
}

.verification__box ul li p.text-alert {
    color: var(--iq-darkred);
}

.verification--rejected .verification__progress,
.verification--success .verification__progress {
    margin-bottom: 18px;
}

.verification--rejected .verification__banner .verification__banner--text a {
    max-width: 220px;
}

@media (max-width: 479px) {
    .verification--rejected
        .verification__banner
        .verification__banner--text
        a {
        max-width: 100%;
    }
}

.verification--rejected .verification__banner--rejected {
    margin-bottom: 45px;
}

@media (max-width: 575px) {
    .verification--rejected .verification__banner--rejected {
        margin-bottom: 15px;
    }
}

.verification--rejected .verification__box {
    width: 460px;
}

.verification--rejected .verification__box ul li:last-child {
    max-width: 100%;
}

@media (max-width: 575px) {
    .verification--rejected .verification__box ul li:last-child {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        max-width: 100%;
        background: rgba(213, 0, 50, 0.08);
        border-radius: 5px;
        padding: 15px;
    }
}

.verification--rejected .verification__box ul li:last-child p:last-child {
    max-width: 100%;
    width: 100%;
}

@media (max-width: 575px) {
    .verification--rejected .verification__box ul li:last-child p {
        max-width: 100%;
    }
}

.verification--rejected .verification__box ul li:last-child p:first-child {
    min-width: 150px;
    max-width: 100%;
}

@media (max-width: 575px) {
    .verification--rejected .verification__box ul li:last-child p:first-child {
        margin-bottom: 5px;
    }
}

.verification--rejected
    .verification__box
    ul
    li:not(:last-child)
    p:first-child {
    min-width: 150px;
    max-width: 100%;
}

.verification--rejected .verification__box ul li:not(:last-child) p:last-child {
    width: 100%;
    max-width: 100%;
}

@media (max-width: 575px) {
    .verification--rejected
        .verification__box
        ul
        li:not(:last-child)
        p:last-child {
        min-width: unset;
    }
}

.review .verification__text {
    padding-bottom: 0;
    margin-bottom: 40px;
    border: none;
}

@media (max-width: 479px) {
    .review .verification__text {
        margin-bottom: 25px;
    }
}

.review .verification__text p {
    margin-bottom: 0;
}

.not-found {
    max-width: 891px;
    margin: 115px auto 150px;
}

@media (max-width: 479px) {
    .not-found {
        margin: 55px auto;
    }
}

.not-found__img {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.not-found__img img {
    max-width: 248px;
}

@media (max-width: 479px) {
    .not-found__img img {
        max-width: 200px;
    }
}

@media (max-width: 479px) {
    .not-found__img--desktop {
        display: none;
    }
}

@media (min-width: 480px) {
    .not-found__img--mobile {
        display: none;
    }
}

.not-found__text {
    margin-top: 20px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .not-found__text {
        width: 100%;
        max-width: 100%;
        margin-bottom: 75px;
        padding: 0 15px;
    }
}

.not-found__text h2 {
    color: var(--iq-darkblack);
    margin-bottom: 20px;
    font-size: 30px;
}

@media (max-width: 575px) {
    .not-found__text h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }
}

.not-found__text p {
    color: var(--iq-darkblack);
    font-size: 22px;
    line-height: 32px;
    max-width: 870px;
    text-align: center;
    margin-bottom: 45px;
}

@media (max-width: 575px) {
    .not-found__text p {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 38px;
    }
}

.not-found__buttons {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 991px) {
    .not-found__buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.not-found__buttons a {
    width: 193px;
    height: 60px;
    max-width: 100%;
}

@media (max-width: 479px) {
    .not-found__buttons a {
        height: 53px;
        max-width: 100%;
    }
}

.not-found__buttons a:not(:last-child) {
    margin-right: 15px;
}

@media (max-width: 991px) {
    .not-found__buttons a:not(:last-child) {
        margin-bottom: 15px;
        margin-right: 0;
    }
}

.maintenance {
    max-width: 570px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 120px auto 142px;
}

@media (max-width: 575px) {
    .maintenance {
        margin: 0;
    }
}

.maintenance__img {
    max-width: 100%;
    width: 127px;
}

@media (max-width: 575px) {
    .maintenance__img {
        width: 93px;
    }
}

.maintenance__img img {
    max-width: 100%;
}

.maintenance__text {
    margin-top: 33px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .maintenance__text {
        margin-top: 23px;
    }
}

@media (max-width: 479px) {
    .maintenance__text {
        padding: 0 15px;
    }
}

.maintenance__text h2 {
    color: var(--iq-darkblack);
    margin-bottom: 38px;
    text-align: center;
}

@media (max-width: 575px) {
    .maintenance__text h2 {
        font-size: 30px;
    }
}

@media (max-width: 479px) {
    .maintenance__text h2 {
        font-size: 26px;
    }
}

.maintenance__text a {
    width: 290px;
    height: 60px;
    position: relative;
    display: none;
}

@media (max-width: 479px) {
    .maintenance__text a {
        width: 100%;
        max-width: 100%;
    }
}

.maintenance__text a i {
    position: absolute;
    right: 20px;
}

.welcome {
    max-width: 940px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 123px auto 131px;
}

@media (max-width: 575px) {
    .welcome {
        margin-top: 106px;
        margin-bottom: 94px;
    }
}

.welcome__img {
    max-width: 100%;
    width: 241px;
}

@media (max-width: 575px) {
    .welcome__img {
        width: 214px;
    }
}

.welcome__img img {
    max-width: 100%;
}

.welcome__text {
    margin-top: 33px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .welcome__text {
        margin-top: 23px;
        padding: 0 15px;
    }
}

.welcome__text h2 {
    color: var(--iq-darkblack);
    margin-bottom: 28px;
    text-align: center;
    line-height: 36px;
}

@media (max-width: 575px) {
    .welcome__text h2 {
        font-size: 30px;
    }
}

@media (max-width: 479px) {
    .welcome__text h2 {
        font-size: 26px;
    }
}

.welcome__text p {
    font-size: 25px;
    text-align: center;
    margin-bottom: 60px;
    max-width: 820px;
    line-height: 40px;
}

@media (max-width: 575px) {
    .welcome__text p {
        font-size: 17px;
        line-height: 30px;
        margin-bottom: 30px;
    }
}

.welcome__text a {
    width: 236px;
    height: 60px;
    position: relative;
    box-shadow: 0 4px 25px rgba(252, 227, 0, 0.69);
}

@media (max-width: 479px) {
    .welcome__text a {
        width: 100%;
        max-width: 100%;
    }
}

.welcome__text a i {
    position: absolute;
    right: 20px;
}

.previous-drawn {
    margin: 50px 0 100px;
}

@media (max-width: 479px) {
    .previous-drawn {
        margin: 20px 0 40px;
    }
}

.previous-drawn__top {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 67px;
}

@media (max-width: 575px) {
    .previous-drawn__top {
        margin-bottom: 20px;
    }
}

.previous-drawn__button {
    width: 270px;
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 479px) {
    .previous-drawn__button {
        width: 100%;
    }
}

.previous-drawn__button a {
    width: 100%;
    max-width: 100%;
    height: 60px;
    position: relative;
}

@media (max-width: 479px) {
    .previous-drawn__button a {
        height: 48px;
    }
}

.previous-drawn__button a i {
    position: absolute;
    right: 20px;
}

@media (max-width: 575px) {
    .previous-drawn__button.desktop {
        display: none;
    }
}

.previous-drawn__button.mobile {
    display: block;
}

@media (min-width: 576px) {
    .previous-drawn__button.mobile {
        display: none;
    }
}

.previous-drawn__details,
.previous-drawn__details--img {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.previous-drawn__details--img {
    width: 154px;
    height: 154px;
    border-radius: 50%;
    background-color: #f1f1f1;
    border: 2px solid var(--iq-pink);
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .previous-drawn__details--img {
        display: none;
    }
}

.previous-drawn__details--img img {
    width: 74px;
    max-width: 100%;
}

@media (max-width: 575px) {
    .previous-drawn__text {
        margin-left: 0;
    }
}

.previous-drawn__text h1 {
    color: var(--iq-darkblack);
    margin-bottom: 10px;
}

@media (max-width: 479px) {
    .previous-drawn__text h1 {
        font-size: 17px;
    }
}

.previous-drawn__text h2 {
    color: var(--iq-pink);
    margin-bottom: 0;
    line-height: 25px;
}

@media (max-width: 479px) {
    .previous-drawn__text h2 {
        font-size: 20px;
    }
}

.previous-drawn__text p {
    color: var(--iq-black);
    margin-bottom: 15px;
}

@media (max-width: 479px) {
    .previous-drawn__text p {
        font-size: 14px;
    }
}

.previous-drawn__text--number-row {
    display: -webkit-box;
    display: flex;
}

.previous-drawn__text--number-row .betNum {
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-position: 50%;
    color: var(--iq-black);
    font-size: 21px;
    margin: 0;
    padding: 0;
}

@media (max-width: 479px) {
    .previous-drawn__text--number-row .betNum {
        width: 41px;
        height: 41px;
        border: none;
        font-size: 18px;
    }
}

@media (max-width: 340px) {
    .previous-drawn__text--number-row .betNum {
        height: 35px;
        font-size: 17px;
    }
}

.previous-drawn__text--number-row .betNumMarked {
    background-image: url(../img/ball.svg);
}

.previous-drawn__buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    align-items: flex-end;
    margin-bottom: 50px;
}

@media (max-width: 575px) {
    .previous-drawn__buttons {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
    }
}

.previous-drawn__select {
    width: 220px;
    max-width: 100%;
}

@media (max-width: 575px) {
    .previous-drawn__select {
        margin-top: 15px;
    }
}

@media (max-width: 479px) {
    .previous-drawn__select {
        width: 100%;
    }
}

.previous-drawn__select p {
    color: var(--iq-black);
    margin-bottom: 10px;
}

.previous-drawn__select .selectize .selectize-input:after {
    color: var(--iq-orange);
}

@media (max-width: 575px) {
    .previous-drawn .table-main {
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .previous-drawn .table-main table thead {
        border: initial;
        clip: unset;
        height: auto;
        position: unset;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 479px) {
    .previous-drawn .table-main table thead tr th:first-child {
        padding: 15px 0 10px 15px;
    }
}

@media (max-width: 479px) {
    .previous-drawn .table-main table thead tr th:nth-child(2) {
        padding: 15px 0 10px 30px;
    }
}

@media (max-width: 575px) {
    .previous-drawn .table-main table thead tr th:nth-child(3) {
        /* display: none; */
    }
}

@media (max-width: 479px) {
    .previous-drawn .table-main table thead tr th:last-child {
        padding: 15px 0 10px 15px;
    }
}

@media (max-width: 575px) {
    .previous-drawn .table-main table thead tr th:not(:last-child) {
        border-right: none;
    }
}

@media (max-width: 479px) {
    .previous-drawn .table-main table thead tr th {
        font-size: 14px;
    }
}

@media (max-width: 575px) {
    .previous-drawn .table-main table tbody {
        display: table-row-group;
    }
}

@media (max-width: 575px) {
    .previous-drawn .table-main table tbody tr {
        display: table-row;
    }
}

@media (max-width: 575px) {
    .previous-drawn .table-main table tbody tr:nth-child(2n) {
        background-color: #ffeee4;
    }
}

@media (max-width: 575px) {
    .previous-drawn .table-main table tbody tr td {
        display: table-cell;
    }
}

@media (max-width: 575px) {
    .previous-drawn .table-main table tbody tr td:nth-child(odd) {
        background-color: initial;
    }
}

.previous-drawn .table-main table tbody tr td:first-child {
    color: var(--iq-darkblack);
}

.previous-drawn .table-main table tbody tr td:before {
    content: none;
}

@media (max-width: 575px) {
    .previous-drawn .table-main table tbody tr td:nth-child(3) {
        /* display: none; */
    }
}

@media (max-width: 479px) {
    .previous-drawn .table-main table tbody tr td:nth-child(2) {
        padding: 15px 15px 12px 30px;
    }
}

.previous-drawn__section:nth-child(3) .previous-drawn__details--img img {
    width: 100px;
}

.previous-drawn__section:nth-child(3) .table-main table tbody tr td:last-child {
    color: var(--iq-darkblack);
}

.ticket-page {
    padding: 50px 0;
}

.ticket-page .ticket {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 420px) {
    .ticket-page .ticket {
        background-position: 0, 100%;
        background-repeat: repeat-y, repeat-y;
        background-size: 35px auto, 35px auto;
    }
}

.ticket-page .ticket--wrapper {
    width: 413px;
    max-width: 100%;
    background-position: 0, 100%;
    background-repeat: repeat-y, repeat-y;
    background-size: 35px auto, 35px auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 420px) {
    .ticket-page .ticket--wrapper {
        background-image: none;
        width: 240px;
    }
}

.ticket-page .ticket__img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 250px;
    max-width: 100%;
    padding-bottom: 58px;
    margin: 35px 0 20px;
}

@media (max-width: 420px) {
    .ticket-page .ticket__img {
        width: 200px;
    }
}

.ticket-page .ticket__winnings {
    margin-bottom: 15px;
}

.ticket-page .ticket__winnings--win {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 10px;
}

.ticket-page .ticket__winnings--win span {
    font-size: 14px;
    color: var(--iq-white);
    padding: 8px 8px 3px;
}

.ticket-page .ticket__winnings p {
    font-size: 13px;
    color: var(--iq-black);
}

.ticket-page .ticket__bottom {
    width: 300px;
    max-width: 100%;
}

@media (max-width: 420px) {
    .ticket-page .ticket__bottom {
        width: 100%;
    }
}

@media (max-width: 350px) {
    .ticket-page .ticket__bottom {
        width: 250px;
    }
}

.ticket-page .ticket__bottom--header {
    border-top: 2px dashed var(--iq-black);
    border-bottom: 2px dashed var(--iq-black);
    padding: 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
}

.ticket-page-side-banners {
    width: 36px;
    max-width: 100%;
}

.ticket-page-logo-img {
    height: auto;
    width: 100%;
    object-fit: contain;
}

.ticket-page .ticket__bottom--header h2 {
    font-size: 14px;
    color: var(--iq-black);
    margin-bottom: 0;
}

.ticket-page .ticket__content {
    width: 300px;
    max-width: 100%;
}

.ticket-page-content {
    width: 325px;
}

@media (max-width: 400px) {
    .ticket-page .ticket__content {
        width: 250px;
    }

    .ticket-page-content {
        width: 230px;
    }
}

.ticket-page .ticket__content h2 {
    font-size: 14px;
    color: var(--iq-black);
    padding: 8px 5px 5px;
    border-top: 2px dashed var(--iq-black);
    border-bottom: 2px dashed var(--iq-black);
    margin-bottom: 15px;
}

.ticket-page .ticket__content ul {
    padding: 0;
    list-style-type: none;
    margin-bottom: 15px;
}

.ticket-page .ticket__content ul li {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.ticket-page .ticket__content ul li:not(:last-child) {
    margin-bottom: 5px;
}

.ticket-page .ticket__content ul li p {
    font-size: 14px;
    color: var(--iq-black);
}

@media (max-width: 420px) {
    .ticket-page .ticket__content ul li p {
        font-size: 12px;
    }
}

.ticket-page .ticket__content ul li span {
    font-size: 14px;
    color: var(--iq-black);
}

@media (max-width: 420px) {
    .ticket-page .ticket__content ul li span {
        font-size: 12px;
        text-align: right;
    }
}

.ticket-page .ticket__numbers {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media (max-width: 420px) {
    .ticket-page .ticket__numbers {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }
}

.ticket-page .ticket__numbers--row {
    display: -webkit-box;
    display: flex;
    margin-bottom: 5px;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 575px) {
    .ticket-page .ticket__numbers--row {
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 8px;
    }
}

.ticket-page .ticket__numbers--row:last-child {
    margin-bottom: 0;
}

.ticket-page .ticket__numbers--number-row {
    display: -webkit-box;
    display: flex;
}

.ticket-page .ticket__numbers--number-row .betNum {
    width: 28px;
    height: 28px;
    margin: 0 5px 0 0;
    border-color: transparent;
    font-size: 14px;
    padding: 0;
    text-align: center;
}

@media (max-width: 575px) {
    .ticket-page .ticket__numbers--number-row .betNum {
        padding-top: 2px;
        font-size: 12px;
        width: 24px;
        height: 24px;
    }
}

.ticket-page .ticket__numbers--number-row .betNumMarked {
    background: none;
    border-color: var(--iq-black);
    border-width: 1px;
}

.subtickets div {
    font-size: 14px;
    color: var(--iq-black) !important;
    line-height: 32px;
}

.ticket-page .ticket__numbers--raffleid {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 4px 10px;
}

.ticket-page .ticket__numbers--raffleid span {
    font-size: 14px;
    color: var(--iq-black);
    letter-spacing: 2px;
}

@media (max-width: 420px) {
    .ticket-page .ticket__numbers--raffleid span {
        padding-top: 3px;
    }
}

@media (max-width: 350px) {
    .ticket-page .ticket__numbers--raffleid span {
        font-size: 12px;
        padding-top: 2px;
    }
}

.ticket-page .ticket__numbers--raffleid.raffleid-win {
    border: 2px solid var(--iq-black);
    border-radius: 50%;
}

.ticket-page .ticket__barcode {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 90px;
}

@media (max-width: 420px) {
    .ticket-page .ticket__barcode {
        margin-top: 50px;
    }
}

.ticket-page .ticket__barcode p {
    color: var(--iq-black);
}

.ticket-page .ticket__barcode--img {
    max-width: 136px;
}

.ticket-page .ticket__barcode--img img {
    max-width: 100%;
    width: auto;
    height: auto;
}

.my-cards {
    padding: 32px 40px 40px;
}

@media (max-width: 575px) {
    .my-cards {
        padding: 0;
        border: none;
    }
}

.my-cards .myaccount__content--heading h3 {
    margin-bottom: 0;
}

@media (max-width: 575px) {
    .my-cards__content .payment__card:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    .subtickets div {
        margin-bottom: 8px !important;
        line-height: 35px;
        font-size: 12px;
    }
}

@media (max-width: 575px) {
    .my-cards__content .payment__card--img {
        margin-right: 10px;
    }
}

html .ui-datepicker .ui-widget-header {
    background: var(--iq-pink);
}

html .ui-datepicker .ui-corner-all {
    cursor: pointer;
    border: 1px solid transparent;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

html .ui-datepicker .ui-corner-all.ui-state-hover {
    border-color: var(--iq-white);
}

html .ui-datepicker .ui-state-default {
    background: var(--iq-mildgrey);
    border-color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
}

html .ui-datepicker .ui-state-active {
    background: var(--iq-pink);
    color: var(--iq-white);
}

html .ui-datepicker .ui-state-hover {
    background-color: var(--iq-pink);
    color: var(--iq-white);
}

html .ui-datepicker .ui-state-highlight {
    background: #7bb8de;
    color: var(--iq-white);
}

html .ui-widget.ui-widget-content {
    border: 1px solid var(--iq-dullgrey);
}

.cookie-bar {
    background-color: var(--iq-black);
    padding: 20px 0;
    z-index: 6;
    width: 100%;
    max-width: 100%;
    display: none;
    position: fixed;
    bottom: 0;
}

.cookie-bar.active {
    display: block;
}

.cookie-bar__content {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .cookie-bar__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.cookie-bar__text {
    max-width: 960px;
    padding-right: 50px;
}

@media (max-width: 575px) {
    .cookie-bar__text {
        padding-right: 0;
        margin-bottom: 25px;
    }
}

.cookie-bar__text p {
    font-size: 14px;
    color: var(--iq-white);
    line-height: 19px;
}

.cookie-bar__text p:first-child {
    margin-bottom: 10px;
}

.cookie-bar__text p a {
    color: var(--iq-white);
    text-decoration: underline;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.cookie-bar__text p a:hover {
    color: #fce300;
}

.cookie-bar__buttons {
    display: -webkit-box;
    display: flex;
}

.cookie-bar__buttons a {
    width: 140px;
    font-size: 17px;
}

.new-password {
    margin: 77px 0 73px;
}

@media (max-width: 575px) {
    .new-password {
        margin: 30px 0 70px;
    }
}

.new-password__content {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.new-password__content .form {
    width: 621px;
    max-width: 100%;
}

.new-password__content .form h2 {
    text-align: left;
    margin-bottom: 30px;
}

.new-password__content .form .form-group:not(:last-child) {
    margin-bottom: 22px;
}

.new-password__content--button {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-top: 36px;
}

.new-password__content--button button {
    width: 267px;
    max-width: 100%;
    height: 60px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.bottle-picker {
    margin: 90px 0;
}

@media (max-width: 575px) {
    .bottle-picker {
        margin: 40px 0;
    }
}

.bottle-picker .error-text {
    background-color: rgba(213, 0, 50, 0.1);
    border-radius: 5px;
    padding: 15px 20px;
    display: none;
    margin-top: 10px;
}

.bottle-picker .error-text.active {
    display: block;
}

@media (max-width: 479px) {
    .bottle-picker .error-text {
        padding: 15px 12px;
    }
}

.bottle-picker .error-text p {
    font-size: 14px;
    color: var(--iq-darkred);
}

.bottle-picker__content--img {
    height: 440px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    border: 1px solid var(--iq-lgtgrey);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
}

@media (max-width: 575px) {
    .bottle-picker__content--img {
        height: 350px;
    }
}

.bottle-picker__content--img.product_1 {
    background-image: url(../img/cart_product_1.png);
}

.bottle-picker__content--img.product_2 {
    background-image: url(../img/cart_product_2.png);
}

.bottle-picker__content--img.product_3 {
    background-image: url(../img/cart_product_3.png);
}

.bottle-picker__inner {
    padding-top: 25px;
}

.bottle-picker__inner h1 {
    color: var(--iq-pink);
    margin-bottom: 15px;
}

@media (max-width: 479px) {
    .bottle-picker__inner h1 {
        font-size: 21px;
    }
}

.bottle-picker__inner p {
    color: var(--iq-darkblack);
}

.bottle-picker__inner--box {
    background-color: var(--iq-mildgrey);
    margin-top: 30px;
    border-radius: 5px;
}

@media (max-width: 479px) {
    .bottle-picker__inner--box {
        margin-top: 15px;
    }
}

.bottle-picker__inner--box p {
    padding: 20px 18px;
}

@media (max-width: 479px) {
    .bottle-picker__inner--box p {
        font-size: 14px;
    }
}

.bottle-picker__inner--bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 30px;
}

@media (max-width: 575px) {
    .bottle-picker__inner--bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 479px) {
    .bottle-picker__inner--bottom {
        margin-top: 15px;
    }
}

@media (max-width: 575px) {
    .bottle-picker__currency {
        margin-bottom: 15px;
    }
}

.bottle-picker__currency span {
    font-size: 25px;
    color: var(--iq-darkblack);
}

@media (max-width: 479px) {
    .bottle-picker__currency span {
        font-size: 21px;
    }
}

.bottle-picker__currency p {
    color: var(--iq-darkblack);
}

.bottle-picker__currency--currency {
    font-size: 12px !important;
    font-style: italic;
    line-height: 11px;
}

.bottle-picker__buttons {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 575px) {
    .bottle-picker__buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.bottle-picker__buttons--value {
    display: -webkit-box;
    display: flex;
    height: 60px;
    margin-right: 10px;
}

@media (max-width: 575px) {
    .bottle-picker__buttons--value {
        width: 100%;
        max-width: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        margin-bottom: 15px;
    }
}

.bottle-picker__buttons--value
    input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
        [type="file"]
    ) {
    width: 55px;
    border-radius: 0;
    text-align: center;
    color: var(--iq-darkblack);
    height: 100%;
    padding: 20px 0;
}

@media (max-width: 575px) {
    .bottle-picker__buttons--value
        input:not([type="checkbox"]):not([type="button"]):not(
            [type="submit"]
        ):not([type="file"]) {
        width: 100%;
        max-width: 100%;
    }
}

.bottle-picker__buttons--value .value-button {
    padding: 0 14px;
    background-color: #f8f8f8;
    border: 1px solid var(--iq-dullgrey);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: var(--iq-darkblack);
    cursor: pointer;
}

.bottle-picker__buttons--value .value-button:first-child {
    border-right: 0;
    border-radius: 4px 0 0 4px;
}

.bottle-picker__buttons--value .value-button:last-child {
    border-left: 0;
    border-radius: 0 4px 4px 0;
}

.bottle-picker__buttons--submit {
    text-align: right;
}

@media (max-width: 575px) {
    .bottle-picker__buttons--submit {
        width: 100%;
        max-width: 100%;
        text-align: left;
    }
}

.bottle-picker__buttons--submit button {
    width: 228px;
    max-width: 100%;
    height: 60px;
    margin-bottom: 15px;
}

@media (max-width: 575px) {
    .bottle-picker__buttons--submit button {
        width: 100%;
        max-width: 100%;
    }
}

.bottle-picker__buttons--submit p {
    color: var(--iq-darkblack);
}

.map {
    width: 100%;
    height: 480px;
    margin: 0;
    max-width: 100%;
}

.gm-style .gm-style-iw-d > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.gm-style .gm-style-iw-d > div a {
    text-align: left;
    max-width: 85px;
    color: var(--iq-pink);
    text-decoration: underline;
    margin-top: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.gm-style .gm-style-iw-d > div a:hover {
    color: #7bb8de;
}

.pac-card {
    background-color: var(--iq-white);
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    margin: 10px;
    font: 400 18px Roboto, Arial, sans-serif;
    overflow: hidden;
    padding: 0;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-size: 13px;
}

#pac-input {
    background-color: var(--iq-white);
    font-size: 17px;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

.find__content {
    text-align: center;
    margin: 90px 0 130px;
}

@media (max-width: 767px) {
    .find__content {
        margin: 60px 0 90px;
    }
}

@media (max-width: 575px) {
    .find__content {
        margin: 40px 0 70px;
    }
}

.find__content h1 {
    color: var(--iq-darkblack);
    margin-bottom: 30px;
    font-size: 28px;
}

@media (max-width: 575px) {
    .find__content h1 {
        font-size: 21px;
        margin-bottom: 10px;
    }
}

.find__content p {
    color: var(--iq-darkgrey);
    margin-bottom: 15px;
    line-height: 30px;
}

@media (max-width: 575px) {
    .find__content p {
        line-height: 27px;
        font-size: 14px;
    }
}

.find__content p:nth-child(3) {
    margin-bottom: 50px;
}

.find__content p a {
    color: var(--iq-pink);
    text-decoration: underline;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.find__content p a:hover {
    color: #7bb8de;
    text-decoration: unset;
}

.find__form {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 30px;
}

.find__form .form-group {
    width: 100%;
    max-width: 100%;
}

.find__form .form-group .selectize-control .selectize-input {
    height: 50px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    color: var(--iq-darkgrey);
    padding: 8px 30px 8px 8px;
    font-size: 13px !important;
}

.find__form .form-group .selectize-control .selectize-input input {
    font-size: 13px !important;
    color: var(--iq-darkgrey);
}

.find__form
    .form-group
    .selectize-control
    .selectize-input
    .selectize-custom-link {
    height: 30px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.find__form
    .form-group
    .selectize-control
    .selectize-dropdown
    .selectize-dropdown-content {
    text-align: left;
    padding-left: 8px;
}

.find__form
    .form-group
    .selectize-control
    .selectize-dropdown
    .selectize-custom-link
    span,
.find__form
    .form-group
    .selectize-control
    .selectize-dropdown
    .selectize-custom-option
    span {
    font-size: 13px;
    color: var(--iq-darkgrey);
}

.share_tab {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    list-style-type: none;
    margin-bottom: 80px;
    padding-left: 0;
}

@media (max-width: 479px) {
    .share_tab {
        margin-bottom: 40px;
    }
}

.share_tab > span {
    margin-right: 12px;
}

.share_tab li {
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.share_tab li:not(:last-child) {
    margin-right: 10px;
}

.share_tab li a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.register__new .login__content .form {
    width: 100%;
    max-width: 100%;
}

.register__new .login__content .form__content {
    width: 100%;
    max-width: 100%;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.05);
    border: none;
    border-radius: 5px;
}

.register__new .login__content .form__content form {
    border: none;
    border-radius: 0;
    padding: 0;
}

.register__new .login__content .form__content form .information-text.secondary {
    margin-top: 5px;
    margin-bottom: 0;
}

.register__new .login__content .form__content form .form-group .info-icon {
    display: none;
}

@media (max-width: 575px) {
    .register__new .login__content .form__content form .form-group .input-row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
}

@media (max-width: 575px) {
    .register__new
        .login__content
        .form__content
        form
        .form-group
        .input-row
        .flag-picker {
        margin-bottom: 0;
    }
}

@media (max-width: 479px) {
    .register__new
        .login__content
        .form__content
        form
        .form-group
        .input-row
        .flag-picker {
        width: 120px;
        min-width: 120px;
    }
}

.register__new
    .login__content
    .form__content
    form
    .form-group.error
    .info-icon.error {
    display: block;
}

.register__new
    .login__content
    .form__content
    form
    .form-group.error
    .captcha_holder {
    border-radius: 5px;
    border: 1px solid var(--iq-darkred);
}

.register__new
    .login__content
    .form__content
    form
    .form-group.success
    .info-icon.success {
    display: block;
}

.register__new
    .login__content
    .form__content
    form
    .form-group#form_register_confirm_passwd
    .info-icon,
.register__new
    .login__content
    .form__content
    form
    .form-group#form_register_passwd
    .info-icon {
    right: 40px;
}

.register__new
    .login__content
    .form__content
    form
    .form-group#form_register_verify-otp
    .info-icon {
    top: 75px;
}

@media (max-width: 575px) {
    .register__new
        .login__content
        .form__content
        form
        .form-group#form_register_verify-otp
        .info-icon {
        top: 95px;
    }
}

.register__new
    .login__content
    .form__content
    form
    .form-group#form_confirm_phone
    .captcha-wrapper {
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 50px;
}

@media (max-width: 767px) {
    .register__new
        .login__content
        .form__content
        form
        .form-group#form_confirm_phone
        .captcha-wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

@media (max-width: 575px) {
    .register__new
        .login__content
        .form__content
        form
        .form-group#form_confirm_phone
        .captcha-wrapper {
        margin-bottom: 0;
    }
}

@media (max-width: 360px) {
    .register__new
        .login__content
        .form__content
        form
        .form-group#form_confirm_phone
        .captcha-wrapper {
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
}

@media (max-width: 479px) {
    .register__new
        .login__content
        .form__content
        form
        .form-group#form_confirm_phone
        .form__bottom {
        padding: 25px 0;
    }
}

.register__new
    .login__content
    .form__content
    form
    .form-group#form_register_start {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.register__new
    .login__content
    .form__content
    form
    .form-group#form_register_start
    a {
    width: 250px;
}

@media (max-width: 479px) {
    .register__new
        .login__content
        .form__content
        form
        .form-group#form_register_start
        a {
        width: 100%;
        max-width: 100%;
    }
}

.register__new
    .login__content
    .form__content
    form
    .form-group
    .visibility-button {
    position: absolute;
    right: 15px;
    top: 47px;
    cursor: pointer;
}

.register__new .login__content .form__center {
    padding: 0 100px 0px;
}

@media (max-width: 575px) {
    .register__new .login__content .form__center {
        padding: 0px 15px;
    }
}

.register__new .login__content .form__center--firststep {
    max-width: 600px;
    margin: 0 auto;
    display: block;
}

.register__new
    .login__content
    .form__center--firststep
    #form_register_verify-otp {
    display: none;
}

.register__new .login__content .form__center--firststep .form-group {
    margin-bottom: 0;
}

.register__new .login__content .form__center--secondstep,
.register__new .login__content .form__center--thirdstep {
    display: none;
}

.register__new .login__content .form__center--labeltext {
    color: #797979;
    font-size: 12px;
    margin-bottom: 10px;
}

.register__new .login__content .form__bottom {
    padding: 0 100px 20px;
}

@media (max-width: 575px) {
    .register__new .login__content .form__bottom {
        padding: 0px 15px;
    }
}

.register__new .login__content .form__bottom .form-group {
    margin-bottom: 0 !important;
}

.register__new .login__content .form__bottom--continue {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    visibility: hidden;
}

.register__new .login__content .form__bottom--continue.active {
    visibility: visible;
}

.register__new .login__content .form__bottom--continue .form-group {
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: flex;
}

.register__new
    .login__content
    .form__bottom--continue
    .form-group#form_register_continue {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.register__new .login__content .form__bottom--continue .form-group button {
    width: 236px;
    height: 60px;
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .register__new .login__content .form__bottom--continue .form-group button {
        height: 45px;
        width: 100%;
    }
}

.register__new .login__content .form__bottom--continue p {
    font-size: 14px;
    color: var(--iq-darkred);
    margin-top: 18px;
}

@media (max-width: 479px) {
    .register__new .login__content .form__bottom--continue p {
        font-size: 12px;
    }
}

.register__new .login__content .form__bottom--create {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    display: none;
    position: relative;
}

.register__new .login__content .form__bottom--create .form-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: flex-end;
    align-items: flex-end;
}

@media (max-width: 575px) {
    .register__new .login__content .form__bottom--create .form-group {
        width: 100%;
    }
}

.register__new .login__content .form__bottom--create .form-group button {
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .register__new .login__content .form__bottom--create .form-group button {
        height: 45px;
        width: 100%;
        max-width: 100%;
    }
}

.register__new .login__content .form__bottom--back {
    position: absolute;
    left: 0;
    top: 0;
    height: 48px;
    width: 130px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--iq-orange);
    color: var(--iq-white);
}

@media (max-width: 575px) {
    .register__new .login__content .form__bottom--back {
        position: unset;
        left: unset;
        top: unset;
        width: 100%;
        height: 45px;
        margin-top: 10px;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

@media (max-width: 575px) {
    .register__new .login__content .form__bottom--back i {
        margin-right: 10px;
    }
}

.register__new .login__content .form__top {
    padding: 44px 100px 30px;
}

@media (max-width: 575px) {
    .register__new .login__content .form__top {
        padding: 25px 15px;
    }
}

.register__new .login__content .form__top p {
    color: var(--iq-darkgrey);
    text-align: center;
    font-size: 14px;
}

@media (max-width: 479px) {
    .register__new .login__content .form__top p {
        font-size: 12px;
    }
}

.register__new .login__content .form__top #register-information-primary {
    display: none;
}

.register__new .login__content .form--span {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-top: 13px;
    position: relative;
}

@media (max-width: 575px) {
    .register__new .login__content .form--span {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.register__new .login__content .form--span .error-text,
.register__new .login__content .form--span .success-text {
    position: absolute;
    left: 0;
    top: -10px;
}

@media (max-width: 575px) {
    .register__new .login__content .form--span .error-text,
    .register__new .login__content .form--span .success-text {
        position: unset;
        left: unset;
        top: unset;
    }
}

.register__new .login__content .form--span span {
    position: unset;
    top: unset;
    right: unset;
    padding: 5px 0;
}

@media (max-width: 575px) {
    .register__new .login__content .form--span span {
        width: 100%;
    }
}

.register__new .login__content .form #form_register_verify-otp input {
    width: 100%;
    max-width: 100%;
}

.register__new .login__content .form #form_register_verify-otp span {
    position: unset;
    top: unset;
    right: unset;
    height: 50px;
    margin-left: 13px;
    background-color: var(--iq-white);
    border: 1px solid var(--iq-dullgrey);
    color: var(--iq-dullgrey);
}

.register__new .login__content .form #form_register_verify-otp span:hover {
    border-color: #0077c8;
    color: #0077c8;
}

.register__new .login__content .form__requirements {
    margin-top: 15px;
}

.register__new .login__content .form__requirements p {
    font-size: 13px;
    color: var(--iq-darkgrey);
    margin-bottom: 15px;
}

.register__new .login__content .form__requirements--row {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 1199px) {
    .register__new .login__content .form__requirements--row {
        flex-wrap: wrap;
    }
}

@media (max-width: 991px) {
    .register__new .login__content .form__requirements--row {
        flex-wrap: nowrap;
    }
}

@media (max-width: 575px) {
    .register__new .login__content .form__requirements--row {
        flex-wrap: wrap;
    }
}

.register__new .login__content .form__requirements--row > div:not(:last-child) {
    margin-right: 5px;
}

@media (max-width: 575px) {
    .register__new .login__content .form__requirements--row > div {
        margin-bottom: 5px;
    }
}

.register__new .login__content .form__requirements--req {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #f2f2f2;
}

@media (max-width: 1199px) {
    .register__new .login__content .form__requirements--req {
        margin-bottom: 5px;
    }
}

@media (max-width: 991px) {
    .register__new .login__content .form__requirements--req {
        margin-bottom: 0;
    }
}

.register__new .login__content .form__requirements--req.active {
    background-color: var(--iq-pink);
}

.register__new .login__content .form__requirements--req.active p {
    color: var(--iq-white);
}

.register__new .login__content .form__requirements--req p {
    font-size: 12px;
    color: var(--iq-black);
    margin-bottom: 0;
}

.register__success h1 {
    color: var(--iq-darkblack);
    text-align: center;
    margin-bottom: 40px;
}

.register__success--img {
    width: 154px;
    min-width: 154px;
    max-width: 100%;
    margin-right: 45px;
}

@media (max-width: 767px) {
    .register__success--img {
        margin-bottom: 10px;
        margin-right: 0;
    }
}

.register__success--img img {
    max-width: 100%;
}

.register__success--box {
    background-color: var(--iq-white);
    max-width: 1080px;
    margin: 0 auto;
    padding: 45px 80px 45px 70px;
    display: -webkit-box;
    display: flex;
    display: none;
}

@media (max-width: 767px) {
    .register__success--box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
    }
}

@media (max-width: 479px) {
    .register__success--box {
        padding: 30px;
    }
}

.register__success--box.active {
    display: -webkit-box;
    display: flex;
}

.register__success--box h2 {
    color: var(--iq-pink);
    font-size: 25px;
    margin-bottom: 10px;
}

@media (max-width: 479px) {
    .register__success--box h2 {
        font-size: 20px;
    }
}

.register__success--box p {
    color: var(--iq-darkblack);
    line-height: 26px;
    /* margin-bottom: 23px; */
}

.register__success--cart a {
    width: 237px;
    max-width: 100%;
}

@media (max-width: 479px) {
    .register__success--cart a {
        width: 100%;
    }
}

.register__success--btnrow {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 479px) {
    .register__success--btnrow {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.register__success--btnrow a {
    width: 160px;
    max-width: 100%;
}

@media (max-width: 479px) {
    .register__success--btnrow a {
        width: 100%;
    }
}

.register__success--btnrow a:first-child {
    margin-right: 10px;
}

@media (max-width: 479px) {
    .register__success--btnrow a:first-child {
        margin-bottom: 10px;
    }
}

.page-rtl .register__new .login__content .form__bottom--create .form-group a {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    left: unset;
    right: 0;
}

.page-rtl
    .register__new
    .login__content
    .form__requirements--row
    > div:not(:last-child) {
    margin-right: 0;
    margin-left: 5px;
}

.page-rtl .register__new .login__content .form .form-group .visibility-button {
    right: unset;
    left: 15px;
}

.page-rtl
    .register__new
    .login__content
    .form
    .form-group#form_register_confirm_passwd
    .info-icon,
.page-rtl
    .register__new
    .login__content
    .form
    .form-group#form_register_passwd
    .info-icon {
    right: unset;
    left: 40px;
}

@media (max-width: 575px) {
    .page-rtl
        .register__new
        .login__content
        .form
        .form-group#form_register_passwd
        .info-icon {
        top: 45px;
    }
}

@media (max-width: 392px) {
    .page-rtl
        .register__new
        .login__content
        .form
        .form-group#form_register_passwd
        .info-icon {
        top: 45px;
    }
}

@media (max-width: 342px) {
    .page-rtl
        .register__new
        .login__content
        .form
        .form-group#form_register_passwd
        .info-icon {
        top: 45px;
    }
}

.page-rtl .register__new .login__content .form--span .error-text,
.page-rtl .register__new .login__content .form--span .success-text {
    position: absolute;
    right: 0;
    top: -10px;
}

@media (max-width: 575px) {
    .page-rtl .register__new .login__content .form--span .error-text,
    .page-rtl .register__new .login__content .form--span .success-text {
        position: unset;
        right: unset;
        top: unset;
    }
}

.page-rtl .register__success--box {
    text-align: right;
}

.page-rtl .register__success--img {
    margin-right: 0;
    margin-left: 45px;
}

.page-rtl .register__success--btnrow a:first-child {
    margin-right: 0;
    margin-left: 10px;
}

@media (max-width: 479px) {
    .page-rtl .flag-picker .selectize-control.rtl .selectize-input:after {
        left: 15px !important;
    }
}

.login__new .form form {
    padding: 0;
    border: none;
    border-radius: 0;
}

@media (max-width: 575px) {
    .login__new .form form .form-group .input-row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
}

@media (max-width: 575px) {
    .login__new .form form .form-group .input-row .flag-picker {
        margin-bottom: 0;
    }
}

@media (max-width: 479px) {
    .login__new .form form .form-group .input-row .flag-picker {
        width: 120px;
        min-width: 120px;
    }
}

.login__new .form__secondchoice {
    display: none;
}

.login__new .form a.forgot-pw {
    color: #0077c8;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.login__new .login__content--link {
    color: #0077c8;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.login__switch {
    height: 55px;
    width: 100%;
    max-width: 100%;
    background: rgba(0, 169, 224, 0.11);
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 3px;
    margin-bottom: 35px;
}

.login__switch,
.login__switch a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 31px;
}

.login__switch a {
    font-size: 17px;
    color: var(--iq-darkblack);
    width: 50%;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 400px) {
    .login__switch a {
        font-size: 13px;
    }
}

@media (min-width: 480px) {
    .login__switch a span:nth-child(2) {
        display: none;
    }
}

@media (max-width: 479px) {
    .login__switch a span:first-child {
        display: none;
    }
}

.login__switch a:hover {
    color: #0077c8;
}

.login__switch a.active {
    background-color: var(--iq-pink);
    color: var(--iq-white);
}

@media (max-width: 575px) {
    .forgotpw__new .forgotpw__content h1 {
        text-align: left;
    }
}

.forgotpw__new .form form {
    padding: 0;
    border: none;
    border-radius: 0;
}

.forgotpw__new .form form .form-group:not(:last-child) {
    margin-bottom: 30px;
}

@media (max-width: 479px) {
    .forgotpw__new .form form .form-group:not(:last-child) {
        margin-bottom: 18px;
    }
}

.forgotpw__new .form form .info-box {
    background: rgba(0, 169, 224, 0.11);
    padding: 15px 20px;
    border-radius: 5px;
    margin-bottom: 40px;
}

@media (max-width: 479px) {
    .forgotpw__new .form form .info-box {
        margin-bottom: 20px;
    }
}

.forgotpw__new .form form .info-box p {
    font-size: 14px;
    line-height: 25px;
    color: #0077c8;
    margin-bottom: 0;
    text-align: left;
}

.forgotpw__new .form .forgotpw__content--btn {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.forgotpw__new .form .forgotpw__content--btn button[type="submit"] {
    margin-top: 0;
}

@media (max-width: 479px) {
    .forgotpw__new .form .forgotpw__content--btn button[type="submit"] {
        height: 45px;
    }
}

@media (max-width: 575px) {
    .page-rtl .forgotpw__new .forgotpw__content h1 {
        text-align: right;
    }
}

.page-rtl .forgotpw__new .form .info-box p {
    text-align: right;
}

.recurring-purchase {
    padding: 32px 40px 40px;
}

@media (max-width: 575px) {
    .recurring-purchase {
        padding: 0;
        border: none;
    }
}

.recurring-purchase .myaccount__content--heading h3 {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.recurring-purchase__heading {
    padding: 10px 0;
    border-top: 1px solid var(--iq-dullgrey);
}

.recurring-purchase__heading h4 {
    color: var(--iq-black);
    margin-bottom: 0;
}

.recurring-purchase__empty {
    color: var(--iq-black);
    margin: 20px 0 50px;
}

.recurring-purchase__empty--bottom {
    margin-bottom: 0;
}

.recurring-purchase__purchases--wrapper {
    margin-bottom: 15px;
    border-bottom: 1px solid var(--iq-dullgrey);
}

.recurring-purchase__content form#form_recurring-purchase {
    margin-bottom: 30px;
}

.recurring-purchase__purchase {
    display: -webkit-box;
    display: flex;
    padding: 15px;
    border: 1px solid var(--iq-dullgrey);
    border-bottom: none;
    background-color: #f7f7f7;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase {
        padding: 25px 15px;
    }
}

.recurring-purchase__purchase--numbers-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase--numbers-wrapper {
        margin-bottom: 10px;
    }
}

.recurring-purchase__purchase--numbers {
    display: -webkit-box;
    display: flex;
}

.recurring-purchase__purchase--id {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 13%;
    max-width: 100%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase--id {
        width: 15%;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

@media (max-width: 360px) {
    .recurring-purchase__purchase--id {
        width: 10%;
    }
}

.recurring-purchase__purchase--id p {
    color: var(--iq-black);
}

@media (max-width: 575px) {
    .recurring-purchase__purchase--id p:first-child {
        display: none;
    }
}

.recurring-purchase__purchase--id p:nth-child(2) {
    display: block;
    margin-top: 5px;
}

@media (min-width: 576px) {
    .recurring-purchase__purchase--id p:nth-child(2) {
        display: none;
    }
}

.recurring-purchase__purchase--numberinfo {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 67%;
    max-width: 100%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase--numberinfo {
        width: 80%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: unset;
        justify-content: unset;
    }
}

@media (max-width: 360px) {
    .recurring-purchase__purchase--numberinfo {
        width: 85%;
    }
}

.recurring-purchase__purchase--info {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    min-width: 257px;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase--info {
        min-width: unset;
    }
}

.recurring-purchase__purchase--info p {
    color: var(--iq-black);
}

.recurring-purchase__purchase--info p:first-child {
    margin-bottom: 5px;
}

@media (max-width: 360px) {
    .recurring-purchase__purchase--info p:first-child {
        font-size: 17px;
    }
}

.recurring-purchase__purchase--info p:not(:first-child) {
    font-size: 13px;
}

@media (max-width: 360px) {
    .recurring-purchase__purchase--info p {
        font-size: 13px;
    }
}

.recurring-purchase__purchase--delete {
    width: 20%;
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase--delete {
        width: 5%;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.recurring-purchase__purchase--delete a {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.recurring-purchase__purchase--delete a i {
    color: #b50023;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 23px;
}

.recurring-purchase__purchase--delete a:hover i {
    color: var(--iq-darkred);
}

.recurring-purchase__purchase-bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 20px 0;
    border: 1px solid var(--iq-dullgrey);
    background-color: #f7f7f7;
    margin-bottom: 15px;
}

.recurring-purchase__purchase-bottom-wrapper {
    padding: 0 15px;
}

.recurring-purchase__purchase-bottom .recurring-purchase__top {
    margin-bottom: 10px;
    padding: 0 15px 10px;
    border-bottom: 1px solid var(--iq-dullgrey);
}

.recurring-purchase__purchase-bottom .recurring-purchase__top > div {
    text-align: center;
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__top
    > div:first-child {
    width: 10%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:first-child {
        display: none;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__top
    > div:nth-child(2) {
    width: 29%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:nth-child(2) {
        width: 53%;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:nth-child(2) {
        width: 100%;
        max-width: 100%;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__top
    > div:nth-child(3) {
    width: 11%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:nth-child(3) {
        display: none;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__top
    > div:nth-child(4) {
    width: 13%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:nth-child(4) {
        display: none;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__top
    > div:nth-child(5) {
    width: 12%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:nth-child(5) {
        display: none;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__top
    > div:nth-child(6) {
    width: 10%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:nth-child(6) {
        width: 19%;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:nth-child(6) {
        display: none;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__top > div:last-child {
    width: 15%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:last-child {
        width: 28%;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__top
        > div:last-child {
        display: none;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__purchase {
    padding: 0;
    border: none;
    background-color: unset;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom .recurring-purchase__purchase {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__purchase > div {
    text-align: center;
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__purchase
    > div:first-child {
    width: 10%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:first-child {
        display: none;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__purchase
    > div:nth-child(2) {
    width: 66%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:nth-child(2) {
        width: 50%;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:nth-child(2) {
        width: 100%;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__purchase
    > div:nth-child(3) {
    width: 9%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:nth-child(3) {
        width: 25%;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:nth-child(3) {
        width: 100%;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__purchase
    > div:last-child {
    width: 15%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:last-child {
        width: 25%;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:last-child {
        width: 100%;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__purchase:not(:last-child) {
    margin-bottom: 15px;
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase:not(:last-child) {
        margin-bottom: 30px;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__purchase--row {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom .recurring-purchase__purchase--row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
        text-align: left;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom .recurring-purchase__purchase--row {
        width: 100%;
        max-width: 100%;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__purchase--row > div {
    max-width: 100%;
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--row
    > div:first-child {
    width: 45%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--row
        > div:first-child {
        width: 100%;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--row
    > div:nth-child(2) {
    width: 16%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--row
        > div:nth-child(2) {
        width: 100%;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--row
    > div:nth-child(3) {
    width: 20%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--row
        > div:nth-child(3) {
        width: 100%;
    }
}

.recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--row
    > div:last-child {
    width: 19%;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--row
        > div:last-child {
        width: 100%;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__purchase--item p {
    color: var(--iq-black);
    font-size: 14px;
    width: 100%;
    max-width: 100%;
}

@media (min-width: 576px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item
        p
        span {
        display: none;
    }
}

@media (min-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item.recurring-purchase__purchase--status
        p
        span {
        display: none;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__purchase--id {
    margin-right: 0;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--duration,
    .recurring-purchase__purchase-bottom .recurring-purchase__purchase--end,
    .recurring-purchase__purchase-bottom .recurring-purchase__purchase--start {
        text-align: left;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--duration,
    .recurring-purchase__purchase-bottom .recurring-purchase__purchase--end,
    .recurring-purchase__purchase-bottom .recurring-purchase__purchase--start {
        text-align: center;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__purchase--numbers {
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--numbers {
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--numbers {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__purchase--button {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom .recurring-purchase__purchase--button {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.recurring-purchase__purchase-bottom .recurring-purchase__purchase--button a {
    width: 100px;
    max-width: 100%;
    border-color: var(--iq-black);
    height: 30px;
}

.recurring-purchase__purchase-bottom .recurring-purchase__top {
    display: -webkit-box;
    display: flex;
}

.recurring-purchase__purchase-bottom .recurring-purchase__top h5 {
    color: var(--iq-black);
    margin-bottom: 0;
    font-size: 14px;
}

@media (max-width: 575px) {
    .recurring-purchase__purchase-bottom .recurring-purchase__top--numbers h5 {
        max-width: 190px;
    }
}

@media (max-width: 450px) {
    .recurring-purchase__purchase-bottom .recurring-purchase__top--numbers h5 {
        max-width: 100%;
    }
}

.recurring-purchase__button {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
}

.recurring-purchase__button a,
.recurring-purchase__button button {
    height: 30px;
    padding: 10px 20px 8px;
    width: 90px;
    max-width: 100%;
}

.addcredit__wrapper {
    max-width: 855px;
}

.addcredit__box {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid var(--iq-dullgrey);
    position: relative;
}

@media (max-width: 767px) {
    .addcredit__box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.addcredit__box--left {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 565px;
    max-width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .addcredit__box--left {
        width: 475px;
    }
}

@media (max-width: 767px) {
    .addcredit__box--left {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
        width: 100%;
    }
}

.addcredit__box--left h3 {
    font-size: 20px;
    color: var(--iq-darkblack);
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .addcredit__box--left h3 {
        margin-bottom: 35px;
    }
}

@media (max-width: 479px) {
    .addcredit__box--left h3 {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .addcredit__box--text {
        position: absolute;
        top: 22px;
        right: 0;
    }
}

.addcredit__box--text p {
    color: var(--iq-darkgrey);
}

@media (max-width: 479px) {
    .addcredit__box--text p {
        font-size: 12px;
    }
}

.addcredit__box--btn {
    width: 170px;
    max-width: 100%;
}

@media (max-width: 767px) {
    .addcredit__box--btn {
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .addcredit__box--btn a {
        height: 70px;
        font-size: 22px;
    }
}

.addcredit__info {
    padding: 20px 0;
    border-bottom: 1px solid var(--iq-dullgrey);
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.addcredit__info--voucher {
    max-width: 580px;
}

.addcredit__info--voucher.success p {
    display: block;
}

@media (max-width: 767px) {
    .addcredit__info--voucher.desktop p {
        display: none;
    }
}

.addcredit__info--voucher.mobile {
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .addcredit__info--voucher.mobile {
        display: none;
    }
}

.addcredit__info--voucher.mobile p {
    text-align: center;
}

@media (min-width: 768px) {
    .addcredit__info--voucher.mobile p {
        display: none;
    }
}

.addcredit__info--voucher p {
    color: #797979;
    text-align: left;
    display: none;
}

.addcredit__info--text p {
    color: var(--iq-darkblack);
    text-align: right;
}

.addcredit__info--text p:not(:last-child) {
    margin-bottom: 5px;
}

.addcredit__info--text span {
    color: var(--iq-darkblack);
    margin-left: 5px;
}

.addcredit__cards {
    padding: 20px 0;
    border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 767px) {
    .addcredit__cards--top {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 20px;
    }
}

@media (max-width: 420px) {
    .addcredit__cards--top {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
        -webkit-box-align: end;
        align-items: flex-end;
    }
}

.addcredit__cards--top p {
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .addcredit__cards--top p {
        margin-bottom: 0;
    }
}

@media (max-width: 420px) {
    .addcredit__cards--top p {
        margin-bottom: 5px;
    }
}

@media (min-width: 768px) {
    .addcredit__cards--top img {
        display: none;
    }
}

.addcredit__cards p {
    color: var(--iq-darkblack);
    text-align: right;
}

.addcredit__cards p span {
    color: var(--iq-darkblack);
    margin-left: 5px;
}

.addcredit__cards--bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .addcredit__cards--bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
    }
}

.addcredit__cards--left {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.addcredit__cards--left p {
    color: #797979;
}

@media (max-width: 380px) {
    .addcredit__cards--left p {
        font-size: 12px;
    }
}

.addcredit__cards--right {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 767px) {
    .addcredit__cards--right {
        margin-bottom: 40px;
    }
}

.addcredit__cards--right img {
    margin-right: 20px;
}

@media (max-width: 767px) {
    .addcredit__cards--right img {
        display: none;
    }
}

.addcredit__cards--img {
    width: 135px;
    margin-right: 10px;
}

.addcredit__cards--img img {
    max-width: 100%;
    height: 28px;
}

.addcredit__cards--btn {
    width: 170px;
}

@media (max-width: 767px) {
    .addcredit__cards--btn {
        width: 100%;
        max-width: 100%;
    }
}

.addcredit__cards--btn button {
    width: 100%;
    max-width: 100%;
}

@media (max-width: 767px) {
    .addcredit__cards--btn button {
        height: 70px;
        font-size: 22px;
    }
}

.addcredit__bottom p {
    font-size: 14px;
    color: #797979;
    margin-top: 10px;
}

.addcredit .form .form-group .input-box {
    width: 300px;
}

@media (max-width: 767px) {
    .addcredit .form .form-group .input-box {
        width: 100%;
        max-width: 100%;
    }
}

.addcredit .form .form-group .input-box input[type="number"],
.addcredit .form .form-group .input-box input[type="text"] {
    border: 1px solid var(--iq-pink);
    text-align: left;
    padding: 20px 20px 15px 95px;
}

.addcredit .form .form-group .input-box--divider {
    content: "";
    display: block;
    width: 1px;
    height: 50px;
    background-color: var(--iq-pink);
    position: absolute;
    top: 0;
    left: 70px;
}

.addcredit .form .form-group .input-box--voucher input[type="text"] {
    padding: 20px 10px 15px;
}

.addcredit
    .form
    .form-group
    .input-box--voucher
    input[type="text"]::-webkit-input-placeholder {
    color: #b2b2b2;
}

.addcredit
    .form
    .form-group
    .input-box--voucher
    input[type="text"]::-moz-placeholder {
    color: #b2b2b2;
}

.addcredit
    .form
    .form-group
    .input-box--voucher
    input[type="text"]:-ms-input-placeholder {
    color: #b2b2b2;
}

.addcredit
    .form
    .form-group
    .input-box--voucher
    input[type="text"]::-ms-input-placeholder {
    color: #b2b2b2;
}

.addcredit
    .form
    .form-group
    .input-box--voucher
    input[type="text"]::placeholder {
    color: #b2b2b2;
}

@media (min-width: 768px) {
    .addcredit .form .form-group .input-box .error-text {
        position: absolute;
        bottom: -21px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        left: 50%;
    }
}

.addcredit .form .form-group .input-box .error-text p {
    position: unset;
    margin-top: 5px;
    text-align: center;
}

.addcredit .form .form-group.error .input-box input {
    border-color: var(--iq-pink) !important;
}

.addcredit .form .form-group.success .addcredit__info--voucher p {
    display: block;
}

@media (max-width: 992px) {
    .header__wrapper--nav.desktop {
        display: block;
    }
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop .container {
        padding: 0;
    }
}

.header__wrapper--nav.desktop #buy_now_mobile_highlighted {
    color: var(--iq-white);
}

.header__wrapper--nav.desktop .header__login-menu--button {
    display: block;
    padding: 0;
    border: none;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop .header__login-menu--button {
        padding: 15px 20px;
        border-bottom: 1px solid var(--iq-dullgrey);
    }
}

.header__wrapper--nav.desktop .header__login-menu--button a {
    display: block;
    margin: 0;
    width: 100%;
    max-width: 100%;
    text-align: left;
}

.header__wrapper--nav.desktop .header__login-menu--button.primary {
    background-color: var(--iq-pink);
    border-color: var(--iq-dullgrey);
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop .header__nav {
        display: block;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--iq-white);
        width: 249px;
        height: 100%;
        box-shadow: 0 0 55px rgba(0, 0, 0, 0.15);
        z-index: 10;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translate(calc(100% + 10px));
        transform: translate(calc(100% + 10px));
        -webkit-transition: -webkit-transform 0.5s
            cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }
}

.header__wrapper--nav.desktop .header__nav a {
    text-align: left;
    color: var(--iq-brown);
}

.header__wrapper--nav.desktop .header__nav a:hover,
.header__nav--right button:hover {
    color: var(--iq-orange) !important;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop .header__nav.active {
        -webkit-transform: none;
        transform: none;
    }
}

.header__wrapper--nav.desktop .header__nav--dropdown li a:hover {
    color: var(--iq-white) !important;
}
@media (max-width: 991px) {
    .header__wrapper--nav.desktop .header__nav--menu {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
        padding-left: 0;
        list-style-type: none;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
    }
}

.header__wrapper--nav.desktop .header__nav--menu li {
    position: relative;
}

.header__wrapper--nav.desktop .header__nav--menu li a {
    text-transform: uppercase;
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop .header__nav--menu li:hover ul {
        position: absolute;
        min-width: 234px;
        max-width: 100%;
        background-color: var(--iq-white);
        border-radius: 0;
        display: block;
        border: 1px solid #857c7c;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop .header__nav--menu li:hover ul ul {
        padding: 0;
    }
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop .header__nav--menu li:hover i {
        -webkit-transform: none;
        transform: none;
    }
}
.header__wrapper--nav.desktop .header__nav--currency {
    position: relative;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop .header__nav--currency {
        width: 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop .header__nav--currency:hover .currency--list {
        display: block;
    }

    .header__wrapper--nav.desktop .header__nav--currency:hover .currency i {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.header__wrapper--nav.desktop .header__nav--wrapper {
    width: 100%;
    max-width: 100%;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop .header__nav--wrapper {
        overflow-y: scroll;
        position: fixed;
        top: 90px;
        bottom: 0;
        overflow: auto;
        width: 249px;
        max-width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: unset;
        justify-content: unset;
    }
}

.header__wrapper--nav.desktop nav {
    width: 100%;
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav {
        position: relative;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        overflow-y: scroll;
    }
}

.header__wrapper--nav.desktop nav > ul {
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav > ul {
        position: relative;
        width: 100%;
        max-width: 100%;
        overflow-y: scroll;
    }
}

.header__wrapper--nav.desktop nav ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    list-style-type: none;
    padding-left: 0;
}

html[lang="ar"] .header__wrapper--nav.desktop nav ul {
    padding-right: 0;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
        padding-left: 0;
        list-style-type: none;
        -webkit-box-pack: start;
        justify-content: flex-start;
    }

    html[lang="ar"] .header__wrapper--nav.desktop nav ul {
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul .hide-on-desktop {
        display: none;
    }
}

@media (max-width: 992px) {
    .header__wrapper--nav.desktop nav ul .hide-on-mobile {
        display: none;
    }
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li {
        width: 100%;
        max-width: 100%;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.header__wrapper--nav.desktop nav ul li a {
    color: var(--iq-darkgrey);
}

.header__wrapper--nav.desktop nav ul li .accordion-top {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li .accordion-top {
        padding: 15px 20px;
        border-bottom: 1px solid var(--iq-dullgrey);
    }
}

.header__wrapper--nav.desktop nav ul li .accordion-top i {
    margin-left: 10px;
    color: var(--iq-pink);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 5px;
}

.page-rtl .header__wrapper--nav.desktop nav ul li .accordion-top i {
    margin-right: 10px;
    margin-left: 0;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li .accordion-top i {
        margin: 0;
    }
}

.header__wrapper--nav.desktop nav ul li .accordion-top.active {
    border-bottom: 1px solid var(--iq-black);
}

.header__wrapper--nav.desktop nav ul li .accordion-top.active i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li .accordion-top.accordion-logged {
        background-color: rgba(21, 168, 224, 0.12);
        border-color: var(--iq-white);
    }
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li .accordion-top.accordion-logged a {
        color: var(--iq-pink);
    }
}

.header__wrapper--nav.desktop nav ul li .accordion-top.currency {
    width: 91px;
    padding: 6px 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--iq-darkblack);
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li .accordion-top.currency {
        padding: 10px 20px;
        background: hsla(0, 0%, 78.4%, 0.25);
        width: 100%;
        max-width: 100%;
        border-radius: 0;
    }
}

.header__wrapper--nav.desktop nav ul li .accordion-top.currency a {
    color: var(--iq-black);
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: flex;
}

.header__wrapper--nav.desktop nav ul li .accordion-top.currency a i {
    margin: 0;
}

.header__wrapper--nav.desktop nav ul li.active ul {
    display: block;
}

.header__wrapper--nav.desktop nav ul li ul {
    display: none;
}

.header__wrapper--nav.desktop nav ul li ul li {
    border-bottom: 1px solid var(--iq-dullgrey);
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li ul li {
        padding: 15px 30px;
    }
}

.header__wrapper--nav.desktop nav ul li ul.accordion-content li {
    margin: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.accordion-content li {
        border-bottom: 1px solid var(--iq-orange);
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.accordion-content li:last-child {
        border-bottom: none;
    }
}

.header__wrapper--nav.desktop nav ul li ul.accordion-content li a {
    display: block;
    width: 100%;
    max-width: 100%;
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.accordion-content li a {
        padding: 15px 15px 10px;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop
        nav
        ul
        li
        ul.accordion-content
        li:not(.accordion-nested):hover
        a {
        color: var(--iq-white);
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop
        nav
        ul
        li
        ul.accordion-content
        li:last-child:hover {
        border-radius: 0 0 5px 5px;
    }
}

.header__wrapper--nav.desktop
    nav
    ul
    li
    ul.accordion-content
    .accordion-dropdown-second {
    overflow: hidden;
    display: none;
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop
        nav
        ul
        li
        ul.accordion-content
        .accordion-dropdown-second {
        position: absolute;
        right: -234px;
        top: 0;
        bottom: unset;
        padding-top: 0;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop
        nav
        ul
        li
        ul.accordion-content
        .accordion-dropdown-second
        li {
        border-top: none;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.countries--list {
        -webkit-padding-start: 0;
        padding-inline-start: 0;
        list-style-type: none;
        display: none;
        position: absolute;
        top: 46px;
        border-radius: 0 0 5px 5px;
        margin-bottom: 0;
        border: 1px solid var(--iq-orange);
        margin-top: 25px;
    }
}

.header__wrapper--nav.desktop nav ul li ul.countries--list li {
    border-bottom: none;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li ul.countries--list li {
        border-bottom: 1px solid var(--iq-white);
        padding: 10px 20px;
    }
}

.header__wrapper--nav.desktop nav ul li ul.countries--list li:first-of-type {
    border-top: none;
}

.header__wrapper--nav.desktop nav ul li ul.countries--list li img {
    width: 32px;
    max-width: 100%;
    margin-right: 14px;
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.countries--list li:last-child a {
        border-radius: 0 0 5px 5px;
    }
}

.header__wrapper--nav.desktop nav ul li ul.countries--list li a {
    color: var(--iq-white);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li ul.countries--list li a {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.countries--list li a {
        width: 155px;
        padding: 8px 10px 4px;
        background-color: var(--iq-white) !important;
        border-radius: 5px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        border-radius: unset;
    }
}

.header__wrapper--nav.desktop nav ul li ul.currency--list {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    list-style-type: none;
    display: none;
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.currency--list {
        position: absolute;
        top: 30px;
        max-height: 200px;
        overflow: hidden;
        overflow-y: scroll;
        background-color: #f1f1f1;
        width: 91px;
        border-radius: 0 0 5px 5px;
        margin-bottom: 0;
    }

    .header__wrapper--nav.desktop
        nav
        ul
        li
        ul.currency--list::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    .header__wrapper--nav.desktop
        nav
        ul
        li
        ul.currency--list::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px hsla(0, 0%, 100%, 0.5);
    }
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li ul.currency--list li {
        padding: 10px 20px;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.currency--list li {
        border-bottom: none;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.currency--list li:first-of-type {
        border-top: none;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.currency--list li:hover {
        background-color: #f1f1f1 !important;
    }

    .header__wrapper--nav.desktop nav ul li ul.currency--list li:hover a {
        color: var(--iq-black) !important;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop nav ul li ul.currency--list li a {
        width: 91px;
        padding: 8px 10px 4px;
        background-color: #f1f1f1;
        border-radius: 5px;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        color: var(--iq-darkblack);
    }
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop nav ul li ul.currency--list li a {
        padding: 0;
    }
}

.header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-top {
    padding: 15px 15px 10px;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop
        .accordion-nested
        .accordion-content
        .accordion-top {
        padding: 15px 20px 15px 35px;
    }
}

.header__wrapper--nav.desktop .accordion-nested .accordion-content ul li {
    padding: 0;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop .accordion-nested .accordion-content ul li {
        padding: 15px 20px 15px 60px;
    }
}

.header__wrapper--nav.desktop .accordion-nested .accordion-content ul li a {
    padding: 15px 15px 10px;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop
        .accordion-nested
        .accordion-content
        .accordion-nested,
    .header__wrapper--nav.desktop .accordion-nested .accordion-content ul li a {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop
        .accordion-nested
        .accordion-content
        .accordion-nested:hover
        .accordion-top
        a,
    .header__wrapper--nav.desktop
        .accordion-nested
        .accordion-content
        .accordion-nested:hover
        .accordion-top
        a
        i {
        color: var(--iq-black);
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop
        .accordion-nested
        .accordion-content
        .accordion-nested
        .accordion-top {
        padding: 0 10px 0 0;
    }
}

.header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-nested
    .accordion-top
    a {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .header__wrapper--nav.desktop
        .accordion-nested
        .accordion-content
        .accordion-nested
        .accordion-top
        a {
        width: unset;
    }
}

@media (min-width: 992px) {
    .header__wrapper--nav.desktop
        .accordion-nested
        .accordion-content
        .accordion-nested
        .accordion-top
        i {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}

.header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-nested:hover
    .accordion-dropdown-second {
    display: block;
}

@media (max-width: 991px) {
    .page-rtl .header__wrapper--nav.desktop .header__nav {
        -webkit-transform: translate(-400%);
        transform: translate(-400%);
        right: unset;
    }
}

@media (max-width: 991px) {
    .page-rtl .header__wrapper--nav.desktop .header__nav.active {
        left: 0;
        right: unset;
        -webkit-transform: none;
        transform: none;
    }
}

.page-rtl .header__wrapper--nav.desktop .header__nav a,
.page-rtl .header__wrapper--nav.desktop .header__nav p {
    text-align: right;
}

.page-rtl .header__wrapper--nav.desktop .header__nav--menu .accordion-top {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 991px) {
    .page-rtl
        .header__wrapper--nav.desktop
        .header__nav--menu
        li:not(:last-child) {
        margin-left: 0;
    }
}

.page-rtl .header__wrapper--nav.desktop .header__nav--menu li i {
    margin: 0 10px 0 0;
}

@media (min-width: 992px) {
    .page-rtl .header__wrapper--nav.desktop .header__nav--menu li:hover ul {
        right: 0;
    }
}

@media (min-width: 992px) {
    .page-rtl .header__wrapper--nav.desktop nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
    }
}

.page-rtl
    .header__wrapper--nav.desktop
    nav
    ul
    li
    ul.accordion-content
    .accordion-dropdown-second {
    right: unset;
    left: -234px;
}

@media (min-width: 992px) {
    .page-rtl
        .header__wrapper--nav.desktop
        .accordion-nested
        .accordion-content
        .accordion-nested
        .accordion-top
        i {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        padding: 0 0 0 10px;
    }
}

@media (max-width: 479px) {
    .page--play-loto .countdown {
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .page--play-loto .countdown__inner {
        display: none;
    }
}

@media (max-width: 1199px) {
    .page--play-loto .countdown__inner {
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

@media (max-width: 1199px) {
    .page--play-loto .countdown__timer.desktop {
        margin: 0;
    }
}

@media (max-width: 991px) {
    .page--play-loto .countdown__timer.mobile {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 479px) {
    .page--play-loto .countdown__timer.mobile {
        margin-bottom: 25px;
    }
}

.page--play-loto .countdown__timer.mobile .countdown__timer--img img {
    width: 100%;
    max-width: 100%;
    height: 180px;
    border-radius: 5px;
}

@media (max-width: 575px) {
    .page--play-loto .countdown__timer.mobile .countdown__timer--img img {
        height: 125px;
    }
}

@media (max-width: 400px) {
    .page--play-loto .countdown__timer.mobile .countdown__timer--img img {
        height: 100px;
    }
}

.page-rtl .base-timer__svg {
    -webkit-transform: unset;
    transform: unset;
}

.page-rtl .svg-wrapp {
    -webkit-transform: rotate(-270deg);
    transform: rotate(-270deg);
}

.page-rtl .header__inner,
.page-rtl .header__inner--right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .header__inner--logo {
    background-image: url(../img/mahzooz-logo-new-ar.png);
    background-position: right;
}

.page-rtl .header__dropdown.primary,
.page-rtl .header__dropdown.secondary {
    left: 0 !important;
}

.page-rtl .header__dropdown.primary ul img {
    right: unset;
    left: 25px;
}

.page-rtl .header__dropdown.primary ul li {
    margin: 0 !important;
}

.page-rtl .header__balance--item {
    text-align: right;
}

.page-rtl .header__nav,
.page-rtl .header__nav--menu {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .header__nav--menu li:not(:last-child) {
    margin-right: 0;
    margin-left: 20px;
}

.page-rtl .header__nav--menu li a {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;

    -webkit-box-align: center;
    align-items: center;
}

.page-rtl .header__nav--menu li a i {
    margin: 0 10px 0 0;
}

.page-rtl .header__nav--dropdown {
    right: 0;
}

.page-rtl .header__nav--dropdown ul li,
.page-rtl .header__nav--dropdown ul li:not(:last-child) {
    margin-left: 0;
}

.page-rtl .header__nav--dropdown ul li a i {
    -webkit-transform: rotate(180deg) !important;
    transform: rotate(180deg) !important;
}

.page-rtl .header__nav--dropdown.secondary {
    left: -234px;
}

.page-rtl .header__login-menu {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .header__login-menu a:not(:last-child) {
    margin-left: 20px;
    margin-right: 0;
}

.page-rtl .header__login-menu--welcome:after {
    right: unset;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(var(--iq-white)),
        to(hsla(0, 0%, 100%, 0))
    );
    background: linear-gradient(90deg, var(--iq-white), hsla(0, 0%, 100%, 0));
    left: 0;
}

.page-rtl .header__login-menu.logged > ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .header__login-menu.logged > ul li:not(:last-child) {
    margin-right: 0;
    margin-left: 28px;
}

.page-rtl .header__login-menu.logged > ul li a {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;

    -webkit-box-align: center;
    align-items: center;
}

.page-rtl .header__login-menu.logged > ul li a i,
.page-rtl .header__login-menu.logged > ul li a strong {
    margin-left: 0;
    margin-right: 5px;
}

.page-rtl .header__login-menu.logged ul li a {
    margin-left: 0;
}

.page-rtl .header__login-menu.logged ul li ul li a {
    text-align: right;
}

.page-rtl .header__login-menu--mobile {
    -webkit-transform: translate(-400%);
    transform: translate(-400%);
    right: unset;
}

.page-rtl .header__login-menu--mobile.active {
    right: unset;
    left: 0;
    -webkit-transform: none;
    transform: none;
}

.page-rtl .header__login-menu--mobile .nav-wrapper nav ul li .accordion-top,
.page-rtl
    .header__login-menu--mobile
    .nav-wrapper
    nav
    ul
    li
    .accordion-top.countries
    a {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl
    .header__login-menu--mobile
    .nav-wrapper
    nav
    ul
    li
    .accordion-top.countries
    a
    img {
    margin-right: 0;
    margin-left: 10px;
}

.page-rtl .header__login-menu--mobile .nav-wrapper nav ul li a {
    margin-left: 0;
    text-align: right;
    margin-right: 0;
}

.page-rtl .header__login-menu--mobile .nav-wrapper nav ul li p {
    text-align: right;
}

.page-rtl .header__login-menu--mobile .nav-wrapper nav ul ul li a {
    margin-left: 0;
    text-align: right;
}

.page-rtl
    .header__login-menu--mobile
    .nav-wrapper
    nav
    ul
    ul.countries--list
    li
    a {
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl
    .header__login-menu--mobile
    .nav-wrapper
    nav
    ul
    ul.countries--list
    li
    img {
    margin-right: 0;
    margin-left: 10px;
}

.page-rtl .header__cart--content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .header__toggle {
    margin-left: 0;
    margin-right: 22px;
}

.page-rtl .countdown {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .countdown__inner {
    background-image: url(../img/counter-bg-newest-ar.jpg);
}

@media (max-width: 575px) {
    .page-rtl .countdown__inner {
        background-image: url(../img/counter-bg-newest-mobile-ar.jpg);
    }
}

.page-rtl .countdown__inner > div:first-child {
    -webkit-box-ordinal-group: 4;
    order: 3;
}

.page-rtl .countdown__inner > div:nth-child(2) {
    -webkit-box-ordinal-group: 3;
    order: 2;
}

.page-rtl .countdown__inner > div:nth-child(3) {
    -webkit-box-ordinal-group: 2;
    order: 1;
}

.page-rtl .countdown__text {
    text-align: right;
}

.page-rtl .countdown__text--info > p {
    margin-bottom: 5px;
}

@media (max-width: 575px) {
    .page-rtl .countdown__text--info {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

@media (max-width: 414px) {
    .page-rtl .countdown__text--info p.first {
        line-height: 11px;
    }
}

@media (max-width: 575px) {
    .page-rtl .countdown__text--info p {
        text-align: right;
    }
}

@media (max-width: 479px) {
    .page-rtl .countdown__text--info p strong {
        font-size: 17px;
    }
}

.page-rtl .countdown__text--date {
    margin-right: 0;
    margin-left: -34px;
    width: unset;
}

@media (max-width: 1199px) {
    .page-rtl .countdown__text--date {
        margin-left: 0;
    }
}

.page-rtl .countdown__text--date p {
    max-width: 100%;
    width: 100%;
    display: block;
}

@media (max-width: 1200px) {
    .page-rtl .countdown__text--date p {
        margin-right: 25px;
    }
}

@media (max-width: 575px) {
    .page-rtl .countdown__text--date p {
        margin-right: 2px;
        font-size: 17px;
        display: -webkit-box;
        display: flex;
    }
}

@media (max-width: 479px) {
    .page-rtl .countdown__text--date p {
        font-size: 17px;
    }
}

@media (max-width: 420px) {
    .page-rtl .countdown__text--date p {
        font-size: 17px;
    }
}

@media (max-width: 380px) {
    .page-rtl .countdown__text--date p {
        font-size: 13px;
    }
}

.page-rtl .countdown__text--text {
    margin-right: 0;
    margin-left: 0;
    padding: 7px 10px;
}

@media (max-width: 575px) {
    .page-rtl .countdown__text--text {
        margin: 0;
    }
}

@media (min-width: 1200px) {
    .page-rtl .countdown__text--sign {
        top: 16px;
    }
}

@media (max-width: 380px) {
    .page-rtl .countdown__text--sign {
        top: 12px;
    }
}

.page-rtl .countdown__text--amount {
    -webkit-box-pack: end;
    justify-content: flex-end;
    direction: ltr;
}

.page-rtl .countdown__text--amount p {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 1200px) {
    .page-rtl .countdown__text--amount p {
        line-height: 38px;
    }
}

@media (max-width: 479px) {
    .page-rtl .countdown__text--amount p {
        font-size: 17px;
        line-height: 40px;
    }
}

.page-rtl .countdown--smalltext {
    left: unset;
}

@media (max-width: 479px) {
    .page-rtl .countdown--smalltext {
        width: 255px;
        left: unset;
        right: 0;
        text-align: right;
    }
}

.page-rtl .countdown--smalltext.mobile {
    -webkit-transform: none;
    transform: none;
}

.page-rtl .countdown__timer {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.page-rtl .countdown__timer.desktop .countdown__timer--wrapper {
    padding: 0 25px;
}

.page-rtl .countdown__timer--valuewrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .countdown__timer--boxes-row p,
.page-rtl .countdown__timer--boxes p,
.page-rtl .countdown__timer--value {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.page-rtl .countdown__timer--boxes-row p {
    font-size: 14px;
}

@media (max-width: 479px) {
    .page-rtl .countdown__timer--boxes-row p {
        font-size: 12px;
    }
}

@media (max-width: 350px) {
    .page-rtl .countdown__timer--boxes-row p {
        font-size: 10px;
    }
}

.page-rtl .countdown__timer--box:not(:last-child) {
    margin-right: 0;
    margin-left: 3px;
}

.page-rtl .countdown__button {
    left: unset;
    right: 0;
}

.page-rtl .countdown-noraffle .countdown__text--info:first-child {
    margin-bottom: 0;
}

@media (max-width: 400px) {
    .page-rtl .countdown-noraffle .countdown__text--info:first-child {
        margin-bottom: 19px;
    }
}

@media (max-width: 575px) {
    .page-rtl .countdown-noraffle .countdown__text--text {
        left: 35%;
        top: -35px;
    }
}

@media (max-width: 415px) {
    .page-rtl .countdown-noraffle .countdown__text--text {
        top: -35px;
    }
}

@media (max-width: 380px) {
    .page-rtl .countdown-noraffle .countdown__text--text {
        top: -35px;
    }
}

@media (max-width: 350px) {
    .page-rtl .countdown-noraffle .countdown__text--text {
        top: -20px;
    }
}

.page-rtl .hp-navigation__info .row [class*="col-"]:nth-child(2),
.page-rtl .hp-navigation__info .row [class*="col-"]:nth-child(4) {
    padding-left: 15px;
}

.page-rtl .hp-navigation__info--box p {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .hp-navigation__box {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.page-rtl .hp-navigation__box.box-results .hp-navigation__box--content h2 {
    margin-bottom: 16px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box.box-results .hp-navigation__box--content h2 {
        font-size: 38px !important;
    }
}

@media (max-width: 412px) {
    .page-rtl .hp-navigation__box.box-results .hp-navigation__box--content h2 {
        font-size: 32px !important;
    }
}

.page-rtl .hp-navigation__box--content {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    text-align: right;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: end;
    align-items: flex-end;
}

.page-rtl .hp-navigation__box.first {
    -webkit-transform: unset;
    transform: unset;
    background-image: url(../img/box1-newest-ar.jpg);
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box.first {
        background-image: url(../img/box1-newest-mobile-ar.jpg);
    }
}

.page-rtl .hp-navigation__box.first .hp-navigation__box--content {
    -webkit-transform: unset;
    transform: unset;
    left: unset;
}

.page-rtl .hp-navigation__box.second {
    -webkit-transform: unset;
    transform: unset;
    background-image: url(../img/box2-newest-ar.jpg);
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box.second {
        background-image: url(../img/box2-newest-mobile-ar.jpg);
    }
}

.page-rtl .hp-navigation__box.second .hp-navigation__box--content {
    -webkit-transform: unset;
    transform: unset;
    left: unset;
}

@media (max-width: 390px) {
    .page-rtl .hp-navigation__box.second .hp-navigation__box--textbg h2 {
        max-width: 180px;
    }
}

.page-rtl .hp-navigation__box.third {
    -webkit-transform: unset;
    transform: unset;
    background-image: url(../img/box3-newest-ar.jpg);
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box.third {
        background-image: url(../img/box3-newest-mobile-ar.jpg);
    }
}

.page-rtl .hp-navigation__box.third .hp-navigation__box--content {
    -webkit-transform: unset;
    transform: unset;
    left: unset;
}

.page-rtl .hp-navigation__box.third .hp-navigation__box--textbg h2 {
    max-width: 220px;
}

.page-rtl .hp-navigation__box.fourth {
    border: 1px solid #0077c8;
}

@media (min-width: 1200px) {
    .page-rtl .hp-navigation__box.fourth {
        padding: 26px 40px;
    }
}

.page-rtl .hp-navigation__box.fourth h2 {
    white-space: nowrap;
}

@media (max-width: 412px) {
    .page-rtl .hp-navigation__box.fourth h2 {
        max-width: 100%;
    }
}

.page-rtl .hp-navigation__box.fourth h3 {
    margin-bottom: 103px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box.fourth h3 {
        font-size: 26px;
        margin-bottom: 30px;
    }
}

@media (max-width: 412px) {
    .page-rtl .hp-navigation__box.fourth h3 {
        font-size: 19px;
    }
}

@media (max-width: 341px) {
    .page-rtl .hp-navigation__box.fourth .hp-navigation__draws--number {
        width: 38px;
    }
}

.page-rtl .hp-navigation__box.fourth .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.page-rtl .hp-navigation__box.fourth .hp-navigation__box--btn {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.page-rtl .hp-navigation__box.fourth .hp-navigation__box--btn a {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.page-rtl .hp-navigation__box.fourth .hp-navigation__info--box {
    -webkit-transform: none;
    transform: none;
}

.page-rtl .hp-navigation__box.fifth,
.page-rtl .hp-navigation__box.sixth {
    background-image: none;
}

.page-rtl .hp-navigation__box.sixth .hp-navigation__box--content {
    top: 15px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box.sixth .hp-navigation__box--content {
        top: 21px;
    }
}

.page-rtl .hp-navigation__box.sixth h2 {
    max-width: 200px;
    font-size: 32px;
    margin-bottom: 10px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box.sixth h2 {
        font-size: 25px;
        margin-bottom: 18px;
    }
}

@media (min-width: 1200px) {
    .page-rtl .hp-navigation__box.seventh {
        padding: 26px 40px;
    }
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box.seventh {
        background-image: none;
    }
}

.page-rtl .hp-navigation__box.seventh h3 {
    margin-bottom: 0;
}

.page-rtl .hp-navigation__box.seventh .hp-navigation__box--content {
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 1199px) {
    .page-rtl .hp-navigation__box.seventh .hp-navigation__box--content {
        right: 90px;
    }
}

@media (max-width: 991px) {
    .page-rtl .hp-navigation__box.seventh .hp-navigation__box--content {
        right: unset;
    }
}

.page-rtl .hp-navigation__box.seventh .hp-navigation__box--content h2,
.page-rtl .hp-navigation__box.seventh .hp-navigation__box--content h3 {
    width: 100%;
    max-width: 100%;
}

.page-rtl .hp-navigation__box--btn {
    width: 100%;
    display: -webkit-box;
    display: flex;
}

@media (max-width: 340px) {
    .page-rtl .hp-navigation__box--btn {
        width: unset;
    }
}

.page-rtl .hp-navigation__box__inner--top .hp-navigation__box--textbg {
    width: 205px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box__inner--top .hp-navigation__box--textbg {
        width: 203px;
    }
}

@media (max-width: 375px) {
    .page-rtl .hp-navigation__box__inner--top .hp-navigation__box--textbg {
        width: 155px;
    }
}

.page-rtl .hp-navigation__box__inner--top .hp-navigation__box--textbg h2 {
    font-size: 32px;
    max-width: 220px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box__inner--top .hp-navigation__box--textbg h2 {
        font-size: 30px;
    }
}

@media (max-width: 375px) {
    .page-rtl .hp-navigation__box__inner--top .hp-navigation__box--textbg h2 {
        font-size: 23px;
    }
}

.page-rtl .hp-navigation__box__inner--top .hp-navigation__box--content {
    top: 22px;
    right: 25px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box__inner--top .hp-navigation__box--content {
        top: 16px;
    }
}

@media (max-width: 375px) {
    .page-rtl .hp-navigation__box__inner--top .hp-navigation__box--content {
        right: 20px;
    }
}

.page-rtl .hp-navigation__box__inner--mid div.row,
.page-rtl .hp-navigation__box__inner--top div.row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .hp-navigation__box.old .hp-navigation__box--btn {
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.page-rtl .hp-navigation__box.old .hp-navigation__box--btn a {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.page-rtl .hp-navigation__box.old.old-six {
    padding: 76px 40px 32px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__box.old.old-six {
        padding: 76px 30px 32px;
    }
}

.page-rtl .hp-navigation__box.old.old-six .hp-navigation__box--content {
    top: -45px;
}

@media (min-width: 1200px) {
    .page-rtl .hp-navigation__box.old.old-six .hp-navigation__box--content h3 {
        margin-bottom: 0;
    }
}

.page-rtl .hp-navigation__box.old.old-six .hp-navigation__box--btn {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    bottom: -45px;
    left: 0;
}

@media (max-width: 340px) {
    .page-rtl .hp-navigation__box.old.old-six .hp-navigation__box--btn {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .page-rtl .hp-navigation__box.old.old-six .hp-navigation__draws--number {
        width: 30px;
        height: 30px;
        border-width: 5px;
    }
}

@media (max-width: 479px) {
    .page-rtl
        .hp-navigation__box.old.old-six
        .hp-navigation__draws--number
        span {
        padding: 6px 0;
    }
}

@media (max-width: 400px) {
    .page-rtl .hp-navigation__box.old.old-six .hp-navigation__draws span {
        font-size: 12px;
    }
}

.page-rtl .hp-navigation__box.old.old-six .hp-navigation__info .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .hp-navigation__box.old.old-six .hp-navigation__info--box p {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.page-rtl .hp-navigation__box.old2 .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .hp-navigation__box.old2 .hp-navigation__box--btn {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

@media (max-width: 340px) {
    .page-rtl .hp-navigation__box.old2 .hp-navigation__box--btn {
        width: 100%;
    }
}

@media (max-width: 341px) {
    .page-rtl .hp-navigation__box.old2 .hp-navigation__draws--number {
        width: 35px;
        height: 35px;
    }
}

.page-rtl .hp-navigation__box.old2 .hp-navigation__info--box {
    -webkit-transform: none;
    transform: none;
}

@media (min-width: 480px) {
    .page-rtl .hp-navigation__draws {
        margin: 29px 0;
    }
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__draws span {
        padding: 10px 0;
    }
}

.page-rtl .hp-navigation__draws > div:not(:last-child) {
    margin-right: 0;
    margin-left: 5px;
}

@media (max-width: 435px) {
    .page-rtl .hp-navigation__draws > div:nth-child(3) {
        margin-left: 0;
    }
}

.page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg {
    width: 205px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg {
        width: 203px;
    }
}

@media (max-width: 375px) {
    .page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg {
        width: 155px;
    }
}

.page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg h2 {
    font-size: 32px;
    max-width: 220px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg h2 {
        font-size: 30px;
    }
}

@media (max-width: 375px) {
    .page-rtl .hp-navigation__inner--top .hp-navigation__box--textbg h2 {
        font-size: 23px;
    }
}

.page-rtl .hp-navigation__inner--top .hp-navigation__box--content {
    top: 22px;
    right: 25px;
}

@media (max-width: 479px) {
    .page-rtl .hp-navigation__inner--top .hp-navigation__box--content {
        top: 16px;
    }
}

@media (max-width: 375px) {
    .page-rtl .hp-navigation__inner--top .hp-navigation__box--content {
        right: 20px;
    }
}

.page-rtl .hp-navigation__inner--mid div.row,
.page-rtl .hp-navigation__inner--top div.row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .download__bg {
    background-image: url(../img/download-bg-new-ar.jpg);
}

@media (max-width: 1199px) {
    .page-rtl .download__bg {
        background-position-x: -235px;
    }
}

@media (max-width: 479px) {
    .page-rtl .download__bg {
        background-position: 50%;
        background-image: url(../img/download-bg-mobile-new-raffle-ar.jpg);
    }
}

.page-rtl .download__content {
    text-align: right;
    left: unset;
    right: 34px;
}

@media (max-width: 479px) {
    .page-rtl .download__content {
        width: 100%;
        max-width: 100%;
        padding: 0 20px;
        right: 0;
        left: unset;
    }
}

@media (max-width: 479px) {
    .page-rtl .download__content--text {
        padding-right: 0;
    }
}

@media (max-width: 375px) {
    .page-rtl .download__content--text h2 {
        font-size: 26px;
    }
}

.page-rtl .download__platforms {
    -webkit-box-pack: start;
    justify-content: flex-start;
}

@media (max-width: 479px) {
    .page-rtl .download__platforms {
        padding-left: 20px;
        padding-right: 0;
    }
}

@media (max-width: 375px) {
    .page-rtl .download__platforms {
        bottom: 50px;
    }
}

.page-rtl .download__platforms p {
    margin-right: 0;
    margin-left: 20px;
}

@media (max-width: 479px) {
    .page-rtl .download__platforms p {
        margin: 0 0 5px;
        padding-right: 0;
    }
}

.page-rtl .download__buttons a div {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;

    -webkit-box-pack: end;
    justify-content: flex-end;
    width: unset;
}

@media (max-width: 479px) {
    .page-rtl .download__buttons a div {
        width: 100%;
        -webkit-box-pack: justify;
        justify-content: space-between;
        text-align: right;
    }
}

@media (max-width: 479px) {
    .page-rtl .download__buttons a div img {
        margin-left: 0;
    }
}

.page-rtl .download__buttons a:first-child {
    margin-right: 0;
    margin-left: 30px;
}

@media (max-width: 479px) {
    .page-rtl .download__buttons a:first-child {
        margin: 0 0 0 15px;
    }
}

@media (max-width: 375px) {
    .page-rtl .download__buttons a:first-child {
        margin: 0 0 5px;
    }
}

@media (max-width: 479px) {
    .page-rtl .download__buttons {
        padding-right: 0;
        padding-left: 20px;
    }
}

.page-rtl .cookie-bar {
    text-align: right;
}

.page-rtl .cookie-bar__buttons a:first-child {
    margin-right: 0;
}

.page-rtl .footer__nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
    .page-rtl .footer__nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.page-rtl .footer__nav--text.desktop {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
    .page-rtl .footer__nav--text.desktop {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.page-rtl .footer__nav--text.desktop ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .footer__nav--text.desktop ul li:not(:last-child) {
    margin-right: 0;
    margin-left: 25px;
}

.page-rtl .footer__nav--text.desktop ul li a {
    font-size: 13px;
}

.page-rtl .footer__nav--img {
    background-image: url(../img/mahzooz-logo-white-ar.png);
    width: 148px;
    padding-bottom: 30px;
    margin-right: 0;
    margin-left: 60px;
}

@media (max-width: 575px) {
    .page-rtl .footer__nav--img {
        margin: 0;
    }
}

.page-rtl .footer__socials {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
    .page-rtl .footer__socials {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
    }
}

.page-rtl .footer__socials p {
    margin-right: 0;
    margin-left: 20px;
    font-size: 13px;
}

@media (max-width: 575px) {
    .page-rtl .footer__socials p {
        margin-left: 0;
    }
}

.page-rtl .footer__socials--item {
    margin-right: 0;
}
.page-rtl .footer__socials--platforms p:not(:last-child) {
    margin-right: 0;
    margin-left: 15px;
}

@media (max-width: 575px) {
    .page-rtl .shopping-cart__box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.page-rtl .shopping-cart__box--withdraw-success .shopping-cart__box--right {
    text-align: right;
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 575px) {
    .page-rtl .shopping-cart__box--withdraw-success .shopping-cart__box--right {
        text-align: center;
        -webkit-box-align: center;
        align-items: center;
    }
}

.page-rtl .shopping-cart__box--buttons a:first-child {
    margin-left: 30px;
}

@media (max-width: 575px) {
    .page-rtl .shopping-cart__box--buttons a:first-child {
        margin-bottom: 15px;
        margin-left: 0;
    }
}

.page-rtl .shopping-cart__content .cart__selectboxes--select:not(:last-child) {
    margin-right: 0;
    margin-left: 5px;
}

@media (max-width: 575px) {
    .page-rtl
        .shopping-cart__content
        .cart__selectboxes--select:not(:last-child) {
        margin-left: 0;
    }
}

.page-rtl .shopping-cart__content .cart__numbers--number-row {
    padding-right: 0;
}

.page-rtl
    .shopping-cart__content
    .cart__summary--voucher
    input::-webkit-input-placeholder {
    font-size: 13px;
    text-align: right;
}

.page-rtl
    .shopping-cart__content
    .cart__summary--voucher
    input::-moz-placeholder {
    font-size: 13px;
    text-align: right;
}

.page-rtl
    .shopping-cart__content
    .cart__summary--voucher
    input:-ms-input-placeholder {
    font-size: 13px;
    text-align: right;
}

.page-rtl
    .shopping-cart__content
    .cart__summary--voucher
    input::-ms-input-placeholder {
    font-size: 13px;
    text-align: right;
}

.page-rtl .shopping-cart__content .cart__summary--voucher input::placeholder {
    font-size: 13px;
    text-align: right;
}

.page-rtl .shopping-cart__content .cart__summary--delete {
    right: unset;
    left: 10px;
}

.page-rtl .previous-drawn__details,
.page-rtl .previous-drawn__top {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .previous-drawn__text {
    text-align: right;
    margin-left: 0;
}

@media (max-width: 575px) {
    .page-rtl .previous-drawn__text {
        margin-right: 0;
    }
}

.page-rtl .previous-drawn__text h1 {
    margin-bottom: 10px;
}

.page-rtl .previous-drawn__text h2 {
    margin-bottom: 7px;
}

.page-rtl .previous-drawn__text p strong {
    margin-left: 5px;
}

.page-rtl .previous-drawn__button a i {
    right: unset;
    left: 20px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.page-rtl .previous-drawn__buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
    .page-rtl .previous-drawn__buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
    }
}

.page-rtl .previous-drawn__select p {
    text-align: right;
}

@media (max-width: 575px) {
    .page-rtl .previous-drawn .table-main table thead tr th:first-child {
        padding: 15px 15px 10px 0;
    }
}

@media (max-width: 575px) {
    .page-rtl .previous-drawn .table-main table thead tr th:nth-child(2) {
        padding: 15px 30px 10px 0;
    }
}

@media (max-width: 575px) {
    .page-rtl .previous-drawn .table-main table thead tr th:last-child {
        padding: 15px 15px 10px 0;
    }
}

@media (max-width: 575px) {
    .page-rtl .previous-drawn .table-main table thead tr th:not(:last-child) {
        border-left: 0;
    }
}

@media (max-width: 575px) {
    .page-rtl .previous-drawn .table-main table tbody tr td:nth-child(2) {
        padding: 15px 30px 12px 15px;
    }
}

.page-rtl .myaccount__wrapper .myaccount--col:first-child {
    padding-right: 15px;
    padding-left: 0;
}

.page-rtl .myaccount__wrapper .myaccount--col:last-child {
    padding-left: 15px;
    padding-right: 0;
    border-radius: 4px 0 0 4px;
}

@media (max-width: 575px) {
    .page-rtl .myaccount__wrapper .myaccount--col:last-child {
        padding-right: 15px;
        border-radius: 4px;
    }
}

.page-rtl .myaccount__content {
    border-radius: 4px 0 0 4px;
}

@media (max-width: 575px) {
    .page-rtl .myaccount__content {
        border-radius: 4px;
    }
}

.page-rtl .myaccount__sidebar {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid var(--iq-dullgrey);
    border-left: none;
}

.page-rtl .myaccount__sidebar ul {
    padding-right: 0;
}

.page-rtl .myaccount__sidebar ul li:first-child a {
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
}

.page-rtl .myaccount__sidebar ul li a:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.page-rtl .myaccount__sidebar.mobile {
    border-right: none;
}

.page-rtl .my-profile__nav ul {
    padding-right: 0;
}

.page-rtl .ticket-history__details--img {
    margin-right: 0;
    margin-left: 18px;
}

.page-rtl .ticket-history__details--ticket p {
    margin-right: 0;
    margin-left: 5px;
}

.page-rtl .ticket-history__buttons a:first-child {
    margin-right: 0;
    margin-left: 10px;
}

@media (max-width: 575px) {
    .page-rtl .ticket-history__box,
    .page-rtl .ticket-history__box--left {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.page-rtl .ticket-history__box--left p {
    margin-left: 0;
    margin-right: 20px;
}

.page-rtl
    .favourite-numbers__content
    .form
    form
    .form-group__input-row
    input:not(:last-child) {
    margin: 0 0 0 13px;
}

.page-rtl .favourite-numbers__item {
    margin: 0 0 25px 13px;
    width: calc(25% - 10px);
}

.page-rtl .form .form-group label,
.page-rtl .form_favourite-numbers .btn.btn--primary {
    font-family: "Swissra-Bold";
}

.page-rtl .favourite-numbers__item:nth-child(4),
.page-rtl .favourite-numbers__item:nth-child(8) {
    margin-left: 0;
}

.page-rtl .favourite-numbers__content .form form .form-group__input-row input {
    text-align: center !important;
}

.page-rtl .transfer {
    text-align: right;
}

.page-rtl .transfer__button {
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.page-rtl .transfer__right--currency {
    margin-right: 15px;
    margin-left: 0;
}

.page-rtl .pagination ul {
    padding-right: 0;
}

.page-rtl .pagination ul li:not(:last-child) {
    margin-right: 0;
    margin-left: 7px;
}

.page-rtl .my-games__details--img {
    margin-right: 0;
    margin-left: 18px;
}

.page-rtl .my-games__details--ticket p {
    margin-right: 0;
    margin-left: 5px;
}

.page-rtl .payment__card--text {
    margin-right: 0;
    margin-left: 13px;
    direction: ltr;
}

.page-rtl .payment__card--checkbox input {
    margin-right: 0;
    margin-left: 40px;
}

@media (max-width: 575px) {
    .page-rtl .favourite-numbers__item {
        width: calc(50% - 5px);
        max-width: 45%;
    }

    .page-rtl .favourite-numbers__item:nth-child(odd) {
        margin: 0 0 20px auto;
    }

    .page-rtl .favourite-numbers__item:nth-child(even) {
        margin: 0 auto 20px 0;
    }

    .page-rtl .payment__card--checkbox input {
        margin-right: 0;
        margin-left: 10px;
    }

    .favourite-numbers__item--heading h5 {
        font-size: 14px;
    }
}

.page-rtl .payment__card--checkbox.empty {
    margin-left: 40px;
    margin-right: 0;
}

@media (max-width: 479px) {
    .page-rtl .payment__card--checkbox.empty {
        margin-left: 10px;
        margin-right: 0;
    }
}

.page-rtl .payment__card--checkbox .checkmark {
    left: unset;
    right: 7px;
}

.page-rtl .payment__button button {
    width: 150px;
}

.page-rtl .payment__wrapper--previous {
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.page-rtl .payment__wrapper--previous a {
    margin-left: 12px;
    margin-right: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.page-rtl .payment--box span {
    margin-left: 0;
    margin-right: 5px;
}

.page-rtl .verification {
    text-align: right;
}

.page-rtl .verification__banner h2 {
    left: unset;
    right: 70px;
}

@media (max-width: 575px) {
    .page-rtl .verification__banner h2 {
        right: 20px;
        top: 20px;
        max-width: 100px;
    }
}

.page-rtl .verification__banner--text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    left: unset;
    right: 50px;
}

@media (max-width: 575px) {
    .page-rtl .verification__banner--text {
        right: 20px;
        padding-left: 20px;
        padding-right: 0;
    }
}

@media (max-width: 479px) {
    .page-rtl .verification__banner--text {
        padding-left: 0;
    }
}

.page-rtl .verification__banner--bg-success {
    background-image: url(../img/verification-success-ar.jpg);
}

@media (max-width: 575px) {
    .page-rtl .verification__banner--bg-success {
        background-image: url(../img/verification-success-mobile-ar.jpg);
    }
}

.page-rtl .verification__banner--bg-rejected {
    background-image: url(../img/verification-rejected-ar.jpg);
}

@media (max-width: 575px) {
    .page-rtl .verification__banner--bg-rejected {
        background-image: url(../img/verification-rejected-mobile-ar.jpg);
    }
}

.page-rtl .verification__section {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.page-rtl .verification__photo--buttons a {
    margin-left: 0;
    margin-right: 10px;
}

.page-rtl .verification__box {
    margin-left: 0;
    margin-right: 15px;
}

.page-rtl .maintenance__text a i {
    right: unset;
    left: 20px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.page-rtl .shopping-cart {
    text-align: right;
}

.page-rtl .cart__top--title:last-child h3 {
    text-align: left;
}

.page-rtl .cart__details--img {
    margin-right: 0;
    margin-left: 18px;
}

.page-rtl .cart__details--ticket p {
    margin-right: 0;
    margin-left: 5px;
}

.page-rtl .cart__summary > div > span {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .cart__summary--img {
    left: unset;
    right: 0;
}

@media (max-width: 575px) {
    .page-rtl .cart__summary--topup a {
        text-align: left;
    }
}

.page-rtl .cart__info--img {
    left: unset;
    right: 0;
}

@media (max-width: 575px) {
    .page-rtl .cart__info--topup a {
        text-align: left;
    }
}

.page-rtl .cart__bottom--checkout button {
    margin-left: 0;
    margin-right: 5px;
}

@media (max-width: 575px) {
    .page-rtl .cart__bottom--checkout button {
        margin-right: 0;
    }
}

.page-rtl .number-picker {
    text-align: right;
}

.page-rtl .number-picker__picker {
    margin: 0 0 14px 14px;
}

@media (max-width: 575px) {
    .page-rtl .number-picker__picker {
        margin: 0 0 14px;
    }
}

.page-rtl .number-picker__picker:nth-child(5n) {
    margin-right: 0;
    margin-left: 0;
}

.page-rtl .number-picker__picker--name span {
    margin-right: 5px;
    margin-left: 0;
}

.page-rtl .number-picker__buttons--submit {
    padding: 4px 15px 4px 4px;
}

.page-rtl .number-picker__buttons--submit p {
    margin-right: 0;
    margin-left: 16px;
}

.page-rtl .number-picker__buttons span {
    margin-right: 0;
    margin-left: 8px;
    font-size: 17px;
}

.page-rtl .number-picker__buttons span img {
    margin-right: 0;
    margin-left: 14px;
}

.page-rtl .number-picker__buttons a {
    margin-right: 0;
    margin-left: 8px;
}
@media (max-width: 991px) {
    .page-rtl .number-picker__buttons span,
    #button_add_to_cart {
        text-align: center;
        font-size: 15px;
    }
    #button_add_to_cart {
        padding: 5px 20px;
    }
}

@media (max-width: 575px) {
    .page-rtl .number-picker__buttons a {
        margin-left: 0;
        margin-bottom: 8px;
    }
}

@media (max-width: 575px) {
    .page-rtl .number-picker__numbers {
        padding: 20px 10px;
    }
}

.page-rtl .number-picker__numbers .betNum {
    margin: 0 0 7px 7px;
}

.page-rtl .number-picker__numbers .betNum:nth-child(5n) {
    margin-left: 0;
    margin-right: 0;
}

@media (max-width: 575px) {
    .page-rtl .number-picker__numbers .betNum:nth-child(5n) {
        margin-right: 0;
        margin-left: 7px;
    }
}

.page-rtl .number-picker__buttons--submit p {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .number-picker__buttons--submit p span {
    background-color: transparent;
    color: initial;
    margin-left: 0;
    margin-right: 5px;
    margin-top: 2px;
}

.page-rtl .total-balance__bottom {
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    align-items: flex-start;
}

.page-rtl .page-numbers.next i,
.page-rtl .page-numbers.previous i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.page-rtl .ticket-page .ticket {
    text-align: right;
}

@media (max-width: 420px) {
    .page-rtl .ticket-page .ticket {
        background-image: url(../img/Iraq-web_ticket-sidestrips-sml.png),
            url(../img/Iraq-web_ticket-sidestrips-sml.png);
    }
}

.page-rtl .ticket-page .ticket--wrapper {
    background-image: url(../img/Iraq-web_ticket-sidestrips.png),
        url(../img/Iraq-web_ticket-sidestrips.png);
}

@media (max-width: 420px) {
    .page-rtl .ticket-page .ticket--wrapper {
        background-image: none;
    }
}

@media (max-width: 420px) {
    .page-rtl .ticket-page .ticket__img {
        width: 200px;
    }
}

.page-rtl .welcome {
    text-align: right;
}

.page-rtl .welcome a i {
    right: unset;
    left: 20px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.page-rtl .login__content .form h2 {
    text-align: center;
}

.page-rtl .login__new .login__switch a {
    font-size: 13px;
}

@media (max-width: 575px) {
    .page-rtl .my-cards__content .payment__card--img {
        margin-right: 0;
        margin-left: 10px;
    }
}

.page-rtl .table-main table thead tr th:first-child {
    border-radius: 0 5px 0 0;
}

.page-rtl .table-main table thead tr th:last-child {
    border-radius: 5px 0 0 0;
}

.page-rtl .table-main table thead tr th:not(:last-child) {
    border-right: none;
    border-left: 1px solid var(--iq-orange);
}

.page-rtl .table-main table tbody tr td {
    padding: 20px 20px 15px 0;
}

@media (max-width: 575px) {
    .page-rtl .table-main table tbody tr td {
        padding: 20px 20px 15px;
    }
}

@media (max-width: 575px) {
    .page-rtl .table-main table tbody tr td:before {
        left: unset;
        right: 15px;
    }
}

.page-rtl .new-password__content .form h2 {
    right: unset;
    left: 10px;
}

.page-rtl .balance-summary__info--top p span {
    margin-left: 0;
    margin-right: 5px;
}

.page-rtl .balance-summary__info--bottom > div:first-child {
    margin-right: 0;
    margin-left: 10px;
}

.page-rtl .balance-summary__info--bottom > div a {
    margin-left: 0;
    margin-right: 20px;
}

@media (max-width: 575px) {
    .page-rtl .balance-summary__info--bottom > div a {
        margin-right: 0;
    }
}

.page-rtl .balance-summary__info--bottom > div p span {
    margin-left: 0;
    margin-right: 5px;
}

.page-rtl .balance-summary--number {
    direction: ltr;
}

.page-rtl .balance-summary--ticketid {
    display: inline-block;
    direction: ltr;
}

.page-rtl .bottle-picker__content--img.product_1 {
    background-image: url(../img/cart_product_1-ar.png);
}

.page-rtl .bottle-picker__content--img.product_2 {
    background-image: url(../img/cart_product_2-ar.png);
}

.page-rtl .bottle-picker__content--img.product_3 {
    background-image: url(../img/cart_product_3-ar.png);
}

.page-rtl .bottle-picker__inner {
    text-align: right;
}

.page-rtl .bottle-picker__buttons--value {
    margin-right: 0;
    margin-left: 10px;
}

.page-rtl .bottle-picker__buttons--value .value-button:first-child {
    border-left: 0;
    border-right: 1px solid var(--iq-dullgrey);
    border-radius: 0 4px 4px 0;
}

.page-rtl .bottle-picker__buttons--value .value-button:last-child {
    border-left: 1px solid var(--iq-dullgrey);
    border-right: 0;
    border-radius: 4px 0 0 4px;
}

.page-rtl .bottle-picker__buttons--value input {
    text-align: center !important;
}

.page-rtl .bottle-picker__buttons--submit p {
    text-align: left;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

@media (max-width: 575px) {
    .page-rtl .bottle-picker__buttons--submit p {
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
}

.page-rtl .bottle-picker__buttons--submit p > span {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .bottle-picker__buttons--submit p > span > span {
    margin-right: 5px;
}

.page-rtl .not-found__buttons a {
    width: 220px;
}

.page-rtl .not-found__buttons a:not(:last-child) {
    margin-right: 0;
    margin-left: 15px;
}

.page-rtl .find__form .form-group .selectize-control .selectize-input {
    padding: 8px 8px 8px 30px;
}

.page-rtl .find__form .form-group .selectize-control .selectize-input:after {
    left: 15px !important;
}

.page-rtl .addcredit__cards--right img {
    margin-right: 0;
    margin-left: 20px;
}

.page-rtl .addcredit__cards--img {
    margin-right: 0;
    margin-left: 10px;
}

.page-rtl .addcredit__box--text {
    right: unset;
    left: 0;
}

.page-rtl .addcredit__info--text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: end;
    align-items: flex-end;
}

.page-rtl .addcredit__info--text p {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
}

.page-rtl .addcredit__info--text span {
    margin-left: 0;
    margin-right: 5px;
}

.page-rtl .addcredit__cards--top {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

@media (max-width: 991px) {
    .page-rtl .addcredit__cards--top {
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

.page-rtl .addcredit__cards--top p {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
}

.page-rtl .addcredit__cards--top span {
    margin-left: 0;
    margin-right: 5px;
}

.page-rtl .addcredit .form .form-group .input-box p {
    left: unset;
    right: 10px;
}

.page-rtl .addcredit .form .form-group .input-box input[type="number"] {
    padding: 20px 95px 15px 20px;
}

.page-rtl .addcredit .form .form-group .input-box--divider {
    left: unset;
    right: 70px;
}

.page-rtl .gm-style .gm-style-iw-d {
    padding: 10px 10px 0 0;
}

.page-rtl .gm-style .gm-style-iw-d > div {
    text-align: right;
}

.page-rtl .gm-style .gm-style-iw-d > div a {
    text-align: right;
    max-width: 100px;
}

.page-rtl .share_tab {
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.page-rtl .share_tab li:not(:last-child) {
    margin-left: 10px;
    margin-right: 0;
}

.page-rtl .share_tab span {
    margin-left: 12px;
    margin-right: 0;
}

.page-rtl .form .alert__img {
    margin-right: 0 !important;
    margin-left: 40px !important;
}

@media (max-width: 575px) {
    .page-rtl .form .alert__img {
        margin-left: 0 !important;
    }
}

.page-rtl .form .form-group__options:first-child {
    margin-right: 0;
    margin-left: 140px;
}

.page-rtl .form .form-group__options ul {
    padding-right: 0;
}

.page-rtl .form .form-group__options--green,
.page-rtl .form .form-group__options--red {
    margin-right: 0;
    margin-left: 6px;
}

.page-rtl .form form,
.page-rtl .form form .form-group label {
    text-align: right;
}

.page-rtl .form form .form-group .info-icon {
    right: unset;
    left: 15px;
}

.page-rtl .form form .form-group span {
    right: unset;
    left: 40px;
}

.page-rtl .form form .form-group .input-row .flag-picker {
    margin-right: 0;
    margin-left: 15px;
}

.page-rtl .form form .form-group .input-box input {
    text-align: right !important;
}

.page-rtl .form form .form-group .input-box p {
    left: 10px;
    right: unset;
}

.page-rtl .form form .form-group .deposit--left {
    -webkit-box-align: end;
    align-items: flex-end;
}

.page-rtl .form form .form-group.error p {
    text-align: right;
}

.page-rtl .form form .form-group.check label input[type="checkbox"] {
    margin-right: 0;
    margin-left: 10px;
}

@media (max-width: 575px) {
    .page-rtl .form form .form-group#form_register_passwd .info-icon {
        top: 95px;
    }
}

@media (max-width: 392px) {
    .page-rtl .form form .form-group#form_register_passwd .info-icon {
        top: 115px;
    }
}

@media (max-width: 342px) {
    .page-rtl .form form .form-group#form_register_passwd .info-icon {
        top: 115px;
    }
}

.page-rtl .form form #form_manage-account_submit input {
    margin-right: 0 !important;
    margin-left: 15px;
}

@media (max-width: 479px) {
    .page-rtl .form form #form_deposit_amount .input-box {
        margin-right: 0;
    }
}

.page-rtl .form form#form_deposit .payment__cards {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .form form#form_deposit .payment__cards--text {
    margin-right: 0;
    margin-left: 25px;
}

@media (max-width: 575px) {
    .page-rtl .form form#form_deposit .payment__cards--text {
        margin-left: 10px;
    }
}

.page-rtl .form form#form_deposit .payment__cards--right a {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.page-rtl .form form#form_payment-method .payment__cards {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.page-rtl .form form#form_payment-method .payment__cards--text {
    margin-right: 0;
    margin-left: 25px;
}

@media (max-width: 575px) {
    .page-rtl .form form#form_payment-method .payment__cards--text {
        margin-left: 10px;
    }
}

.page-rtl .form form#form_payment-method .payment__cards--right a {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.page-rtl .form form#payment_form {
    text-align: left;
    direction: ltr;
}

.page-rtl .form form button i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.page-rtl .form .form-group .captcha-wrapper .send_otp {
    margin-left: 0;
}

@media (max-width: 768px) {
    .page-rtl .form .form-group .captcha-wrapper .send_otp {
        margin-right: 0;
    }
}

.page-rtl .register__new .login__content .form__requirements--row {
    flex-wrap: wrap;
}

.page-rtl .register__new .login__content .form__requirements--req {
    margin-bottom: 5px;
}

.page-rtl .draw_name span {
    display: inline-block;
}

.page-rtl
    input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
        [type="file"]
    ) {
    text-align: right;
}

.page-rtl input[type="checkbox"] {
    margin-right: 0;
    margin-left: 10px;
}

.page-rtl .remodal form .form-group__buttons .btn:first-child {
    margin-right: 0;
    margin-left: 10px;
}

.page-rtl .remodal__numberlist--number {
    margin-right: 0;
    margin-left: 7px;
}

.page-rtl .remodal ul li {
    text-align: right;
}

@media (min-width: 1200px) {
    .page-rtl .remodal.modal--change-fourth {
        padding: 26px 40px 65px;
    }
}

.page-rtl .remodal.modal--change-fourth ul {
    padding-left: 0;
    padding-right: 17px;
}

@media (min-width: 1200px) {
    .page-rtl .remodal.modal--change-fourth .hp-navigation__box--content h3 {
        margin-bottom: 93px;
    }
}

.page-rtl .remodal.modal--change-fourth .form-group__buttons p {
    text-align: right;
}

.page-rtl
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--button
    a {
    width: 115px;
}

.page-rtl
    .modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers
    .betNum:nth-child(5n) {
    margin-left: 7px;
    margin-right: 0;
}

.page-rtl
    .modal--numberpicker
    .modal__numberpicker--numberpicker
    .number-picker__numbers
    .betNum:nth-child(7n) {
    margin-left: 0;
}

@media (max-width: 380px) {
    .page-rtl
        .modal--numberpicker
        .modal__numberpicker--numberpicker
        .number-picker__numbers
        .betNum:nth-child(7n) {
        margin: 0 0 7px 7px;
    }
}

.page-rtl .modal--numberpicker .modal__selectboxes--select:not(:last-child) {
    margin-right: 0;
    margin-left: 10px;
}

.page-rtl .modal--game-soon {
    padding: 30px 80px 30px 50px;
}

@media (max-width: 479px) {
    .page-rtl .modal--game-soon {
        padding: 30px 70px 80px 50px;
    }
}

.page-rtl .modal--game-soon .modal__game-soon--heading {
    margin-left: 0;
    margin-right: -30px;
}

.page-rtl .modal--game-soon .modal__game-soon--text {
    text-align: right;
}

.page-rtl .modal--game-soon .modal__game-soon--line {
    left: unset;
    right: -22%;
    -webkit-transform: rotate(34deg);
    transform: rotate(34deg);
}

@media (max-width: 479px) {
    .page-rtl .modal--game-soon .modal__game-soon--line {
        right: -29%;
    }
}

@media (max-width: 375px) {
    .page-rtl .modal--game-soon .modal__game-soon--line {
        right: -37%;
    }
}

.page-rtl .modal--new-game .modal__boxes--line-top {
    left: unset;
    right: -23%;
    -webkit-transform: rotate(136deg);
    transform: rotate(136deg);
}

@media (max-width: 479px) {
    .page-rtl .modal--new-game .modal__boxes--line-top {
        right: -54%;
    }
}

.page-rtl .modal--new-game .modal__boxes--line-bottom {
    right: unset;
    left: -23%;
    -webkit-transform: rotate(-42deg);
    transform: rotate(-42deg);
}

@media (max-width: 479px) {
    .page-rtl .modal--new-game .modal__boxes--line-bottom {
        left: -56%;
    }
}

@media (min-width: 480px) {
    .page-rtl .modal--new-game .modal__boxes--box p strong {
        font-size: 21px;
    }
}

@media (max-width: 366px) {
    .page-rtl .captcha-wrapper .g-recaptcha {
        -webkit-transform-origin: right;
        transform-origin: right;
    }
}

.page-rtl.logged_out .shopping-cart__content .cart__summary--voucher {
    width: 240px;
}

@media (max-width: 991px) {
    .page-rtl.logged_out .shopping-cart__content .cart__summary--voucher {
        width: 100%;
        max-width: 100%;
    }
}

.page-rtl.logged_out
    .shopping-cart__content
    .cart__summary--voucher
    input::-webkit-input-placeholder {
    font-size: 10px;
    text-align: right;
}

.page-rtl.logged_out
    .shopping-cart__content
    .cart__summary--voucher
    input::-moz-placeholder {
    font-size: 10px;
    text-align: right;
}

.page-rtl.logged_out
    .shopping-cart__content
    .cart__summary--voucher
    input:-ms-input-placeholder {
    font-size: 10px;
    text-align: right;
}

.page-rtl.logged_out
    .shopping-cart__content
    .cart__summary--voucher
    input::-ms-input-placeholder {
    font-size: 10px;
    text-align: right;
}

.page-rtl.logged_out
    .shopping-cart__content
    .cart__summary--voucher
    input::placeholder {
    font-size: 10px;
    text-align: right;
}

.page-rtl .selectize .subflex {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.page-rtl .downloadbanner__buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.skin_449 .droplink {
    line-height: 1;
}

.skin_449 .balance-summary__info--bottom > div a {
    font-size: 17px;
}

@media (max-width: 992px) {
    .skin_449 .footer__content {
        padding-bottom: 75px;
    }
}

.skin_449 .priceproduct1 {
    max-width: 175px;
}

@media (max-width: 575px) {
    .skin_449 .priceproduct1 {
        max-width: 90px;
    }
}

.skin_449 .hp-navigation__box {
    padding-bottom: 0;
    border-radius: 0;
}

.skin_449 .hp-navigation__box--content {
    position: unset;
}

.skin_449 .hp-navigation__box--draw {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.skin_449 .hp-navigation__box--draw h2 {
    color: #0078f5;
    font-size: 35px;
    margin-bottom: 0;
    margin-top: 7px;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box--draw h2 {
        font-size: 21px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box--draw h2 {
        font-size: 30px;
    }
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box--draw h2 {
        font-size: 19px;
        margin-top: 0;
    }
}

@media (max-width: 415px) {
    .skin_449 .hp-navigation__box--draw h2 {
        font-size: 19px;
    }
}

@media (max-width: 385px) {
    .skin_449 .hp-navigation__box--draw h2 {
        font-size: 13px;
    }
}

.skin_449 .hp-navigation__box--draw > p {
    color: #0078f5;
    font-size: 14px;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box--draw > p {
        font-size: 17px;
    }
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box--draw > p {
        font-size: 14px;
    }
}

@media (max-width: 415px) {
    .skin_449 .hp-navigation__box--draw > p {
        font-size: 12px;
    }
}

@media (max-width: 385px) {
    .skin_449 .hp-navigation__box--draw > p {
        font-size: 10px;
    }
}

.skin_449 .hp-navigation__box--sum {
    padding: 10px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 15px;
    width: 300px;
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box--sum {
        width: 160px;
        max-width: 100%;
        border-radius: 5px;
        padding: 10px 5px;
    }
}

@media (max-width: 415px) {
    .skin_449 .hp-navigation__box--sum {
        width: 145px;
    }
}

@media (max-width: 385px) {
    .skin_449 .hp-navigation__box--sum {
        width: 115px;
        padding: 5px;
    }
}

.skin_449 .hp-navigation__box--sum p {
    color: #0078f5;
    font-size: 32px;
    height: 40px;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box--sum p {
        font-size: 33px;
        height: 37px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box--sum p {
        font-size: 40px;
        height: 50px;
    }
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box--sum p {
        font-size: 18px;
        height: 22px;
    }
}

@media (max-width: 415px) {
    .skin_449 .hp-navigation__box--sum p {
        font-size: 17px;
        height: 20px;
    }
}

@media (max-width: 385px) {
    .skin_449 .hp-navigation__box--sum p {
        font-size: 13px;
        height: 17px;
    }
}

.skin_449 .hp-navigation__box--sum.sum-grand {
    background-color: var(--iq-white);
}

.skin_449 .hp-navigation__box--sum.sum-raffle {
    background-color: #ffe765;
    margin-bottom: 5px;
}

.skin_449 .hp-navigation__box.box-draw-info {
    background: var(--iq-pink);
    border: 1px solid var(--iq-orange);
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-draw-info {
        padding: 30px 20px;
    }
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box.box-draw-info {
        padding: 10px 10px;
    }
}

.skin_449 .hp-navigation__box.box-draw-info .countdown__timer_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 10px;
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box.box-draw-info .countdown__timer_wrapper {
        width: 100%;
        -webkit-box-pack: start;
        justify-content: flex-start;
        margin-top: 10px;
        margin-bottom: 0px;
    }
}

.skin_449
    .hp-navigation__box.box-draw-info
    .countdown__timer_wrapper:nth-child(2)
    .countdown__timer--priceproduct
    h2 {
    max-width: 175px;
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .countdown__timer_wrapper:nth-child(2)
        .countdown__timer--priceproduct
        h2 {
        max-width: 90px;
    }
}

.skin_449 .hp-navigation__box.box-draw-info .countdown__timer--priceproduct h2 {
    color: var(--iq-black);
    text-transform: uppercase;
    margin-bottom: 3px;
    line-height: 40px;
}

@media (max-width: 1200px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .countdown__timer--priceproduct
        h2 {
        font-size: 30px;
        line-height: 30px;
    }
}

@media (max-width: 479px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .countdown__timer--priceproduct
        h2 {
        font-size: 25px;
    }
}

.skin_449 .hp-navigation__box.box-draw-info .countdown__timer--priceproduct p {
    color: var(--iq-white);
    font-size: 17px;
    text-transform: uppercase;
}

@media (max-width: 479px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .countdown__timer--priceproduct
        p {
        font-size: 13px;
        max-width: 115px;
    }
}

.skin_449 .hp-navigation__box.box-draw-info .countdown__pricebubble {
    padding: 10px 0;
    background-color: var(--iq-white);
    width: 100%;
    border-radius: 50px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box.box-draw-info .countdown__pricebubble {
        height: auto;
    }
}

.skin_449 .hp-navigation__box.box-draw-info .countdown__pricebubble--content {
    text-align: center;
}

.skin_449 .hp-navigation__box.box-draw-info .countdown__pricebubble--content h3,
.skin_449
    .hp-navigation__box.box-draw-info
    .countdown__pricebubble--content
    h5 {
    margin-bottom: 0;
}

.skin_449
    .hp-navigation__box.box-draw-info
    .countdown__pricebubble--content
    h5 {
    color: var(--iq-black);
    font-size: 17px;
    margin-top: 8px;
}

.skin_449
    .hp-navigation__box.box-draw-info
    .countdown__pricebubble--content
    h1 {
    color: var(--iq-black);
    font-size: 17px;
    margin: 8px 0px 0px;
}

@media (max-width: 1200px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .countdown__pricebubble--content
        h5 {
        font-size: 17px;
    }
}

.skin_449
    .hp-navigation__box.box-draw-info
    .countdown__pricebubble--content
    h2 {
    font-size: 38px;
    color: var(--iq-pink);
    line-height: 60px;
    margin-bottom: 0px;
}

.skin_449
    .hp-navigation__box.box-draw-info
    .countdown__pricebubble--content
    h3 {
    font-size: 38px;
    color: var(--iq-pink);
    line-height: 54px;
}

@media (max-width: 1199px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .countdown__pricebubble--content
        h3 {
        font-size: 30px;
        line-height: 42px;
    }
}

@media (max-width: 992px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .countdown__pricebubble--content
        h3,
    .skin_449 .number-picker__top--text h1 {
        font-size: 28px !important;
        line-height: 28px;
    }

    .skin_449 .number-picker__picker--randomize img {
        width: 12px !important;
    }
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-draw-info .countdown__pricebubble {
        width: 100%;
    }
}

.skin_449
    .hp-navigation__box.box-draw-info
    .hp-navigation__box--content.long-currency
    .hp-navigation__box--sum
    p {
    font-size: 30px;
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .hp-navigation__box--content.long-currency
        .hp-navigation__box--sum
        p {
        font-size: 14px;
        height: 18px;
    }

    .skin_449
        .hp-navigation__box.box-draw-info
        .countdown__pricebubble--content
        h3,
    .skin_449 .number-picker__top--text h1,
    .skin_449 .number-picker__top--text h1 span {
        font-size: 25px !important;
    }
}

@media (max-width: 385px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .hp-navigation__box--content.long-currency
        .hp-navigation__box--sum
        p {
        font-size: 10px;
        height: 13px;
    }
}

.skin_449
    .hp-navigation__box.box-draw-info
    .hp-navigation__box--sumwrapper
    > div:first-child {
    margin-bottom: 15px;
}

@media (max-width: 479px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .hp-navigation__box--sumwrapper
        > div:first-child {
        margin-bottom: 0;
    }
}

.skin_449
    .hp-navigation__box.box-draw-info
    .hp-navigation__box--sumwrapper
    > div:nth-child(2) {
    margin-bottom: 20px;
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .hp-navigation__box--sumwrapper
        > div:nth-child(2) {
        margin-bottom: 25px;
    }
}

@media (max-width: 479px) {
    .skin_449
        .hp-navigation__box.box-draw-info
        .hp-navigation__box--sumwrapper
        > div:nth-child(2) {
        margin-bottom: 0;
    }
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box.box-draw-info .hp-navigation__box--wrapper {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 385px) {
    .skin_449 .hp-navigation__box.box-draw-info .hp-navigation__box--wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        text-align: left;
    }
}

.skin_449 .hp-navigation__box.box-draw-info .hp-navigation__box--btn {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.hp-navigation__box.box-total.desktop > div.hp-nav_box-wrap {
    padding-right: 50px;
    border-right: 2px solid white;
}

.hp-navigation__box.box-total.desktop > div.hp-nav_box-wrapar {
    padding-left: 50px;
    border-left: 2px solid white;
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-draw-info .hp-navigation__box--btn {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box.box-draw-info .hp-navigation__box--btn {
        display: block;
    }
}

.skin_449 .hp-navigation__box.box-draw-info .hp-navigation__box--btn a {
    background: var(--iq-orange);
    border: 1px solid var(--iq-white);
    color: var(--iq-white);
    width: 100%;
    height: auto;
    font-size: 20px;
    padding: 10px 15px;
    text-transform: uppercase;
    border-radius: 12px;
    margin-top: 24px;
    margin-bottom: 7px;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-draw-info .hp-navigation__box--btn a {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .hp-navigation__box.box-total.desktop > div.hp-nav_box-wrap {
        padding-right: 20px;
    }

    .hp-navigation__box.box-total.desktop > div.hp-nav_box-wrapar {
        padding-left: 20px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-draw-info .hp-navigation__box--btn a {
        font-size: 14px;
        margin: 5px auto;
    }
}

@media (max-width: 580px) {
    .skin_449
        .hp-navigation__box
        .countdown.play
        .countdown__timer--priceproduct
        .priceproduct1,
    .skin_449
        .hp-navigation__box
        .hp-navigation__box.box-draw-info
        .countdown__timer--priceproduct
        .priceproduct1 {
        max-width: 90px;
    }
}

.skin_449 .hp-navigation__box.box-total {
    background: var(--iq-pink);
    padding: 5px 20px;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border: 1px solid var(--iq-orange);
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-total {
        padding: 10px 20px 5px;
        border-radius: 10px;
    }
}

@media (max-width: 991px) {
    .skin_449 .hp-navigation__box.box-total.desktop {
        display: none;
    }
}

@media (min-width: 992px) {
    .skin_449 .hp-navigation__box.box-total.mobile {
        display: none;
    }
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    line-height: 32px;
}

.skin_449
    .hp-navigation__box.box-total
    .hp-navigation__box--content.full-width {
    width: 100%;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content.first-row {
    -webkit-box-ordinal-group: 2;
    order: 1;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content.item2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h2 {
    text-transform: uppercase;
    line-height: 30px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    font-size: 20px;
    color: var(--iq-white);
    margin-bottom: 0;
    font-weight: 300;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h2 {
        font-size: 20px;
        line-height: 20px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h2 {
        line-height: 18px;
        font-size: 14px;
        margin-bottom: 0;
    }

    .skin_449 .hp-navigation__box.box-total .hp-navigation__box--price h3 {
        font-size: 22px;
    }
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content span {
    text-transform: uppercase;
    font-size: 35px;
    color: var(--iq-white);
}

@media (max-width: 1200px) {
    .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content span {
        font-size: 28px;
    }
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h4 {
    color: var(--iq-white);
    text-transform: uppercase;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h3 {
    color: #0078f5;
    font-size: 60px;
    margin-bottom: 0;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h3 {
        font-size: 60px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h3 {
        font-size: 50px;
        color: #0078f5;
        line-height: 80px;
        width: 100%;
        max-width: 100%;
        text-align: right;
    }
}

@media (max-width: 385px) {
    .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h3 {
        font-size: 55px;
        line-height: 55px;
    }
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--price {
    display: -webkit-box;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--price.item3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--price h3 {
    font-size: 40px;
    position: relative;
    color: var(--iq-yellow);
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-total .hp-navigation__box--price h3 {
        font-size: 26px;
    }
}

.skin_449 .hp-navigation__box.box-latest {
    background: transparent;
    border: 1px solid var(--iq-orange);
    border-radius: 15px;
    position: relative;
    margin-top: 15px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .skin_449 .hp-navigation__box.box-latest {
        margin-top: 10px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-latest {
        padding: 0px 0px 10px;
        margin-top: 8px;
    }

    .skin_449 .hp-navigation__box.box-stories {
        margin-top: 0 !important;
    }
}

.skin_449 .hp-navigation__box.box-latest h2 {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 3px;
    background: var(--iq-orange);
    padding: 12px 0px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-latest h2 {
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
}

.skin_449 .hp-navigation__box.box-latest h2 span {
    color: var(--iq-white);
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 0;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-latest h2 span {
        font-size: 20px;
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-latest h2 span {
        font-size: 14px;
        text-align: center;
    }

    .skin_449 .hp-navigation__box.box-latest h2 span:first-child {
        display: block;
    }
}

.skin_449 .hp-navigation__box.box-results {
    background: none;
    border: none;
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__box.box-results {
        padding: 0;
    }
}

.skin_449 .hp-navigation__box.box-results h3 {
    text-transform: uppercase;
    font-size: 28px;
    color: var(--iq-brown);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 10px 0 5px;
    border-radius: 10px;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-results h3 {
        font-size: 20px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-results h3 {
        margin-bottom: 15px;
        font-size: 20px;
    }
}

.skin_449 .hp-navigation__box.box-results.box-raffle {
    height: 100%;
}

@media (max-width: 385px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__info
        > [class*="col-"]:not(:last-child) {
        margin-bottom: 5px !important;
    }
}

.skin_449 .hp-navigation__box.box-results.box-raffle .hp-navigation__info--box {
    padding: 40px 18px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__info--box {
        padding: 25px 18px;
        margin: 0 !important;
    }

    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__info--box
        p {
        font-size: 30px;
        line-height: 40px;
    }
}

@media (max-width: 385px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__info--box
        p {
        font-size: 24px;
    }
}

.skin_449
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__info--box
    p:nth-child(2) {
    font-size: 37px;
}

@media (max-width: 1199px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__info--box
        p:nth-child(2) {
        font-size: 27px;
    }
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__info--box
        p:nth-child(2) {
        font-size: 38px;
    }
}

@media (max-width: 385px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__info--box
        p:nth-child(2) {
        font-size: 31px;
    }
}

.skin_449
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__info--box
    p:nth-child(2)
    span {
    margin-right: 5px;
}

.skin_449
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__info__info--box.raffleid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.skin_449
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__box--content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 100%;
}

.skin_449
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__box--content
    h3 {
    margin-bottom: 12px;
}

.skin_449
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__box--content.raffledraw {
    display: -webkit-box;
    display: flex;
}

.skin_449 .hp-navigation__box.box-results.box-raffle .hp-navigation__box--btn {
    margin-top: 4px;
}

@media (max-width: 1199px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__box--btn {
        margin-bottom: 15px;
        margin-top: 1px;
    }
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__box--btn {
        position: unset;
        right: unset;
        bottom: unset;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.skin_449
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__box--btn
    a {
    background: var(--iq-orange);
    color: var(--iq-white);
    width: 100%;
    height: 51px;
    font-size: 20px;
    text-transform: uppercase;
    border-radius: 12px;
    display: block;
    padding: 5px 15px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 3px;
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__box--btn {
        margin-bottom: 0;
    }

    .skin_449
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__box--btn
        a {
        width: 100%;
        height: 35px;
        font-size: 14px;
        margin-top: 12px;
        border-radius: 8px;
    }
}

.skin_449
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__box--content
    > p {
    color: #0078f5;
    font-size: 38px;
    text-align: center;
    margin-bottom: 12px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 70px;
}

@media (max-width: 1199px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__box--content
        > p {
        font-size: 32px;
    }
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__box--content
        > p {
        height: unset;
        margin-bottom: 0;
    }
}

@media (max-width: 385px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__box--content
        > p {
        font-size: 27px;
    }
}

.skin_449
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__box--content
    > p
    span {
    margin-right: 5px;
}

.skin_449
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__info--box {
    background-color: transparent;
    color: var(--iq-pink);
    border: 1px solid var(--iq-pink);
    margin: 5px 0;
    padding: 5px 10px;
    height: unset;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.skin_449
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__info--box
    p {
    color: var(--iq-pink);
    font-size: 17px;
}

@media (max-width: 1199px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p {
        font-size: 13px;
    }

    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box {
        margin: 0;
        padding: 5px 10px;
    }
}

@media (max-width: 992px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box {
        padding: 3px 10px;
    }

    .skin_449 .hp-navigation__box.box-total {
        border-radius: 15px;
        padding: 8px 20px;
    }
}

@media (max-width: 479px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p {
        line-height: 1.5;
    }
}

@media (max-width: 385px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p {
        font-size: 13px;
    }
}

.skin_449
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__info--box
    p:nth-child(2) {
    color: var(--iq-pink);
    font-size: 23px;
}

@media (max-width: 385px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p:nth-child(2) {
        font-size: 17px;
    }
}

.skin_449
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__info--box.raffleid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 48px 5px;
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box.raffleid {
        padding: 19px 5px;
    }
}

@media (max-width: 479px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box.raffleid {
        padding: 19px 5px 15px;
    }
}

.skin_449
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__info--box.raffleid
    p {
    margin-bottom: -1px;
    font-size: 17px;
}

@media (max-width: 479px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box.raffleid
        p {
        font-size: 14px;
    }
}

.skin_449
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__info--box.raffleid
    .raffle_price {
    font-size: 35px;
}

@media (max-width: 479px) {
    .skin_449
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box.raffleid
        .raffle_price {
        font-size: 29px;
    }
}

.skin_449 .hp-navigation__box.box-stories {
    border: none;
    border-radius: 15px;
}

.skin_449 .hp-navigation__box.box-stories .hp-navigation__box--heading {
    padding: 20px;
    margin-bottom: 13px;
}

.skin_449
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    p.winners-text {
    display: flex;
    font-family: "Product-Sans-Bold";
}

.skin_449.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    p.winners-text {
    display: flex;
    font-family: "Swissra-Bold";
}

.skin_449 .hp-navigation__box.box-stories .hp-navigation__box--heading h2 {
    font-weight: 500;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-stories .hp-navigation__box--heading {
        padding: 20px 0 0 20px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-stories .hp-navigation__box--heading {
        padding: 0 0 0 20px;
    }
}

.skin_449 .hp-navigation__box.box-stories .hp-navigation__box--heading h2,
.skin_449 .hp-navigation__box.box-stories .hp-navigation__box--heading h2 p {
    color: var(--iq-white);
    text-transform: uppercase;
    max-width: 290px;
    font-size: 30px;
    margin-bottom: 0;
    position: relative;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-stories .hp-navigation__box--heading h2 {
        max-width: 100%;
        font-size: 21px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-stories .hp-navigation__box--heading h2,
    .skin_449
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2
        p.winners-text {
        font-size: 18px;
    }
}

@media (max-width: 1199px) {
    .skin_449
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2
        span {
        font-size: 50px;
        bottom: -19px;
        right: 10px;
    }
}

@media (max-width: 575px) {
    .skin_449
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2
        span {
        font-size: 33px;
        bottom: -23px;
        right: 0;
    }
}

@media (max-width: 335px) {
    .skin_449
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2
        span {
        font-size: 28px;
        bottom: -18px;
        right: 20px;
    }
}

.skin_449
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2:nth-child(2) {
    color: var(--iq-white);
}

.skin_449 .hp-navigation__box.box-stories .hp-navigation__box--video {
    overflow: hidden;
    margin-bottom: 15px;
}

.skin_449 .hp-navigation__box.box-stories .hp-navigation__box--video iframe {
    border: none;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__box.box-stories .hp-navigation__box--video {
        margin-bottom: 11px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-stories .hp-navigation__box--video {
        margin-bottom: 10px;
    }
}

.skin_449 .hp-navigation__box.box-stories .hp-navigation__box--btn {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding-bottom: 20px;
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-stories .hp-navigation__box--btn {
        padding: 0 0 5px;
    }
}

.skin_449 .hp-navigation__box.box-stories .hp-navigation__box--btn a {
    background: var(--iq-orange);
    border: 1px solid var(--iq-white);
    color: var(--iq-white);
    width: 100%;
    height: auto;
    font-size: 20px;
    padding: 10px 15px;
    text-transform: uppercase;
    border-radius: 12px;
    margin-top: 3px;
    margin-bottom: 18px;

    margin: 0 20px 0px 20px;
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__box.box-stories .hp-navigation__box--btn a {
        font-size: 16px;
        padding: 5px 20px !important;
    }
}

@media (min-width: 1200px) {
    .skin_449 .hp-navigation__info .row [class*="col-"]:not(:last-child) {
        margin-bottom: 5px;
    }
}

.skin_449 .hp-navigation__info .row [class*="col-"]:last-child {
    margin-bottom: 0;
}

.skin_449 .hp-navigation__info--box {
    background-color: var(--iq-pink);
    padding: 8px 18px;
    height: unset;
}

.skin_449 .hp-navigation__info--box.small {
    padding: 9px;
}

.skin_449 .hp-navigation__info--box.small p {
    font-size: 21px;
    line-height: 27px;
    margin-top: 5px;
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__info--box {
        padding: 2px 10px;
    }
}

.skin_449 .hp-navigation__info--box p {
    text-transform: uppercase;
    color: var(--iq-white);
    font-size: 20px;
    line-height: 37px;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__info--box p {
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__info--box p,
    .skin_449 .hp-navigation__info--box p span {
        font-size: 14px !important;
    }
}

.skin_449 .hp-navigation__info--box p span {
    margin: 0 5px;
}

.skin_449 .hp-navigation__info--box p:last-child span {
    margin-right: 0;
    margin-left: 5px;
}

.skin_449 .hp-navigation__info--box:after {
    content: none;
}

.skin_449 .hp-navigation__draws {
    -webkit-box-pack: justify;
    justify-content: space-evenly;
    margin: 20px 0px;
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__draws {
        flex-wrap: nowrap;
        justify-content: center;
        margin: 15px 0;
    }

    .skin_449 .hp-navigation__draws--number {
        margin: 0 20px;
    }
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__draws > div:not(:last-child) {
        margin-right: 0;
    }
}

@media (max-width: 479px) {
    .skin_449
        .hp-navigation__draws
        > div:not(:last-child):not(:nth-last-child(2)):not(:last-child) {
        margin-bottom: 0;
    }
}

.skin_449 .hp-navigation__draws--number {
    border: 1px solid var(--iq-pink);
    background-color: var(--iq-pink);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: relative;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__draws--number {
        width: 55px;
        height: 55px;
    }
}

@media (max-width: 992px) {
    .skin_449 .hp-navigation__draws--number {
        width: 40px;
        height: 40px;
        margin-right: 0 !important;
    }
}

@media (max-width: 350px) {
    .skin_449 .hp-navigation__draws--number {
        width: 40px;
        height: 40px;
    }
}

.skin_449 .hp-navigation__draws--number span {
    color: var(--iq-white);
    font-size: 18px;
    position: relative;
    top: 3px;
    padding: 0;
}

@media (max-width: 385px) {
    .skin_449 .hp-navigation__draws--number span {
        font-size: 20px;
    }
}

@media (max-width: 350px) {
    .skin_449 .hp-navigation__draws--number span {
        font-size: 18px;
    }
}

.skin_449 .hp-navigation__date {
    margin-bottom: 10px;
}

@media (max-width: 575px) {
    .skin_449 .hp-navigation__date {
        margin-bottom: 25px;
    }
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__date {
        margin-bottom: 0;
    }
}

@media (max-width: 385px) {
    .skin_449 .hp-navigation__date {
        margin-bottom: 5px;
    }
}

.skin_449 .hp-navigation__date p {
    color: #0078f5;
    font-size: 14px;
    text-align: center;
}

@media (max-width: 1199px) {
    .skin_449 .hp-navigation__date p {
        font-size: 17px;
    }
}

@media (max-width: 479px) {
    .skin_449 .hp-navigation__date p {
        font-size: 30px;
        line-height: 27px;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        text-align: left;
        color: var(--iq-white);
    }
}

@media (max-width: 415px) {
    .skin_449 .hp-navigation__date p {
        font-size: 19px;
        line-height: 21px;
    }
}

@media (max-width: 385px) {
    .skin_449 .hp-navigation__date p {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
}

@media (max-width: 415px) {
    .skin_449 .hp-navigation__date p span {
        font-size: 11px;
    }
}

@media (min-width: 992px) {
    .skin_449 .hp-navigation__inner > .row > [class*="col-"]:first-child {
        -webkit-box-flex: 0;
        flex: 0 0 63.66667%;
        max-width: 63.66667%;
    }
}

@media (max-width: 991px) {
    .skin_449 .hp-navigation__inner > .row > [class*="col-"]:first-child {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }
}

@media (min-width: 992px) {
    .skin_449 .hp-navigation__inner > .row > [class*="col-"]:nth-child(2) {
        -webkit-box-flex: 0;
        flex: 0 0 36.33333%;
        max-width: 36.33333%;
    }
}

.skin_449
    .hp-navigation__inner
    > .row
    > [class*="col-"]:nth-child(2)
    > [class*="col-"] {
    padding: 0;
    margin-bottom: 10px;
}

@media (max-width: 991px) {
    .skin_449 .hp-navigation__inner > .row > [class*="col-"]:nth-child(2) {
        -webkit-box-ordinal-group: 2;
        order: 1;
        margin-bottom: 20px;
    }

    .skin_449
        .hp-navigation__inner
        > .row
        > [class*="col-"]:nth-child(2)
        > [class*="col-"] {
        margin-bottom: 5px;
    }

    .skin_449 .hp-navigation__inner > .row > [class*="col-"]:nth-child(3) {
        -webkit-box-ordinal-group: 5;
        order: 5;
        margin-bottom: 20px;
    }

    .skin_449 .hp-navigation__inner > .row > [class*="col-"]:nth-child(4) {
        -webkit-box-ordinal-group: 4;
        order: 3;
        margin-bottom: 20px;
    }

    .skin_449 .hp-navigation__inner > .row > [class*="col-"]:nth-child(5) {
        -webkit-box-ordinal-group: 6;
        order: 4;
        margin-bottom: 20px;
    }
}

.skin_449 #buy_now_header_desktop {
    color: var(--iq-white);
    border: 1px solid;
    background-color: var(--iq-pink);
    padding: 2px 10px;
    border-radius: 7px;
}

.skin_449 .number-picker {
    margin-bottom: 20px;
}

.skin_449 .number-picker.homepage {
    margin-bottom: 0;
}

.skin_449 .number-picker.homepage .number-picker--btn {
    margin-bottom: 13px;
}

@media (max-width: 575px) {
    .skin_449 .number-picker.homepage .number-picker__picker {
        margin: 0 15px 0 0;
        border-radius: 10px;
    }

    .skin_449.page-rtl .number-picker.homepage .number-picker__picker {
        margin: 0 0 0 15px;
    }

    .skin_449 .number-picker.homepage .number-picker--btn {
        padding-top: 2px;
    }

    .skin_449 .number-picker__numbers .betNum:nth-child(7n),
    .skin_449 .number-picker.homepage .number-picker__picker:last-child {
        margin-right: 0;
    }
}

@media (min-width: 575px) {
    .skin_449 .number-picker__numbers .betNum:nth-child(5n) {
        margin: 0 7px 7px 0;
    }
}

.skin_449 .number-picker .betNum {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 19px;
    background-color: transparent;
    border: 1px solid var(--iq-orange);
}

.skin_449 .number-picker .betNum span {
    position: relative;
    top: 2px;
}

.skin_449 .number-picker.play .betNum {
    width: 32px;
    height: 32px;
    line-height: 32px;
}

@media (max-width: 1199px) {
    .skin_449 .number-picker .betNum,
    .skin_449 .number-picker.play .betNum {
        width: 30px;
        height: 30px;
    }
}

.skin_449 .number-picker .betNum:hover {
    border-color: var(--iq-pink);
}

.skin_449 .number-picker .betNumMarked {
    background-image: none;
    border-color: transparent;
    background-color: var(--iq-orange);
    color: var(--iq-white);
}

@media (max-width: 575px) {
    .skin_449 .number-picker__bottle {
        position: unset;
        right: unset;
        bottom: unset;
    }
}

.skin_449 .number-picker__bottle--value {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.skin_449
    .number-picker__bottle--value
    input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
        [type="file"]
    ) {
    padding: 0;
    border: 0;
    width: 30px;
    text-align: center;
    height: unset;
    background-color: transparent;
    height: 100%;
    font-size: 19px;
    margin: 0 10px;
}

@media (max-width: 1199px) {
    .skin_449
        .number-picker__bottle--value
        input:not([type="checkbox"]):not([type="button"]):not(
            [type="submit"]
        ):not([type="file"]) {
        font-size: 17px;
    }
}

.skin_449 .number-picker__bottle--value .value-button.deactive-card {
    pointer-events: none;
}

.skin_449 .number-picker__bottle--value .value-button {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background: var(--iq-orange);
    -webkit-box-align: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

@media (max-width: 1199px) {
    .skin_449 .number-picker__bottle--value .value-button {
        width: 33px;
        height: 33px;
    }
}

@media (max-width: 575px) {
    .skin_449 .number-picker__bottle--value .value-button,
    .skin_449 .number-picker__bottle--value .value-button img {
        width: 20px !important;
        height: 20px !important;
    }
}

.skin_449 .number-picker__bottle--value .value-button span {
    background: none;
    cursor: pointer;
    color: #0078f5;
    font-size: 30px;
    height: 100%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 37%;
    left: 50%;
    margin: 0;
}

@media (max-width: 1199px) {
    .skin_449 .number-picker__bottle--value .value-button span {
        font-size: 25px;
    }
}

@media (max-width: 575px) {
    .skin_449 .number-picker__bottle--value .value-button span {
        font-size: 30px;
    }
}

@media (max-width: 385px) {
    .skin_449 .number-picker__bottle--value .value-button span {
        font-size: 24px;
    }
}

.skin_449 .number-picker__bottle--value .value-button#decrease span {
    top: 37% !important;
}

.skin_449 .number-picker__content {
    width: 100%;
    flex-wrap: nowrap;
}

@media (max-width: 575px) {
    .skin_449 .number-picker__content {
        overflow-x: auto;
        justify-content: flex-start;
    }

    .skin_449 .number-picker.play .number-picker__content {
        flex-wrap: nowrap !important;
    }
}

.skin_449 .number-picker__content > div:last-child {
    margin-right: 0;
}

.skin_449 .number-picker__picker {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid var(--iq-orange);
}

@media (max-width: 575px) {
    .skin_449 .number-picker__picker {
        margin-right: 10px;
        flex: 0 0 281px;
    }
}

.skin_449 .number-picker__picker--bottom {
    background-color: transparent;
    border: none;
    padding: 0 20px 17px;
    -webkit-box-pack: start;
    justify-content: flex-end;
}

@media (max-width: 1199px) {
    .skin_449 .number-picker__picker--bottom {
        padding: 0 10px 13px;
    }
}

@media (max-width: 575px) {
    .skin_449 .number-picker__picker--bottom {
        padding: 0 13px 13px;
    }
}

.skin_449 .number-picker__picker--bottom a,
.skin_449 .number-picker__picker--bottom div {
    background-color: var(--iq-white);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid var(--iq-orange);
    margin-right: 7px;
}

.skin_449 .number-picker__picker--bottom a:nth-child(2) {
    margin-right: 0;
}

.skin_449 .number-picker__picker--name a.linecard-icon,
.skin_449 .number-picker__picker--name div.linecard-icon {
    background-color: var(--iq-white);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid var(--iq-orange);
    margin-right: 7px;
}

@media (max-width: 575px) {
    .skin_449 .number-picker__picker--name a.linecard-icon,
    .skin_449 .number-picker__picker--name div.linecard-icon,
    .skin_449 .number-picker__picker--bottom a,
    .skin_449 .number-picker__picker--bottom div {
        width: 24px;
        height: 24px;
    }
}

.skin_449 .number-picker__picker--name a:nth-child(2) {
    margin-right: 0;
}

.skin_449 .number-picker__picker--divider {
    width: 1px;
    background-color: #27a9ff;
    margin: 0 27px;
}

.skin_449 .number-picker__picker--name {
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 10px 0 10px 18px;
    background-color: var(--iq-orange);
    border: 2px solid var(--iq-orange);
    width: 100%;
    display: flex;
    align-items: center;
}

@media (max-width: 575px) {
    .skin_449 .number-picker__picker--name {
        padding: 5px 0 5px 10px;
        width: 100%;
    }
}

.skin_449 .number-picker__picker--name p {
    text-transform: unset;
    font-size: 22px;
    line-height: 22px;
    color: var(--iq-white);
}

.skin_449 .number-picker__picker--name span {
    font-size: 22px;
    line-height: 22px;
    color: var(--iq-white);
}

@media (max-width: 1200px) {
    .skin_449 .number-picker__picker--name p,
    .skin_449 .number-picker__picker--name span {
        font-size: 18px !important;
    }

    .skin_449.page-rtl .number-picker__picker--name {
        padding: 15px 12px;
    }
}

@media (max-width: 575px) {
    .skin_449 .number-picker__picker--name p,
    .skin_449 .number-picker__picker--name span {
        font-size: 16px !important;
    }
}

.skin_449 .number-picker__picker--randomize img {
    width: 15px;
}

.skin_449 .number-picker__picker--delete img {
    width: 10px !important;
}

.skin_449 .number-picker__picker--favourite-numbers img,
.skin_449 .number-picker__picker--favourites img {
    width: 13px !important;
    height: 13px !important;
}

.skin_449 .number-picker__picker--disabled {
    z-index: 1;
    opacity: 0.98;
    display: none;
    position: absolute;
    width: 102%;
    height: 102%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: hsla(0, 0%, 98.8%, 0.8);

    left: -2px;
    top: -2px;
    cursor: pointer;
}

.skin_449 .number-picker__picker--disabled p,
.skin_449 .number-picker__picker--disabled p span {
    color: var(--iq-brown);
    font-size: 14px;
    max-width: 130px;
    text-align: center;
    font-weight: 600;
    /* font-family: Product-Sans-Bold !important; */
}

.skin_449 .number-picker__picker.pickerFull .number-picker__picker--name {
    background-color: var(--iq-orange);
}

.skin_449 .number-picker__picker.pickerFull .number-picker__picker--bottom {
    background-color: transparent;
    border: none;
}

.skin_449 .number-picker__picker.disabled {
    position: relative;
}

@media (max-width: 575px) {
    .skin_449 .number-picker__picker.disabled {
        display: block;
    }
}

.skin_449 .number-picker__picker.disabled .number-picker__picker--disabled {
    display: -webkit-box;
    display: flex;
}

.skin_449 .number-picker__picker.disabled .number-picker__picker--name {
    background-color: var(--iq-orange);
    border: none;
}

.skin_449 .number-picker__top {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 18px 10px;
    border: 1px solid var(--iq-orange);
    border-radius: 10px;
    margin-bottom: 15px;
}

.skin_449 .number-picker__top--text {
    display: -webkit-box;
    display: flex;
    position: relative;
}

.skin_449 .number-picker__top--text_div {
    color: var(--iq-brown);
    margin-top: 0px;
    font-size: 18px;
    margin-bottom: 0;
}

.skin_449 .number-picker__top--textFont45 {
    font-size: 45px;
}

.skin_449 .number-picker__top--textFont36 {
    font-size: 368px;
}

@media (min-width: 576px) {
    .skin_449 .number-picker__top--text {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .skin_449 .number-picker__top--text {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: row;
        width: 100%;
    }

    .skin_449 .number-picker__top--text h5 {
        font-size: 11px !important;
        margin-bottom: 0;
    }

    .skin_449 .number-picker__top {
        padding: 10px 10px 0 10px;
    }
}

@media (max-width: 400px) {
    .skin_449 .number-picker__top {
        padding: 10px 10px 5px;
    }
}

.skin_449 .number-picker__top--text h1 {
    font-size: 45px;
    max-width: 355px;
    text-transform: uppercase;
    color: var(--iq-pink);
    line-height: 41px;
    margin-bottom: 0;
}

.skin_449 .number-picker__top--text h2 {
    font-size: 17px;
    text-transform: uppercase;
    color: var(--iq-orange);
    color: var(--iq-black);
}

@media (max-width: 1199px) {
    .skin_449 .number-picker__top--text h2 {
        font-size: 21px;
    }
}

@media (max-width: 575px) {
    .skin_449 .number-picker__top--text h2 {
        font-size: 17px;
        line-height: 27px;
        margin-bottom: 0;
        max-width: 100%;
    }
}

@media (max-width: 415px) {
    .skin_449 .number-picker__top--text h2 {
        margin-bottom: 0;
    }
}

@media (max-width: 385px) {
    .skin_449 .number-picker__top--text h2 {
        font-size: 13px;
        line-height: 30px;
    }
}

.skin_449 .number-picker__top--text h1 span {
    color: var(--iq-pink);
    font-size: 45px;
}

.skin_449 .number-picker__top--text h2 span {
    color: var(--iq-pink);
    font-size: 45px;
}

.skin_449 .number-picker__top--text h2 span.number-picker__currency-wrapper {
    font-size: 35px;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: start;
    align-items: flex-start;
    margin-left: 5px;
    color: #7ad1ff;
}

@media (max-width: 1199px) {
    .skin_449
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper {
        font-size: 21px;
    }
}

@media (max-width: 575px) {
    .skin_449
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper {
        font-size: 35px;
    }
}

@media (max-width: 415px) {
    .skin_449
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper {
        font-size: 30px;
    }
}

@media (max-width: 385px) {
    .skin_449
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper {
        font-size: 27px;
    }
}

.skin_449 .number-picker__top--text h2 span span {
    font-size: 70px;
    position: absolute;
    top: -26px;
    right: -65px;
    color: #7ad1ff;
    position: unset;
    line-height: 43px;
    margin-left: 5px;
}

@media (max-width: 1199px) {
    .skin_449 .number-picker__top--text h2 span span {
        font-size: 40px;
        top: -14px;
        right: -37px;
    }
}

@media (max-width: 575px) {
    .skin_449 .number-picker__top--text h2 span span {
        font-size: 70px;
        position: unset;
        top: -40px;
        right: -65px;
        color: #94d4ff;
        line-height: 10px;
    }
}

@media (max-width: 415px) {
    .skin_449 .number-picker__top--text h2 span span {
        font-size: 60px;
        top: -35px;
        right: -55px;
    }
}

@media (max-width: 385px) {
    .skin_449 .number-picker__top--text h2 span span {
        font-size: 55px;
        top: -32px;
        right: -55px;
    }
}

.skin_449 .number-picker__top--img {
    width: 140px;
    height: 140px;
    position: absolute;
    right: 235px;
    bottom: -19px;
}

@media (min-width: 1200px) {
    .skin_449 .number-picker__top--img {
        width: 115px;
        height: 135px;
        right: 220px;
    }
}

@media (max-width: 1199px) {
    .skin_449 .number-picker__top--img {
        width: 113px;
        right: 210px;
        height: 138px;
    }
}

@media (max-width: 992px) {
    .skin_449 .number-picker__top--img {
        height: 127px;
        bottom: -18px;
    }
}

@media (max-width: 767px) {
    .skin_449 .number-picker__top--img {
        width: 115px;
        right: 190px;
    }
}

@media (max-width: 575px) {
    .skin_449 .number-picker__top--img {
        width: 78px;
        height: 98px;
        position: static;
        margin-top: -22px;
    }
}

@media (max-width: 400px) {
    .skin_449 .number-picker__top--img {
        display: none;
    }
}

.hp-navigation__box--wrapper .terms {
    font-size: 10px;
    margin-top: 2px;
}

.skin_449 .number-picker__top--img img {
    width: 100%;
    max-width: 100%;
}

.skin_449 .number-picker--btn {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.skin_449 .number-picker--btn button {
    text-transform: uppercase;
    font-size: 22px;
    width: 100%;
    max-width: 100%;
    height: 60px;
    background-color: var(--iq-yellow);
    border: none;
    border-radius: 15px;
    padding: 10px 15px;
    color: var(--iq-black);
    margin-top: 20px;
}

.skin_449 .number-picker--btn button:disabled {
    background-color: var(--iq-yellow);
    opacity: 0.5;
    color: var(--iq-black);
}

@media (max-width: 1199px) {
    .skin_449 .number-picker--btn button {
        font-size: 20px;
    }
}

@media (max-width: 576px) {
    .skin_449 .number-picker--btn button {
        width: 100%;
        height: 35px;
        font-size: 14px;
        margin-top: 12px;
        border-radius: 8px;
    }
}

.skin_449 .number-picker.homepage .number-picker__numbers {
    padding: 20px 18px 15px;
}

@media (max-width: 1220px) {
    .skin_449 .number-picker.homepage .number-picker__numbers {
        padding: 20px 7px 15px;
    }
}

@media (max-width: 992px) {
    .skin_449 .number-picker.homepage .number-picker__numbers {
        padding: 20px 15px 15px;
    }
}

@media (max-width: 575px) {
    .skin_449 .number-picker .number-picker__numbers {
        width: 100%;
    }

    .skin_449 .number-picker.homepage .number-picker__numbers {
        padding: 20px 10px 15px;
    }
}

.skin_449 .number-picker.play .number-picker__top {
    padding: 0 10px;
}

@media (max-width: 1199px) {
    .skin_449 .number-picker.play .number-picker__top {
        padding: 0px 10px;
    }
}

@media (max-width: 991px) {
    .skin_449 .number-picker.play .number-picker__top {
        margin-right: 0;
    }
}

@media (max-width: 479px) {
    .skin_449 .number-picker.play .number-picker__top {
        padding: 10px;
    }
}

.skin_449 .number-picker.play .number-picker__top--text {
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 575px) {
    .skin_449 .number-picker.play .number-picker__top--text {
        flex-direction: row;
    }
}

@media (max-width: 479px) {
    .skin_449 .number-picker.play .number-picker__top--img {
        right: 12px;
    }
}

@media (max-width: 479px) {
    .skin_449 .number-picker.play .number-picker__bottle {
        position: unset;
        right: unset;
        bottom: unset;
    }
}

.skin_449 .number-picker.play .number-picker__picker {
    margin: 0 14px 14px 0;
    width: 298px;
}

@media (max-width: 575px) {
    .skin_449 .number-picker.play .number-picker__picker {
        width: 100%;
    }
}

@media (min-width: 1199px) {
    .skin_449 .number-picker.play .number-picker__picker:nth-child(4n) {
        margin-right: 0;
    }
}

.skin_449 .number-picker.play .number-picker__picker--name {
    width: 100%;
    padding: 15px 0 10px 10px;
}

.skin_449 .number-picker.play .number-picker__picker--name p,
.skin_449 .number-picker.play .number-picker__picker--name span {
    font-size: 26px;
    line-height: 20px;
}

.skin_449 .number-picker.play .number-picker__picker--bottom {
    padding: 0 10px 10px;
}

.homepage .number-picker__picker--bottom {
    padding-bottom: 0;
}

.skin_449 .number-picker.play .number-picker__picker--bottom a,
.skin_449 .number-picker.play .number-picker__picker--bottom div,
.skin_449 .number-picker.play .number-picker__picker--name div {
    width: 32px;
    height: 32px;
}

.skin_449 .number-picker.play .number-picker__picker--divider {
    margin: 0 23px;
}

.skin_449 .number-picker.play .number-picker__content {
    flex-wrap: wrap;
}

@media (min-width: 480px) {
    .skin_449 .number-picker.play .number-picker__content {
        margin-bottom: 26px;
    }
}

.skin_449 .number-picker.play .number-picker__numbers {
    padding: 20px 10px 15px;
}

.skin_449 .number-picker__select .selectize .selectize-input {
    border-radius: 10px;
    border-color: var(--iq-darkgrey);
    padding: 10px 20px;
}

.skin_449 .number-picker__select .selectize .selectize-input:after {
    right: 40px !important;
}

.skin_449 .number-picker__buttons span {
    padding: 5px 20px;
    text-transform: uppercase;
    border-radius: 5px;
}

.skin_449 .number-picker__buttons span:first-child {
    background-color: var(--iq-orange);
    color: var(--iq-white);
}

@media (max-width: 991px) {
    .skin_449 .number-picker__buttons span:first-child {
        -webkit-box-flex: 0;
    }
}

@media (max-width: 767px) {
    .skin_449 .number-picker__buttons span:first-child {
        -webkit-box-flex: 0;
        flex: 0 0 49%;
        width: 49%;
    }
}
@media (max-width: 575px) {
    .skin_449 .number-picker__buttons span:first-child,
    .skin_449 .number-picker__buttons span:nth-child(2) {
        margin: 0 0 10px 0;
    }
}

.skin_449 .number-picker__buttons span:nth-child(2) {
    background-color: var(--iq-orange);
    color: var(--iq-white);
}

@media (max-width: 991px) {
    .skin_449 .number-picker__buttons span:nth-child(2) {
        -webkit-box-flex: 0;
    }
}

@media (max-width: 767px) {
    .skin_449 .number-picker__buttons span:nth-child(2) {
        -webkit-box-flex: 0;
        flex: 0 0 49%;
        width: 49%;
        margin-right: 0;
    }
}

.skin_449 .number-picker__buttons span:nth-child(2) img {
    width: 8px;
}

.skin_449 .number-picker__buttons span:nth-child(3) {
    background-color: #dedede;
    color: var(--iq-black);
}

@media (max-width: 991px) {
    .skin_449 .number-picker__buttons span:nth-child(3) {
        -webkit-box-flex: 0;
        flex: 0 0 31%;
        width: 31%;
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .skin_449 .number-picker__buttons span:nth-child(3) {
        -webkit-box-flex: 0;
        flex: 0 0 49%;
        width: 49%;
        margin-right: 8px;
        margin-bottom: 0;
    }
}

.skin_449 .number-picker__buttons button {
    padding: 4px 20px;
    text-transform: uppercase;
    border-radius: 5px;
    color: var(--iq-black);
}

.skin_449 .number-picker__buttons button.disabled {
    padding: 7px 20px;
}

.skin_449 .number-picker .blue-box {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
    border-radius: 0;
}

@media (max-width: 479px) {
    .skin_449 .number-picker .blue-box {
        padding: 0;
        text-align: center;
        margin-bottom: 20px;
    }
}

.skin_449 .number-picker .blue-box p {
    color: var(--iq-brown);
    font-size: 14px;
}

@media (max-width: 479px) {
    .skin_449 .number-picker .blue-box p {
        font-size: 12px;
    }
}

.skin_449
    input:not([type="checkbox"]):not([type="button"]):not([type="submit"]):not(
        [type="file"]
    ):not([type="radio"]) {
    text-transform: inherit;
    color: var(--iq-black);
}

.skin_449 .countdown.hp.date p,
.skin_449 .countdown.hp.date p span {
    text-transform: uppercase;
    text-align: center;
    color: var(--iq-black);
    font-size: 20px;
}

.countdown.hp.date {
    min-height: 30px;
}

@media (max-width: 576px) {
    .skin_449 .countdown.hp.date p,
    .skin_449 .countdown.hp.date span {
        font-size: 16px !important;
    }

    .countdown.hp.date {
        min-height: 20px;
    }

    .col-lg-12 > .bg-white {
        padding: 8px 0 !important;
        border-radius: 10px 10px 0 0 !important;
    }
}

.skin_449 .countdown .base-timer {
    width: 80px;
    height: 80px;
    background-color: var(--iq-orange);
    border-radius: 50%;
    border: 1px solid white;
}

@media (max-width: 1199px) {
    .skin_449 .countdown .base-timer {
        width: 65px;
        height: 65px;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown .base-timer {
        width: 60px;
        height: 60px;
    }
}

.skin_449 .countdown .base-timer__path-color {
    color: var(--iq-white);
}

.skin_449 .countdown .base-timer__path-remaining {
    stroke-width: 10px;
}

.skin_449 .countdown .base-timer__path-elapsed {
    stroke: #0078f5;
}

.skin_449 .countdown__timer {
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 10px;
}

@media (max-width: 1199px) {
    .skin_449 .countdown__timer {
        margin: 0;
    }
}

.skin_449 .countdown__timer--wrapper {
    display: -webkit-box;
    display: flex;
}

.skin_449 .countdown__timer--wrapper > div:not(:last-child) {
    margin-right: 15px;
}

@media (max-width: 575px) {
    .skin_449 .countdown__timer--wrapper > div:not(:last-child) {
        margin-right: 20px;
    }
}

.skin_449 .countdown__timer--text p {
    font-size: 19px;
    max-width: 100px;
}

@media (max-width: 479px) {
    .skin_449 .countdown__timer--text p {
        font-size: 17px;
    }
}

@media (max-width: 370px) {
    .skin_449 .countdown__timer--text p {
        font-size: 11px;
        max-width: 60px;
    }
}

.skin_449 .countdown__timer.desktop {
    width: 100%;
    max-width: 100%;
}

.skin_449 .countdown__timer.desktop h2 {
    color: var(--iq-white);
    text-transform: uppercase;
}

@media (max-width: 575px) {
    .skin_449 .countdown__timer.desktop h2 {
        margin-top: 16px;
        color: #0078f5;
        font-size: 17px;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown__timer.desktop .desktop {
        display: none;
    }
}

.skin_449 .countdown__timer.desktop .mobile {
    color: var(--iq-yellow);
    text-transform: uppercase;
    display: none;
}

@media (max-width: 575px) {
    .skin_449 .countdown__timer.desktop .mobile {
        display: block;
        margin-top: 0;
        color: var(--iq-white);
        font-size: 15px;
    }
    .skin_449 .countdown__timer.desktop .mobile span {
        font-size: 15px;
    }
}

.skin_449 .countdown__timer.desktop .mobile span {
    margin: 0 5px;
}

.skin_449 .countdown__timer.desktop .date {
    color: var(--iq-white);
    margin: 10px 0 0;
}

@media (max-width: 575px) {
    .skin_449 .countdown__timer.desktop .date {
        display: none;
    }
    .skin_449 .countdown__timer.desktop .mobile span {
        margin: 0 5px 0 0;
    }
}

@media (max-width: 1199px) {
    .skin_449 .countdown__timer.desktop {
        display: -webkit-box;
        display: flex;
        margin-top: 10px;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown__timer.desktop {
        margin-bottom: 10px;
    }
}

.skin_449 .countdown__timer--boxes-row {
    left: 0;
    position: relative;
    -webkit-transform: none;
    transform: none;
    height: 100%;
}

@media (max-width: 575px) {
    .skin_449 .countdown__timer--boxes-row {
        top: 0px;
    }
}

.skin_449 .countdown__timer--boxes-row p {
    font-size: 13px;
    color: var(--iq-white);
}

@media (max-width: 479px) {
    .skin_449 .countdown__timer--boxes-row p {
        font-size: 11px;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown__timer--boxes-row p {
        margin-top: 0;
        font-size: 12px;
    }
}

@media (max-width: 385px) {
    .skin_449 .countdown__timer--boxes-row p {
        font-size: 8px;
    }
}

.skin_449 .countdown__timer--valuewrapper {
    height: 52px;
}

@media (max-width: 1199px) {
    .skin_449 .countdown__timer--valuewrapper {
        height: 40px;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown__timer--valuewrapper {
        height: 35px;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown__timer--valuewrapper {
        height: 35px;
    }
}

@media (max-width: 385px) {
    .skin_449 .countdown__timer--valuewrapper {
        height: 22px;
    }
}

.skin_449 .countdown__timer span.countdown__timer--value {
    font-size: 38px;
    color: var(--iq-white);
}

@media (max-width: 1199px) {
    .skin_449 .countdown__timer span.countdown__timer--value {
        font-size: 28px;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown__timer span.countdown__timer--value {
        font-size: 35px;
    }
}

@media (max-width: 385px) {
    .skin_449 .countdown__timer span.countdown__timer--value {
        font-size: 20px;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown {
        margin-bottom: 10px;
    }
}

.skin_449 .countdown.play .countdown__timer_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
}
@media (max-width: 767px) {
    .skin_449 .countdown.play .countdown__timer_wrapper {
        flex-direction: column;
    }
}
@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__timer_wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
    }
}

.skin_449 .countdown.play .countdown__timer_wrapper img {
    max-width: 100%;
    height: 108px;
    margin-right: 10px;
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__timer_wrapper img {
        display: none;
    }
}

.skin_449 .countdown.play .countdown__pricebubble {
    background-color: var(--iq-white);
    min-width: 400px;
    height: 85px;
    border-radius: 60px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 1200px) {
    .skin_449 .countdown.play .countdown__pricebubble {
        min-width: 265px;
        height: 75px;
        margin-right: 0;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__pricebubble {
        min-width: 260px;
    }
}

.skin_449 .countdown.play .countdown__pricebubble_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 20px;
}

@media (max-width: 1200px) {
    .skin_449 .countdown.play .countdown__pricebubble_wrapper {
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__pricebubble_wrapper {
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.skin_449 .countdown.play .countdown__pricebubble--content {
    text-align: center;
}

.skin_449 .countdown.play .countdown__pricebubble--content h5 {
    margin-bottom: 0;
    color: var(--iq-pink) !important;
    font-size: 34px !important;
}

.skin_449 .countdown.play .countdown__pricebubble--content h3 {
    margin-bottom: 0;
    font-size: 66px;
    color: var(--iq-pink);
    line-height: 52px;
}

@media (max-width: 1200px) {
    .skin_449 .countdown.play .countdown__pricebubble--content h3 {
        font-size: 50px;
        line-height: 40px;
    }
    .skin_449 .countdown.play .countdown__pricebubble--content h5 {
        font-size: 28px !important;
    }
    .skin_449 .play .countdown__timer.desktop {
        margin-top: 0;
    }
}

@media (max-width: 992px) {
    .skin_449 .play .countdown__timer.desktop {
        margin-top: 10px;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__pricebubble--content h3 {
        font-size: 51px;
        line-height: 41px;
    }
}

.skin_449 .countdown.play .countdown__timer--priceproduct {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

.skin_449 .countdown.play .countdown__timer--priceproduct h2 {
    color: var(--iq-white);
    text-transform: uppercase;
    margin-bottom: 3px;
    line-height: 31px;
}

@media (max-width: 1200px) {
    .skin_449 .countdown.play .countdown__timer--priceproduct h2 {
        font-size: 23px;
        line-height: 25px;
        text-align: center;
        margin-top: 5px;
        max-width: 200px;
    }
}

@media (max-width: 1200px) and (max-width: 479px) {
    .skin_449 .countdown.play .countdown__timer--priceproduct h2 {
        font-size: 21px;
        line-height: 22px;
        margin-top: 12px;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__timer--priceproduct h2 {
        text-align: left;
        margin-left: 13px;
        font-size: 25px;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__timer--priceproduct .priceproduct1 {
        max-width: 90px;
    }
}

.skin_449 .countdown.play .countdown__timer--priceproduct p {
    color: #0078f5;
    font-size: 12px;
    text-transform: uppercase;
    max-width: 104px;
    line-height: 15px;
    position: relative;
    top: 14px;
}

@media (max-width: 1200px) {
    .skin_449 .countdown.play .countdown__timer--priceproduct p {
        font-size: 12px;
        max-width: 170px;
        text-align: center;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__timer--priceproduct p {
        top: 0;
        left: 10px;
        text-align: left;
    }
}

.skin_449 .countdown.play .countdown__inner {
    background: var(--iq-pink);
    padding: 17px 5px;
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .skin_449 .countdown.play .countdown__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        height: 100%;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__inner {
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__inner {
        padding: 10px 10px 20px 10px;
        border-radius: 15px;
    }
}

.skin_449 .countdown.play .countdown__inner_playbtn {
    display: none;
}

.skin_449 .countdown.play .countdown__inner_playbtn a {
    background: transparent;
    border: 1px solid var(--iq-orange);
    color: var(--iq-white);
    width: unset;
    height: unset;
    font-size: 27px;
    padding: 0 15px;
    text-transform: uppercase;
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 18px;
    padding: 10px 0px;
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__inner_playbtn a {
        font-size: 22px;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__inner_playbtn {
        display: block;
        width: 100%;
    }
}

.skin_449 .countdown.play .countdown__text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: start;
    align-items: flex-start;
    position: relative;
}

@media (max-width: 991px) {
    .skin_449 .countdown.play .countdown__text {
        align-self: unset;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.skin_449 .countdown.play .countdown__text--img {
    width: 160px;
}

@media (max-width: 1199px) {
    .skin_449 .countdown.play .countdown__text--img {
        width: 145px;
    }
}

@media (max-width: 991px) {
    .skin_449 .countdown.play .countdown__text--img {
        display: none;
    }
}

.skin_449 .countdown.play .countdown__text--img img {
    width: 100%;
    max-width: 100%;
}

.skin_449 .countdown.play .countdown__text--text {
    padding: 0;
    border-radius: 0;
    background: transparent;
    margin-left: 15px;
    margin-top: 20px;
}

@media (max-width: 1199px) {
    .skin_449 .countdown.play .countdown__text--text {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin-top: 0;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__text--text {
        margin-left: 0;
    }
}

.skin_449 .countdown.play .countdown__text--text > div:first-child {
    z-index: 1;
}

@media (max-width: 1199px) {
    .skin_449 .countdown.play .countdown__text--text > div:first-child {
        margin-bottom: 10px;
    }
}

.skin_449 .countdown.play .countdown__text--text > div:nth-child(2) {
    position: absolute;
    right: -240px;
    bottom: 0;
}

@media (max-width: 1199px) {
    .skin_449 .countdown.play .countdown__text--text > div:nth-child(2) {
        position: unset;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__text--text > div:nth-child(2) {
        margin-bottom: 5px;
    }
}

.skin_449
    .countdown.play
    .countdown__text--text
    > div:nth-child(2)
    .countdown__text--amount {
    background-color: #7bd1ff;
}

@media (max-width: 479px) {
    .skin_449
        .countdown.play
        .countdown__text--text
        > div:nth-child(2)
        .countdown__text--amount {
        margin-bottom: 10px;
    }
}

.skin_449 .countdown.play .countdown__text--info {
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__text--info {
        height: unset;
    }
}

.skin_449 .countdown.play .countdown__text--info > p {
    color: #0078f5;
    font-size: 27px;
    margin-bottom: 5px;
}

@media (max-width: 1199px) {
    .skin_449 .countdown.play .countdown__text--info > p {
        margin: 0;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__text--info > p {
        line-height: normal;
        margin-bottom: 5px;
    }
}

@media (max-width: 385px) {
    .skin_449 .countdown.play .countdown__text--info > p {
        font-size: 20px;
    }
}

.skin_449 .countdown.play .countdown__text--info > p:last-child {
    font-size: 14px;
}

.skin_449 .countdown.play .countdown__text--amount {
    background-color: var(--iq-white);
    padding: 15px 10px 10px;
    border-radius: 10px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 290px;
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__text--amount {
        padding: 10px 10px 7px;
    }
}

@media (max-width: 385px) {
    .skin_449 .countdown.play .countdown__text--amount {
        width: 232px;
        padding: 10px 10px 5px;
    }
}

.skin_449 .countdown.play .countdown__text--amount span {
    color: #0078f5;
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__text--amount span {
        margin-right: 5px;
    }
}

.skin_449 .countdown.play .countdown__text--amount p {
    color: #0078f5;
    font-size: 35px;
    height: 30px;
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__text--amount p {
        line-height: normal;
        -webkit-box-pack: center;
        justify-content: center;
        height: 40px;
    }
}

@media (max-width: 385px) {
    .skin_449 .countdown.play .countdown__text--amount p {
        font-size: 27px;
        height: 33px;
    }
}

.skin_449 .countdown.play .countdown__text--amount p span {
    font-size: 35px;
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__text--amount p span {
        line-height: normal;
    }
}

@media (max-width: 385px) {
    .skin_449 .countdown.play .countdown__text--amount p span {
        font-size: 27px;
    }
}

.skin_449
    .countdown.play
    .countdown__text.long-currency
    .countdown__text--amount
    p,
.skin_449
    .countdown.play
    .countdown__text.long-currency
    .countdown__text--amount
    span {
    font-size: 30px;
}

@media (max-width: 385px) {
    .skin_449
        .countdown.play
        .countdown__text.long-currency
        .countdown__text--amount
        p,
    .skin_449
        .countdown.play
        .countdown__text.long-currency
        .countdown__text--amount
        span {
        font-size: 23px;
    }
}

.skin_449 .countdown.play .countdown__timer {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__timer {
        width: 100%;
        max-width: 100%;
    }
}

.skin_449 .countdown.play .countdown__timer .base-timer_wrapper {
    display: -webkit-box;
    display: flex;
}

@media (min-width: 1200px) {
    .skin_449 .countdown.play .countdown__timer .base-timer {
        width: 75px;
        height: 75px;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__timer .countdown__timer--boxes-row {
        top: -3px;
    }
}

@media (max-width: 380px) {
    .skin_449 .countdown.play .countdown__timer .countdown__timer--boxes-row {
        top: -2px;
    }
}

.skin_449 .countdown.play .countdown__timer .countdown__timer--boxes-row p {
    font-size: 17px;
    margin-top: 0;
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__timer .countdown__timer--boxes-row p {
        font-size: 13px;
        margin-top: 1px;
    }
}

@media (max-width: 385px) {
    .skin_449 .countdown.play .countdown__timer .countdown__timer--boxes-row p {
        font-size: 12px;
    }
}

.skin_449 .countdown.play .countdown__timer .countdown__timer--valuewrapper {
    height: 36px;
}

@media (max-width: 1199px) {
    .skin_449
        .countdown.play
        .countdown__timer
        .countdown__timer--valuewrapper {
        height: 30px;
    }
}

.skin_449 .countdown.play .countdown__timer .countdown__timer--value {
    font-size: 38px;
}

@media (max-width: 1199px) {
    .skin_449 .countdown.play .countdown__timer .countdown__timer--value {
        font-size: 30px;
    }
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__timer .countdown__timer--value {
        font-size: 26px;
    }
}

.skin_449 .countdown.play .countdown__right {
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: flex;
}

@media (max-width: 991px) {
    .skin_449 .countdown.play .countdown__right {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin-bottom: 10px;
    }
}

.skin_449 .countdown.play .countdown__right--date {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    top: -8px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 46%;
}

@media (max-width: 991px) {
    .skin_449 .countdown.play .countdown__right--date {
        position: unset;
        -webkit-transform: unset;
        transform: unset;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__right--date {
        margin-bottom: 20px;
    }
}

.skin_449 .countdown.play .countdown__right--date h2 {
    font-size: 40px;
    color: var(--iq-yellow);
    text-transform: uppercase;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-bottom: 0;
}

@media (max-width: 575px) {
    .skin_449 .countdown.play .countdown__right--date h2 {
        color: #27a9ff;
    }
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__right--date h2 {
        font-size: 30px;
    }
}

.skin_449 .countdown.play .countdown__right--date h2 span {
    font-size: 27px;
    margin-left: 10px;
    margin-top: 18px;
}

@media (max-width: 479px) {
    .skin_449 .countdown.play .countdown__right--date h2 span {
        font-size: 17px;
        margin-top: 10px;
    }
}

.skin_449 .countdown.homepage {
    margin: 20px 0px;
}

@media (max-width: 575px) {
    .skin_449 .countdown.homepage .base-timer {
        width: 56px;
        height: 56px;
    }

    .skin_449 .countdown.homepage .countdown__timer {
        margin: 0;
    }

    .skin_449 .countdown.homepage {
        margin: 10px 0 15px;
    }
}

.skin_449 .countdown.homepage .countdown__timer--boxes-row {
    position: relative;
    left: 0;
    -webkit-transform: none;
    transform: none;
    height: 100%;
}

@media (max-width: 575px) {
    .skin_449 .countdown.homepage .countdown__timer--boxes-row p {
        font-size: 10px;
    }

    .skin_449 .countdown.homepage .countdown__timer--valuewrapper {
        height: auto;
    }

    .skin_449 .countdown.homepage .countdown__timer--value {
        font-size: 18px;
        line-height: 18px;
    }
}

.skin_449 .shopping-cart__content .cart__top--title:nth-child(2) {
    width: 60%;
}

.skin_449 .shopping-cart__content .cart__details {
    width: 10%;
}

.skin_449 .shopping-cart__content .cart__details--ticket-wrapper {
    width: 186px;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__details {
        width: 100%;
        max-width: 100%;
    }

    .skin_449 .shopping-cart__content .cart__details--ticket-wrapper {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
}

.skin_449
    .shopping-cart__content
    .cart__details--ticket-wrapper
    .cart__details--ticket {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 991px) {
    .skin_449
        .shopping-cart__content
        .cart__details--ticket-wrapper
        .cart__details--ticket {
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__details--wrapper {
        width: 100%;
        max-width: 100%;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__details--img {
        width: 100px;
        height: 100px;
        margin-right: 0;
    }
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__details--img img {
        width: 40px;
    }
}

.skin_449 .shopping-cart__content .cart__numbers {
    width: 84%;
    display: -webkit-box;
    display: flex;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__numbers {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }
}

.skin_449 .shopping-cart__content .cart__numbers--number-row {
    padding-right: 0;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__numbers--row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-pack: center;
        justify-content: center;
    }
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__numbers--heading {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.skin_449 .shopping-cart__content .cart__edit {
    width: 16%;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__edit {
        width: 100%;
        max-width: 100%;
        display: none;
    }
}

.skin_449 .shopping-cart__content .cart__edit--empty {
    width: 15px;
    height: 15px;
    padding: 1px 6px;
}

.skin_449 .shopping-cart__content .cart__edit.mobile {
    display: none;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__edit.mobile {
        display: -webkit-box;
        display: flex;
    }
}

.skin_449 .shopping-cart__content .cart__selectboxes {
    margin-top: 0;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__selectboxes {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 575px) {
    .skin_449 .shopping-cart__content .cart__selectboxes--select:last-child {
        padding-bottom: 30px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--iq-dullgrey);
    }
}

.skin_449 .shopping-cart__content .cart__content--wrapper {
    width: 90%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__content--wrapper {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 991px) {
    .skin_449
        .shopping-cart__content
        .cart__content--wrapper
        .cart__content--row:not(:last-child) {
        margin-bottom: 20px;
    }
}

@media (max-width: 991px) {
    .skin_449
        .shopping-cart__content
        .cart__content--wrapper
        .cart__content--row:first-child {
        padding-bottom: 0;
    }
}

.skin_449
    .shopping-cart__content
    .cart__content--wrapper
    .cart__content--row:first-child:after {
    content: "";
    display: block;
    width: 73%;
    height: 1px;
    background-color: var(--iq-dullgrey);
    position: absolute;
    bottom: 0;
}

@media (max-width: 991px) {
    .skin_449
        .shopping-cart__content
        .cart__content--wrapper
        .cart__content--row:first-child:after {
        display: none;
    }
}

.skin_449 .shopping-cart__content .cart__content--row {
    display: -webkit-box;
    display: flex;
    width: 100%;
    max-width: 100%;
    position: relative;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__content--row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 991px) {
    .skin_449
        .shopping-cart__content
        .cart__content--friday
        .cart__numbers--heading {
        display: none;
    }
}

.skin_449 .shopping-cart__content .cart__purchase {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 73%;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__purchase {
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        padding: 0 18px;
        position: relative;
    }
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__purchase--check {
        position: absolute;
        top: 0;
        right: 18px;
    }
}

.skin_449 .shopping-cart__content .cart__purchase--title {
    position: relative;
    display: -webkit-box;
    display: flex;
}

@media (max-width: 991px) {
    .skin_449 .shopping-cart__content .cart__purchase--title {
        width: 100%;
        -webkit-box-pack: start;
        justify-content: flex-start;
        height: 28px;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 15px;
    }
}

.skin_449 .shopping-cart__content .cart__purchase--title h3 {
    color: #797979;
    margin-bottom: 0;
}

.skin_449 .shopping-cart__content .cart__purchase--title span {
    color: #797979;
    margin-bottom: 0;
    width: 15px;
    height: 15px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid #797979;
    border-radius: 50%;
    font-size: 11px;
    padding-top: 4px;
    margin-left: 5px;
    cursor: pointer;
}

.skin_449
    .shopping-cart__content
    .cart__purchase--title
    span:hover
    + .cart__purchase--titlehover {
    display: block;
}

.skin_449 .shopping-cart__content .cart__purchase--titlehover {
    position: absolute;
    background-color: var(--iq-white);
    width: 310px;
    text-align: center;
    z-index: 2;
    border: 1px solid #797979;
    border-radius: 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    top: 22px;
    left: -144px;
    display: none;
}

.skin_449 .shopping-cart__content .cart__purchase--titlehover p {
    color: #797979;
    line-height: 20px;
    padding: 10px 0;
}

.skin_449
    .recurring-purchase__content
    form#form_recurring-purchase
    .recurring-purchase__purchase--id {
    width: unset;
}

.skin_449
    .recurring-purchase__content
    form#form_recurring-purchase
    .recurring-purchase__purchase--id
    p {
    margin-bottom: 15px;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__content
        form#form_recurring-purchase
        .recurring-purchase__purchase--id
        p {
        margin-bottom: 0;
    }
}

.skin_449
    .recurring-purchase__content
    form#form_recurring-purchase
    .recurring-purchase__purchase--numberinfo {
    width: unset;
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchases--wrapper {
        border-bottom: none;
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchases--wrapper > div:not(:last-child) {
        margin-bottom: 10px;
    }
}

.skin_449 .recurring-purchase__purchase {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        border-radius: 15px;
        border-bottom: 1px solid var(--iq-dullgrey);
        padding: 15px;
    }
}

.skin_449 .recurring-purchase__purchase--mobile {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (min-width: 576px) {
    .skin_449 .recurring-purchase__purchase--mobile {
        display: none;
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--mobile {
        border-bottom: 1px solid var(--iq-dullgrey);
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--mobile > div:first-child {
        margin-left: 15px;
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--mobile > div:last-child {
        margin-right: 15px;
    }
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase--mobile
        .recurring-purchase__purchase--id {
        display: block;
    }
}

.skin_449
    .recurring-purchase__purchase--mobile
    .recurring-purchase__purchase--id
    p {
    margin: 0;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase--mobile
        .recurring-purchase__purchase--delete {
        display: block;
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--id {
        display: none;
    }
}

.skin_449 .recurring-purchase__purchase--numberinfo {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.skin_449 .recurring-purchase__purchase--numberinfo-section {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 20px;
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--numberinfo-section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.skin_449 .recurring-purchase__purchase--numberinfo-section > div:first-child {
    margin-right: 50px;
    position: relative;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase--numberinfo-section
        > div:first-child {
        margin-right: 0;
    }
}

.skin_449
    .recurring-purchase__purchase--numberinfo-section
    > div:first-child:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: var(--iq-dullgrey);
    display: block;
    position: absolute;
    right: -25px;
    top: 0;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase--numberinfo-section
        > div:first-child:after {
        position: unset;
        right: unset;
        top: unset;
        width: 100%;
        height: 1px;
        margin: 20px 0;
    }
}

.skin_449 .recurring-purchase__purchase--delete {
    width: unset;
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--delete {
        display: none;
    }
}

.skin_449 .recurring-purchase__purchase--delete a {
    margin-bottom: 20px;
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--delete a {
        margin-bottom: 0;
    }
}

.skin_449 .recurring-purchase__purchase--numbers:not(:last-child) {
    margin-bottom: 7px;
}

.skin_449 .recurring-purchase__purchase--numbers div {
    margin-bottom: 0;
}

.skin_449 .recurring-purchase__purchase--numbers div:last-child {
    margin-right: 0;
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--numbers-wrapper {
        margin-bottom: 0;
    }
}

.skin_449 .recurring-purchase__purchase--numbers-wrapper > p {
    color: var(--iq-black);
    margin-bottom: 5px;
}

@media (min-width: 576px) {
    .skin_449 .recurring-purchase__purchase--numbers-wrapper > p {
        text-align: center;
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--numbers-wrapper > div,
    .skin_449 .recurring-purchase__purchase--numbers-wrapper > p {
        margin-left: 15px;
    }
}

.skin_449 .recurring-purchase__purchase--info {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    min-width: unset;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--info {
        margin: 0 15px;
    }
}

@media (max-width: 405px) {
    .skin_449 .recurring-purchase__purchase--info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.skin_449 .recurring-purchase__purchase--info p:first-child {
    margin-bottom: 0;
    display: -webkit-box;
    display: flex;
}

.skin_449 .recurring-purchase__purchase--info p:first-child:after {
    content: "";
    display: block;
    width: 1px;
    background-color: var(--iq-black);
    height: 15px;
    margin: 3px 10px 0 10px;
}

@media (max-width: 405px) {
    .skin_449 .recurring-purchase__purchase--info p:first-child:after {
        display: none;
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--info p {
        font-size: 12px;
    }
}

@media (max-width: 405px) {
    .skin_449 .recurring-purchase__purchase--info p {
        font-size: 14px;
    }
}

.skin_449 .recurring-purchase__purchase--info p:not(:first-child) {
    font-size: 17px;
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase--info p:not(:first-child) {
        font-size: 12px;
    }
}

@media (max-width: 405px) {
    .skin_449 .recurring-purchase__purchase--info p:not(:first-child) {
        font-size: 14px;
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase-bottom {
        padding: 0;
        border: none;
        background-color: var(--iq-white);
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase-bottom-wrapper {
        padding: 0;
    }
}

@media (max-width: 575px) {
    .skin_449 .recurring-purchase__purchase-bottom .recurring-purchase__top {
        display: none;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__top
    > div:first-child {
    width: 8%;
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__top
    > div:nth-child(2) {
    width: 32%;
}

.skin_449 .recurring-purchase__purchase-bottom .recurring-purchase__purchase {
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase {
        border: 1px solid var(--iq-dullgrey);
        padding: 15px;
        background-color: #f7f7f7;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase
    > div:first-child {
    width: unset;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:first-child {
        display: -webkit-box;
        display: flex;
        width: 100%;
        max-width: 100%;
        -webkit-box-align: center;
        align-items: center;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase
    > div:nth-child(2) {
    width: 10%;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:nth-child(2) {
        display: none;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase
    > div:nth-child(3) {
    width: 66%;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:nth-child(3) {
        width: 50%;
        width: 100%;
        max-width: 100%;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase
    > div:nth-child(4) {
    width: 9%;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:last-child,
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase
        > div:nth-child(4) {
        display: none;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--numbers-wrapper {
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (min-width: 576px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--numbers-wrapper
        p {
        display: none;
    }
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--numbers-row {
        margin-bottom: 20px;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--numbers-row
    > div:first-child {
    margin-bottom: 7px;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--numbers-row
        > div:first-child {
        margin-bottom: 0;
    }
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--numbers-row
        > div:first-child:after {
        content: "";
        display: block;
        background-color: var(--iq-dullgrey);
        width: 100%;
        height: 1px;
        margin: 20px 0;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--row {
    -webkit-box-align: start;
    align-items: flex-start;
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--row
    > div:last-child {
    width: 55%;
    display: -webkit-box;
    display: flex;
    justify-content: space-around;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--row
        > div:last-child {
        width: 100%;
        max-width: 100%;
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item-wrapper {
        margin: 0 15px;
    }
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item-wrapper
        > div {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item-wrapper
        > div
        p {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item-wrapper
        > div:not(:last-child):after {
        content: "";
        width: 1px;
        background-color: var(--iq-black);
        display: block;
        height: 20px;
        margin: 0 15px;
    }
}

@media (max-width: 575px) and (max-width: 350px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item-wrapper
        > div:not(:last-child):after {
        height: 15px;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--item
    p {
    height: 31px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: flex;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item
        p {
        height: unset;
        display: block;
    }
}

@media (max-width: 350px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item
        p {
        font-size: 11px;
    }
}

@media (max-width: 350px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item.recurring-purchase__purchase--status
        p {
        font-size: 14px;
    }
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--item.recurring-purchase__purchase--status
        p
        span {
        display: none;
    }
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--id {
        width: unset;
    }
}

.skin_449
    .recurring-purchase__purchase-bottom
    .recurring-purchase__purchase--id
    p {
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: left;
}

@media (max-width: 575px) {
    .skin_449
        .recurring-purchase__purchase-bottom
        .recurring-purchase__purchase--numbers:not(:last-child) {
        margin-bottom: 0;
    }
}

.skin_449 .modal--purchase-details-skin {
    padding: 25px 10px;
    max-width: 530px;
}

.skin_449 .modal--purchase-details-skin .modal__heading {
    margin-bottom: 10px;
    font-size: 17px;
}

@media (max-width: 479px) {
    .skin_449 .modal--purchase-details-skin .modal__heading {
        font-size: 17px;
    }
}

.skin_449 .modal--purchase-details-skin .modal__purchase--row-wrapper {
    border: 1px solid var(--iq-pink);
    border-radius: 15px;
    margin: 0 15px 15px;
}

.skin_449 .modal--purchase-details-skin .modal__purchase--row {
    border-color: var(--iq-pink);
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .skin_449 .modal--purchase-details-skin .modal__purchase--row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.skin_449 .modal--purchase-details-skin .modal__purchase--text {
    width: 100%;
    max-width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    text-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

@media (max-width: 425px) {
    .skin_449 .modal--purchase-details-skin .modal__purchase--text {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.skin_449 .modal--purchase-details-skin .modal__purchase--numbers {
    -webkit-box-align: center;
    align-items: center;
}

.skin_449 .modal--purchase-details-skin .modal__purchase--numbers p {
    font-size: 17px;
}

@media (max-width: 479px) {
    .skin_449 .modal--purchase-details-skin .modal__purchase--numbers p {
        font-size: 13px;
    }
}

@media (max-width: 575px) {
    .skin_449
        .modal--purchase-details-skin
        .modal__purchase--numbers:first-child {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
    }
}

.skin_449 .modal--purchase-details-skin .modal__purchase--numbers:last-child {
    margin-right: 0;
}

.skin_449 .modal--purchase-details-skin .modal__purchase--text {
    padding: 10px 0;
}

.skin_449 .modal--purchase-details-skin .modal__purchase--text p {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 14px;
}

@media (max-width: 479px) {
    .skin_449 .modal--purchase-details-skin .modal__purchase--text p {
        font-size: 11px;
    }
}

.skin_449
    .modal--purchase-details-skin
    .modal__purchase--text
    p:first-child:after {
    content: "";
    display: block;
    width: 1px;
    background-color: var(--iq-black);
    height: 14px;
    margin: 0 10px 0 10px;
}

@media (max-width: 425px) {
    .skin_449
        .modal--purchase-details-skin
        .modal__purchase--text
        p:first-child:after {
        display: none;
    }
}

.skin_449 .modal--purchase-details-skin .modal__purchase--bottom {
    padding-top: 15px;
    border-top: 1px solid var(--iq-dullgrey);
}

.skin_449 .modal--purchase-details-skin .modal__purchase--bottom p {
    font-size: 17px;
}

@media (max-width: 479px) {
    .skin_449 .modal--purchase-details-skin .modal__purchase--bottom p {
        font-size: 13px;
        max-width: 245px;
    }
}

.skin_449 .modal--purchase-details-skin .modal__purchase--button {
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0 15px;
}

@media (max-width: 425px) {
    .skin_449 .modal--purchase-details-skin .modal__purchase--button {
        padding: 0;
    }
}

.skin_449 .modal--purchase-details-skin .modal__purchase--button a.btn,
.skin_449 .modal--purchase-details-skin .modal__purchase--button button.btn {
    width: 130px;
    height: 30px;
}

.skin_449 .modal--purchase-details .modal__purchase--bottom {
    padding-top: 15px;
    border-top: 1px solid var(--iq-dullgrey);
}

.skin_449 .modal--purchase-details .modal__purchase--bottom p {
    font-size: 17px;
}

@media (max-width: 479px) {
    .skin_449 .modal--purchase-details .modal__purchase--bottom p {
        font-size: 13px;
        max-width: 245px;
    }
}

.skin_449 .modal--purchase-details .modal__purchase--button {
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0 15px;
}

@media (max-width: 425px) {
    .skin_449 .modal--purchase-details .modal__purchase--button {
        padding: 0;
    }
}

.skin_449 .modal--purchase-details .modal__purchase--button a.btn,
.skin_449 .modal--purchase-details .modal__purchase--button button.btn {
    width: 130px;
    height: 30px;
}

.skin_449 .modal--numberpicker {
    border-width: 3px;
}

.skin_449 .modal--numberpicker .modal__selectboxes {
    display: -webkit-box;
    display: flex;
    margin-top: 10px;
}

@media (max-width: 575px) {
    .skin_449 .modal--numberpicker .modal__selectboxes {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.skin_449 .modal--numberpicker .modal__selectboxes--select {
    width: 190px;
}

@media (max-width: 575px) {
    .skin_449 .modal--numberpicker .modal__selectboxes--select {
        width: 100%;
        max-width: 100%;
    }
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select.error
    .selectize
    .selectize-input {
    border-color: var(--iq-darkred);
}

.skin_449 .modal--numberpicker .modal__selectboxes--select .selectize-control {
    height: 30px;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input {
    font-size: 12px !important;
    height: 30px;
    border: 1px solid var(--iq-pink);
    color: var(--iq-pink) !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.input-active {
    display: -webkit-box;
    display: flex;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input
    input::-webkit-input-placeholder {
    color: #00a9e0 !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input
    input::-moz-placeholder {
    color: #00a9e0 !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input
    input:-ms-input-placeholder {
    color: #00a9e0 !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input
    input::-ms-input-placeholder {
    color: #00a9e0 !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input
    input::placeholder {
    color: #00a9e0 !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input:after {
    line-height: 30px;
    color: var(--iq-pink);
    content: "\f0d7" !important;
    font-size: 22px;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.dropdown-active
    input {
    top: 0;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.dropdown-active:after {
    margin-top: -3px;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.disabled {
    border-color: var(--iq-darkgrey);
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.disabled
    input::-webkit-input-placeholder {
    color: var(--iq-darkgrey) !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.disabled
    input::-moz-placeholder {
    color: var(--iq-darkgrey) !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.disabled
    input:-ms-input-placeholder {
    color: var(--iq-darkgrey) !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.disabled
    input::-ms-input-placeholder {
    color: var(--iq-darkgrey) !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.disabled
    input::placeholder {
    color: var(--iq-darkgrey) !important;
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.disabled
    .item,
.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input.disabled:after {
    color: var(--iq-darkgrey);
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-dropdown {
    font-size: 12px !important;
    color: var(--iq-pink);
}

.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-control {
    height: 30px;
}

.skin_449 .modal--numberpicker .modal__selectboxes--select .selectize input,
.skin_449
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    input::-webkit-input-placeholder {
    font-size: 12px !important;
}

.skin_449 .modal--numberpicker .modal__selectboxes--select:not(:last-child) {
    margin-right: 5px;
}

@media (max-width: 575px) {
    .skin_449
        .modal--numberpicker
        .modal__selectboxes--select:not(:last-child) {
        margin-right: 0;
        margin-bottom: 5px;
    }
}

.skin_449 .modal--numberpicker-secondary .modal__selectboxes {
    margin-bottom: 10px;
}

.skin_449 .modal--numberpicker-secondary .modal__numberpicker--text {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.skin_449 .modal--numberpicker-secondary .modal__numberpicker--text p {
    color: #3f3f3f;
    margin-bottom: 20px;
    max-width: 465px;
}

@media (max-width: 479px) {
    .skin_449 .modal--numberpicker-secondary .modal__numberpicker--text p {
        max-width: 390px;
        font-size: 13px;
    }
}

.skin_449 .modal--numberpicker .number-picker__picker {
    width: 340px;
}

.skin_449 .modal--344 {
    border: 2px solid #2ba8dd;
    padding-top: 70px;
    border-radius: 15px;
}

.skin_449 .modal--344 .remodal-close {
    border-radius: 50%;
    background-color: #d2d5db;
    color: var(--iq-white);
    right: -22px;
    top: -22px;
    position: absolute;
}

@media (max-width: 575px) {
    .skin_449 .modal--344 .remodal-close {
        right: -10px;
        top: -18px;
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 575px) {
    .skin_449 .modal--344 .remodal-close i {
        font-size: 17px;
    }
}

.skin_449 .modal--344 .fa-times {
    color: var(--iq-white);
}

.skin_449 .modal--344 img {
    height: 130px;
    position: absolute;
    top: -66px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
}

.skin_449 .modal--344 .modal__main {
    color: #0076f2;
}

.skin_449 .modal--344 h2 {
    margin-bottom: 5px;
    font-size: 25px;
    margin-top: 14px;
    color: #0076f2;
}

.skin_449 .modal--344 .win-number {
    border-radius: 10px;
    font-size: 35px;

    padding-top: 5px;
}

@media (max-width: 575px) {
    .skin_449 .modal--344 .win-number {
        font-size: 27px;
    }
}

.skin_449 .modal--344 .y-bg {
    background-color: #ffed68;
}

.skin_449 .modal--344 .b-bg {
    background-color: #94d4ff;
}

.skin_449 .modal--344 p {
    color: #0076f2;
    font-size: 17px;
    margin: 13px 0 20px;
}

@media (max-width: 575px) {
    .skin_449 .modal--344 p {
        font-size: 14px;
        margin: 13px 0;
    }
}

.skin_449 .modal--344 .modal__footer {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 25px;
}

.skin_449 .modal--344 .modal__footer .btn {
    font-size: 20px;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 575px) {
    .skin_449 .modal--344 .modal__footer {
        position: unset;
        padding: 0;
        bottom: 20px;
    }

    .skin_449 .modal--344 .modal__footer p {
        margin: 0;
    }
}

.skin_449 .modal--344-secondary {
    padding-bottom: 20px;
}

.skin_449 .modal--344-secondary h2 {
    background-color: #0078f5;
    text-transform: uppercase;
    color: var(--iq-white);
    font-size: 32px;
    border-radius: 10px;
    padding: 11px 5px 5px;
    margin-bottom: 20px;
}

@media (max-width: 575px) {
    .skin_449 .modal--344-secondary h2 {
        font-size: 24px;
    }
}

@media (max-width: 375px) {
    .skin_449 .modal--344-secondary h2 {
        font-size: 18px;
    }
}

.skin_449 .modal--344-secondary p {
    margin-bottom: 8px;
}

.skin_449 .modal--344-secondary .modal__footer {
    position: unset;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 23px;
}

.skin_449 .modal--344-secondary .btn {
    background-color: #7bd1ff;
    color: #0078f5;
    width: 150px;
}

.skin_449 .modal--344-rp {
    border: 2px solid #7bd1ff;
    padding: 130px 30px 20px;
    max-width: 365px;
    border-radius: 10px;
}

.skin_449 .modal--344-rp .remodal-close {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #d2d5db;
    color: var(--iq-white);
    right: -18px;
    top: -18px;
    position: absolute;
}

@media (max-width: 575px) {
    .skin_449 .modal--344-rp .remodal-close {
        right: -10px;
        top: -18px;
        width: 35px;
        height: 35px;
    }
}

.skin_449 .modal--344-rp .remodal-close i {
    font-size: 17px;
}

.skin_449 .modal--344-rp .modal__stamp {
    position: absolute;
    top: -40px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
    width: 140px;
    height: 140px;
}

.skin_449 .modal--344-rp .modal__stamp svg {
    position: relative;
    z-index: 3;
    -webkit-transform: rotate(-155deg);
    transform: rotate(-155deg);
    overflow: unset;
}

.skin_449 .modal--344-rp .modal__stamp svg circle {
    stroke: #7bd1ff;
}

.skin_449 .modal--344-rp .modal__stamp img {
    height: 140px;
    max-width: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
    padding: 15px;
    background-color: var(--iq-white);
    z-index: 1;
}

.skin_449 .modal--344-rp .modal__stamp--wrapper {
    position: relative;
}

.skin_449 .modal--344-rp .modal__stamp--border {
    width: 140px;
    height: 140px;
    border-color: #0078f5 transparent transparent #0078f5;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 2;
    position: relative;
}

.skin_449 .modal--344-rp .modal__main--inner {
    display: -webkit-box;
    display: flex;
    margin-bottom: 20px;
}

.skin_449 .modal--344-rp .modal__main--inner img {
    max-width: 100px;
}

@media (max-width: 370px) {
    .skin_449 .modal--344-rp .modal__main--inner img {
        max-width: 80px;
    }
}

.skin_449 .modal--344-rp .modal__main--text {
    max-width: 187px;
}

.skin_449 .modal--344-rp .modal__main--text p {
    color: #0078f5;
}

@media (max-width: 370px) {
    .skin_449 .modal--344-rp .modal__main--text p {
        font-size: 13px;
    }
}

.skin_449 .modal--344-rp .modal__footer a {
    text-decoration: underline;
    color: #0078f5;
    font-size: 19px;
}

@media (max-width: 370px) {
    .skin_449 .modal--344-rp .modal__footer a {
        font-size: 17px;
    }
}

.skin_449 .modal--344-rp h1 {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    color: #0078f5;
    font-size: 22px;
    margin-bottom: 20px;
}

@media (max-width: 370px) {
    .skin_449 .modal--344-rp h1 {
        font-size: 17px;
    }
}

.skin_449 .modal--344-rp h1 span {
    color: var(--iq-white);
    background-color: #0078f5;
    padding: 6px 10px 2px;
    border-radius: 5px;
}

.skin_449 .modal--344-winner {
    padding: 0;
    border-radius: 15px;
    border: 2px solid #7bd1ff;
    max-width: 480px;
}

.skin_449 .modal--344-winner .remodal-close {
    border-radius: 50%;
    background-color: #d2d5db;
    color: var(--iq-white);
    right: -22px;
    top: -22px;
    position: absolute;
}

@media (max-width: 575px) {
    .skin_449 .modal--344-winner .remodal-close {
        right: -10px;
        top: -18px;
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 575px) {
    .skin_449 .modal--344-winner .remodal-close i {
        font-size: 17px;
    }
}

.skin_449 .modal--344-winner .modal__stamp {
    position: absolute;
    top: -40px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
    width: 140px;
    height: 140px;
}

.skin_449 .modal--344-winner .modal__stamp svg {
    position: relative;
    z-index: 3;
    -webkit-transform: rotate(-155deg);
    transform: rotate(-155deg);
    overflow: unset;
}

.skin_449 .modal--344-winner .modal__stamp svg circle {
    stroke: #7bd1ff;
}

.skin_449 .modal--344-winner .modal__stamp img {
    height: 140px;
    max-width: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
    padding: 15px;
    background-color: #ffed63;
    z-index: 1;
}

.skin_449 .modal--344-winner .modal__stamp--wrapper {
    position: relative;
}

.skin_449 .modal--344-winner .modal__stamp--border {
    width: 140px;
    height: 140px;
    border-color: #0078f5 transparent transparent #0078f5;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 2;
    position: relative;
}

.skin_449 .modal--344-winner .modal__main {
    padding-top: 100px;
    background-color: #ffed63;
    border-radius: 15px 15px 0 0;
}

.skin_449 .modal--344-winner .modal__main--inner {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 25px 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.skin_449 .modal--344-winner .modal__main--inner img {
    width: 129px;
    position: relative;
    z-index: 2;
    bottom: -10px;
}

@media (max-width: 479px) {
    .skin_449 .modal--344-winner .modal__main--inner img {
        width: 110px;
    }
}

@media (max-width: 355px) {
    .skin_449 .modal--344-winner .modal__main--inner img {
        width: 91px;
    }
}

.skin_449 .modal--344-winner .modal__main--inner h2 {
    margin-bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    font-size: 53px;
    color: #0078f5;
    text-align: left;
    margin-left: 5px;
    z-index: 2;
    position: relative;
}

@media (max-width: 479px) {
    .skin_449 .modal--344-winner .modal__main--inner h2 {
        font-size: 40px;
    }
}

@media (max-width: 400px) {
    .skin_449 .modal--344-winner .modal__main--inner h2 {
        font-size: 30px;
    }
}

@media (max-width: 355px) {
    .skin_449 .modal--344-winner .modal__main--inner h2 {
        font-size: 28px;
    }
}

.skin_449 .modal--344-winner .modal__main--inner h2 span {
    font-size: 100px;
    line-height: 30px;
    margin-left: 15px;
    color: #7bd1ff;
}

@media (max-width: 479px) {
    .skin_449 .modal--344-winner .modal__main--inner h2 span {
        font-size: 81px;
        margin-left: 10px;
    }
}

@media (max-width: 400px) {
    .skin_449 .modal--344-winner .modal__main--inner h2 span {
        font-size: 60px;
    }
}

@media (max-width: 355px) {
    .skin_449 .modal--344-winner .modal__main--inner h2 span {
        font-size: 55px;
    }
}

.skin_449 .modal--344-winner .modal__main--textwrapper {
    position: relative;
}

.skin_449 .modal--344-winner .modal__main--textwrapper img:first-child {
    width: 60px;
    position: absolute;
    left: -20px;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    bottom: -30px;
}

@media (max-width: 479px) {
    .skin_449 .modal--344-winner .modal__main--textwrapper img:first-child {
        width: 45px;
        left: -15px;
        bottom: -25px;
    }
}

@media (max-width: 355px) {
    .skin_449 .modal--344-winner .modal__main--textwrapper img:first-child {
        width: 40px;
        left: -10px;
        bottom: -20px;
    }
}

.skin_449 .modal--344-winner .modal__main--textwrapper img:last-child {
    width: 45px;
    position: absolute;
    right: 10px;
    top: -10px;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

@media (max-width: 479px) {
    .skin_449 .modal--344-winner .modal__main--textwrapper img:last-child {
        width: 35px;
        top: -12px;
    }
}

.skin_449 .modal--344-winner .modal__footer {
    padding: 40px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.skin_449 .modal--344-winner .modal__footer p {
    color: #0078f5;
    font-size: 25px;
}

.skin_449 .modal--344-winner .modal__footer p:nth-child(2) {
    max-width: 300px;
    margin-bottom: 20px;
}

@media (max-width: 400px) {
    .skin_449 .modal--344-winner .modal__footer p {
        font-size: 20px;
    }
}

.skin_449 .modal--344-winner .modal__footer a {
    color: #0078f5;
    text-decoration: underline;
    font-size: 25px;
}

@media (max-width: 400px) {
    .skin_449 .modal--344-winner .modal__footer a {
        font-size: 20px;
    }
}

.skin_449 .recurring-purchase__purchase--numberinfo-section > div:first-child {
    margin-right: 0;
}

.skin_449
    .recurring-purchase__purchase--numberinfo-section
    > div:first-child:after {
    content: none;
}

.skin_449 .my-games__item,
.skin_449 .ticket-history__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media (max-width: 1199px) {
    .skin_449 .my-games__item > div:first-child,
    .skin_449 .ticket-history__item > div:first-child {
        border-bottom: 1px solid var(--iq-dullgrey);
    }
}

.skin_449 .my-games__item--category,
.skin_449 .ticket-history__item--category {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 25px 0;
}

@media (max-width: 1199px) {
    .skin_449 .my-games__item--category,
    .skin_449 .ticket-history__item--category {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.skin_449 .my-games__details--ticket-wrapper h4,
.skin_449 .ticket-history__details--ticket-wrapper h4 {
    margin-bottom: 0;
    color: var(--iq-black);
}

.skin_449 .my-games__details--ticket.ticket-id,
.skin_449 .ticket-history__details--ticket.ticket-id {
    margin: 0;
}

@media (min-width: 992px) {
    .skin_449 .my-games__numbers,
    .skin_449 .ticket-history__numbers {
        min-width: 224px;
    }
}

.skin_449 .my-games__numbers--row,
.skin_449 .ticket-history__numbers--row {
    -webkit-box-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .skin_449 .my-games {
        padding: 0;
        border: none;
    }
}

@media (max-width: 575px) {
    .skin_449 .my-games__numbers {
        margin-bottom: 0;
    }
}

@media (max-width: 575px) {
    .skin_449 .my-games__content--item-wrapper {
        padding: 20px 12px;
        border: 1px solid var(--iq-dullgrey);
        border-radius: 10px;
    }
}

@media (max-width: 575px) {
    .skin_449 .my-games__content--item-wrapper:not(:last-child) {
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .skin_449 .my-games__item:first-child {
        border: none;
    }
}

@media (max-width: 575px) {
    .skin_449 .my-games__item {
        padding-top: 0;
    }
}

.skin_449 .my-games__item--category {
    position: relative;
}

@media (max-width: 575px) {
    .skin_449 .my-games__item--category:first-child {
        padding-top: 0;
    }
}

@media (max-width: 575px) {
    .skin_449 .my-games__item--friday {
        padding-bottom: 0;
    }
}

@media (max-width: 575px) {
    .skin_449 .ticket-history {
        padding: 0;
        border: none;
    }
}

@media (max-width: 575px) {
    .skin_449 .ticket-history__content--item-wrapper {
        padding: 20px 12px;
        border: 1px solid var(--iq-dullgrey);
        border-radius: 10px;
    }
}

@media (max-width: 575px) {
    .skin_449 .ticket-history__content--item-wrapper:not(:last-child) {
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .skin_449 .ticket-history__item:first-child {
        border: none;
    }
}

@media (max-width: 575px) {
    .skin_449 .ticket-history__item {
        padding-top: 0;
    }
}

.skin_449 .ticket-history__item--category {
    position: relative;
}

@media (max-width: 575px) {
    .skin_449 .ticket-history__item--category:first-child {
        padding-top: 0;
    }
}

.skin_449 .ticket-history__item--btn {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    bottom: 10%;
    left: 26.5%;
}

@media (max-width: 1199px) {
    .skin_449 .ticket-history__item--btn {
        bottom: -30px;
        width: 100%;
        max-width: 100%;
        left: 50%;
    }
}

.skin_449 .ticket-history__item--btn a {
    width: 224px;
    max-width: 100%;
    height: 40px;
    padding: 10px 20px 8px;
    font-size: 17px;
    color: var(--iq-white);
}

@media (max-width: 1199px) {
    .skin_449 .ticket-history__item--btn a {
        width: 100%;
        max-width: 100%;
    }
}

.skin_449 .modal--numberpicker .betNum,
.skin_449 .modal--purchase-details-skin .betNum,
.skin_449 .modal__purchase .betNum,
.skin_449 .myaccount .betNum,
.skin_449 .previous-drawn .betNum,
.skin_449 .shopping-cart .betNum,
.skin_449 .ticket-history .betNum {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: transparent;
    border: 1px solid #55a0fa;
    background-image: none;
    border-radius: 50%;
    color: #55a0fa;
    padding-top: 0 !important;
}

.skin_449 .modal--numberpicker .betNum:hover,
.skin_449 .modal--purchase-details-skin .betNum:hover,
.skin_449 .modal__purchase .betNum:hover,
.skin_449 .myaccount .betNum:hover,
.skin_449 .previous-drawn .betNum:hover,
.skin_449 .shopping-cart .betNum:hover,
.skin_449 .ticket-history .betNum:hover {
    border-color: var(--iq-pink);
}

.skin_449 .modal--numberpicker .betNumMarked,
.skin_449 .modal--purchase-details-skin .betNumMarked,
.skin_449 .modal__purchase .betNumMarked,
.skin_449 .myaccount .betNumMarked,
.skin_449 .previous-drawn .betNumMarked,
.skin_449 .shopping-cart .betNumMarked,
.skin_449 .ticket-history .betNumMarked {
    background-image: none;
    background-color: var(--iq-pink);
    color: var(--iq-white);
    border-radius: 50%;
    border: 1px solid var(--iq-pink);
}

@media (max-width: 575px) {
    .skin_449 .modal--numberpicker .betNumMarked,
    .skin_449 .modal--purchase-details-skin .betNumMarked,
    .skin_449 .modal__purchase .betNumMarked,
    .skin_449 .myaccount .betNumMarked,
    .skin_449 .previous-drawn .betNumMarked,
    .skin_449 .shopping-cart .betNumMarked,
    .skin_449 .ticket-history .betNumMarked {
        border-radius: 50% !important;
    }
}

.skin_449 .previous-drawn .table-main table tbody tr:nth-child(2n) {
    background-color: transparent;
}

@media (max-width: 575px) {
    .skin_449 .previous-drawn .table-main table tbody tr td {
        font-size: 13px;
    }
}

.skin_449 .previous-drawn .table-main table tbody tr td,
.skin_449 .previous-drawn .table-main table tbody tr td:first-child,
.skin_449 .previous-drawn .table-main table tbody tr td:last-child {
    color: var(--iq-black);
    padding: 11px 15px 12px 21px;
}

.skin_449 .previous-drawn .table-main table tbody tr td span {
    color: var(--iq-black);
}

.skin_449 .header__wrapper--nav.desktop nav ul li .accordion-top i {
    margin-bottom: 0;
}

.skin_449 .previous-drawn__button {
    width: unset;
}

.skin_449 .previous-drawn__button a {
    background-color: var(--iq-pink);
    color: var(--iq-white);
    text-transform: uppercase;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    font-size: 21px;
    padding: 0 40px;
    height: 47px;
}

.skin_449 .previous-drawn__button a i {
    display: none;
}

@media (max-width: 575px) {
    .skin_449 .previous-drawn__button.mobile {
        display: -webkit-box;
        display: flex;
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin: 0 auto;
    }
}

@media (max-width: 575px) {
    .skin_449 .previous-drawn__select {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: start;
        align-items: flex-start;
        width: 100%;
    }
}

.skin_449 .previous-drawn .table-main table thead tr {
    border: 2px solid var(--iq-orange);
}

.skin_449 .previous-drawn .table-main table thead tr th {
    color: var(--iq-white);
    font-size: 17px;
    text-transform: uppercase;
    padding: 10px 0 10px 19px;
    border-right: 2px solid var(--iq-orange);
    background-color: var(--iq-orange);
    text-align: center;
}

@media (max-width: 575px) {
    .skin_449 .previous-drawn .table-main table thead tr th {
        border: 2px solid var(--iq-orange);
        padding: 10px 0 10px 15px;
    }
}

@media (max-width: 479px) {
    .skin_449 .previous-drawn .table-main table thead tr th {
        border: 2px solid var(--iq-orange);
        padding: 10px 17px 10px 15px;
    }
}

@media (max-width: 575px) {
    .skin_449 .previous-drawn .table-main table thead tr th:nth-child(2) {
        text-align: left;
    }
}

@media (max-width: 479px) {
    .skin_449 .previous-drawn .table-main table thead tr th:nth-child(2) {
        line-height: 20px;
    }
}

.skin_449 .previous-drawn .table-main table tbody tr td:nth-child(2) {
    padding: 11px 15px 12px 21px;
}

@media (max-width: 575px) {
    .skin_449 .previous-drawn .table-main table tbody tr td:nth-child(2) {
        padding: 15px 15px 12px 16px;
    }
}

@media (max-width: 575px) {
    .skin_449 .previous-drawn__table .prize-type {
        display: none;
    }
}

.skin_449 .previous-drawn__top {
    margin-bottom: 10px;
}

.skin_449 .previous-drawn__select .selectize-input {
    border-width: 2px;
    border-color: var(--iq-orange);
}

.skin_449 .previous-drawn .betNum {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 20px;
}

@media (max-width: 479px) {
    .skin_449 .previous-drawn .betNum {
        border-radius: 5px;
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
}

@media (max-width: 390px) {
    .skin_449 .previous-drawn .betNum {
        width: 33px;
        height: 33px;
        border-radius: 5px;
        font-size: 17px;
    }
}

.skin_449 .previous-drawn__text h1,
.skin_449 .previous-drawn__text h2 {
    text-transform: uppercase;
}

.skin_449 .previous-drawn__text h2.heading_sec {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.skin_449 .previous-drawn__text h1 {
    margin-bottom: 5px;
    font-size: 20px;
}

.skin_449 .previous-drawn__text--number-row div:not(:last-child) {
    margin-right: 32px;
}

.skin_449
    .previous-drawn__section--friday
    .table-main
    table
    thead
    tr
    th:not(:last-child) {
    border-right: 1px solid var(--iq-white);
}

@media (max-width: 479px) {
    .skin_449
        .previous-drawn__section--friday
        .table-main
        table
        thead
        tr
        th:not(:last-child) {
        border-right: 0;
    }
}

.skin_449
    .previous-drawn__section--friday
    .table-main
    table
    tbody
    tr:nth-child(2n) {
    background-color: rgba(0, 119, 200, 0.1);
}

.skin_449 .verification__banner h2 {
    font-size: 40px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    bottom: unset;
    margin-bottom: 0;
    color: #ffe765;
}

@media (max-width: 575px) {
    .skin_449 .verification__banner h2 {
        position: unset;
        -webkit-transform: none;
        transform: none;
        padding: 15px;
        background-color: #28a8ff;
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 479px) {
    .skin_449 .verification__banner img {
        max-height: 500px;
        width: 100%;
    }
}

.skin_449 .verification__banner--bg-rejected {
    background-image: url(../img/kyc-rejected.jpg);
}

@media (max-width: 575px) {
    .skin_449 .verification__banner--bg-rejected {
        background-image: unset;
        padding-bottom: 0;
        border-radius: 0;
    }
}

@media (max-width: 479px) {
    .skin_449 .verification__banner--bg-rejected {
        padding-bottom: 0;
    }
}

.skin_449 .verification__banner--bg-success {
    background-image: url(../img/kyc-verified.jpg);
}

@media (max-width: 575px) {
    .skin_449 .verification__banner--bg-success {
        background-image: unset;
        padding-bottom: 0;
        border-radius: 0;
    }
}

@media (max-width: 479px) {
    .skin_449 .verification__banner--bg-success {
        padding-bottom: 0;
    }
}

@media (max-width: 575px) {
    .skin_449 .verification__banner--text {
        background-color: #28a8ff;
        position: unset;
        width: 100%;
        max-width: 100%;
        padding: 25px;
    }
}

.skin_449 .verification__banner--text h3 {
    color: var(--iq-white);
    font-size: 40px;
}

@media (max-width: 479px) {
    .skin_449 .verification__banner--text h3 {
        font-size: 27px;
    }
}

@media (max-width: 415px) {
    .skin_449 .verification__banner--text h3 {
        font-size: 21px;
    }
}

.skin_449 .verification__banner--text p {
    color: var(--iq-white);
    line-height: 22px;
}

.skin_449 .verification__banner--text a {
    height: 40px;
    border-radius: 10px;
}

@media (max-width: 479px) {
    .skin_449 .verification__banner--text a {
        width: unset;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
    }
}

.skin_449 .verification__banner--icon {
    margin-bottom: 20px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
}

@media (min-width: 576px) {
    .skin_449 .verification__banner--icon {
        display: none;
    }
}

.skin_449 .verification__banner--icon img {
    width: 200px;
    max-width: 100%;
}

@media (max-width: 575px) {
    .skin_449
        .verification--success
        .verification__banner
        .verification__banner--text {
        background-color: #ffe765;
    }
}

.skin_449
    .verification--success
    .verification__banner
    .verification__banner--text
    h3,
.skin_449
    .verification--success
    .verification__banner
    .verification__banner--text
    p {
    color: #28a8ff;
}

@media (max-width: 479px) {
    .skin_449
        .verification--rejected
        .verification__banner
        .verification__banner--text
        a {
        width: unset;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
    }
}

.skin_449.page-rtl .price-wrapp {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.skin_449.page-rtl .footer__nav--text.desktop ul li a,
.skin_449.page-rtl .footer__socials p {
    font-size: 16px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .priceproduct1 {
        max-width: 90px !important;
    }
}

.skin_449.page-rtl .verification__banner--bg-rejected {
    background-image: url(../img/kyc-rejected-ar.jpg);
}

@media (max-width: 575px) {
    .skin_449.page-rtl .verification__banner--bg-rejected {
        background-image: unset;
    }
}

.skin_449.page-rtl .verification__banner--bg-success {
    background-image: url(../img/kyc-verified-ar.jpg);
}

@media (max-width: 575px) {
    .skin_449.page-rtl .verification__banner--bg-success {
        background-image: unset;
    }
}

.skin_449.page-rtl .modal--344 .remodal-close {
    right: unset;
    left: -22px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .modal--344 .remodal-close {
        left: -10px;
    }
}

.skin_449.page-rtl .modal--344-rp .remodal-close {
    right: unset;
    left: -18px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .modal--344-rp .remodal-close {
        left: -10px;
    }
}

.skin_449.page-rtl .modal--344-rp h1 span {
    margin-top: 10px;
    padding: 7px 10px;
}

.skin_449.page-rtl .modal--344-rp .modal__main--text {
    max-width: 100%;
}

.skin_449.page-rtl .modal--purchase .modal__purchase--numbers {
    margin-right: 0;
    margin-left: 10px;
}

.skin_449.page-rtl .modal--purchase .modal__purchase--text {
    text-align: right;
}

.skin_449.page-rtl .modal--purchase .betNum {
    margin: 0 0 7px 7px;
}

.skin_449.page-rtl .modal--344-winner .modal__main--inner {
    -webkit-box-align: start;
    align-items: flex-start;
}

.skin_449.page-rtl .modal--344-winner .modal__main--inner img {
    bottom: unset;
}

@media (max-width: 479px) {
    .skin_449.page-rtl .modal--344-winner .modal__main--inner img {
        bottom: 10px;
    }
}

.skin_449.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child {
    -webkit-transform: unset;
    transform: unset;
    left: unset;
    right: -20px;
    bottom: -70px;
}

@media (max-width: 479px) {
    .skin_449.page-rtl
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child {
        bottom: -45px;
    }
}

@media (max-width: 400px) {
    .skin_449.page-rtl
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child {
        bottom: -50px;
    }
}

.skin_449.page-rtl .modal--344-winner .modal__main--textwrapper img:last-child {
    right: unset;
    left: 0;
    -webkit-transform: unset;
    transform: unset;
    top: 0;
}

.skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 {
    text-align: right;
    font-size: 53px;
    margin-left: 0;
}

@media (max-width: 479px) {
    .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 {
        font-size: 45px;
    }
}

@media (max-width: 355px) {
    .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 {
        font-size: 35px;
    }
}

.skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 span {
    font-size: 75px;
    margin-left: 0;
}

@media (max-width: 479px) {
    .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 span {
        font-size: 55px;
    }
}

@media (max-width: 400px) {
    .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 span {
        line-height: 10px;
        font-size: 50px;
    }
}

@media (max-width: 355px) {
    .skin_449.page-rtl .modal--344-winner .modal__main--textwrapper h2 span {
        font-size: 42px;
    }
}

@media (max-width: 355px) {
    .skin_449.page-rtl .modal--344-winner .modal__footer a,
    .skin_449.page-rtl .modal--344-winner .modal__footer p {
        font-size: 17px;
    }
}

.skin_449.page-rtl .recurring-purchase__purchase--numbers .betNum {
    margin: 0 0 0 7px;
}

@media (min-width: 480px) {
    .skin_449.page-rtl .recurring-purchase__purchase--numbers .betNum {
        padding-top: 0;
    }
}

.skin_449.page-rtl .hp-navigation__box,
.skin_449.page-rtl .hp-navigation__box--content {
    -webkit-transform: unset;
    transform: unset;
}

.skin_449.page-rtl .hp-navigation__box--content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: unset;
    align-items: unset;
}

.skin_449.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading {
    padding: 18px 13px 0 0;
    margin-bottom: 19px;
}

.skin_449.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: right;
    padding-bottom: 2px;
    max-width: 300px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2 {
        max-width: 220px;
    }

    .skin_449.page-rtl
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading {
        padding: 0 13px 0 0;
        margin-bottom: 13px;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span {
    font-size: 30px;
    bottom: 0;
    right: 0;
    line-height: inherit;
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2
        span {
        font-size: 22px;
    }
}

.skin_449.page-rtl .hp-navigation__box.box-stories .hp-navigation__box--btn {
    -webkit-box-pack: center;
    justify-content: center;
}

.skin_449.page-rtl .hp-navigation__box.box-stories .hp-navigation__box--btn a {
    padding: 12px 15px 15px;
}

.skin_449.page-rtl .hp-navigation__box .countdown__timer--priceproduct h2 {
    margin-right: 11px;
    font-size: 34px;
    line-height: 36px;
}

@media (max-width: 479px) {
    .skin_449.page-rtl .hp-navigation__box .countdown__timer--priceproduct h2 {
        font-size: 25px;
        line-height: 29px;
        text-align: right;
    }
}

.skin_449.page-rtl .hp-navigation__box .countdown__timer--priceproduct p {
    margin-right: 10px;
    margin-top: 10px;
    line-height: 20px;
    max-width: 220px;
}

@media (max-width: 479px) {
    .skin_449.page-rtl .hp-navigation__box .countdown__timer--priceproduct p {
        text-align: right;
        line-height: 18px;
    }
}

.skin_449.page-rtl .hp-navigation__box .countdown__timer--valuewrapper p {
    margin-top: 0 !important;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .hp-navigation__box .countdown__timer--valuewrapper {
        height: 32px;
    }
}

.skin_449.page-rtl .hp-navigation__box.box-draw-info,
.skin_449.page-en .hp-navigation__box.box-draw-info {
    padding: 10px 30px;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl .hp-navigation__box.box-draw-info,
    .skin_449.page-en .hp-navigation__box.box-draw-info {
        padding: 10px 15px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .hp-navigation__box.box-draw-info,
    .skin_449.page-en .hp-navigation__box.box-draw-info {
        border-radius: 0 0 10px 10px;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-draw-info
    .hp-navigation__box--btn
    a {
    background-color: transparent;
    color: var(--iq-white);
    border: 2px solid var(--iq-yellow);
    padding: 9px 25px;
    font-size: 20px;
    margin-top: 26px;
}
@media (max-width: 1200px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-draw-info
        .hp-navigation__box--btn
        a {
        margin-top: 21px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-draw-info
        .hp-navigation__box--btn
        a {
        font-size: 18px;
    }
}

.skin_449.page-rtl .hp-navigation__box.box-results h3 {
    margin-bottom: 0;
    padding: 5px 0;
}

.skin_449.page-rtl
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__box--btn {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

@media (min-width: 576px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__box--btn {
        bottom: -5px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__box--btn {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__box--btn
    a {
    padding: 8px 15px 5px;
}

@media (max-width: 479px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__info--box
        p {
        font-size: 24px;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__box--content
    p {
    direction: ltr;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p {
        font-size: 18px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p {
        line-height: 1.5;
        font-size: 18px;
    }
}

@media (max-width: 385px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p {
        font-size: 17px;
    }
}

@media (max-width: 1199px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p:nth-child(2) {
        font-size: 18px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p:nth-child(2) {
        line-height: 1.5;
        font-size: 18px;
    }
}

@media (max-width: 385px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__info--box
        p:nth-child(2) {
        font-size: 17px;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--content {
    text-align: left;
}

@media (min-width: 480px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-stories
        .hp-navigation__box--btn {
        padding: 7px 0 31px;
    }
}

@media (min-width: 576px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-stories
        .hp-navigation__box--video {
        margin-bottom: 13px;
    }
}

.skin_449.page-rtl .hp-navigation__box.box-total {
    padding: 10px 16px;
}

@media (max-width: 767px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2 {
    font-size: 20px;
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2 {
        font-size: 16px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2 {
        text-align: right;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 50px;
    line-height: 8px;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span {
        font-size: 50px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span {
        font-size: 26px;
        line-height: 33px;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    small {
    font-size: 17px;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        small {
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h3 {
        font-size: 50px;
        width: 100%;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: left;
    }
}

.skin_449.page-rtl .hp-navigation__box.box-latest {
    padding: 0px 0px 10px;
}

.skin_449.page-rtl .hp-navigation__box.box-latest h2 span:nth-child(3) {
    margin-left: 0;
}

.skin_449.page-rtl .hp-navigation__box.box-latest .hp-navigation {
    padding: 5px 20px 18px;
}

.skin_449.page-rtl
    .hp-navigation__box.box-latest
    .hp-navigation__inner--container {
    position: relative;
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-latest
        .hp-navigation__inner--container {
        padding-top: 0;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-latest
    .hp-navigation__inner--container
    h2 {
    -webkit-box-align: baseline;
    align-items: baseline;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 34px;
    left: 0;
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-latest
        .hp-navigation__inner--container
        h2 {
        -webkit-box-align: center;
        align-items: center;
    }
}

.skin_449.page-rtl
    .hp-navigation__box.box-latest
    .hp-navigation__inner--container
    h2
    span:first-child {
    font-size: 28px;
}

@media (max-width: 380px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-latest
        .hp-navigation__inner--container
        h2
        span:first-child {
        font-size: 22px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-latest
        .hp-navigation__inner--container
        h2
        span:nth-child(2) {
        font-size: 20px;
        margin-right: 0;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .hp-navigation__box.box-latest
        .hp-navigation__inner--container
        h2
        span:nth-child(3) {
        height: unset;
    }
}

@media (max-width: 1199px) {
    .skin_449.page-rtl .hp-navigation__box--sum {
        width: 334px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-rtl .hp-navigation__box--sum {
        width: 160px;
        max-width: 100%;
        border-radius: 5px;
        padding: 10px 5px;
    }
}

@media (max-width: 415px) {
    .skin_449.page-rtl .hp-navigation__box--sum {
        width: 145px;
    }
}

@media (max-width: 385px) {
    .skin_449.page-rtl .hp-navigation__box--sum {
        width: 115px;
        padding: 5px;
    }
}

.skin_449.page-rtl .hp-navigation__box--sum p {
    font-size: 31px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl .hp-navigation__box--sum p {
        font-size: 36px;
        line-height: 31px;
        height: 37px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-rtl .hp-navigation__box--sum p {
        font-size: 18px;
        height: 22px;
        line-height: normal;
    }
}

@media (max-width: 415px) {
    .skin_449.page-rtl .hp-navigation__box--sum p {
        font-size: 17px;
        height: 20px;
    }
}

@media (max-width: 385px) {
    .skin_449.page-rtl .hp-navigation__box--sum p {
        font-size: 13px;
        height: 17px;
    }
}

.skin_449.page-rtl .hp-navigation__box--sum p span {
    margin-right: 5px;
}

.skin_449.page-rtl .hp-navigation__box .countdown__timer--boxes-row p {
    margin-top: 0px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .hp-navigation__box .countdown__timer--boxes-row p {
        margin-top: 1px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-rtl .hp-navigation__date p {
        text-align: right;
    }
}

@media (min-width: 480px) {
    .skin_449.page-rtl .hp-navigation__draws {
        margin: 19px 0 25px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-rtl .hp-navigation__draws > div {
        margin-right: 0;
        margin-left: 10px;
    }

    .skin_449.page-rtl .hp-navigation__draws--number span {
        padding-top: 0;
    }
}

@media (min-width: 576px) {
    .skin_449.page-rtl .number-picker.homepage .number-picker__picker {
        margin: 0 0 0 30px;
    }

    .skin_449.page-rtl
        .number-picker.homepage
        .number-picker__picker:last-child {
        margin-left: 0;
    }
}

.skin_449.page-rtl .number-picker.homepage .number-picker--btn button {
    text-transform: uppercase;
    font-size: 20px;
    width: 100%;
    max-width: 100%;
    height: 60px;
    background-color: var(--iq-yellow);
    border: none;
    padding: 10px 15px;
}

.skin_449.page-rtl .number-picker.homepage .number-picker--btn button:disabled {
    background-color: var(--iq-yellow);
    border: none;
}

@media (min-width: 576px) {
    .skin_449.page-rtl .number-picker__numbers .betNum:nth-child(5n) {
        margin: 0 0 7px 7px;
    }
}

.skin_449.page-rtl .number-picker .betNum {
    padding-top: 0;
}

.skin_449.page-rtl .number-picker--btn button {
    font-size: 24px;
    padding: 15px 5px;
}

.skin_449.page-rtl .number-picker__content > div:last-child {
    margin-left: 0;
}

.skin_449.page-rtl .number-picker__top--img {
    right: 310px;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl .number-picker__top--img {
        left: 20px;
    }
}

.skin_449.page-rtl .number-picker__top--text h2 {
    line-height: 55px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .number-picker__top--text h2 {
        margin-bottom: 0;
        line-height: normal;
    }
}

.skin_449.page-rtl .number-picker__top--text h2 small {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .number-picker__top--text h2 small {
        margin-top: 0;
    }
}

.skin_449.page-rtl .number-picker__top--text h2 span {
    display: inline-block;
    margin: 0 5px;
}

.skin_449.page-rtl .number-picker__top--text h2 span span {
    position: unset;
    font-size: 70px;
    line-height: 65px;
    margin: 0 5px;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl .number-picker__top--text h2 span span {
        font-size: 50px;
        line-height: 45px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .number-picker__top--text h2 span span {
        line-height: 60px;
    }
}

.skin_449.page-rtl .number-picker__bottle {
    right: unset;
    left: 0;
    bottom: -5px;
}

.skin_449.page-rtl .number-picker__bottle--value {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.skin_449.page-rtl .number-picker__bottle--value .value-button#increase span {
    top: 47%;
}

.skin_449.page-rtl .number-picker__bottle--value .value-button#decrease span {
    top: 42% !important;
}

.skin_449.page-rtl .number-picker__picker--name {
    padding: 15px 18px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .number-picker__picker--name {
        padding: 5px 9px 5px 15px;
    }
}

.skin_449.page-rtl .number-picker__picker--bottom {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-box-pack: end;
    padding: 0;
}

.skin_449.page-rtl .number-picker__picker--bottom a,
.skin_449.page-rtl .number-picker__picker--bottom div {
    margin-right: 0;
    margin-left: 5px;
}

.skin_449.page-rtl .number-picker__picker--bottom div:last-child {
    margin: 0;
}

.skin_449.page-rtl
    .number-picker__picker--bottom
    div.number-picker__picker--randomize {
    margin-left: 7px;
}

.skin_449.page-rtl .number-picker__picker--disabled span span {
    margin-left: 5px;
}

.skin_449.page-rtl .number-picker.play .number-picker__bottle {
    right: unset;
    left: 110px;
}

.skin_449.page-rtl .number-picker.play .number-picker__picker {
    margin: 0 0 14px 14px;
}

.skin_449.page-rtl .number-picker.play .number-picker__picker:nth-child(4n) {
    margin-left: 0;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl
        .number-picker.play
        .number-picker__picker:nth-child(4n) {
        margin-left: 14px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .number-picker.play
        .number-picker__picker:nth-child(4n) {
        margin-left: 0;
    }
}

.skin_449.page-rtl .number-picker.play .number-picker__picker--name {
    padding: 15px 18px 10px 0;
}

.skin_449.page-rtl .number-picker.play .number-picker__picker--name p,
.skin_449.page-rtl .number-picker.play .number-picker__picker--name span {
    font-size: 20px;
}

.skin_449.page-rtl .number-picker.play .number-picker__top--img {
    right: unset;
}

.skin_449.page-rtl
    .number-picker.play
    .number-picker__select
    .selectize
    .selectize-input:after {
    right: unset !important;
    left: 30px !important;
}

.skin_449.page-rtl .countdown__timer--wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.skin_449.page-rtl .countdown__timer span.countdown__timer--value {
    font-size: 41px;
}

@media (max-width: 1199px) {
    .skin_449.page-rtl .countdown__timer span.countdown__timer--value {
        font-size: 28px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown__timer span.countdown__timer--value {
        font-size: 18px;
        line-height: 20px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__inner > div:first-child,
    .skin_449.page-rtl .countdown.play .countdown__inner > div:nth-child(2) {
        -webkit-box-ordinal-group: unset;
        order: unset;
        margin-bottom: 0px;
    }
}

.skin_449.page-rtl .countdown.play .countdown__right--date {
    width: 100%;
    -webkit-transform: unset;
    transform: unset;
    left: 25px;
    top: -20px;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__right--date {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.skin_449.page-rtl .countdown.play .countdown__right--date h2 {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    line-height: 40px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__right--date h2 {
        font-size: 20px;
        line-height: 20px;
        color: #0078f5;
    }
}

.skin_449.page-rtl .countdown.play .countdown__right--date h2 span {
    margin-top: 0;
    margin-left: 0;
    margin-right: 5px;
}

.skin_449.page-rtl .countdown.play .countdown__text--text {
    margin-left: 0;
    margin-right: 15px;
}

.skin_449.page-rtl .countdown.play .countdown__text--text > div:nth-child(2) {
    right: unset;
    left: -235px;
    bottom: 0;
}

.skin_449.page-rtl .countdown.play .countdown__text--amount {
    padding: 10px 10px 15px;
}

.skin_449.page-rtl .countdown.homepage .countdown__timer--valuewrapper {
    height: 38px;
}

.skin_449.page-rtl .previous-drawn__text--number-row div:not(:last-child) {
    margin-right: 0;
    margin-left: 32px;
}

.skin_449.page-rtl .hp-navigation__box--heading h2 {
    max-width: 339px;
}

@media (max-width: 575px) {
    .skin_449.page-ar .number-picker__top--text h2 span span {
        line-height: 45px;
    }

    .skin_449.page-rtl .countdown.homepage .countdown__timer--valuewrapper {
        height: auto;
    }

    .skin_449.page-rtl .countdown.homepage .countdown__timer--boxes-row {
        top: 0;
    }

    .skin_449.page-rtl .countdown.homepage .countdown__timer {
        margin: 0;
    }

    .skin_449.page-rtl .countdown.homepage .countdown__timer--boxes-row p {
        font-size: 11px;
    }
}

@media (min-width: 480px) {
    .skin_449.page-hi .verification--rejected .verification__banner--text a {
        max-width: 245px;
    }
}

.skin_449.page-hi .modal--344-winner .modal__main--inner h2 span {
    margin-left: 0;
    font-size: 55px;
}

@media (max-width: 479px) {
    .skin_449.page-hi .modal--344-winner .modal__main--inner h2 span {
        font-size: 42px;
    }
}

@media (max-width: 400px) {
    .skin_449.page-hi .modal--344-winner .modal__main--inner h2 span {
        font-size: 37px;
    }
}

@media (max-width: 355px) {
    .skin_449.page-hi .modal--344-winner .modal__main--inner h2 span {
        font-size: 33px;
    }
}

.skin_449.page-hi .modal--344-winner .modal__main--textwrapper img:first-child {
    bottom: -35px;
}

@media (max-width: 400px) {
    .skin_449.page-hi
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child {
        left: -15px;
    }
}

.skin_449.page-hi .modal--344-rp h1 span {
    padding: 10px 10px 5px;
    margin-top: 5px;
}

@media (max-width: 370px) {
    .skin_449.page-hi .modal--344-rp .modal__footer a {
        font-size: 13px;
    }
}

.skin_449.page-hi .countdown__timer--valuewrapper {
    height: 45px;
}

@media (max-width: 1199px) {
    .skin_449.page-hi .countdown__timer--valuewrapper {
        height: 50px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-hi .countdown__timer--valuewrapper {
        height: 40px;
    }
}

@media (max-width: 385px) {
    .skin_449.page-hi .countdown__timer--valuewrapper {
        height: 25px;
    }
}

.skin_449.page-hi .number-picker__picker--disabled span {
    margin-right: 5px;
}

.skin_449.page-hi .number-picker__picker--disabled span span {
    margin-right: 3px;
}

@media (max-width: 575px) {
    .skin_449.page-hi .number-picker.play .number-picker__top--text {
        padding-top: 5px;
    }
}

.skin_449.page-hi .hp-navigation__box.box-total {
    padding: 14px 16px;
}

.skin_449.page-hi
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 0;
}

.skin_449.page-hi .hp-navigation__box.box-stories .hp-navigation__box--heading {
    margin-bottom: 0;
}

.skin_449.page-hi
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2 {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.skin_449.page-hi
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span {
    position: unset;
    font-size: 47px;
}

.skin_449.page-hi .hp-navigation__box.box-stories .hp-navigation__box--video {
    margin-bottom: 37px;
}

@media (min-width: 480px) {
    .skin_449.page-hi .hp-navigation__box.box-latest h2 span {
        font-size: 35px;
        line-height: 48px;
    }
}

.skin_449.page-hi .hp-navigation__info--box p {
    line-height: 42px;
}

@media (min-width: 480px) {
    .skin_449.page-ml .verification--rejected .verification__banner--text a {
        max-width: 355px;
    }
}

.skin_449.page-ml
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input {
    font-size: 9px !important;
}

@media (max-width: 479px) {
    .skin_449.page-ml .modal--344 .modal__footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
        -webkit-box-align: end;
        align-items: flex-end;
    }
}

@media (max-width: 479px) {
    .skin_449.page-ml .modal--344 .modal__footer p {
        width: 100%;
        text-align: left;
        max-width: 100%;
        margin: 13px 0 0;
    }
}

.skin_449.page-ml .modal--344-rp h1 {
    font-size: 17px;
}

@media (max-width: 370px) {
    .skin_449.page-ml .modal--344-rp h1 {
        font-size: 14px;
    }
}

.skin_449.page-ml .modal--344-rp h1 span {
    margin-top: 10px;
}

.skin_449.page-ml .modal--344-rp .modal__main--text p {
    font-size: 13px;
}

@media (max-width: 370px) {
    .skin_449.page-ml .modal--344-rp .modal__main--text p {
        font-size: 11px;
    }
}

.skin_449.page-ml .modal--344-rp .modal__footer a {
    font-size: 13px;
}

@media (max-width: 370px) {
    .skin_449.page-ml .modal--344-rp .modal__footer a {
        font-size: 11px;
    }
}

.skin_449.page-ml .modal--344-winner .modal__main--inner h2 {
    font-size: 45px;
}

@media (max-width: 479px) {
    .skin_449.page-ml .modal--344-winner .modal__main--inner h2 {
        font-size: 30px;
    }
}

@media (max-width: 400px) {
    .skin_449.page-ml .modal--344-winner .modal__main--inner h2 {
        font-size: 25px;
    }
}

@media (max-width: 355px) {
    .skin_449.page-ml .modal--344-winner .modal__main--inner h2 {
        font-size: 22px;
    }
}

.skin_449.page-ml .modal--344-winner .modal__main--inner h2 span {
    margin-left: 0;
    font-size: 38px;
}

@media (max-width: 479px) {
    .skin_449.page-ml .modal--344-winner .modal__main--inner h2 span {
        font-size: 30px;
    }
}

@media (max-width: 400px) {
    .skin_449.page-ml .modal--344-winner .modal__main--inner h2 span {
        font-size: 25px;
    }
}

@media (max-width: 355px) {
    .skin_449.page-ml .modal--344-winner .modal__main--inner h2 span {
        font-size: 22px;
    }
}

.skin_449.page-ml .modal--344-winner .modal__main--textwrapper img:first-child {
    bottom: -35px;
}

@media (max-width: 479px) {
    .skin_449.page-ml
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child {
        bottom: -25px;
    }
}

.skin_449.page-ml .modal--344-winner .modal__footer a,
.skin_449.page-ml .modal--344-winner .modal__footer p {
    font-size: 20px;
}

.skin_449.page-ml .number-picker__picker--disabled span {
    margin-right: 5px;
}

.skin_449.page-ml .number-picker__picker--disabled span span {
    margin-right: 3px;
}

.skin_449.page-ml .number-picker.play .number-picker__picker--name p,
.skin_449.page-ml .number-picker.play .number-picker__picker--name span {
    font-size: 20px;
}

@media (min-width: 576px) {
    .skin_449.page-ml .number-picker--btn button {
        font-size: 14px;
    }
}

@media (min-width: 576px) {
    .skin_449.page-ml .hp-navigation__box.box-total {
        padding: 36px 16px;
    }
}

.skin_449.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2 {
    font-size: 20px;
}

@media (max-width: 355px) {
    .skin_449.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2 {
        font-size: 28px;
    }
}

.skin_449.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 0;
}

@media (max-width: 355px) {
    .skin_449.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span {
        font-size: 40px;
    }
}

@media (min-width: 576px) {
    .skin_449.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        small {
        font-size: 19px;
    }
}

@media (min-width: 480px) {
    .skin_449.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h3 {
        font-size: 60px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2 {
        max-width: 100%;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.skin_449.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span {
    font-size: 48px;
    right: 14px;
    bottom: -4px;
}

@media (max-width: 479px) {
    .skin_449.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2
        span {
        position: unset;
    }
}

@media (min-width: 576px) {
    .skin_449.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--video {
        margin-bottom: 36px;
    }
}

.skin_449.page-ml .hp-navigation__box.box-draw-info .hp-navigation__box--btn a {
    width: auto;
    padding: 15px 15px 12px;
}

@media (max-width: 575px) {
    .skin_449.page-ml
        .hp-navigation__box.box-draw-info
        .hp-navigation__box--btn
        a {
        font-size: 17px;
    }
}

@media (max-width: 385px) {
    .skin_449.page-ml
        .hp-navigation__box.box-draw-info
        .hp-navigation__box--btn
        a {
        font-size: 14px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-ml
        .hp-navigation__box.box-results.box-raffle
        .hp-navigation__info--box
        p {
        font-size: 18px;
        line-height: 1.5;
    }
}

@media (min-width: 480px) {
    .skin_449.page-ml
        .hp-navigation__box.box-results.box-raffle-temporary
        .hp-navigation__box--content
        > p {
        margin: 15px 0;
    }
}

.skin_449.page-ml .countdown.play .countdown__timer--boxes-row p {
    font-size: 10px;
}

@media (max-width: 479px) {
    .skin_449.page-ml .countdown.play .countdown__timer--boxes-row p {
        font-size: 8px;
    }
}

@media (max-width: 375px) {
    .skin_449.page-ml .header__button a {
        font-size: 10px;
    }
}

.skin_449.windows .betNum span {
    position: relative;
    top: -1px;
}

.skin_449.windows .hp-navigation__draws--number span {
    top: -1px;
}

.skin_449.windows .hp-navigation__info--box.small p {
    margin-top: 2px;
    margin-bottom: 3px;
}

.skin_449.linux.page-rtl .betNum span,
.skin_449.ubuntu.page-rtl .betNum span,
.skin_449.windows.page-rtl .betNum span {
    position: relative;
    top: 0;
}

@media (min-width: 992px) {
    .skin_449.linux.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span,
    .skin_449.ubuntu.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span,
    .skin_449.windows.page-rtl
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span {
        font-size: 70px;
        line-height: 50px;
        margin-bottom: 2px;
    }
}

.skin_449.linux.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span,
.skin_449.ubuntu.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span,
.skin_449.windows.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span {
    bottom: -15px;
}

@media (min-width: 576px) {
    .skin_449.linux.page-rtl
        .hp-navigation__box.box-latest
        .hp-navigation__inner--container
        h2,
    .skin_449.ubuntu.page-rtl
        .hp-navigation__box.box-latest
        .hp-navigation__inner--container
        h2,
    .skin_449.windows.page-rtl
        .hp-navigation__box.box-latest
        .hp-navigation__inner--container
        h2 {
        top: 0;
    }
}

.skin_449.linux.page-rtl
    .hp-navigation
    .countdown__pricebubble--content
    .million,
.skin_449.ubuntu.page-rtl
    .hp-navigation
    .countdown__pricebubble--content
    .million,
.skin_449.windows.page-rtl
    .hp-navigation
    .countdown__pricebubble--content
    .million {
    margin-top: 9px;
}

.skin_449.linux.page-rtl .hp-navigation__draws--number span,
.skin_449.ubuntu.page-rtl .hp-navigation__draws--number span,
.skin_449.windows.page-rtl .hp-navigation__draws--number span {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media (min-width: 480px) {
    .skin_449.page-ur .verification--rejected .verification__banner--text a {
        max-width: 250px;
    }
}

.skin_449.page-ur .modal--344-winner .modal__main--textwrapper h2 {
    font-size: 40px;
}

.skin_449.page-ur .modal--344-winner .modal__main--textwrapper h2 span {
    line-height: 90px;
    font-size: 45px;
}

.skin_449.page-ur .modal--344-winner .modal__main--textwrapper img:first-child {
    right: -30px;
    bottom: -25px;
}

.skin_449.page-ur .number-picker__picker--disabled span span {
    margin-right: 3px;
    margin-left: 0;
}

.skin_449.page-ur
    .number-picker__top--text
    h2
    small
    span.number-picker__currency-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media (min-width: 576px) {
    .skin_449.page-ur .hp-navigation__box.box-total {
        padding: 8px 38px;
    }
}

@media (min-width: 992px) {
    .skin_449.page-ur
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span {
        margin-bottom: 10px;
        line-height: 66px;
        font-size: 40px;
    }
}

@media (max-width: 991px) {
    .skin_449.page-ur
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span {
        line-height: 60px;
        font-size: 35px;
        margin-bottom: 7px;
    }
}

.skin_449.page-ur
    .hp-navigation__box.box-latest
    .hp-navigation__inner--container
    h2 {
    top: 8px;
}

.skin_449.page-ur
    .hp-navigation__box.box-latest
    .hp-navigation__inner--container
    h2
    span:first-child {
    font-size: 20px;
}

.skin_449.page-ur .hp-navigation__box.box-stories .hp-navigation__box--heading {
    margin-bottom: 0;
}

.skin_449.page-ur
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span {
    font-size: 30px;
    line-height: 60px;
    left: 75px;
    right: unset;
    bottom: 8px;
}

@media (min-width: 576px) {
    .skin_449.page-ur
        .hp-navigation__box.box-stories
        .hp-navigation__box--video {
        margin-bottom: 13px;
    }
}

@media (min-width: 576px) {
    .skin_449.linux .countdown__timer--valuewrapper,
    .skin_449.ubuntu .countdown__timer--valuewrapper,
    .skin_449.windows .countdown__timer--valuewrapper {
        height: 38px;
    }
}

@media (min-width: 992px) {
    .skin_449.linux .number-picker__top--text h2 span span,
    .skin_449.ubuntu .number-picker__top--text h2 span span,
    .skin_449.windows .number-picker__top--text h2 span span {
        top: -18px;
    }
}

.skin_449.linux .hp-navigation__box--btn a,
.skin_449.ubuntu .hp-navigation__box--btn a,
.skin_449.windows .hp-navigation__box--btn a {
    padding: 5px 22px 10px;
}

.skin_449.linux
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__box--btn
    a,
.skin_449.ubuntu
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__box--btn
    a,
.skin_449.windows
    .hp-navigation__box.box-results.box-raffle
    .hp-navigation__box--btn
    a {
    padding: 5px 15px;
}

.skin_449.linux .skin_449 .number-picker .betNum,
.skin_449.ubuntu .skin_449 .number-picker .betNum,
.skin_449.windows .skin_449 .number-picker .betNum {
    line-height: 33px;
}

.skin_449.linux.page-rtl .hp-navigation__info--box.small p,
.skin_449.ubuntu.page-rtl .hp-navigation__info--box.small p,
.skin_449.windows.page-rtl .hp-navigation__info--box.small p {
    margin-top: 2px;
    margin-bottom: 3px;
}

.skin_449.linux.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
.skin_449.ubuntu.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
.skin_449.windows.page-rtl
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child {
    bottom: -30px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-rtl
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child,
    .skin_449.ubuntu.page-rtl
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child,
    .skin_449.windows.page-rtl
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child {
        bottom: -50px;
    }
}

@media (min-width: 480px) {
    .skin_449.linux.page-rtl
        .modal--344-winner
        .modal__main--textwrapper
        h2
        span,
    .skin_449.ubuntu.page-rtl
        .modal--344-winner
        .modal__main--textwrapper
        h2
        span,
    .skin_449.windows.page-rtl
        .modal--344-winner
        .modal__main--textwrapper
        h2
        span {
        line-height: 60px;
    }
}

.skin_449.linux.page-ur
    .hp-navigation__box.box-latest
    .hp-navigation__inner--container
    h2,
.skin_449.ubuntu.page-ur
    .hp-navigation__box.box-latest
    .hp-navigation__inner--container
    h2,
.skin_449.windows.page-ur
    .hp-navigation__box.box-latest
    .hp-navigation__inner--container
    h2 {
    top: 8px;
}

@media (min-width: 992px) {
    .skin_449.linux.page-ur
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span,
    .skin_449.ubuntu.page-ur
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span,
    .skin_449.windows.page-ur
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span {
        margin-bottom: 10px;
        line-height: 65px;
        font-size: 35px;
    }
}

.skin_449.linux.page-ur
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span,
.skin_449.ubuntu.page-ur
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span,
.skin_449.windows.page-ur
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span {
    bottom: 8px;
}

.skin_449.linux.page-ur .modal--344-winner .modal__main--textwrapper h2 span,
.skin_449.ubuntu.page-ur .modal--344-winner .modal__main--textwrapper h2 span,
.skin_449.windows.page-ur .modal--344-winner .modal__main--textwrapper h2 span {
    line-height: 90px;
    font-size: 45px;
}

.skin_449.linux.page-ur
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
.skin_449.ubuntu.page-ur
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
.skin_449.windows.page-ur
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child {
    right: -30px;
    bottom: -25px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ur
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child,
    .skin_449.ubuntu.page-ur
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child,
    .skin_449.windows.page-ur
        .modal--344-winner
        .modal__main--textwrapper
        img:first-child {
        bottom: -15px;
    }
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .header__button a,
    .skin_449.ubuntu.page-ml .header__button a,
    .skin_449.windows.page-ml .header__button a {
        font-size: 13px;
    }
}

@media (max-width: 375px) {
    .skin_449.linux.page-ml .header__button a,
    .skin_449.ubuntu.page-ml .header__button a,
    .skin_449.windows.page-ml .header__button a {
        font-size: 7px;
    }
}

.skin_449.linux.page-ml .verification__banner--text h3,
.skin_449.linux.page-ml .verification__banner h2,
.skin_449.ubuntu.page-ml .verification__banner--text h3,
.skin_449.ubuntu.page-ml .verification__banner h2,
.skin_449.windows.page-ml .verification__banner--text h3,
.skin_449.windows.page-ml .verification__banner h2 {
    font-size: 23px;
}

.skin_449.linux.page-ml .verification__banner--text p,
.skin_449.ubuntu.page-ml .verification__banner--text p,
.skin_449.windows.page-ml .verification__banner--text p {
    font-size: 13px;
}

.skin_449.linux.page-ml .verification__banner--text a,
.skin_449.ubuntu.page-ml .verification__banner--text a,
.skin_449.windows.page-ml .verification__banner--text a {
    max-width: 300px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .verification__banner--text a,
    .skin_449.ubuntu.page-ml .verification__banner--text a,
    .skin_449.windows.page-ml .verification__banner--text a {
        font-size: 13px;
    }
}

.skin_449.linux.page-ml .verification--rejected .verification__banner--text a,
.skin_449.ubuntu.page-ml .verification--rejected .verification__banner--text a,
.skin_449.windows.page-ml
    .verification--rejected
    .verification__banner--text
    a {
    font-size: 10px;
}

@media (min-width: 576px) {
    .skin_449.linux.page-ml .number-picker__top,
    .skin_449.ubuntu.page-ml .number-picker__top,
    .skin_449.windows.page-ml .number-picker__top {
        padding: 23px 0 34px 34px;
    }
}

.skin_449.linux.page-ml .number-picker__top--text h2,
.skin_449.ubuntu.page-ml .number-picker__top--text h2,
.skin_449.windows.page-ml .number-picker__top--text h2 {
    font-size: 25px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .number-picker__top--text h2,
    .skin_449.ubuntu.page-ml .number-picker__top--text h2,
    .skin_449.windows.page-ml .number-picker__top--text h2 {
        font-size: 19px;
    }
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .number-picker__top--text h2,
    .skin_449.ubuntu.page-ml .number-picker__top--text h2,
    .skin_449.windows.page-ml .number-picker__top--text h2 {
        font-size: 17px;
    }
}

.skin_449.linux.page-ml .number-picker__top--text h2 small,
.skin_449.ubuntu.page-ml .number-picker__top--text h2 small,
.skin_449.windows.page-ml .number-picker__top--text h2 small {
    font-size: 25px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .number-picker__top--text h2 small,
    .skin_449.ubuntu.page-ml .number-picker__top--text h2 small,
    .skin_449.windows.page-ml .number-picker__top--text h2 small {
        font-size: 19px;
    }
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .number-picker__top--text h2 small,
    .skin_449.ubuntu.page-ml .number-picker__top--text h2 small,
    .skin_449.windows.page-ml .number-picker__top--text h2 small {
        font-size: 17px;
    }
}

.skin_449.linux.page-ml
    .number-picker__top--text
    h2
    span.number-picker__currency-wrapper,
.skin_449.ubuntu.page-ml
    .number-picker__top--text
    h2
    span.number-picker__currency-wrapper,
.skin_449.windows.page-ml
    .number-picker__top--text
    h2
    span.number-picker__currency-wrapper {
    font-size: 35px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper,
    .skin_449.ubuntu.page-ml
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper,
    .skin_449.windows.page-ml
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper {
        font-size: 19px;
    }
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper,
    .skin_449.ubuntu.page-ml
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper,
    .skin_449.windows.page-ml
        .number-picker__top--text
        h2
        span.number-picker__currency-wrapper {
        font-size: 17px;
    }
}

@media (min-width: 992px) {
    .skin_449.linux.page-ml .number-picker__top--text h2 span span,
    .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
    .skin_449.windows.page-ml .number-picker__top--text h2 span span {
        font-size: 55px;
        line-height: 47px;
    }
}

@media (max-width: 575px) {
    .skin_449.linux.page-ml .number-picker__top--text h2 span span,
    .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
    .skin_449.windows.page-ml .number-picker__top--text h2 span span {
        top: -27px;
    }
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .number-picker__top--text h2 span span,
    .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
    .skin_449.windows.page-ml .number-picker__top--text h2 span span {
        top: -24px;
        font-size: 45px;
        right: -42px;
    }
}

@media (max-width: 415px) {
    .skin_449.linux.page-ml .number-picker__top--text h2 span span,
    .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
    .skin_449.windows.page-ml .number-picker__top--text h2 span span {
        right: -42px;
    }
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .number-picker__top--text h2 span span,
    .skin_449.ubuntu.page-ml .number-picker__top--text h2 span span,
    .skin_449.windows.page-ml .number-picker__top--text h2 span span {
        font-size: 35px;
        right: -33px;
        top: -18px;
    }
}

@media (min-width: 576px) {
    .skin_449.linux.page-ml .number-picker__top--img,
    .skin_449.ubuntu.page-ml .number-picker__top--img,
    .skin_449.windows.page-ml .number-picker__top--img {
        bottom: -93px;
    }
}

.skin_449.linux.page-ml .number-picker__picker--name p,
.skin_449.ubuntu.page-ml .number-picker__picker--name p,
.skin_449.windows.page-ml .number-picker__picker--name p {
    font-size: 17px;
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .number-picker__picker--name p,
    .skin_449.ubuntu.page-ml .number-picker__picker--name p,
    .skin_449.windows.page-ml .number-picker__picker--name p {
        font-size: 12px;
    }
}

.skin_449.linux.page-ml .number-picker__picker--name span,
.skin_449.ubuntu.page-ml .number-picker__picker--name span,
.skin_449.windows.page-ml .number-picker__picker--name span {
    font-size: 17px;
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .number-picker__picker--name span,
    .skin_449.ubuntu.page-ml .number-picker__picker--name span,
    .skin_449.windows.page-ml .number-picker__picker--name span {
        font-size: 12px;
    }
}

.skin_449.linux.page-ml .number-picker.play .number-picker__bottle,
.skin_449.ubuntu.page-ml .number-picker.play .number-picker__bottle,
.skin_449.windows.page-ml .number-picker.play .number-picker__bottle {
    right: 120px;
}

.skin_449.linux.page-ml .number-picker.play .number-picker__picker--name p,
.skin_449.ubuntu.page-ml .number-picker.play .number-picker__picker--name p,
.skin_449.windows.page-ml .number-picker.play .number-picker__picker--name p {
    font-size: 13px;
}

@media (min-width: 479px) {
    .skin_449.linux.page-ml .hp-navigation__box--draw h2,
    .skin_449.ubuntu.page-ml .hp-navigation__box--draw h2,
    .skin_449.windows.page-ml .hp-navigation__box--draw h2 {
        font-size: 30px;
    }
}

@media (max-width: 415px) {
    .skin_449.linux.page-ml .hp-navigation__box--draw h2,
    .skin_449.ubuntu.page-ml .hp-navigation__box--draw h2,
    .skin_449.windows.page-ml .hp-navigation__box--draw h2 {
        font-size: 13px;
    }
}

@media (min-width: 480px) {
    .skin_449.linux.page-ml .hp-navigation__box--draw > p,
    .skin_449.ubuntu.page-ml .hp-navigation__box--draw > p,
    .skin_449.windows.page-ml .hp-navigation__box--draw > p {
        font-size: 17px;
    }
}

.skin_449.linux.page-ml
    .hp-navigation__box.box-draw-info
    .hp-navigation__box--btn
    a,
.skin_449.ubuntu.page-ml
    .hp-navigation__box.box-draw-info
    .hp-navigation__box--btn
    a,
.skin_449.windows.page-ml
    .hp-navigation__box.box-draw-info
    .hp-navigation__box--btn
    a {
    font-size: 17px;
}

@media (min-width: 992px) {
    .skin_449.linux.page-ml .hp-navigation__box.box-total,
    .skin_449.ubuntu.page-ml .hp-navigation__box.box-total,
    .skin_449.windows.page-ml .hp-navigation__box.box-total {
        padding: 37px 16px;
    }
}

.skin_449.linux.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2,
.skin_449.ubuntu.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2,
.skin_449.windows.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2 {
    font-size: 25px;
}

@media (min-width: 576px) {
    .skin_449.linux.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2,
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2,
    .skin_449.windows.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2 {
        font-size: 13px;
    }
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2,
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2,
    .skin_449.windows.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2 {
        font-size: 25px;
    }
}

.skin_449.linux.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
.skin_449.ubuntu.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span,
.skin_449.windows.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    span {
    font-size: 35px;
    line-height: 62px;
    margin-bottom: -14px;
}

@media (min-width: 576px) {
    .skin_449.linux.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span,
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span,
    .skin_449.windows.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span {
        font-size: 14px;
        line-height: 55px;
        margin-bottom: -12px;
    }
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span,
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span,
    .skin_449.windows.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        span {
        font-size: 30px;
    }
}

.skin_449.linux.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    small,
.skin_449.ubuntu.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    small,
.skin_449.windows.page-ml
    .hp-navigation__box.box-total
    .hp-navigation__box--content
    h2
    small {
    font-size: 21px;
}

@media (min-width: 576px) {
    .skin_449.linux.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        small,
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        small,
    .skin_449.windows.page-ml
        .hp-navigation__box.box-total
        .hp-navigation__box--content
        h2
        small {
        font-size: 14px;
    }
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading,
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading,
    .skin_449.windows.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading {
        margin-bottom: 0;
    }
}

.skin_449.linux.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2,
.skin_449.ubuntu.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2,
.skin_449.windows.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2 {
    font-size: 20px;
}

.skin_449.linux.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span,
.skin_449.ubuntu.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span,
.skin_449.windows.page-ml
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span {
    font-size: 30px;
    right: 38px;
    bottom: -8px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2
        span,
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2
        span,
    .skin_449.windows.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--heading
        h2
        span {
        line-height: 53px;
    }
}

@media (min-width: 576px) {
    .skin_449.linux.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--video,
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--video,
    .skin_449.windows.page-ml
        .hp-navigation__box.box-stories
        .hp-navigation__box--video {
        margin-bottom: 6px;
    }
}

.skin_449.linux.page-ml .hp-navigation__box.box-latest,
.skin_449.ubuntu.page-ml .hp-navigation__box.box-latest,
.skin_449.windows.page-ml .hp-navigation__box.box-latest {
    padding: 10px 20px 20px;
}

.skin_449.linux.page-ml
    .hp-navigation__box.box-latest
    h2
    span.box-latest--title,
.skin_449.ubuntu.page-ml
    .hp-navigation__box.box-latest
    h2
    span.box-latest--title,
.skin_449.windows.page-ml
    .hp-navigation__box.box-latest
    h2
    span.box-latest--title {
    font-size: 22px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .hp-navigation__box.box-latest h2 span:nth-child(2),
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-latest
        h2
        span:nth-child(2),
    .skin_449.windows.page-ml
        .hp-navigation__box.box-latest
        h2
        span:nth-child(2) {
        font-size: 27px;
    }
}

.skin_449.linux.page-ml .hp-navigation__box.box-latest h2 span:nth-child(3),
.skin_449.ubuntu.page-ml .hp-navigation__box.box-latest h2 span:nth-child(3),
.skin_449.windows.page-ml .hp-navigation__box.box-latest h2 span:nth-child(3) {
    font-size: 20px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .hp-navigation__box.box-latest h2 span:nth-child(3),
    .skin_449.ubuntu.page-ml
        .hp-navigation__box.box-latest
        h2
        span:nth-child(3),
    .skin_449.windows.page-ml
        .hp-navigation__box.box-latest
        h2
        span:nth-child(3) {
        height: unset;
    }
}

.skin_449.linux.page-ml .hp-navigation__box.box-results h3,
.skin_449.ubuntu.page-ml .hp-navigation__box.box-results h3,
.skin_449.windows.page-ml .hp-navigation__box.box-results h3 {
    font-size: 30px;
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .hp-navigation__box.box-results h3,
    .skin_449.ubuntu.page-ml .hp-navigation__box.box-results h3,
    .skin_449.windows.page-ml .hp-navigation__box.box-results h3 {
        font-size: 25px;
    }
}

.skin_449.linux.page-ml .hp-navigation__date p,
.skin_449.ubuntu.page-ml .hp-navigation__date p,
.skin_449.windows.page-ml .hp-navigation__date p {
    font-size: 17px;
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .hp-navigation__date p,
    .skin_449.ubuntu.page-ml .hp-navigation__date p,
    .skin_449.windows.page-ml .hp-navigation__date p {
        font-size: 11px;
    }
}

.skin_449.linux.page-ml .hp-navigation__info--box p,
.skin_449.ubuntu.page-ml .hp-navigation__info--box p,
.skin_449.windows.page-ml .hp-navigation__info--box p {
    font-size: 17px;
    line-height: 27px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .hp-navigation__info--box p,
    .skin_449.ubuntu.page-ml .hp-navigation__info--box p,
    .skin_449.windows.page-ml .hp-navigation__info--box p {
        font-size: 17px;
    }
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .hp-navigation__info--box p,
    .skin_449.ubuntu.page-ml .hp-navigation__info--box p,
    .skin_449.windows.page-ml .hp-navigation__info--box p {
        font-size: 14px;
    }
}

.skin_449.linux.page-ml .countdown__timer--boxes-row p,
.skin_449.ubuntu.page-ml .countdown__timer--boxes-row p,
.skin_449.windows.page-ml .countdown__timer--boxes-row p {
    font-size: 7px;
}

@media (max-width: 575px) {
    .skin_449.linux.page-ml .countdown__timer--boxes-row p,
    .skin_449.ubuntu.page-ml .countdown__timer--boxes-row p,
    .skin_449.windows.page-ml .countdown__timer--boxes-row p {
        font-size: 6px;
    }
}

.skin_449.linux.page-ml .countdown__timer--valuewrapper,
.skin_449.ubuntu.page-ml .countdown__timer--valuewrapper,
.skin_449.windows.page-ml .countdown__timer--valuewrapper {
    height: 47px;
}

@media (max-width: 575px) {
    .skin_449.linux.page-ml .countdown__timer--valuewrapper,
    .skin_449.ubuntu.page-ml .countdown__timer--valuewrapper,
    .skin_449.windows.page-ml .countdown__timer--valuewrapper {
        height: 51px;
    }
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .countdown__timer--valuewrapper,
    .skin_449.ubuntu.page-ml .countdown__timer--valuewrapper,
    .skin_449.windows.page-ml .countdown__timer--valuewrapper {
        height: 40px;
    }
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .countdown__timer--valuewrapper,
    .skin_449.ubuntu.page-ml .countdown__timer--valuewrapper,
    .skin_449.windows.page-ml .countdown__timer--valuewrapper {
        height: 25px;
    }
}

@media (min-width: 576px) {
    .skin_449.linux.page-ml .countdown.play .countdown__text--info > p,
    .skin_449.ubuntu.page-ml .countdown.play .countdown__text--info > p,
    .skin_449.windows.page-ml .countdown.play .countdown__text--info > p {
        font-size: 20px;
    }
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml
        .countdown.play
        .countdown__text--text
        > div:nth-child(2),
    .skin_449.ubuntu.page-ml
        .countdown.play
        .countdown__text--text
        > div:nth-child(2),
    .skin_449.windows.page-ml
        .countdown.play
        .countdown__text--text
        > div:nth-child(2) {
        margin-bottom: 10px;
    }
}

@media (min-width: 576px) {
    .skin_449.linux.page-ml .countdown.play .countdown__right--date h2,
    .skin_449.ubuntu.page-ml .countdown.play .countdown__right--date h2,
    .skin_449.windows.page-ml .countdown.play .countdown__right--date h2 {
        font-size: 30px;
    }
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .countdown.play .countdown__right--date h2,
    .skin_449.ubuntu.page-ml .countdown.play .countdown__right--date h2,
    .skin_449.windows.page-ml .countdown.play .countdown__right--date h2 {
        font-size: 18px;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: baseline;
        align-items: baseline;
    }
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .countdown.play .countdown__right--date h2 span,
    .skin_449.ubuntu.page-ml .countdown.play .countdown__right--date h2 span,
    .skin_449.windows.page-ml .countdown.play .countdown__right--date h2 span {
        margin-top: 0;
    }
}

.skin_449.linux.page-ml .countdown.play .countdown__timer--boxes-row p,
.skin_449.ubuntu.page-ml .countdown.play .countdown__timer--boxes-row p,
.skin_449.windows.page-ml .countdown.play .countdown__timer--boxes-row p {
    font-size: 8px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .countdown.homepage .countdown__timer--valuewrapper,
    .skin_449.ubuntu.page-ml
        .countdown.homepage
        .countdown__timer--valuewrapper,
    .skin_449.windows.page-ml
        .countdown.homepage
        .countdown__timer--valuewrapper {
        height: 32px;
    }
}

@media (max-width: 385px) {
    .skin_449.linux.page-ml .countdown.homepage .countdown__timer--valuewrapper,
    .skin_449.ubuntu.page-ml
        .countdown.homepage
        .countdown__timer--valuewrapper,
    .skin_449.windows.page-ml
        .countdown.homepage
        .countdown__timer--valuewrapper {
        height: 30px;
    }
}

.skin_449.linux.page-ml
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input,
.skin_449.ubuntu.page-ml
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input,
.skin_449.windows.page-ml
    .modal--numberpicker
    .modal__selectboxes--select
    .selectize
    .selectize-input {
    font-size: 7px !important;
}

.skin_449.linux.page-ml
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
.skin_449.ubuntu.page-ml
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child,
.skin_449.windows.page-ml
    .modal--344-winner
    .modal__main--textwrapper
    img:first-child {
    bottom: -25px;
}

@media (min-width: 480px) {
    .skin_449.linux.page-ml .modal--344-winner .modal__main--inner h2,
    .skin_449.ubuntu.page-ml .modal--344-winner .modal__main--inner h2,
    .skin_449.windows.page-ml .modal--344-winner .modal__main--inner h2 {
        font-size: 40px;
    }
}

.skin_449.linux.page-ml .modal--344-winner .modal__main--inner h2 span,
.skin_449.ubuntu.page-ml .modal--344-winner .modal__main--inner h2 span,
.skin_449.windows.page-ml .modal--344-winner .modal__main--inner h2 span {
    font-size: 30px;
}

@media (max-width: 479px) {
    .skin_449.linux.page-ml .modal--344-winner .modal__main--inner h2 span,
    .skin_449.ubuntu.page-ml .modal--344-winner .modal__main--inner h2 span,
    .skin_449.windows.page-ml .modal--344-winner .modal__main--inner h2 span {
        font-size: 23px;
    }
}

@media (max-width: 400px) {
    .skin_449.linux.page-ml .modal--344-winner .modal__main--inner h2 span,
    .skin_449.ubuntu.page-ml .modal--344-winner .modal__main--inner h2 span,
    .skin_449.windows.page-ml .modal--344-winner .modal__main--inner h2 span {
        font-size: 20px;
    }
}

.skin_449.linux.page-ml .modal--344-winner .modal__footer a,
.skin_449.linux.page-ml .modal--344-winner .modal__footer p,
.skin_449.ubuntu.page-ml .modal--344-winner .modal__footer a,
.skin_449.ubuntu.page-ml .modal--344-winner .modal__footer p,
.skin_449.windows.page-ml .modal--344-winner .modal__footer a,
.skin_449.windows.page-ml .modal--344-winner .modal__footer p {
    font-size: 17px;
}

.skin_449.linux.page-ml .modal--344-rp h1,
.skin_449.ubuntu.page-ml .modal--344-rp h1,
.skin_449.windows.page-ml .modal--344-rp h1 {
    font-size: 12px;
}

@media (max-width: 370px) {
    .skin_449.linux.page-ml .modal--344-rp h1,
    .skin_449.ubuntu.page-ml .modal--344-rp h1,
    .skin_449.windows.page-ml .modal--344-rp h1 {
        font-size: 10px;
    }
}

.skin_449.linux.page-ml .modal--344-rp h1 span,
.skin_449.ubuntu.page-ml .modal--344-rp h1 span,
.skin_449.windows.page-ml .modal--344-rp h1 span {
    margin-top: 10px;
    padding: 10px 10px 5px;
}

.skin_449.linux.page-ml .modal--344-rp .modal__main--text p,
.skin_449.ubuntu.page-ml .modal--344-rp .modal__main--text p,
.skin_449.windows.page-ml .modal--344-rp .modal__main--text p {
    font-size: 11px;
}

@media (max-width: 370px) {
    .skin_449.linux.page-ml .modal--344-rp .modal__main--text p,
    .skin_449.ubuntu.page-ml .modal--344-rp .modal__main--text p,
    .skin_449.windows.page-ml .modal--344-rp .modal__main--text p {
        font-size: 9px;
    }
}

.skin_449.linux.page-ml .modal--344-rp .modal__footer a,
.skin_449.ubuntu.page-ml .modal--344-rp .modal__footer a,
.skin_449.windows.page-ml .modal--344-rp .modal__footer a {
    font-size: 10px;
}

@media (max-width: 370px) {
    .skin_449.linux.page-ml .modal--344-rp .modal__footer a,
    .skin_449.ubuntu.page-ml .modal--344-rp .modal__footer a,
    .skin_449.windows.page-ml .modal--344-rp .modal__footer a {
        font-size: 8px;
    }
}

@media (min-width: 480px) {
    .skin_449.linux .modal--344-winner .modal__main--inner h2 span,
    .skin_449.ubuntu .modal--344-winner .modal__main--inner h2 span,
    .skin_449.windows .modal--344-winner .modal__main--inner h2 span {
        line-height: 40px;
    }
}

.skin_449.linux.page-hi .modal--344-rp .modal__footer a,
.skin_449.ubuntu.page-hi .modal--344-rp .modal__footer a,
.skin_449.windows.page-hi .modal--344-rp .modal__footer a {
    font-size: 17px;
}

@media (max-width: 370px) {
    .skin_449.linux.page-hi .modal--344-rp .modal__footer a,
    .skin_449.ubuntu.page-hi .modal--344-rp .modal__footer a,
    .skin_449.windows.page-hi .modal--344-rp .modal__footer a {
        font-size: 13px;
    }
}

.skin_449.android.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span,
.skin_449.linux.page-rtl
    .hp-navigation__box.box-stories
    .hp-navigation__box--heading
    h2
    span {
    bottom: -5px;
}

.skin_449.page-rtl .priceproduct1 {
    max-width: 175px;
}

.skin_449.page-rtl
    .hp-navigation__box.box-draw-info
    .countdown__timer_wrapper:nth-child(2)
    .countdown__timer--priceproduct
    h2 {
    max-width: 155px;
}

.skin_449.page-rtl .hp-navigation__box.box-total {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
}

.skin_449.page-rtl .countdown__timer--wrapper > div:not(:last-child) {
    margin-left: 15px;
    margin-right: 0;
}

.skin_449.page-rtl .countdown.play .countdown__timer .base-timer_wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.skin_449.page-rtl .countdown__pricebubble--content .million {
    color: var(--iq-yellow);
}

.skin_449.page-rtl .hp-navigation__info--price {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.skin_449.page-rtl .countdown.play .countdown__pricebubble--content .million {
    color: var(--iq-yellow);
}

.skin_449.page-rtl
    .hp-navigation__box.box-results.box-raffle-temporary
    .hp-navigation__info--box
    p:nth-child(2) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.skin_449.page-rtl .number-picker__top--text h2 {
    max-width: unset !important;
}

.skin_449.page-rtl .previous-drawn__text h2 {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;

    -webkit-box-pack: end;
    justify-content: flex-end;
}

.skin_449.page-rtl .previous-drawn__text h2 span {
    margin-left: 10px;
}

.skin_449.page-rtl .previous-drawn__text .heading_sec {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.skin_449.page-rtl .hp-navigation__info--box.small p:nth-child(2),
.skin_449.page-rtl .previous-drawn__text .heading_sec {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    display: -webkit-box;
    display: flex;
}

.skin_449.page-rtl .hp-navigation__info--box.small p:nth-child(2) span {
    margin-right: 7px;
    margin-left: 0;
}

.skin_449.page-rtl .countdown.hp.date {
    -webkit-box-pack: center;
    justify-content: center;
}

.skin_449.page-rtl .hp-navigation__info--box .mtch {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.skin_449.page-rtl .number-picker.homepage .number-picker--btn {
    padding-top: 3px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__timer_wrapper {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .skin_449.page-rtl .number-picker.homepage .number-picker--btn button,
    .skin_449.page-rtl
        .number-picker.homepage
        .number-picker--btn
        button:disabled {
        font-size: 14px;
        height: 35px;
    }
}

.skin_449.page-rtl .countdown.play .countdown__pricebubble_wrapper {
    display: -webkit-box;
    display: flex;
}

.skin_449.page-rtl .countdown__timer,
.skin_449.page-rtl .countdown__timer--boxes-row p,
.skin_449.page-rtl .countdown__timer--value {
    -webkit-transform: none;
    transform: none;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown__timer .base-timer_wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
    }
}

.skin_449.page-rtl .countdown__timer--wrapper {
    -webkit-box-orient: initial !important;
    -webkit-box-direction: initial !important;
    flex-direction: row !important;
}

.skin_449.page-rtl .countdown.play .countdown__pricebubble--content h3 {
    font-size: 55px;
    line-height: 40px;
}

@media (max-width: 479px) {
    .skin_449.page-rtl .countdown.play .countdown__pricebubble--content h3 {
        font-size: 50px;
    }
}

.skin_449.page-rtl
    .countdown.play
    .countdown__pricebubble--content
    h5:first-of-type {
    font-size: 25px;
}

.skin_449.page-rtl
    .countdown.play
    .countdown__pricebubble--content
    h5:last-of-type {
    font-size: 17px;
    color: var(--iq-pink);
    font-family: Product-Sans-Regular;
}
@media (max-width: 1200px) {
    .skin_449.page-rtl
        .countdown.play
        .countdown__pricebubble--content
        h5:last-of-type {
        font-size: 26px;
    }
}

@media (min-width: 1201px) {
    .skin_449.page-rtl
        .countdown.play
        .countdown__pricebubble--content
        h5:last-of-type {
        font-size: 34px;
    }
}

@media (max-width: 479px) {
    .skin_449.page-rtl .countdown__timer.desktop .date {
        font-size: 21px;
    }
}

.skin_449.page-rtl .hp-navigation .countdown__pricebubble {
    margin-right: 0;
}

.skin_449.page-rtl .hp-navigation .countdown__pricebubble--content {
    position: relative;
}

@media (max-width: 479px) {
    .skin_449.page-rtl .hp-navigation .countdown__pricebubble--content h3 {
        font-size: 27px;
    }
}

.skin_449.page-rtl .hp-navigation .countdown__pricebubble--content h5 {
    color: var(--iq-white);
}

.skin_449.page-rtl
    .hp-navigation
    .countdown__pricebubble--content
    h5:first-of-type {
    font-size: 25px;
    line-height: 25px;
    color: var(--iq-black);
}

.skin_449.page-rtl
    .hp-navigation
    .countdown__pricebubble--content
    h5:last-of-type {
    font-size: 17px;
}

.skin_449.page-rtl
    .hp-navigation__box.box-draw-info
    .countdown__pricebubble--content
    h5:nth-child(2) {
    color: var(--iq-yellow);
}

.skin_449.page-rtl .countdown.hp.date {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.skin_449 .countdown.hp.date p span {
    margin: 0 4px;
}

.skin_449.page-rtl .countdown.play .countdown__timer_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__timer_wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: end;
        align-items: flex-end;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__timer--pricebubble_wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
    }
}

.skin_449.page-rtl .countdown.play .countdown__timer .date {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

.skin_449.page-rtl .countdown.play .countdown__timer .date span {
    margin-right: 5px;
}

.skin_449.page-rtl .countdown.play .countdown__timer .date span:nth-child(2) {
    margin-left: 5px;
    margin-right: 0;
}

.skin_449.page-rtl .countdown.play .countdown__timer--priceproduct {
    text-align: right;
    margin-right: 20px;
}

.skin_449.page-rtl .countdown.play .countdown__timer--priceproduct p {
    max-width: unset;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__timer--priceproduct p {
        max-width: 180px;
        margin: 0 auto;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__timer--priceproduct h2 {
        margin: 12px 13px 2px 10px;
        text-align: right;
        max-width: 155px;
        font-size: 29px;
        line-height: 32px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__timer--priceproduct p {
        text-align: right;
        margin-bottom: 10px;
        margin-top: 7px;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__timer--priceproduct {
        text-align: center;
        margin-right: 0;
    }
}

.skin_449.page-rtl .countdown.play .countdown__timer--boxes-row p {
    font-size: 14px;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__timer--boxes-row p {
        margin-top: 7px;
        font-size: 11px;
    }
}

@media (max-width: 380px) {
    .skin_449.page-rtl .countdown.play .countdown__timer--boxes-row p {
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__pricebubble {
        margin-right: 0;
    }
}

.skin_449.page-rtl .countdown.play .countdown__pricebubble_wrapper {
    margin-left: 25px;
    margin-right: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__pricebubble_wrapper {
        margin-left: 0;
    }
}

@media (max-width: 575px) {
    .skin_449.page-rtl .countdown.play .countdown__pricebubble--content h3 {
        font-size: 44px;
    }
}

.skin_449.page-rtl .countdown.play .countdown__pricebubble--content h5 {
    margin-bottom: unset !important;
}

@media (max-width: 575px) {
    .skin_449.page-rtl
        .countdown.play
        .countdown__pricebubble--content
        .million {
        color: #ffe765;
        font-size: 25px;
    }
}

.skin_449.page-rtl .previous-drawn .table-main table tr th {
    padding: 10px 18px 10px 19px;
}

@media (max-width: 380px) {
    .skin_449.page-rtl .previous-drawn .table-main table thead tr th {
        font-size: 14px;
    }
}

.skin_449.page-rtl .previous-drawn .table-main table thead tr th:nth-child(2) {
    text-align: right;
    padding: 15px 13px 10px 0;
}

@media (max-width: 420px) {
    .skin_449.page-rtl .ticket-page .ticket__img--img {
        width: 210px;
    }
}

.skin_449 .ticket-page .ticket__img {
    background-image: none;
    width: unset;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.skin_449 .ticket-page .ticket__img--img {
    background-image: url(../img/Iraq-web_ticket-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 300px;
    max-width: 100%;
    padding-bottom: 58px;
    margin: 35px 0 0;
}

@media (max-width: 420px) {
    .skin_449 .ticket-page .ticket__img--img {
        width: 210px;
    }
}

.skin_449 .ticket-page .ticket__img h1 {
    color: var(--iq-pink);
    font-size: 27px;
}

@media (max-width: 420px) {
    .skin_449 .ticket-page .ticket__img h1 {
        font-size: 22px;
    }
}

.skin_449 .ticket-page .ticket--wrapper {
    background-repeat: no-repeat;
}

/*# sourceMappingURL=app.min.css.map */
.m-b-0 {
    margin-bottom: 0;
}

.page-heading {
    font-size: 19px !important;
}

.draw-mobile-data-div {
    border: 1px solid var(--iq-orange);
    border-top: none;
}

.draw-mobile-heading-div {
    border: 1px solid var(--iq-orange);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 0px;
    text-align: center;
}

.draw-mobile-heading-div-font {
    font-size: 17px !important;
}

.active-div {
    background-color: var(--iq-orange);
}

@media (max-width: 992px) {
    .skin_449 .box-draw-info .hp-navigation__box--content {
        max-width: 70%;
        margin: 0 auto;
    }

    .number-picker__picker {
        margin: 0 25px 14px 0;
    }

    .hp-navigation__info--price {
        font-size: 20px !important;
    }

    .skin_449 .number-picker__bottle--value .value-button,
    .skin_449 .number-picker__bottle--value .value-button img {
        width: 28px !important;
        height: 28px !important;
    }

    .jumbotron {
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .skin_449 .box-results .hp-navigation__box--content {
        max-width: 70%;
        margin: 0 auto;
    }

    .draw-mobile-heading-div-font {
        font-size: 14px !important;
    }

    .draw-mobile-data-div .flex.space-x-2 span {
        height: 28px !important;
        width: 28px !important;
        line-height: 21px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 2px !important;
    }
}

@media (max-width: 575px) {
    #form_add-favourite input,
    #form_add-favourite input::placeholder {
        font-size: 13px;
        height: 40px;
    }
    .draw-mobile-data-div .row.row_padding p,
    .draw-mobile-data-div .row.row_padding a,
    .draw-mobile-data-div .row.row_padding span {
        font-size: 13px !important;
    }

    .draw-mobile-data-div #upcomingDraw .col-5.space-y-2 {
        max-width: 200px !important;
    }
    .draw-mobile-data-div #upcomingDraw .col-5.space-y-2 p {
        line-height: 28px;
    }

    #form_add-favourite input {
        padding: 10px 15px;
    }

    .error-text p,
    .remodal form .form-group__buttons .btn {
        font-size: 12px;
    }

    .skin_449 .box-draw-info .hp-navigation__box--content,
    .skin_449 .box-results .hp-navigation__box--content {
        max-width: 100%;
    }

    .remodal form .form-group__buttons .btn {
        height: 35px;
        padding: 10px;
    }

    .jumbotron .container {
        padding: 0;
    }

    .header__inner {
        padding: 15px 0;
    }

    .page-heading {
        font-size: 16px !important;
    }
}
@media (max-width: 400px) {
    .draw-mobile-data-div .row.row_padding p,
    .draw-mobile-data-div .row.row_padding a,
    .draw-mobile-data-div .row.row_padding span {
        font-size: 12px !important;
    }
}

.box-shadow-none {
    box-shadow: none !important;
}
